import React, { Component } from 'react';
import ProjectCard from './ProjectCard/ProjectCard';
import ProjectSideBar from './Sidebar/Sidebar';
import { Form, FormControl } from 'react-bootstrap';
import MapsBar from '../MapsBar/MapsBar'
import DetailsPane from './DetailsPane'
import moment from 'moment'
import axios from 'axios';
import './Projects.css';
import { APIURL } from '../../config'
import { withStyles } from '@mui/styles';
import Tooltip from "@mui/material/Tooltip";
import { TextField, InputAdornment, IconButton, Button, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Redirect } from 'react-router-dom';

const styles = {
    projList1: {
        height: 'calc(100vh - 249px)',
        width: "100%",
        overflowY: 'auto',
        scrollbarGutter: 'stable',
    },
    projList2: {
        height: 'calc(100vh - 545px)',
        width: "100%",
        overflowY: 'auto',
        scrollbarGutter: 'stable',
    }
};

class Projects extends Component {
    state = {
        showProjectDetails: false,
        projects: [],
        searchTerm: '',
        projectSearchResults: [],
        dropDowns: null,
        isLoading: true,
        current_project: null,
        project_type: 'new', // new, old, initial
        ongoing_project: [],
        showMap: false,
        isSupport: this.props.user?.comp_settings.find(setting => setting.feature_id === 101 && setting.feature_value === 'primaryBusinessType') ? true : false,
        tabName: this.props.companySettings.length > 1 && this.props.companySettings.filter(setting => [101, 102].includes(setting.feature_id)).length > 0 ? 'Plan' : this.props.companySettings.length > 1 && this.props.companySettings.filter(setting => [104].includes(setting.feature_id)).length > 0 ? 'Cases' : 'Projects',
        loadingAllProjects: true,
        redirectToNew: false,
    }

    async componentDidMount() {
        this.props.checkTokenExpiry()
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }

        // queryType: 0 --> Fetch only 25 Projects
        // queryType: 1 --> Fetch rest of the Projects
        let pData = (this.props.user.type === 'Admin') ?
            await axios.get(APIURL + "/project/v1", { params: { company_id: this.props.comp_id, queryType: 0 } }) :
            await axios.get(APIURL + "/project/v1", { params: { company_id: this.props.comp_id, emp_id: this.props.user.emp_id, type: (this.props.user.type === 'Manager') ? 'Manager' : 'Fieldworker' } })

        let projList = pData.data
        let ongoing_project = projList.filter((proj) => proj.pro_starts_on && proj.pro_ends_on && !moment(proj.pro_ends_on).isBefore(moment()))

        this.setState({
            projects: projList,
            projectSearchResults: ongoing_project,
            ongoing_project,
            isLoading: false,
            current_project: ongoing_project.length > 0 ? ongoing_project[0].pro_id : null
        })

        if (this.props.user.type === 'Admin') {
            let pData = await axios.get(APIURL + "/project/v1", { params: { company_id: this.props.comp_id, queryType: 1 } })
            let projList = pData.data
            let ongoing_project = projList.filter((proj) => proj.pro_starts_on && proj.pro_ends_on && !moment(proj.pro_ends_on).isBefore(moment()))
            this.setState({
                projects: [...this.state.projects, ...projList],
                projectSearchResults: [...this.state.projectSearchResults, ...ongoing_project],
                ongoing_project: [...this.state.ongoing_project, ...ongoing_project],
                loadingAllProjects: false,
            })
        } else {
            this.setState({
                loadingAllProjects: false,
            })
        }
    }

    showProjectDetails = (key) => {
        this.setState({
            current_project: key
        })
    }

    onBackClicked = () => {
        this.setState({
            showProjectDetails: false,
            current_project: null
        })
    }

    showProjectList = () => {
        if (this.state.ongoing_project) {
            return this.state.projectSearchResults.map(
                projects => <ProjectCard
                    {...this.props}
                    key={projects.pro_id}
                    id={projects.pro_id}
                    title={projects.pro_title}
                    start={projects.pro_starts_on || ''}
                    end={projects.pro_ends_on}
                    customerName={projects.customer_details[0].cus_name}
                    location={projects.locations ? projects.locations[0] : {}}
                    onClick={this.showProjectDetails}
                    onBack={this.onBackClicked}
                    selected={this.state.current_project === projects.pro_id ? 'active' : 'inactive'}
                />
            )
        } else {
            return <div>Loading....</div>
        }
    }

    generateMapsData = () => {
        let mapsData = this.state.projects.length !== 0 ? this.state.projects.map((pushPinData) => {
            if (!!pushPinData.locations) {
                return {
                    "location": [+pushPinData.locations[0].loc_lat || 0, +pushPinData.locations[0].loc_lon || 0],
                    "addHandler": "mouseover",
                    "infoboxOption": { title: pushPinData.customer_details[0].cus_name, description: pushPinData.locations[0].loc_addr_line1 + ',' + pushPinData.locations[0].loc_addr_line2 + "," + pushPinData.locations[0].loc_city + "," + pushPinData.locations[0].loc_state + "," + pushPinData.locations[0].loc_zip },
                    "pushPinOption": { color: 'red', title: pushPinData.pro_title }
                }
            } else { return null }

        }).filter((mp) => !!mp) : []
        return mapsData
    }

    searchProjHandler = (e) => {
        // e.preventDefault()
        var searchQuery = e.target.value
        // console.log(searchQuery)
        this.setState({ searchTerm: searchQuery })
    }

    searchProjResults = (e) => {
        e.preventDefault()
        var searchTerm = new RegExp(this.state.searchTerm, 'i')
        console.log(searchTerm)
        let projectSearchResults = this.state.ongoing_project.map((proj) => {
            if (searchTerm.exec(proj.pro_title) || searchTerm.exec(proj.customer_details[0].cus_name)) {
                return proj
            }
            else {
                return null
            }
        }).filter((proj) => !!proj)

        this.setState({ projectSearchResults: projectSearchResults })
    }

    filterProjects = (screen) => {
        if ((screen === 'old' && this.state.project_type === 'old') || (screen === 'new' && this.state.project_type === 'new') || (screen === 'initial' && this.state.project_type === 'initial') || (screen === 'renewal' && this.state.project_type === 'renewal')) {
            return
        } else {
            let newProjects = [];
            let oldProjects = [];
            let initialProjects = [];
            let renewalProjects = [];
            let pData = []

            this.state.projects.forEach((proj) => {

                console.log(proj.pro_title)

                console.log(proj.pro_ends_on)
                console.log(moment(proj.pro_ends_on.replace(/Z/g, "")).format('LLL'))

                if (moment(proj.pro_starts_on).isSameOrBefore("1900-01-01") && moment(proj.pro_ends_on.replace(/Z/g, "")).isSame("1900-01-02", 'day')) {
                    renewalProjects.push(proj)
                } else if (!proj.pro_starts_on || !proj.pro_ends_on || moment(proj.pro_starts_on).isSameOrBefore("1900-01-01")) {
                    initialProjects.push(proj)
                } else if (!moment(proj.pro_ends_on).isBefore(moment())) {
                    newProjects.push(proj)
                } else if (moment(proj.pro_ends_on).isBefore(moment())) {
                    oldProjects.push(proj)
                }
            })

            if (screen === 'new') {
                pData = newProjects
            } else if (screen === 'old') {
                pData = oldProjects
            } else if (screen === 'initial') {
                pData = initialProjects
            } else if (screen === 'renewal') {
                pData = renewalProjects
            }

            this.setState({ searchTerm: '', ongoing_project: pData, projectSearchResults: pData, project_type: screen, current_project: pData.length > 0 ? pData[0].pro_id : null })
        }
    }

    sortBy = (param) => {
        let pData = this.state.projectSearchResults
        if (param === 'title') {
            // sort by title
            pData.sort((projA, projB) => ('' + projA.pro_title).localeCompare(projB.pro_title))
        } else {
            // sort by date
            pData.sort((projA, projB) => moment(projB.pro_starts_on) - moment(projA.pro_starts_on))
        }
        this.setState({ projectSearchResults: pData })
    }

    toggleMap = () => {
        this.setState(prev => ({ showMap: !this.state.showMap }));
    };

    render() {
        const { classes } = this.props;
        let dashBoard = null

        const onGoingTab =
            <div style={{ opacity: '90%' }} className={`project_button ${this.state.project_type == 'new' ? 'active' : 'inactive'}`} onClick={() => this.filterProjects('new')}>
                <div>On Going {this.state.tabName}</div>
                {this.state.project_type == 'new' && <div className='project_button_number'>{this.state.projectSearchResults.length}</div>}
            </div>

        const completedTab =
            <div style={{ opacity: '90%' }} className={`project_button ${this.state.project_type == 'old' ? 'active' : 'inactive'}`} onClick={() => !this.state.loadingAllProjects && this.filterProjects('old')}>
                <div>Completed {this.state.tabName}</div>
                {this.state.project_type == 'old' && <div className='project_button_number'>{this.state.projectSearchResults.length}</div>}
            </div>

        const initialTab =
            <div style={{ opacity: '90%' }} className={`project_button ${this.state.project_type == 'initial' ? 'active' : 'inactive'}`} onClick={() => !this.state.loadingAllProjects && this.filterProjects('initial')}>
                <div>Initial {this.state.tabName}</div>
                {this.state.project_type == 'initial' && <div className='project_button_number'>{this.state.projectSearchResults.length}</div>}
            </div>

        const renewalTab =
            <div style={{ opacity: '90%' }} className={`project_button ${this.state.project_type == 'renewal' ? 'active' : 'inactive'}`} onClick={() => !this.state.loadingAllProjects && this.filterProjects('renewal')}>
                <div>Late Renewal {this.state.tabName}</div>
                {this.state.project_type == 'renewal' && <div className='project_button_number'>{this.state.projectSearchResults.length}</div>}
            </div>

        if (!this.state.isLoading) {
            if (this.state.showProjectDetails) {
                dashBoard = (<ProjectSideBar
                    comp_id={this.props.comp_id}
                    user={this.props.user}
                    history={this.props.history}
                    onBack={this.onBackClicked}
                    project_details={this.state.projects.find(project => project.pro_id === this.state.current_project)} />)
            } else {
                dashBoard = (
                    <div classname="projectsPage-mainDiv" style={{ display: 'flex', justifyContent: 'start', maxHeight: '100%', width: '100%' }}>
                        <div style={{ width: '45%', height: '100%', marginRight: '10px' }}>
                            <div className="row">
                                <div className="col-12">
                                    <MapsBar mapsDta={this.generateMapsData()}
                                        hideMap={this.state.showMap} toggleMap={this.toggleMap} currentProjectLocation={this.state.projects.find(project => project.pro_id === this.state.current_project)?.locations?.[0] || {}}
                                    />
                                </div>
                            </div>                            <Divider />
                            <div>

                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <TextField
                                        onChange={this.searchProjHandler}
                                        value={this.state.searchTerm}
                                        placeholder="Search"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton type="submit" onClick={this.searchProjResults}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        style={{ width: '70%' }}
                                    />
                                    <div className="dropdown" style={{ marginTop: '20px', marginBottom: '10px' }}>
                                        <button style={{ outline: 'none', color: '#1976D2', border: '1px solid #1976D2', height: '38px' }} className="btn dropdown-toggle bg-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span><i className="fas fa-sort-amount-up"></i></span> SORT BY
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            <button onClick={() => this.sortBy('title')} className="dropdown-item" type="button">Project Title</button>
                                            <button onClick={() => this.sortBy('date')} className="dropdown-item" type="button">Project Date</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ minHeight: '500px' }}>
                                    <div className="col-md-8 col-sm-12 order-md-1 order-sm-12">
                                        <div className="projectListHeader">
                                            <div className='project_button_group'>
                                                {onGoingTab}
                                                {this.state.loadingAllProjects ?
                                                    <Tooltip title={<div style={{ fontSize: 15 }}>Loading all {this.state.tabName.toLowerCase()}, please wait...</div>}>
                                                        {completedTab}
                                                    </Tooltip>
                                                    :
                                                    completedTab
                                                }
                                                {this.state.isSupport ?
                                                    this.state.loadingAllProjects ?
                                                        <Tooltip title={<div style={{ fontSize: 15 }}>Loading all {this.state.tabName.toLowerCase()}, please wait...</div>}>
                                                            {initialTab}
                                                        </Tooltip>
                                                        :
                                                        initialTab
                                                    :
                                                    null
                                                }
                                                {this.state.loadingAllProjects ?
                                                    <Tooltip title={<div style={{ fontSize: 15 }}>Loading all {this.state.tabName.toLowerCase()}, please wait...</div>}>
                                                        {renewalTab}
                                                    </Tooltip>
                                                    :
                                                    renewalTab
                                                }
                                            </div>
                                        </div>

                                        <div
                                            className={this.state.showMap ?
                                                classes.projList1
                                                :
                                                classes.projList2}
                                        >
                                            {this.showProjectList()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '55%', height: '100%' }}>
                            {this.state.projectSearchResults.length !== 0 && <DetailsPane
                                status={this.state.project_type === 'new' ? 'Ongoing' :
                                    this.state.project_type === 'old' ? 'Older' : 'Initial'}
                                comp_id={this.props.comp_id}
                                user={this.props.user}
                                history={this.props.history}
                                project_details={this.state.projects.find(project => project.pro_id === this.state.current_project)}
                                updateProject={async () => {
                                    let projData = (this.props.user.type === 'Admin') ?
                                        await axios.get(APIURL + "/project/v1", { params: { company_id: this.props.comp_id } }) :
                                        await axios.get(APIURL + "/project/v1", { params: { company_id: this.props.comp_id, emp_id: this.props.user.emp_id, type: (this.props.user.type === 'Manager') ? 'Manager' : 'Fieldworker' } })
                                    let projList = projData.data
                                    this.setState({
                                        projects: projList
                                    })
                                }}
                                companySettings={this.props.companySettings}
                                checkTokenExpiry={this.props.checkTokenExpiry}
                            />}
                            {this.state.projectSearchResults.length === 0 &&
                                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '500px', marginTop: 10 }}>
                                    <img src={this.state.projectSearchResults.length === 0 ? './nosearchresult.png' : './projectgraphic.png'} alt='projectgraphic' style={{ width: '15rem', height: '10rem', margin: 10 }} />
                                    <h5 style={{ fontFamily: 'Lato', marginTop: 48, marginBottom: 30 }} className='text-center'>{this.state.projects.length !== 0 ? 'No Projects Found' : this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator' ? 'You are not assigned any project yet. Please contact your manager.' : 'You dont have any Projects yet'} </h5>
                                    <p style={{ fontFamily: 'Lato', fontSize: 12, width: '70%' }} className='text-muted text-center text-wrap'>Project's related information includes Overviews, Tasks , Employees, Documents, Comments and Contacts will show here once you create your projects. <br />On the left side, you will see a list of your Ongoing or Previous projects.</p>
                                    <button disabled={this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator'} style={{ height: 30 }} className={`btn btn-sm btn-primary w-25 ${this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator' ? 'd-none' : ''}`} onClick={(e) => { this.setState({ redirectToNew: true }); this.props.changeScreen('new', 'proj') }}>Create a Project</button>
                                </div>
                            }
                        </div>
                        {this.state.redirectToNew &&
                            <Redirect to="/new" />
                        }
                    </div>
                )
            }
        } else {
            dashBoard = (
                <div className="d-flex flex-row justify-content-center">
                    <div style={{ color: '#3FA7D6', height: '10rem', width: '10rem', marginTop: 120 }} className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        return (
            <div style={{ height: 'calc(100vh - 90px)', width: '100%', overflow: 'hidden' }}>
                {dashBoard}
            </div>
        )
    }
}

export default withStyles(styles)(Projects);
