import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function CustomerDashboard(props) {
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [iframeUrl, setiframeUrl] = useState('');
    const [isIframeLoading, setIsIframeLoading] = useState(false);

    useEffect(() => {
        fetchCustomerData();
    }, []);

    useEffect(() => {
        if (selectedCustomer) {
            fetchMetabaseData();
        }
    }, [selectedCustomer]);

    const fetchCustomerData = async () => {
        axios
            .get(APIURL + "/customers/v2/associated", {
                params: {
                    company_id: props.user.comp_id,
                    employee_id: props.user.emp_id
                },
            })
            .then((response) => {
                setCustomerList(response?.data.filter((cus) => cus.cus_status === 'Active') || []);
            });
    };

    const fetchMetabaseData = async () => {
        setIsIframeLoading(true);

        axios
            .get(APIURL + "/metabase/customerDashboard", {
                params: { customerID: selectedCustomer },
            })
            .then((response) => {
                setiframeUrl(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    };

    return (
        <div>
            <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10, width: '75vw' }}>
                <Autocomplete
                    id="client-select"
                    options={customerList}
                    getOptionLabel={(option) => option.cus_name}
                    onChange={(event, value) => setSelectedCustomer(value?.cus_id ? value.cus_id : null)}
                    renderOption={(props, option) => (
                        <li {...props} key={option.cus_id}>
                            {option.cus_name}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Select Client" />}
                    sx={{ width: 250 }}
                    size="small"
                />
            </div>
            {selectedCustomer &&
                <div style={{ marginLeft: 10, marginRight: 10, width: '75vw', marginTop: '-60px' }}>
                    <iframe
                        src={iframeUrl}
                        style={{ width: '100%', height: '80vh', border: 'none' }}
                        allowTransparency
                        onLoad={handleIframeLoad}
                    />
                </div>
            }
        </div>
    );
};