import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Chip,
    Stack,
    Modal,
    TextField,
    Button,
    ListItemText,
    Autocomplete,
    IconButton,
    Grid
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Resource = (props) => {
    const [resources, setResources] = useState([]);
    const [originalResources, setOriginalResources] = useState([]);
    const [locations, setLocations] = useState([]);
    const [contacts, setContacts] = useState([]); // New state for contacts
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);

    const [resourceName, setResourceName] = useState('');
    const [capacity, setCapacity] = useState('');
    const [location, setLocation] = useState(null);
    const [notes, setNotes] = useState('');
    const [contact, setContact] = useState(null); // New state for selected contact
    const [editMode, setEditMode] = useState(false); // New state for edit mode
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState({
        resourceName: false,
        location: false,
        capacity: false,
        contact: false // No longer required
    });
    const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
    const [newLocationAddress, setNewLocationAddress] = useState('');
    const [newLocationAddressObj, setNewLocationAddressObj] = useState(null);
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [newLocationAssociationDate, setNewLocationAssociationDate] = useState(new Date());
    const [newLocationPhone, setNewLocationPhone] = useState('');
    const [newLocationLocationStatus, setNewLocationLocationStatus] = useState('');
    const [newLocationLocationType, setNewLocationLocationType] = useState('');

    const [locStatusOptions, setLocStatusOptions] = useState([]);
    const [locTypeOptions, setLocTypeOptions] = useState([]);

    const defaultLocStatus = ["Active", "Inactive"];
    const defaultLocType = ["Type A", "Type B"];

    useEffect(() => {
        if (isAddLocationModalOpen) {
            axios.get(APIURL + "/dropdowns/dropdownType/locStatus", { params: { comp_id: props.comp_id } })
                .then(response => { setLocStatusOptions(response.data); })
                .catch(err => { console.error("Error fetching locStatus", err); setLocStatusOptions([]); });
            axios.get(APIURL + "/dropdowns/dropdownType/locType", { params: { comp_id: props.comp_id } })
                .then(response => { setLocTypeOptions(response.data); })
                .catch(err => { console.error("Error fetching locType", err); setLocTypeOptions([]); });
        }
    }, [isAddLocationModalOpen, props.comp_id]);

    const validate = () => {
        let errors = {
            resourceName: resourceName === '',
            location: location === null,
            capacity: capacity === ''
            // Removed contact validation
        };
        setErrors(errors);
        setSubmitted(true);
        return !Object.values(errors).some((err) => err);
    };

    const handleClose = () => {
        setOpen(false);
        setEditMode(false);
    };

    useEffect(() => {
        setResources(props.resources || []);
        setOriginalResources(props.resources || []);
        setLocations(props.locations || []);
        // Fetch or define your contacts here
        setContacts(props.contacts || []);
    }, [props.resources, props.locations, props.contacts]); // Add contacts to the dependency array

    useEffect(() => {
        if (!open) {
            setResourceName('');
            setCapacity('');
            setLocation(null);
            setNotes('');
            setContact(null); // Reset contact
            setSubmitted(false);
            setErrors({
                resourceName: false,
                location: false,
                capacity: false,
                contact: false // Reset contact error
            });
        }
    }, [open]);

    useEffect(() => {
        // On component mount, fetch default address suggestions (e.g. "New York")
        handleNewAddressSuggestions("New York");
    }, []);

    const addResource = async () => {
        if (!validate()) {
            return;
        }
        setResources([
            ...resources,
            {
                resource_name: resourceName,
                location_id: location ? location.loc_id : null,
                capacity: capacity,
                notes: notes,
                contact_id: contact ? contact.con_id : null // Optional contact ID
            }
        ]);
        setResourceName('');
        setCapacity('');
        setLocation(null);
        setNotes('');
        setContact(null); // Reset contact
        setOpen(false);
    };

    const editResource = async () => {
        if (!validate()) {
            return;
        }
        const updatedResources = resources.map(resource =>
            resource.resource_id === selectedResource.resource_id
                ? { ...resource, resource_name: resourceName, location_id: location.loc_id, capacity, notes, contact_id: contact ? contact.con_id : null, edited: true }
                : resource
        );
        setResources(updatedResources);
        setResourceName('');
        setCapacity('');
        setLocation(null);
        setNotes('');
        setContact(null);
        setOpen(false);
        setEditMode(false);
    };

    const handleSave = async () => {
        const newResources = resources.filter(resource => !originalResources.some(original => original.resource_id === resource.resource_id));
        const removedResources = resources.filter(resource => resource.markedForDeletion);
        const editedResources = resources.filter(resource => resource.edited);

        try {
            const promises = [];
            for (const resource of newResources) {
                promises.push(axios.post(`${APIURL}/resources`, resource));
            }
            for (const resource of removedResources) {
                promises.push(axios.delete(`${APIURL}/resources/${resource.resource_id}`));
            }
            for (const resource of editedResources) {
                promises.push(axios.put(`${APIURL}/resources/${resource.resource_id}`, resource));
            }
            await Promise.all(promises);
            setResources(resources.filter(resource => !resource.markedForDeletion));
            setOriginalResources(resources.filter(resource => !resource.markedForDeletion));
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (resource) => {
        setSelectedResource(resource);
        setResourceName(resource.resource_name);
        setCapacity(resource.capacity);
        setLocation(locations.find(loc => loc.loc_id === resource.location_id));
        setContact(contacts.find(contact => contact.con_id === resource.contact_id));
        setNotes(resource.notes);
        setEditMode(true);
        setOpen(true);
    };

    const handleOpenAddLocationModal = () => setIsAddLocationModalOpen(true);
    const handleCloseAddLocationModal = () => {
        setNewLocationAddress('');
        setNewLocationAddressObj(null);
        setAddressSuggestions([]);
        setNewLocationAssociationDate(new Date());
        setNewLocationPhone('');
        setNewLocationLocationStatus('');
        setNewLocationLocationType('');
        setIsAddLocationModalOpen(false);
    };

    const handleNewAddressSuggestions = async (value) => {
        if (!value) {
            setAddressSuggestions([]);
            return;
        }
        try {
            console.log("Fetching suggestions for:", value);
            const response = await axios.get(APIURL + '/maps', { params: { input: value } });
            console.log("Response from /maps:", response.data);
            if (response.data && Array.isArray(response.data.predictions)) {
                setAddressSuggestions(response.data.predictions);
            } else {
                console.warn("No predictions found in response", response.data);
                setAddressSuggestions([]);
            }
        } catch (error) {
            console.error("Error fetching address suggestions:", error);
            setAddressSuggestions([]);
        }
    };

    const handleNewAddressSelect = async (value) => {
        if (!value) return;
        try {
            const response = await axios.get(APIURL + '/maps/details', { params: { place_id: value.place_id } });
            const result = response.data.result;
            const getComp = (types) => result.address_components.find(comp => comp.types.some(t => types.includes(t))) || {};
            const address_line_1 = ((getComp(["street_number"]).long_name || "") + " " + (getComp(["route"]).long_name || "")).trim();
            const city = getComp(["locality"]).long_name || "";
            const state = getComp(["administrative_area_level_1"]).short_name || "";
            const zip = getComp(["postal_code"]).long_name || "";
            const country = getComp(["country"]).long_name || "";
            const latitude = result.geometry.location.lat;
            const longitude = result.geometry.location.lng;
            setNewLocationAddress(value.description);
            setNewLocationAddressObj({
                address_line_1,
                address_line_2: "", 
                city,
                state,
                zip,
                country,
                latitude,
                longitude
            });
        } catch (error) {
            console.error(error);
        }
    };

    // Updated handleAddLocation (using the old payload format):
    const handleAddLocation = async () => {
        if (!newLocationAddressObj ||
            !newLocationAssociationDate ||
            !newLocationPhone ||
            !newLocationLocationStatus ||
            !newLocationLocationType) {
            alert("Please fill all required fields");
            return;
        }
        // Build payload using the old format as expected by the backend
        const data = {
            address_line_1: newLocationAddressObj.address_line_1,
            address_line_2: newLocationAddressObj.address_line_2,
            city: newLocationAddressObj.city,
            state: newLocationAddressObj.state,
            zip: newLocationAddressObj.zip,
            association_date: new Date(newLocationAssociationDate).toISOString(),
            phone: newLocationPhone,
            location_status: newLocationLocationStatus,
            location_type: newLocationLocationType,
            company_id: props.comp_id
        };
        try {
            const msg = await axios.post(APIURL + "/location", data);
            if (msg.data.code === 200) {
                console.log("Location added successfully:", msg.data);
                // Convert data into the front end format
                const newLocation = {
                    FacilityCodeQualifier: null,
                    FacilityType: null,
                    loc_addr_line1: data.address_line_1,
                    loc_addr_line2: data.address_line_2,
                    loc_city: data.city,
                    loc_state: data.state,
                    loc_zip: data.zip,
                    loc_association_date: data.association_date,
                    loc_phone_number: data.phone,
                    loc_status: data.location_status,
                    loc_type: data.location_type,
                    loc_company_id: data.company_id,
                    loc_county: "United States",
                    loc_lat: newLocationAddressObj.latitude.toString(),
                    loc_lon: newLocationAddressObj.longitude.toString(),
                    loc_is_active: true,
                    loc_id: msg.data.id || Date.now().toString()
                };
                setLocations([...locations, newLocation]);
                setLocation(newLocation);
                handleCloseAddLocationModal();
            } else {
                console.log(msg.data);
                alert("Error adding location");
            }
        } catch (error) {
            console.error(error);
            alert("Error adding location");
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">Resources</Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Resources available when scheduling a session
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Box sx={{ minHeight: 32 }}>
                            <Box sx={{ minHeight: 40 }}>
                                {resources.length === 0 && (
                                    <Typography variant="body1" sx={{ color: 'gray' }}>
                                        No Resources selected
                                    </Typography>
                                )}
                                {resources.map((resource, index) => (
                                    <Chip
                                        key={index}
                                        label={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {resource.resource_name}
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedResource(resource);
                                                        setOpenInfo(true);
                                                    }}
                                                    sx={{ mr: -1 }}
                                                >
                                                    <InfoOutlinedIcon sx={{ color: 'white' }} />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleEdit(resource)}
                                                    sx={{ mr: -1 }}
                                                >
                                                    <EditIcon sx={{ color: 'white' }} fontSize='small' />
                                                </IconButton>
                                            </div>
                                        }
                                        onDelete={() => {
                                            const newResources = resources.map(r => {
                                                if (r.resource_id === resource.resource_id) {
                                                    return { ...r, markedForDeletion: !r.markedForDeletion };
                                                }
                                                return r;
                                            });
                                            setResources(newResources);
                                        }}
                                        color={resource.markedForDeletion ? 'error' : 'primary'}
                                        sx={{ mr: 1, mb: 1 }}
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Button
                            onClick={() => setOpen(true)}
                            sx={{ marginTop: 4, width: '200px' }}
                            variant="contained"
                            color="primary"
                        >
                            + Add New Resource
                        </Button>
                        <SaveButton
                            onClick={handleSave}
                            disabled={JSON.stringify(resources) === JSON.stringify(originalResources)}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        maxWidth: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ mb: 3 }}>
                        {editMode ? 'Edit Resource' : 'Add New Resource'}
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            label="Resource Name"
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            value={resourceName}
                            onChange={(e) => setResourceName(e.target.value)}
                            error={errors.resourceName && submitted}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="location"
                            // Change options to insert "Add New Location" as the first option
                            options={[{ loc_id: 'ADD_LOCATION_OPTION', label: '+ Add New Location' }, ...locations]}
                            getOptionLabel={(option) =>
                                option.loc_id === 'ADD_LOCATION_OPTION'
                                    ? option.label
                                    : `${option.loc_addr_line1}, ${option.loc_addr_line2 || ''}, ${option.loc_city || ''}, ${option.loc_state || ''}, ${option.loc_zip || ''}`
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Location"
                                    error={errors.location && submitted}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.loc_id}>
                                    {option.loc_id === 'ADD_LOCATION_OPTION'
                                        ? <span style={{ color: '#3fa7d6' }}>{option.label}</span>
                                        : <ListItemText primary={`${option.loc_addr_line1}, ${option.loc_addr_line2 || ''}, ${option.loc_city || ''}, ${option.loc_state || ''}, ${option.loc_zip || ''}`} />}
                                </li>
                            )}
                            value={location}
                            onChange={(event, newValue) => {
                                if (newValue && newValue.loc_id === 'ADD_LOCATION_OPTION') {
                                    handleOpenAddLocationModal();
                                    setLocation(null);
                                } else {
                                    setLocation(newValue);
                                    console.log(newValue);
                                    
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="contact"
                            options={contacts}
                            getOptionLabel={(option) => option.con_first_name + ' ' + option.con_last_name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Contact"
                                    error={errors.contact && submitted}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.con_id}>
                                    <ListItemText primary={option.con_first_name + ' ' + option.con_last_name} />
                                </li>
                            )}
                            value={contact}
                            onChange={(event, newValue) => {
                                setContact(newValue);
                            }}
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            label="Capacity"
                            type="number"
                            fullWidth
                            value={capacity}
                            onChange={(e) => setCapacity(e.target.value)}
                            inputProps={{ max: 1000, min: 1 }}
                            error={errors.capacity && submitted}
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            label="Notes"
                            fullWidth
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            multiline
                            inputProps={{ maxLength: 500 }}
                            rows={4}
                        />
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={editMode ? editResource : addResource}
                    >
                        {editMode ? 'Save Changes' : 'Add Resource'}
                    </Button>
                </Box>
            </Modal>

            {isAddLocationModalOpen && (
                <Modal open={isAddLocationModalOpen} onClose={handleCloseAddLocationModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            maxWidth: '90%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 3,
                            borderRadius: 2
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Add New Location
                        </Typography>
                        <Autocomplete
                            id="newLocationAddress"
                            options={addressSuggestions}
                            getOptionLabel={(option) => option.description || ""}
                            onInputChange={(event, value) => {
                                setNewLocationAddress(value);
                                handleNewAddressSuggestions(value);
                            }}
                            onChange={(event, value) => {
                                handleNewAddressSelect(value);
                            }}
                            renderInput={(params) => <TextField {...params} label="Address *" fullWidth sx={{ mb: 2 }} />}
                        />
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Association Date *"
                                        value={newLocationAssociationDate}
                                        onChange={(newValue) => setNewLocationAssociationDate(newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Phone *"
                                    fullWidth
                                    value={newLocationPhone}
                                    onChange={(e) => setNewLocationPhone(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        {(() => {
                            const statusOptions = (locStatusOptions && locStatusOptions.length ? locStatusOptions : defaultLocStatus)
                                .map(status => ({ value: status, title: status }));
                            const typeOptions = (locTypeOptions && locTypeOptions.length ? locTypeOptions : defaultLocType)
                                .map(type => ({ value: type, title: type }));
                            return (
                                <>
                                    <Autocomplete
                                        id="location_status"
                                        value={statusOptions.find(s => s.value === newLocationLocationStatus) || null}
                                        options={statusOptions}
                                        getOptionLabel={(option) => option.title}
                                        onChange={(event, newValue) => {
                                            setNewLocationLocationStatus(newValue ? newValue.value : "");
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Location Status *" variant="outlined" sx={{ mb: 2 }} />}
                                    />
                                    <Autocomplete
                                        id="location_type"
                                        value={typeOptions.find(t => t.value === newLocationLocationType) || null}
                                        options={typeOptions}
                                        getOptionLabel={(option) => option.title}
                                        onChange={(event, newValue) => {
                                            setNewLocationLocationType(newValue ? newValue.value : "");
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Location Type *" variant="outlined" sx={{ mb: 2 }} />}
                                    />
                                </>
                            );
                        })()}
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2 }}
                            onClick={handleAddLocation}
                        >
                            Add Location
                        </Button>
                    </Box>
                </Modal>
            )}

            <Modal open={openInfo} onClose={() => setOpenInfo(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        maxWidth: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ mb: 3 }}>
                        Resource Information
                    </Typography>
                    {selectedResource && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Resource name
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {selectedResource.resource_name}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Location
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_addr_line1}, 
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_addr_line2}, 
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_city}, 
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_state}, 
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_zip}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Contact
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {contacts.find((contact) => contact.con_id === selectedResource.contact_id)?.con_first_name}{' '}{contacts.find((contact) => contact.con_id === selectedResource.contact_id)?.con_last_name}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Capacity
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {selectedResource.capacity}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Notes
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {selectedResource.notes}
                                </Typography>
                            </Box>
                        </>
                    )}
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => setOpenInfo(false)}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Resource;
