import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import withStyles from "@mui/styles/withStyles";
import { APIURL } from "../../../config";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import './CMSForm.css';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#3FA7D6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3FA7D6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightblue",
      },
      "&:hover fieldset": {
        borderColor: "#3FA7D6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3FA7D6",
      },
    },
  },
})(TextField);

export default function CMSForm(props) {
	const [payerDets, setPayerDets] = useState(props.payerDets);
	const [currentFormValues, setCurrentFormValues] = useState(props.formVals);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		console.log(currentFormValues);
		console.log(payerDets);
		setIsLoading(false);
	}, [])

	async function handleChanges(key, value) {
		let updatedFormValues = {...currentFormValues, [key] : value};
		setCurrentFormValues(updatedFormValues);
		props.handleCMSFormChange(key,value);
	}


    return (
		<Box sx={{height: '75vh', overflow: 'auto'}}>

			{!isLoading && <table style={{width: '100%'}}>
					<colgroup>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
						<col span={1} style={{width:'calc(100%/18)'}}></col>
					</colgroup>
					<tr>
							<td colSpan={12}>
								<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
									<div style={{paddingLeft: 2, marginRight: 18, width: '75%'}}>
										<Typography fontSize={13}>1. PAYER NAME</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.payerName} onChange={(event) => handleChanges("payerName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{paddingRight: 2}}>
										<Typography fontSize={13}>PAYER ID</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.payerId} onChange={(event) => handleChanges("payerId", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{width: '100%', paddingLeft: 2, paddingRight: 2}}>
									<Typography fontSize={13}>1a. INSURED I.D. NUMBER</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.insuredIdNumber} onChange={(event) => handleChanges("insuredIdNumber", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
									<div style={{paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>2. PATIENT'S NAME</Typography>
										<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
											<div style={{width: '47.5%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patLastName} onChange={(event) => handleChanges("patLastName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
											<div style={{marginTop: 10, marginLeft: '2.5%', marginRight: '2.5%'}}> , </div>
											<div style={{paddingRight: 2, width: '47.5%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patFirstName} onChange={(event) => handleChanges("patFirstName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>3. PATIENT'S BIRTH DAY / SEX</Typography>
										<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
											<div style={{width: '65%', marginRight: 25, marginTop: 5}}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													margin="normal"
													label="DOB *"
													format="MM/DD/YYYY"
													value={currentFormValues.patDob? dayjs(currentFormValues.patDob) : null}
													onChange={(d) => handleChanges('patDob', d.format("MM/DD/YYYY"))}
													slotProps={{textField: {size: 'small'}}}
													sx={{
														'& .MuiInputBase-root': {
															height: '25px',
														},
													}}
													// className={`mx-2 my-3`}
													// onChange={(d) => {this.onDOBChange(d)}}
												/>
											</LocalizationProvider>
												{/* <TextField variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/> */}
											</div>
											<div style={{paddingRight: 2, width: '30%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} onChange={(event) => handleChanges("patGender", event.target.value)} value={currentFormValues.patGender} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{width: '100%', paddingLeft: 2, paddingRight: 2}}>
									<Typography fontSize={13}>4. INSURED'S NAME</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.insuredName} onChange={(event) => handleChanges("insuredName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
									<Typography fontSize={13}>5. PATIENT'S ADDRESS</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patAddrLine1} onChange={(event) => handleChanges("patAddrLine1", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{width: '100%', paddingLeft: 2, paddingRight: 2 }}>
									<Typography fontSize={13}>6. PATIENT RELATIONSHIP</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patRelationship} onChange={(event) => handleChanges("patRelationship", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
									<Typography fontSize={13}>7. INSURED'S ADDRESS</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.insuredAddrLine1} onChange={(event) => handleChanges("insuredAddrLine1", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
									<Typography fontSize={13}>ADDRESS 2</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patAddrLine2} onChange={(event) => handleChanges("patAddrLine2", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{width: '100%', paddingLeft: 2, paddingRight: 2 }}>
									<Typography fontSize={13}>8. MARITAL STATUS</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.maritalStatus} onChange={(event) => handleChanges("maritalStatus", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
									<Typography fontSize={13}>ADDRESS 2</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.insuredAddrLine2} onChange={(event) => handleChanges("insuredAddrLine2", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{paddingLeft: 2, width: '40%', paddingRight: 20}}>
										<Typography fontSize={13}>CITY</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patCity} onChange={(event) => handleChanges("patCity", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{width: '20%', paddingRight: 3}}>
										<Typography fontSize={13}>ST</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patState} onChange={(event) => handleChanges("patState", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{width: '30%', paddingRight: 3}}>
										<Typography fontSize={13}>ZIP</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patZip} onChange={(event) => handleChanges("patZip", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{width: '10%', paddingRight: 2}}>
										<Typography fontSize={13}>C</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patCountry} onChange={(event) => handleChanges("patCountry", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{width: '100%', paddingLeft: 2, paddingRight: 2 }}>
										<Typography fontSize={13}>EMPLOYMENT STATUS</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.employmentStatus} onChange={(event) => handleChanges("employmentStatus", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{paddingLeft: 2, width: '40%', paddingRight: 20}}>
										<Typography fontSize={13}>CITY</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.insuredCity} onChange={(event) => handleChanges("insuredCity", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{width: '20%', paddingRight: 3}}>
										<Typography fontSize={13}>ST</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.insuredState} onChange={(event) => handleChanges("insuredState", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{width: '30%', paddingRight: 3}}>
										<Typography fontSize={13}>ZIP</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.insuredZip} onChange={(event) => handleChanges("insuredZip", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{width: '10%', paddingRight: 2}}>
										<Typography fontSize={13}>C</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.insuredCountry} onChange={(event) => handleChanges("insuredCountry", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>9. SECONDARY INSURED NAME</Typography>
										<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
											<div style={{width: '47.5%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.secondaryLastName} onChange={(event) => handleChanges("secondaryLastName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
											<div style={{marginTop: 10, marginLeft: '2.5%', marginRight: '2.5%'}}> , </div>
											<div style={{paddingRight: 2, width: '47.5%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.secondaryFirstName} onChange={(event) => handleChanges("secondaryFirstName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
										</div>
									</div>
								</div>
							</td>
							<td rowSpan={3} colSpan={6} style={{ verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{display:'flex', paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>10. PATIENT CONDITION RELATED:</Typography>
									</div>
									<div style={{ display:'flex', flexDirection: 'column', marginTop: '2%', paddingLeft: 25, paddingRight: 25, marginBottom: '2%'}}>
										<div style={{ width: '100%',}}>
											<Typography fontSize={13}>a. EMPLOYMENT:</Typography>
											<div style={{width: '25%'}}><TextField value={currentFormValues.patConditionEmp} onChange={(event) => handleChanges("patConditionEmp", event.target.value)} disabled sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }}variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										</div>
										<div style={{width: '100%'}}>
											<Typography fontSize={13}>b. AUTO ACCIDENT:</Typography>
											<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-around'}}>
												<div style={{width: '25%'}}><TextField value={currentFormValues.patConditionAuto} onChange={(event) => handleChanges("patConditionAuto", event.target.value)} disabled sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
												<Typography fontSize={13} margin={'auto'}>STATE:</Typography>
												<div style={{width: '15%'}}><TextField  disabled value={currentFormValues.patConditionAutoState} onChange={(event) => handleChanges("patConditionAutoState", event.target.value)} sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
												<Typography fontSize={13} margin={'auto'}>COUNTRY:</Typography>
												<div style={{width: '15%'}}><TextField disabled value={currentFormValues.patConditionAutoCountry} onChange={(event) => handleChanges("patConditionAutoCountry", event.target.value)} sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
											</div>
										</div>
										<div style={{width: '100%'}}>
											<Typography fontSize={13}>c. OTHER ACCIDENT:</Typography>
											<div style={{width: '25%'}}><TextField value={currentFormValues.patConditionOther} onChange={(event) => handleChanges("patConditionOther", event.target.value)} disabled sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{width: '100%', paddingLeft: 2, paddingRight: 2}}>
										<Typography fontSize={13}>11. INSURED'S POLICY GROUP</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.insuredPolicyGroup} onChange={(event) => handleChanges("insuredPolicyGroup", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
									<div style={{paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>a. SECONDARY INSURED'S POLICY</Typography>
										<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
											<div style={{width: '100%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.secondaryPolicy} onChange={(event) => handleChanges("secondaryPolicy", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
										</div>
									</div>
								</div>
							</td>
							{/* <td rowSpan={3}  style={{width:'calc(100% / 3)', display: 'flex', flexDirection: 'row'}}>
								<div style={{paddingLeft: 2, width: '100%'}}>
									<Typography fontSize={13}>10. PATIENT CONDITION RELATED:</Typography>
									<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
										<div style={{width: '65%', marginRight: '5%'}}>
											<TextField variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
										<div style={{paddingRight: 2, width: '30%'}}>
											<TextField variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
								</div>
							</td> */}
							<td colSpan={6}>
								<div style={{width: '100%', paddingLeft: 2, paddingRight: 2}}>
									<Typography fontSize={13}>a. INSURED'S DATE OF BIRTH / SEX</Typography>
									<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
										<div style={{width: '65%', marginRight: 25, marginTop: 5}}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													margin="normal"
													label="DOB"
													format="MM/DD/YYYY"
													value={currentFormValues.insuredDob? dayjs(currentFormValues.insuredDob) : null}
													slotProps={{textField: {size: 'small'}}}
													sx={{
														'& .MuiInputBase-root': {
															height: '25px',
														},
													}}
													disabled
													onChange={(d) => handleChanges("insuredDob", d.format("MM/DD/YYYY"))}
													// className={`mx-2 my-3`}
													// onChange={(d) => {this.onDOBChange(d)}}
												/>
											</LocalizationProvider>
											{/* <TextField variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/> */}
										</div>
										<div style={{paddingRight: 2, width: '30%'}}>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled onChange={(event) => handleChanges("insuredGender", event.target.value)} value={currentFormValues.insuredGender} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>b. SECONDARY INSURED DATE OF BIRTH / SEX</Typography>
										<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
											<div style={{width: '65%', marginRight: 25, marginTop: 5}}>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DatePicker
														margin="normal"
														// label="Secondary DOB"
														format="MM/DD/YYYY"
														value={currentFormValues.secondaryDob? dayjs(currentFormValues.secondaryDob) : null}
														slotProps={{textField: {size: 'small'}}}
														sx={{
															'& .MuiInputBase-root': {
																height: '25px',
															},
														}}
														disabled
														// className={`mx-2 my-3`}
														// onChange={(d) => {this.onDOBChange(d)}}
													/>
												</LocalizationProvider>
												{/* <TextField variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/> */}
											</div>
											<div style={{paddingRight: 2, width: '30%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.secondaryGender} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{width: '100%', paddingLeft: 2, paddingRight: 2}}>
										<Typography fontSize={13}>b. EMPLOYER'S NAME</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.employerName} onChange={(event) => handleChanges("employerName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
									<Typography fontSize={13}>c. PRIMARY PAYMENT DATE</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.primaryPaymentDate} onChange={(event) => handleChanges("primaryPaymentDate", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{width: '100%', paddingLeft: 2, paddingRight: 2 }}>
									<Typography fontSize={13}>10d. CLAIM CODES</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.claimCodes} onChange={(event) => handleChanges("claimCodes", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
									<Typography fontSize={13}>c. INSURANCE PLAN NAME</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.insurancePlanName} onChange={(event) => handleChanges("insurancePlanName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: 'calc(200%/3)'}}>
											<Typography fontSize={13}>d. SECONDARY PAYER NAME</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.secondaryPayerName} onChange={(event) => handleChanges("secondaryPayerName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
										<div style={{paddingLeft: 2, paddingRight: 2, width: 'calc(100%/3)'}}>
											<Typography fontSize={13}>PAYER ID</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.secondaryPayerId} onChange={(event) => handleChanges("secondaryPayerId", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: 'calc(200%/3)'}}>
											<Typography fontSize={13}>SECONDARY PAYER CLAIM ID</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.secondaryPayerClaimId} onChange={(event) => handleChanges("secondaryPayerClaimId", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
											<Typography fontSize={13}>MEDICARE TYPE CODE</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.medicareTypeCode} onChange={(event) => handleChanges("medicareTypeCode", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
											<Typography fontSize={13}>9e. SECONDARY PATIENT RELATIONSHIP</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.secondaryPatRelationship} onChange={(event) => handleChanges("secondaryPatRelationship", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
											<Typography fontSize={13}>9f. SECONDARY PAYER GROUP NAME</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.secondaryPayerGroupName} onChange={(event) => handleChanges("secondaryPayerGroupName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
											<Typography fontSize={13}>9g. SECONDARY PAYER GROUP NUMBER</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.secondaryPayerGroupNumber} onChange={(event) => handleChanges("secondaryPayerGroupNumber", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6} style={{verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
											<Typography fontSize={13}>SUPERVISING PROVIDER NAME</Typography>
											<div style={{display: 'flex', flexDirection: 'row'}}>
												<div style={{width: '47.5%'}}>
													<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.supervisingProviderLastName} onChange={(event) => handleChanges("supervisingProviderLastName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
												</div>
												<div style={{marginTop: 10, marginLeft: '2.5%', marginRight: '2.5%'}}> , </div>
												<div style={{width: '47.5%'}}>
													<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.supervisingProviderFirstName} onChange={(event) => handleChanges("supervisingProviderFirstName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
												</div>
											</div>
										</div>
									</div>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%', display: 'flex', flexDirection: 'row'}}>
											<Typography fontSize={13} margin={'auto'}>ID</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.supervisingProviderId} onChange={(event) => handleChanges("supervisingProviderId", event.target.value)} disabled variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
											<Typography fontSize={13} margin={'auto'}>NPI</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.supervisingProviderNPI} onChange={(event) => handleChanges("supervisingProviderNPI", event.target.value)} disabled variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6} style={{verticalAlign:'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
											<Typography fontSize={13}>14. DATE OF CONDITION</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.dateOfCondition} onChange={(event) => handleChanges("dateOfCondition", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
											<Typography fontSize={13}>ADD DATE:</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 }, width: '80%' }} value={currentFormValues.addDate} onChange={(event) => handleChanges("addDate", event.target.value)} disabled variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6} style={{verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
											<Typography fontSize={13}>17. REFERRING PHYSICIAN NAME</Typography>
											<div style={{display: 'flex', flexDirection: 'row'}}>
												<div style={{width: '47.5%'}}>
													<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.referringPhysicianLastName} onChange={(event) => handleChanges("referringPhysicianLastName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
												</div>
												<div style={{marginTop: 10, marginLeft: '2.5%', marginRight: '2.5%'}}> , </div>
												<div style={{width: '47.5%'}}>
													<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.referringPhysicianFirstName} onChange={(event) => handleChanges("referringPhysicianFirstName", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6} style={{verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, paddingRight: 2, width: 'calc(200%/3)'}}>
											<Typography fontSize={13}>17a. REFERRING NPI:</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.referringNPI} onChange={(event) => handleChanges("referringNPI", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
										<div style={{paddingLeft: 2, paddingRight: 2, width: 'calc(100%/3)'}}>
											<Typography fontSize={13}>OTHER ID:</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.referringOtherId} onChange={(event) => handleChanges("referringOtherId", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={12} >
								<div style={{paddingLeft: 2, paddingRight: 2, width: '100%'}}>
									<Typography fontSize={13}>19. CLAIM NARRATIVE</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.claimNarrative} onChange={(event) => handleChanges("claimNarrative", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={6}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<Typography fontSize={13}>18. HOSPITALIZATION DATES</Typography>
									<div style={{paddingLeft: 2, paddingRight: 2, width: '100%', display: 'flex', flexDirection: 'row'}}>
										<Typography value={currentFormValues.hospitalizationDateFrom} onChange={(event) => handleChanges("hospitalizationDateFrom", event.target.value)} fontSize={13} margin={'auto'}>FROM</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
										<Typography fontSize={13} margin={'auto'}>TO</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.hospitalizationDateTo} onChange={(event) => handleChanges("hospitalizationDateTo", event.target.value)} disabled variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={12} rowSpan={2} style={{verticalAlign: 'top'}}>
								<div style={{paddingLeft: 2, paddingRight: 2, width: '100%', display:'flex', flexDirection: 'column'}}>
									<Typography fontSize={13}>19. DIAGNOSIS OR NATURE OF ILLNESS OR INJURY (Relate to 24E by line)</Typography>
									<div style={{display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 12}}>
										<Typography fontSize={13} margin={'auto'}>A.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagA} onChange={(event) => handleChanges("diagA", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>B.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagB} onChange={(event) => handleChanges("diagB", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>C.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagC} onChange={(event) => handleChanges("diagC", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>D.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagD} onChange={(event) => handleChanges("diagD", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
									</div>
									<div style={{display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 12, paddingTop: 5}}>
										<Typography fontSize={13} margin={'auto'}>E.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagE} onChange={(event) => handleChanges("diagE", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>F.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagF} onChange={(event) => handleChanges("diagF", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>G.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagG} onChange={(event) => handleChanges("diagG", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>H.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagH} onChange={(event) => handleChanges("diagH", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
									</div>
									<div style={{display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 12, paddingTop: 5}}>
										<Typography fontSize={13} margin={'auto'}>I.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagI} onChange={(event) => handleChanges("diagI", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>J.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagJ} onChange={(event) => handleChanges("diagJ", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>K.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagK} onChange={(event) => handleChanges("diagK", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<Typography fontSize={13} margin={'auto'}>L.</Typography>
										<div style={{width: '20%'}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagL} onChange={(event) => handleChanges("diagL", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
									</div>
								</div>
							</td>
							<td colSpan={6} style={{verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<Typography fontSize={13}>22. RESUBMISSION CODE (Payer ICN)</Typography>
									<div style={{paddingLeft: 2, paddingRight: 2, width: '100%', display: 'flex', flexDirection: 'row'}}>
										<div style={{width: '25%', paddingRight: 3}}><TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.resubmissionCode} onChange={(event) => handleChanges("resubmissionCode", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/></div>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.resubmissionPayerICN} onChange={(event) => handleChanges("resubmissionPayerICN", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6} style={{verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<Typography fontSize={13}>23. PRIOR AUTHORIZATION NUMBER</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.priorAuthNumber} onChange={(event) => handleChanges("priorAuthNumber", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									<Typography fontSize={13}>REFERRAL NUMBER</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.referralNumber} onChange={(event) => handleChanges("referralNumber", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									<Typography fontSize={13}>CLIA NUMBER</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.cliaNumber} onChange={(event) => handleChanges("cliaNumber", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={4}>
								<Typography fontSize={13}>24. A. DATE(S) OF SERVICE</Typography>
							</td>
							<td colSpan={1}>
								<Typography fontSize={13}>B. POS</Typography>
							</td>
							<td colSpan={1}>
								<Typography fontSize={13}>C. EMG</Typography>
							</td>
							<td colSpan={2}>
								<Typography fontSize={13}>D. PROCEDURE </Typography>
							</td>
							<td colSpan={3}>
								<Typography fontSize={13}>MODIFIER</Typography>
							</td>
							<td colSpan={2}>
								<Typography fontSize={13}>E. DIAG REF</Typography>
							</td>
							<td colSpan={3}>
								<Typography fontSize={13}>F. $ CHARGES</Typography>
							</td>
							<td colSpan={2}>
								<Typography fontSize={13}>G. UNITS</Typography>
							</td>
					</tr>
					<tr>
							<td colSpan={2}>
								<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.dateOfServiceFrom} onChange={(event) => handleChanges("dateOfServiceFrom", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
							</td>
							<td colSpan={2}>
								<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.dateOfServiceTo} onChange={(event) => handleChanges("dateOfServiceTo", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
							</td>
							<td colSpan={1}>
								<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.pos} onChange={(event) => handleChanges("pos", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
							</td>
							<td colSpan={1}>
								<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.emg} onChange={(event) => handleChanges("emg", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
							</td>
							<td colSpan={2}>
								<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.procedureCode} onChange={(event) => handleChanges("procedureCode", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
							</td>
							<td colSpan={3}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<TextField sx={{margin: 0.1, "& .MuiInputBase-input": { height: 15, padding: 1 }}} value={currentFormValues.procedureCodeModifier1} onChange={(event) => handleChanges("procedureCodeModifier1", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
									<TextField sx={{margin: 0.1, "& .MuiInputBase-input": { height: 15, padding: 1 }}} value={currentFormValues.procedureCodeModifier2} onChange={(event) => handleChanges("procedureCodeModifier2", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
									<TextField sx={{margin: 0.1, "& .MuiInputBase-input": { height: 15, padding: 1 }}} value={currentFormValues.procedureCodeModifier3} onChange={(event) => handleChanges("procedureCodeModifier3", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
									<TextField sx={{margin: 0.1, "& .MuiInputBase-input": { height: 15, padding: 1 }}} value={currentFormValues.procedureCodeModifier4} onChange={(event) => handleChanges("procedureCodeModifier4", event.target.value)} variant="filled" size="small" InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={2}>
								<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.diagRef} onChange={(event) => handleChanges("diagRef", event.target.value)} disabled variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
							</td>
							<td colSpan={3}>
								<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.charges} onChange={(event) => handleChanges("charges", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
							</td>
							<td colSpan={2}>
								<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.units} onChange={(event) => handleChanges("units", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
							</td>
					</tr>
					<tr>
							<td colSpan={4}>
								<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
									<div style={{paddingLeft: 2, marginRight: 18, flex: 4}}>
										<Typography fontSize={13}>25. TAX ID</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.taxId} onChange={(event) => handleChanges("taxId", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{paddingRight: 2, flex: 1}}>
										<Typography fontSize={13}>TYPE</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.taxIdType} onChange={(event) => handleChanges("taxIdType", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
									<div style={{paddingLeft: 2, flex: 1}}>
										<Typography fontSize={13}>26. PATIENT ACCT #</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.patientAccountNumber} onChange={(event) => handleChanges("patientAccountNumber", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
									<div style={{paddingLeft: 2, flex: 1}}>
										<Typography fontSize={13}>27. ACCEPT ASSIGN</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.acceptAssign} onChange={(event) => handleChanges("acceptAssign", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
									<div style={{paddingLeft: 2, flex: 1}}>
										<Typography fontSize={13}>28. TOTAL CHARGE</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.totalCharge} onChange={(event) => handleChanges("totalCharge", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
									<div style={{paddingLeft: 2, flex: 1}}>
										<Typography fontSize={13}>29. AMOUNT PAID</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.amountPaid} onChange={(event) => handleChanges("amountPaid", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={2}>
								<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
									<div style={{paddingLeft: 2, flex: 1}}>
										<Typography fontSize={13}>30. BALANCE</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} disabled value={currentFormValues.balance} onChange={(event) => handleChanges("balance", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={6} style={{verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>31. RENDERING PROVIDER</Typography>
										<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
											<div style={{width: '47.5%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.renderingProviderLastName} onChange={(event) => handleChanges("renderingProviderLastName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
											<div style={{marginTop: 10, marginLeft: '2.5%', marginRight: '2.5%'}}> , </div>
											<div style={{paddingRight: 2, width: '47.5%'}}>
												<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.renderingProviderFirstName} onChange={(event) => handleChanges("renderingProviderFirstName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
											</div>
										</div>
									</div>
									<div style={{paddingLeft: 2, paddingRight:2, width: '100%'}}>
										<Typography fontSize={13}>TAXONOMY</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.renderingProviderTaxonomy} onChange={(event) => handleChanges("renderingProviderTaxonomy", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
							<td colSpan={6} style={{verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>32. FACILITY</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityName} onChange={(event) => handleChanges("facilityName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{paddingLeft: 2, paddingRight:2, width: '100%'}}>
										<Typography fontSize={13}>Address 1</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityAddrLine1} onChange={(event) => handleChanges("facilityAddrLine1", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{paddingLeft: 2, paddingRight:2, width: '100%'}}>
										<Typography fontSize={13}>Address 2</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityAddrLine2} onChange={(event) => handleChanges("facilityAddrLine2", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, width: '40%', paddingRight: 20}}>
											<Typography fontSize={13}>City</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityCity} onChange={(event) => handleChanges("facilityCity", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
										<div style={{width: '20%', paddingRight: 3}}>
											<Typography fontSize={13}>St</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityState} onChange={(event) => handleChanges("facilityState", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
										<div style={{width: '30%', paddingRight: 3}}>
											<Typography fontSize={13}>Zip</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityZip} onChange={(event) => handleChanges("facilityZip", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
										<div style={{width: '10%', paddingRight: 2}}>
											<Typography fontSize={13}>C</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityCountry} onChange={(event) => handleChanges("facilityCountry", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
								</div>
							</td>
							<td colSpan={6} style={{verticalAlign: 'top'}}>
								<div style={{display: 'flex', flexDirection: 'column'}}>
									<div style={{paddingLeft: 2, width: '100%'}}>
										<Typography fontSize={13}>33. BILLING PROVIDER</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvName} onChange={(event) => handleChanges("billingProvName", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{paddingLeft: 2, paddingRight:2, width: '100%'}}>
										<Typography fontSize={13}>Address 1</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvAddrLine1} onChange={(event) => handleChanges("billingProvAddrLine1", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{paddingLeft: 2, paddingRight:2, width: '100%'}}>
										<Typography fontSize={13}>Address 2</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvAddrLine2} onChange={(event) => handleChanges("billingProvAddrLine2", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<div style={{paddingLeft: 2, width: '40%', paddingRight: 20}}>
											<Typography fontSize={13}>City</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvCity} onChange={(event) => handleChanges("billingProvCity", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
										<div style={{width: '20%', paddingRight: 3}}>
											<Typography fontSize={13}>St</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvState} onChange={(event) => handleChanges("billingProvState", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
										<div style={{width: '40%', paddingRight: 2}}>
											<Typography fontSize={13}>Zip</Typography>
											<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvZip} onChange={(event) => handleChanges("billingProvZip", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
										</div>
									</div>
									<div style={{paddingLeft: 2, paddingRight:2, width: '100%'}}>
										<Typography fontSize={13}>Phone</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvPhone} onChange={(event) => handleChanges("billingProvPhone", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
									<div style={{paddingLeft: 2, paddingRight:2, width: '100%'}}>
										<Typography fontSize={13}>Taxonomy</Typography>
										<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvTaxonomy} onChange={(event) => handleChanges("billingProvTaxonomy", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
									</div>
								</div>
							</td>
					</tr>
					<tr>
							<td colSpan={3}>
								<div style={{paddingLeft: 2, width: '100%'}}>
									<Typography fontSize={13}>NPI:</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.renderingProviderNPI} onChange={(event) => handleChanges("renderingProviderNPI", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{paddingLeft: 2, width: '100%'}}>
									<Typography fontSize={13}>PROV ID:</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.renderingProviderId} onChange={(event) => handleChanges("renderingProviderId", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{paddingLeft: 2, width: '100%'}}>
									<Typography fontSize={13}>NPI:</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityNPI} onChange={(event) => handleChanges("facilityNPI", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{paddingLeft: 2, width: '100%'}}>
									<Typography fontSize={13}>FACIL ID:</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.facilityId} onChange={(event) => handleChanges("facilityId", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{paddingLeft: 2, width: '100%'}}>
									<Typography fontSize={13}>NPI:</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvNPI} onChange={(event) => handleChanges("billingProvNPI", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
							<td colSpan={3}>
								<div style={{paddingLeft: 2, width: '100%'}}>
									<Typography fontSize={13}>BILL ID:</Typography>
									<TextField sx={{ "& .MuiInputBase-input": { height: 15, padding: 1 } }} value={currentFormValues.billingProvId} onChange={(event) => handleChanges("billingProvId", event.target.value)} variant="filled" size="small" fullWidth InputProps={{ disableUnderline: true }}/>
								</div>
							</td>
					</tr>
			</table>}

		</Box>
    )
}