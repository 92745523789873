import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Divider,
  TextField,
  Grid,
  Autocomplete,
  Button,
  Modal,
  ListItemText,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { APIURL } from "../../../config";
import ccs from "countrycitystatejson";
import normalize from "us-states-normalize";
import moment from "moment";

const PayerInformationv2 = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [payerDetails, setPayerDetails] = useState([]);
  const [openCred, setOpenCred] = useState(false);
  const [credDetails, setCredDetails] = useState([]);
  const [payerList, setPayerList] = useState([]);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [open, setOpen] = useState(false);
  const [editModeCred, setEditModeCred] = useState(false);

  // let defaultPayerCredDetails = {
  //   "id": -1,
  //   "payerId": null,
  //   "compId": props.comp_id,
  //   "credId": null,
  //   "enrollmentStatus": null,
  //   "createdOn": null,
  //   "updatedOn": null,
  //   "relStartDate": null,
  //   "relEndDate": null,
  //   "relEnrollmentDate": null,
  //   "payerTaxId": null,
  //   "conFirstName": null,
  //   "conLastName": null,
  //   "conDesignation": null,
  //   "payerAddrLine1": null,
  //   "payerAddrLine2": null,
  //   "payerCity": null,
  //   "payerZip": null,
  //   "payerPhone": null,
  //   "payerDets": {
  //       "payer_id": null,
  //       "payer_is_active": null,
  //       "payer_name": null,
  //       "payer_state": null,
  //       "claimMd_payerId": null,
  //       "prof_claims_supp": null,
  //       "inst_claims_supp": null,
  //       "dent_claims_supp": null,
  //       "remittance_supp": null,
  //       "payer_type": null,
  //       "secondary_supp": null,
  //       "eligibility_supp": null,
  //       "workers_comp_supp": null,
  //       "attachment_supp": null
  //   },
  //   "credDets": {
  //       "cred_id": null,
  //       "cred_clientID": null,
  //       "cred_clientSecret": null,
  //       "cred_con_email": null,
  //       "cred_is_active": null,
  //       "bus_entity_id": null,
  //       "bus_entity_Medicaid_identifier": null
  //   },
  //   "payerAddress": null,
  // };

  const getData = async () => {
    try {
      const payerDets = {
        data: [
          {
            payer_id: 2003,
            com_id: 2004,
            payerName: "ANNJ",
            payer_is_active: true,
            payerTaxID: "364643230",
            con_first_name: "Prabhat",
            con_last_name: "Vaish",
            payer_Addr_line1: "321 Kearny Ave",
            payer_Addr_line2: "",
            payer_city: "Kearny",
            payer_state: "NJ",
            payer_zip: "08904",
            payer_phone: "8111111111",
            con_designation: "Officer",
            claimMd_payerId: "51909",
          },
          {
            payer_id: 2004,
            com_id: 2004,
            payerName: "AGNJ",
            payer_is_active: 1,
            payerTaxID: "364243230",
            con_first_name: "Sunny",
            con_last_name: "Nair",
            payer_Addr_line1: "323 Kearny Ave",
            payer_Addr_line2: "",
            payer_city: "Kearny",
            payer_state: "NJ",
            payer_zip: "08904",
            payer_phone: "8111111511",
            con_designation: "RA",
            claimMd_payerId: "68055",
          },
        ],
      };

      let credDets = {
        data: [
          {
            cred_id: 500000,
            cred_company_id: 2004,
            payer_id: 2003,
            cred_clientID: "gx9w8hqJPe",
            cred_clientSecret: "P9Wsz7bERy000000000",
            cred_created_at: "2021-04-16T13:08:21.9900000",
            cred_updated_at: "2024-07-19T16:38:29.7730000",
            cred_updatedby_id: 700751,
            cred_is_active: 1,
            cred_con_email: "raghu@analytiq.us",
            bus_entity_id: "201987",
            bus_entity_Medicaid_identifier: "642875",
          },
        ],
      };

      let newPayerDets = await axios.get(APIURL + `/associate_payer`, {
        params : {
            compId: props.comp_id,
        }
      });

      newPayerDets.data = newPayerDets.data.filter(p => p.payerDets !== undefined);

      let payerResp = await axios.get(APIURL + `/payer`, {
        params: {
          comp_id: props.comp_id,
        },
      });
      setPayerList(
        payerResp.data.filter(
          (payer) =>
            !newPayerDets.data.map((p) => p.payerId).includes(payer.payer_id) && (payer.prof_claims_supp === 'yes' || payer.prof_claims_supp === 'enrollment')
        )
      );

      let payerDetails = newPayerDets.data.filter((p) => p.id);
      
      setPayerDetails(payerDetails.map((p) => {
        let addrFields = [p.payerAddrLine1, p.payerAddrLine2, p.payerCity, p.payerDets.payer_state, p.payerZip];
        addrFields = addrFields.filter((field) => field !== "" && field !== undefined && field !== null)
        return {
            ...p,
            payerAddress: addrFields.join(", ")
        }
      }));
      // setCredDetails(credDets.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
  }, []);

  useEffect(() => {
    getData();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setSelectedPayer(null);
  };

  const handleCloseCred = () => {
    setOpenCred(false);
    setEditModeCred(false);
    setSelectedPayer({...selectedPayer, credDets: {...payerDetails.find((p) => p.payerId === selectedPayer.payerId).credDets}})
  };

  const addCred = async () => {
    if (validate("cred")) {

      if (selectedPayer.id === undefined) {
        setOpenCred(false);
        setEditModeCred(false);
        return;
      }

      let credDets = selectedPayer.credDets;

      let addCredResp = await axios.post(APIURL + `/creds`, {
        com_id: props.comp_id,
        ...credDets,
        emp_id: props.user_id,
        payerId: selectedPayer.payerId,
      });

      console.log(addCredResp);

      let newCredId = addCredResp.data.credId, newCredDets = addCredResp.data.credDets;

      let updatedPayerDetails = payerDetails.map((p) => {
        if (p.payerId === selectedPayer.payerId) {
          return {
            ...selectedPayer,
            credDets: newCredDets,
            credId: newCredId,
          }
        }
        return {
          ...p
        };
      })

      setSelectedPayer({...updatedPayerDetails.find(p => p.payerId === selectedPayer.payerId)});
      setPayerDetails(updatedPayerDetails);

      handleCloseCred();
    } else {
    }
  };

  const editCred = async () => {

    let updateResp = await axios.put(APIURL + `/creds`, {
      ...selectedPayer.credDets,
      cred_id: selectedPayer.credId,
      emp_id: props.user_id,
    })

    console.log(updateResp);

    let updatedPayerDetails = payerDetails.map((p) => {
      if (p.payerId === selectedPayer.payerId) {
        return {
          ...selectedPayer,
        }
      }
      return {
        ...p
      };
    })

    setSelectedPayer(updatedPayerDetails.find(p => p.payerId === selectedPayer.payerId));
    setPayerDetails(updatedPayerDetails);

    // check if any changes compared to already stored data and save

    handleCloseCred();
  };

  const addPayer = async () => {
    if (validate("payer")) {
      var payerData = {
        ...selectedPayer,
        compId: props.comp_id,
        createdBy: props.user_id,
        updatedBy: props.user_id,
      };

      let addPayerResp = await axios.post(APIURL + `/associate_payer`, {
        ...payerData
      });

      console.log(addPayerResp);

      handleClose();
    } else {
    }
  };

  const editPayer = async () => {

    let updateResp = await axios.put(APIURL + `/associate_payer`, {
      compId: props.comp_id,
      payerAddrLine1: selectedPayer.payerAddrLine1,
      payerAddrLine2: selectedPayer.payerAddrLine2,
      payerCity: selectedPayer.payerCity,
      payerPhone: selectedPayer.payerPhone,
      relStartDate: selectedPayer.relStartDate,
      payerTaxId: selectedPayer.payerTaxId,
      payerZip: selectedPayer.payerZip,
      conFirstName: selectedPayer.conFirstName,
      conLastName: selectedPayer.conLastName,
      conDesignation: selectedPayer.conDesignation,
      relEndDate: selectedPayer.relEndDate,
      payerId: selectedPayer.payerId
    })

    console.log(updateResp);

    // check if any changes compared to already stored data and save

    handleClose();
  };

  const validate = (mode) => {
    if (mode === "payer") {
      if (!selectedPayer) {
        alert(`Please select a payer!`);
        return false;
      } else {
      }
      return true;
    } else if (mode === 'cred') {
      if (!selectedPayer.credDets.cred_clientID) {
        alert(`Please enter a valid ID!`)
        return false;
      } else if (!selectedPayer.credDets.cred_clientSecret) {
        alert(`Please enter a valid password!`)
        return false;
      } else {

      }
      return true;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h5">Payers</Typography>
      </Box>
      {payerDetails.map((formValues, index) => {
        return (
          <Box sx={{border: 1, borderColor: '#e6e6e6', borderRadius: 3, marginBottom: 2}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 2,
              }}
            >
              <Grid item xs={2}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {formValues.payerDets.payer_name || ""}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setEditMode(true);
                    setSelectedPayer({
                        ...formValues
                    })
                  }}
                >
                  <Edit />
                </IconButton>
              </Grid>
            </Box>
            <Box sx={{marginLeft: 5}}>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Payer ID
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.payerDets.payer_id || ""}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Payer Status
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.payerDets.payer_is_active === true ? "Active" : "Inactive"}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Start Date
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {moment.utc(formValues.relStartDate).format("MM/DD/YYYY") || ""}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  End Date
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {moment.utc(formValues.relEndDate).format("MM/DD/YYYY") || ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Contact First Name
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.conFirstName || ""}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Contact Last Name
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.conLastName || ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Contact designation
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.conDesignation || ""}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Payer phone
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.payerPhone || ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Payer Address
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.payerAddress || ""}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Payer TaxID
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.payerTaxId || ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Enrollment Status
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.enrollmentStatus || ""}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Enrollment Date
                </Typography>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.enrollmentDate ? moment.utc(formValues.enrollmentDate).format("MM/DD/YYYY") : "-"}
                </Typography>
              </Grid>
            </Grid>
            {formValues.credId && <Box>
            <Divider sx={{ my: 1 }} />
            <Grid item xs={2}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Credentials
                </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Client ID
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.credDets.cred_clientID ? formValues.credDets.cred_clientID : ''}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Client Secret
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>{formValues.credDets.cred_clientSecret ? '********' : ''}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Business Entity ID
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.credDets.bus_entity_id ? formValues.credDets.bus_entity_id : ""}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Medicaid Identifier
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.credDets.bus_entity_Medicaid_identifier ? formValues.credDets.bus_entity_Medicaid_identifier : ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Contact Email
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {formValues.credDets.cred_con_email ? formValues.credDets.cred_con_email : ""}
                </Typography>
              </Grid>
            </Grid>
            </Box>}
            </Box>
          </Box>
        );
      })}
      <Button
        onClick={() => setOpen(true)}
        sx={{ marginTop: 4 }}
        variant="contained"
        color="primary"
      >
        + Add New Payer
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxWidth: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="h6" component="div" sx={{ mb: 3 }}>
              {editMode ? "Edit Payer" : "Add New Payer"}
            </Typography>
            <Button
              variant="contained"
              sx={{mb: 3}}
              onClick={() => {
                setOpenCred(true);
                if (selectedPayer && selectedPayer.credId) {
                  setEditModeCred(true);
                }
              }}
            >
              {selectedPayer ? (selectedPayer.credId ? "Edit cred" : "Add cred") : "Add cred"}
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            {!editMode ? <Autocomplete
              id="payerName"
              options={payerList}
              getOptionLabel={(option) => option.payer_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ required: true }}
                  label="Payer Name"
                  error={""}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.payer_id}>
                  {option.default_payer ? <Typography sx={{fontWeight: 1000}}>{option.payer_name + " (default)"}</Typography> : <ListItemText primary={option.payer_name} />}
                </li>
              )}
              onChange={(event, newValue) => {
                setSelectedPayer({
                  ...selectedPayer,
                  payerDets: newValue,
                  credDets: {},
                  relStartDate: moment().format("MM/DD/YYYY"),
                  relEndDate: moment().add(1, "years").format("MM/DD/YYYY"),
                });
              }}
            /> :
            <Grid container spacing={2}>
              <Grid item xs={2.5}>
                <Typography variant="body1" sx={{ color: "gray", my: 1 }}>
                  Payer Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  {selectedPayer.payerDets.payer_name || ""}
                </Typography>
              </Grid>
            </Grid>}
          </Box>
          <Box sx={{ mb: 2 }}>
              <TextField
                  label="Payer Tax ID"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={selectedPayer ? selectedPayer.payerTaxId : ''}
                  onChange={(e) => setSelectedPayer({...selectedPayer, payerTaxId: e.target.value})}
                  error={''}
              />
          </Box>
          <Box sx={{ display: "flex", justifyContent: 'space-between'}}>
            <Box sx={{ mb: 2}}>
                <TextField
                    label="Payer Addr Line 1"
                    inputProps={{ maxLength: 256 }}
                    fullWidth
                    style={{width: 247}}
                    value={selectedPayer ? selectedPayer.payerAddrLine1 : ''}
                    onChange={(e) => setSelectedPayer({...selectedPayer, payerAddrLine1: e.target.value})}
                    error={''}
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <TextField
                    label="Payer Addr Line 2"
                    inputProps={{ maxLength: 256 }}
                    fullWidth
                    style={{width: 247}}
                    value={selectedPayer ? selectedPayer.payerAddrLine2 : ''}
                    onChange={(e) => setSelectedPayer({...selectedPayer, payerAddrLine2: e.target.value})}
                    error={''}
                />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: 'space-between'}}>
            <Box sx={{ mb: 2}}>
                <TextField
                    label="Payer City"
                    inputProps={{ maxLength: 256 }}
                    value={selectedPayer ? selectedPayer.payerCity : ''}
                    onChange={(e) => setSelectedPayer({...selectedPayer, payerCity: e.target.value})}
                    error={''}
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <TextField
                    label="Payer State"
                    inputProps={{ maxLength: 256 }}
                    style={{width: 100}}
                    value={selectedPayer ? selectedPayer.payerDets.payer_state : ''}
                    disabled
                    error={''}
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <TextField
                    label="Payer Zip"
                    inputProps={{ maxLength: 256 }}
                    value={selectedPayer ? selectedPayer.payerZip : ''}
                    onChange={(e) => setSelectedPayer({...selectedPayer, payerZip: e.target.value})}
                    error={''}
                />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: 'space-between'}}>
            <Box sx={{ mb: 2}}>
                <TextField
                    label="Contact First Name"
                    inputProps={{ maxLength: 256 }}
                    fullWidth
                    style={{width: 247}}
                    value={selectedPayer ? selectedPayer.conFirstName : ''}
                    onChange={(e) => setSelectedPayer({...selectedPayer, conFirstName: e.target.value})}
                    error={''}
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <TextField
                    label="Contact Last Name"
                    inputProps={{ maxLength: 256 }}
                    fullWidth
                    style={{width: 247}}
                    value={selectedPayer ? selectedPayer.conLastName : ''}
                    onChange={(e) => setSelectedPayer({...selectedPayer, conLastName: e.target.value})}
                    error={''}
                />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: 'space-between'}}>
            <Box sx={{ mb: 2 }}>
                <TextField
                    label="Contact Designation"
                    inputProps={{ maxLength: 256 }}
                    fullWidth
                    style={{width: 247}}
                    value={selectedPayer ? selectedPayer.conDesignation : ''}
                    onChange={(e) => setSelectedPayer({...selectedPayer, conDesignation: e.target.value})}
                    error={''}
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <TextField
                    label="Payer phone"
                    inputProps={{ maxLength: 256 }}
                    fullWidth
                    style={{width: 247}}
                    value={selectedPayer ? selectedPayer.payerPhone : ''}
                    onChange={(e) => setSelectedPayer({...selectedPayer, payerPhone: e.target.value})}
                    error={''}
                />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="flex-start">
                  <DatePicker
                    id="start"
                    label="START DATE"
                    value={new Date(selectedPayer ? moment.utc(selectedPayer.relStartDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"))}
                    onChange={(d) => {
                      setSelectedPayer({
                        ...selectedPayer,
                        relStartDate: moment(d).format("MM/DD/YYYY"),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="START DATE" error={""} />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
            </Box>
            <Box sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="flex-start">
                  <DatePicker
                    id="end"
                    label="END DATE"
                    value={new Date(selectedPayer ? moment.utc(selectedPayer.relEndDate).format("MM/DD/YYYY") : moment().add(1, "years").format("MM/DD/YYYY"))}
                    onChange={(d) => {
                      setSelectedPayer({
                        ...selectedPayer,
                        relEndDate: moment(d).format("MM/DD/YYYY"),
                      });
                    }}
                    slotProps={{textField: {variant: 'outlined'}}}
                  />
                </Grid>
              </LocalizationProvider>
            </Box>
          </Box>

          {/* {editMode ? (
            newProviderDetails.prov_iden_type === "NPI" ? (
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="NPI ID"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={newProviderDetails.prov_iden}
                  onChange={(e) =>
                    setNewProviderDetails({
                      ...newProviderDetails,
                      npi_id: e.target.value,
                      prov_iden: e.target.value,
                    })
                  }
                  error={""}
                />
              </Box>
            ) : (
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Medicaid Number"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={newProviderDetails.prov_iden}
                  onChange={(e) =>
                    setNewProviderDetails({
                      ...newProviderDetails,
                      medicaid_number: e.target.value,
                      prov_iden: e.target.value,
                    })
                  }
                  error={""}
                />
              </Box>
            )
          ) : (
            <>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="NPI ID"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={newProviderDetails.npi_id}
                  onChange={(e) =>
                    setNewProviderDetails({
                      ...newProviderDetails,
                      npi_id: e.target.value,
                    })
                  }
                  error={""}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Medicaid Number"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={newProviderDetails.medicaid_number}
                  onChange={(e) =>
                    setNewProviderDetails({
                      ...newProviderDetails,
                      medicaid_number: e.target.value,
                    })
                  }
                  error={""}
                />
              </Box>
            </>
          )} */}
          {/* <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="type"
                            options={types}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type"
                                    error={''}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.con_id}>
                                    <ListItemText primary={option.title} />
                                </li>
                            )}
                            value={{ value: newProviderDetails.prov_iden_type, title: newProviderDetails.prov_iden_type }}
                            onChange={(event, newValue) => {
                                setNewProviderDetails({ ...newProviderDetails, prov_iden_type: newValue.value, prov_iden_issuing_entity: providerType_EntityMap[newValue.value] });
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            label="Issuing Entity"
                            inputProps={{ maxLength: 256 }}
                            fullWidth
                            value={newProviderDetails.prov_iden_issuing_entity}
                            onChange={(e) => setNewProviderDetails({ ...newProviderDetails, prov_iden_issuing_entity: e.target.value })}
                            error={''}
                        />
                    </Box> */}
          {/* <Box sx={{ mb: 2 }}>
            <Autocomplete
              id="status"
              options={statuses}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} label="Status" error={""} />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.con_id}>
                  <ListItemText primary={option.title} />
                </li>
              )}
              value={{
                value: newProviderDetails.prov_iden_status,
                title:
                  newProviderDetails.prov_iden_status === 1
                    ? "Active"
                    : "Inactive",
              }}
              onChange={(event, newValue) => {
                setNewProviderDetails({
                  ...newProviderDetails,
                  prov_iden_status: newValue.value,
                });
              }}
            />
          </Box> */}
          {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="flex-start">
                  <DatePicker
                    id="start"
                    label="START DATE"
                    value={moment(
                      newProviderDetails.prov_iden_start_date
                    ).format("YYYY-MM-DDTHH:mm:ss")}
                    onChange={(d) => {
                      setNewProviderDetails({
                        ...newProviderDetails,
                        prov_iden_start_date: moment(d).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="START DATE" error={""} />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
            </Box>
            <Box sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="flex-start">
                  <DatePicker
                    id="end"
                    label="END DATE"
                    value={moment(newProviderDetails.prov_iden_end_date).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}
                    onChange={(d) => {
                      setNewProviderDetails({
                        ...newProviderDetails,
                        prov_iden_end_date: moment(d).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="END DATE" error={""} />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
            </Box>
          </Box> */}
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={editMode ? editPayer : addPayer}
          >
            {editMode ? "Save Changes" : "Add Contract"}
          </Button>
        </Box>
      </Modal>
      <Modal open={openCred} onClose={handleCloseCred}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              maxWidth: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h6" component="div" sx={{ mb: 3 }}>
                {editModeCred ? "Edit Credentials" : "Add Credentials"}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                  label="Client ID"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={selectedPayer ? (selectedPayer.credDets ? selectedPayer.credDets.cred_clientID : '') : ''}
                  onChange={(e) => {
                    let current = {...selectedPayer};
                    current.credDets.cred_clientID = e.target.value;
                    setSelectedPayer({...current});
                  }}
                  error={''}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                  label="Client Secret"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  type="password"
                  value={selectedPayer ? (selectedPayer.credDets ? selectedPayer.credDets.cred_clientSecret : '') : ''}
                  onChange={(e) => {
                    let current = {...selectedPayer};
                    current.credDets.cred_clientSecret = e.target.value;
                    setSelectedPayer({...current});
                  }}
                  error={''}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                  label="Business Entity ID"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={selectedPayer ? (selectedPayer.credDets ? selectedPayer.credDets.bus_entity_id : '') : ''}
                  onChange={(e) => {
                    let current = {...selectedPayer};
                    current.credDets.bus_entity_id = e.target.value;
                    setSelectedPayer({...current});
                  }}
                  error={''}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                  label="Medicaid Identifier"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={selectedPayer ? (selectedPayer.credDets ? selectedPayer.credDets.bus_entity_Medicaid_identifier: '') : ''}
                  onChange={(e) => {
                    let current = {...selectedPayer};
                    current.credDets.bus_entity_Medicaid_identifier = e.target.value;
                    setSelectedPayer({...current});
                  }}
                  error={''}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                  label="Contact Email"
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  value={selectedPayer ? (selectedPayer.credDets ? selectedPayer.credDets.cred_con_email: '') : ''}
                  onChange={(e) => {
                    let current = {...selectedPayer};
                    current.credDets.cred_con_email = e.target.value;
                    setSelectedPayer({...current});
                  }}
                  error={''}
              />
            </Box>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={editModeCred ? editCred : addCred}
            >
              {editModeCred ? "Save Changes" : "Add credentials"}
            </Button>
          </Box>
      </Modal>
    </Box>
  );
};

export default PayerInformationv2;
