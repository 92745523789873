import React, { useState, useEffect, useRef } from "react"
import { Form, Button } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import StopIcon from '@mui/icons-material/Stop';
import "./recordActivity.css";
import axios from "axios";
import withStyles from "@mui/styles/withStyles";
import { APIURL } from "../../../config";
import TextField from "@mui/material/TextField";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIRichTextEditor from 'mui-rte';
import { EditorState, convertToRaw, Modifier, SelectionState } from 'draft-js';
import { borderBottom, borderLeft, borderRight, fontWeight } from "@mui/system";
import moment from "moment";
import MicIcon from '@mui/icons-material/Mic';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const myTheme = createTheme({
    overrides: {
        MUIRichTextEditor: {
            container: {
                paddingLeft: 5
            },
            editor: {
                backgroundColor: "white",
                padding: "20px",
                height: "200px",
                maxHeight: "200px",
                overflow: "auto",
                borderBottom: "1px solid lightblue",
                borderLeft: "1px solid lightblue",
                borderRight: "1px solid lightblue",
            },
            toolbar: {
                border: "1px solid gray",
                backgroundColor: "#ebebeb",
            },
            placeHolder: {
                color: "gray",
                borderTop: "1px solid lightblue",
                borderLeft: "1px solid lightblue",
                borderRight: "1px solid lightblue",
                padding: 20,
            },
            anchorLink: {
                color: "lightblue",
                textDecoration: "underline",
            },
        },
    },
});

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#3FA7D6",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#3FA7D6",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "lightblue",
            },
            "&:hover fieldset": {
                borderColor: "#3FA7D6",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3FA7D6",
            },
        },
    },
})(TextField);

/*
~~~ props ~~~
note_id
mode
user
onSubmit
com_id
*/
export default function NoteFormv2(props) {

    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [projectList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [taskList, setTaskList] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [noteText, setNoteText] = useState(JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())));
    const [noteTitle, setNoteTitle] = useState('');
    const [defaultRteValue, setDefaultRteValue] = useState(`{"blocks":[{"key":"block1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`)
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [taskTypes, setTaskTypes] = useState([]);
    const [selectedTaskType, setSelectedTaskType] = useState(null);
    const [fieldError, setFieldError] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    const [taskTitle, setTaskTitle] = useState("");
    const [startTime, setStartTime] = useState(moment().format('YYYY-MM-DDTHH:mm:ss'));
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DDTHH:mm:ss'));
    const [duration, setDuration] = useState("");
    const [noteTextEditorState, setNoteTextEditorState] = useState(EditorState.createEmpty());
    const [selectedProjectLocation, setSelectedProjectLocation] = useState(null);
    const durations = Array.from({ length: 12 * 4 }, (_, i) => {
        const hours = Math.floor(i / 4);
        const minutes = (i % 4) * 15;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    });
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const [errorAlert, setErrorAlert] = useState('')
    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorAlert('')
        if (props.isVoiceNote) {
            props.onSubmit();
        }
    }

    const [successAlert, setSuccessAlert] = useState('')
    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessAlert('')
        if (props.isVoiceNote) {
            props.onSubmit();
        }
    }

    const MAX_NOTE_LENGTH = 6000;
    const [charCount, setCharCount] = useState(0);

    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [microphoneStream, setMicrophoneStream] = useState(null);
    const [timer, setTimer] = useState(0);
    const voicedTranscript = useRef('');
    const engaged = useRef(false);

    useEffect(() => {
        if (isRecording) {
            let interval = setInterval(() => setTimer(prevTimer => prevTimer + 1), 1000);
            return () => clearInterval(interval);
        }
    }, [isRecording]);

    useEffect(() => {
        const fetchData = async () => {
            await axios.get(`${APIURL}/projlocations`, {
                params: {
                    project_id: selectedProject ? selectedProject.pro_id : null
                }
            })
                .then(response => {
                    let newProjectLocation = response.data.length > 0 ? response.data[0] : null;
                    setSelectedProjectLocation(newProjectLocation);
                })
        }
        fetchData();
    }, [selectedProject]);

    const onStartRecording = async () => {
        try {
            // Clear previous state and resources
            setIsRecording(false);
            setAudioURL('');
            setRecordedAudio(null);
            setTimer(0);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMicrophoneStream(stream);
            const recorder = new MediaRecorder(stream);
            recorder.ondataavailable = (event) => setRecordedAudio(event.data);
            recorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error(error);
        }
    };

    const onStopRecording = () => {
        microphoneStream.getTracks().forEach((track) => track.stop());
        setIsRecording(false);
    };

    useEffect(() => {
        if (!listening) {
            voicedTranscript.current = transcript;
            engaged.current = false;
            resetTranscript();
        } else {
            engaged.current = true;
        }
    }, [listening]);

    const onPlayRecording = () => {
        const url = URL.createObjectURL(recordedAudio);
        setAudioURL(url)
    };

    useEffect(() => {

        const fetchTaskTypes = async () => {
            await axios.get(`${APIURL}/dropdowns/dropdownType/taskType`, {
                params: {
                    comp_id: props.user.comp_id,
                    employeeType: props.user.emp_type
                }
            })
                .then(response => {
                    let newTaskTypes = response.data;
                    setTaskTypes(newTaskTypes);
                })
        }

        fetchTaskTypes();
    }, [])

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (props.note_id && props.mode == 'edit') {
                const noteDataResponse = await axios.get(APIURL + `/comments/details?comment_id=${props.note_id}`);
                const noteData = noteDataResponse.data[0];

                setSelectedCustomer({ cus_id: noteData.cus_id ? noteData.cus_id : "", cus_name: noteData.cus_name ? noteData.cus_name : "" });
                setSelectedProject({ pro_id: noteData.pro_id ? noteData.pro_id : "", pro_title: noteData.pro_title ? noteData.pro_title : "" });
                setSelectedTask({ tas_id: noteData.tas_id ? noteData.tas_id : "", tas_title: noteData.tas_title ? noteData.tas_title : "" });
                setDefaultRteValue(isJSON(noteData.comment_text) ? noteData.comment_text : `{"blocks":[{"key":"block1","text":"${noteData.comment_text.replace(/\n/g, ' ').replace(/\t/g, ' ').replace(/"/g, '\\"')}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`)
                setNoteText(noteData.comment_text)
                setNoteTitle(noteData.note_title)
            } else if (props.customer_id) {
                const customerResponse = await axios.get(APIURL + `/customers?com_id=${props.company_id}&cust_id=${props.customer_id}`)
                setSelectedCustomer(customerResponse.data[0])
            } else if (props.project_id) {
                const response = await axios.get(APIURL + `/project?proj_id=${props.project_id}&comp_id=${props.company_id}`)
                const customerResponse = await axios.get(APIURL + `/customers?com_id=${props.company_id}&cust_id=${response.data.pro_customer_id}`)
                setSelectedCustomer(customerResponse.data[0])
                setTimeout(() => setSelectedProject(response.data), 100);
            } else {
                try {
                    const response = await axios.get(APIURL + `/customers/assigned?emp_id=${props.user.emp_id}`)
                    setCustomerList(response.data.customers)
                }
                catch (err) {
                    setErrorAlert('An error occured while loading customers')
                }
            }
            // Fetch Data Depending on Case
            // if (props.task_id) {
            //     //TODO IF NEEDED
            //     const response = await axios.get(APIURL + `/project?proj_id=${props.project_id}&comp_id=${props.company_id}`)
            //     const customerResponse = await axios.get(APIURL + `/customers?com_id=${props.company_id}&cust_id=${response.data.pro_customer_id}`)
            //     const taskResponse = await axios.get(APIURL + `/task?taskId=${props.task_id}`)
            //     setSelectedTask(taskResponse.data[0]);
            //     setSelectedCustomer(customerResponse.data[0]);
            //     setSelectedProject(response.data);
            // }
            // else if (props.project_id) {
            //     const response = await axios.get(APIURL + `/project?proj_id=${props.project_id}&comp_id=${props.company_id}`)

            //     console.log(response.data)
            //     const customerResponse = await axios.get(APIURL + `/customers?com_id=${props.company_id}&cust_id=${response.data.pro_customer_id}`)
            //     setSelectedCustomer(customerResponse.data[0])
            //     await setTimeout(() => setSelectedProject(response.data), 500);
            // }
            // else if (props.customer_id) {
            //     const customerResponse = await axios.get(APIURL + `/customers?com_id=${props.company_id}&cust_id=${props.customer_id}`)
            //     console.log(customerResponse.data[0])
            //     setSelectedCustomer(customerResponse.data[0])
            // } else {
            //     try {
            //         const response = await axios.get(APIURL + `/customers/assigned?emp_id=${props.user.emp_id}`)
            //         setCustomerList(response.data.customers)
            //     }
            //     catch (err) {
            //         setErrorAlert('An error occured while loading customers')
            //     }
            // }

            // // Get Note Text for Edit Mode
            // if (props.note_id && props.mode == 'edit') {
            //     const response = await axios.get(APIURL + `/comments?comment_id=${props.note_id}`)
            //     setDefaultRteValue(isJSON(response.data[0].comment_text) ? response.data[0].comment_text : `{"blocks":[{"key":"block1","text":"${response.data[0].comment_text.replace(/\n/g, ' ')}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`)
            //     setNoteText(response.data[0].comment_text)
            //     setNoteTitle(response.data[0].note_title)
            // }
        }
        fetchData();
        return () => { mounted = false; engaged.current = false; SpeechRecognition.stopListening(); };
    }, []);

    useEffect(async () => {
        if (props.mode === 'edit') {
            return;
        }
        setSelectedProject(null)
        setProjectList([])
        if (selectedCustomer != null) {
            await getCustomerProjects()
        }
    }, [selectedCustomer]);

    useEffect(async () => {
        if (props.mode === 'edit') {
            return;
        }
        setSelectedTask(null)
        setTaskList([])
        if (selectedProject != null) {
            await getProjectTasks()
        }
    }, [selectedProject]);

    const getCustomerProjects = async () => {
        try {
            const response = await axios.get(APIURL + `/project?cus_id=${selectedCustomer.cus_id}`)
            setProjectList(response.data)
        }
        catch (err) {
            console.log(err)
            setErrorAlert(`An error while loading projects for the selected customer`)
        }
    }

    const getProjectTasks = async () => {
        try {
            const response = await axios.get(APIURL + `/task?pro_id=${selectedProject.pro_id}`)
            if (props.task_id) {
                let tasks = response.data;
                setSelectedTask(tasks.filter(t => t.tas_id === props.task_id)[0])
            }
            let activeTasks = response.data.filter(t => t.tas_is_active !== 2)
            let tempTL = [{ tas_title: 'Add New Task (Timesheet)', tas_id: -1 }, ...activeTasks];
            setTaskList(tempTL)
        }
        catch (err) {
            console.log(err)
            setErrorAlert(`An error while loading tasks for the selected project`)
        }
    }

    const createVoiceNote = async () => {
        try {
            props.checkTokenExpiry()
        } catch (err) {
            console.log(err)
        }

        let errorWhileSaving = false;
        let assignedTaskId = null;

        if (selectedCustomer && selectedProject && selectedTask && recordedAudio) {
            if (selectedTask.tas_id === -1) {
                if (taskDetsValidator()) {
                    await axios.post(APIURL + '/task', {
                        empId: props.user.emp_id,
                        empName: props.user.emp_first_name + ' ' + props.user.emp_last_name,
                        company_id: props.user.comp_id,
                        tTitle: taskTitle,
                        tDesc: 'Time Sheet Entry',
                        tProject: selectedProject.pro_id,
                        tPreReq: 'NULL',
                        tType: selectedTaskType,
                        tStrtDate: moment(startTime).format('YYYY-MM-DD'),
                        tEndDate: moment(endTime).format('YYYY-MM-DD'),
                        tStartTime: moment(startTime).format('YYYY-MM-DDTHH:mm:ss'),
                        tEndTime: moment(endTime).format('YYYY-MM-DDTHH:mm:ss'),
                        tLoc: selectedProjectLocation.loc_id,
                        tActive: 1,
                        tAssTo: props.user.emp_id,
                        tPriority: 'Low',
                        tIsBillable: true,
                        tNotes: 'N/A',
                        user_id: props.user.emp_id
                    }).then(async (response) => {
                        console.log(response);
                        assignedTaskId = response.data.id;
                        await axios.post(APIURL + '/timesheets', {
                            assignedById: props.user.emp_id,
                            projId: selectedProject.pro_id,
                            empId: props.user.emp_id,
                            empName: props.user.emp_first_name + ' ' + props.user.emp_last_name,
                            companyId: props.user.comp_id,
                            taskName: taskTitle,
                            locId: selectedProjectLocation.loc_id,
                            taskId: response.data.id,
                            emploc_lat: parseFloat(selectedProjectLocation.location_lat),
                            emploc_lon: parseFloat(selectedProjectLocation.location_lon),
                            start_time: startTime,
                            end_time: endTime,
                            timesheet_status: 1
                        }).then((resp) => {
                            console.log(resp);
                        }).catch((err) => {
                            console.log(err);
                            errorWhileSaving = true;
                            setErrorAlert('An error occured while trying to create the timesheet')
                        })

                    }).catch((err) => {
                        console.log(err);
                        errorWhileSaving = true;
                        setErrorAlert('An error occured while trying to create the task')
                    })

                } else {
                    errorWhileSaving = true;
                }
            }

            const formData = new FormData();

            formData.append("audio_note", recordedAudio, "voice_note.webm");
            formData.append("emp_id", props.user.emp_id);
            formData.append("project_id", selectedProject.pro_id);
            formData.append("task_id", selectedTask.tas_id === -1 ? assignedTaskId : selectedTask.tas_id);
            formData.append("customer_id", selectedCustomer.cus_id);
            formData.append("note_title", noteTitle);

            if (!errorWhileSaving) {
                let response = await axios.post(APIURL + '/audio_note', formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                }).then(function (response) {
                    setSuccessAlert('Voice Note successfully created')
                }).catch(function (error) {
                    console.log(error);
                    setErrorAlert('An error occured while trying to create the voice note')
                })
            }
        } else {
            if (!selectedCustomer) {
                setErrorAlert(`A selected customer is required to create a voice note`)
            } else if (!selectedProject) {
                setErrorAlert(`A selected project is required to create a voice note`)
            } else if (!selectedTask) {
                setErrorAlert(`A selected task is required to create a voice note`)
            } else {
                setErrorAlert(`Voice Note cannot be empty, please try again.`)
            }
        }
    }

    const taskDetsValidator = () => {
        if (!taskTitle) {
            setErrorAlert(`A task title is required to create a note associated to a new task`);
            return false;
        } else if (!selectedTaskType) {
            setErrorAlert(`A valid task type is required to create a note associated to a new task`);
            return false;
        } else if (!duration) {
            setErrorAlert(`A valid duration is required to create a note associated to a new task`);
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (!listening) {
            return;
        }
        const updateTranscript = async () => {
            return new Promise((resolve) => {
                function updateIt() {
                    let state = noteTextEditorState;
                    let currEditorState = EditorState.createWithContent(state.getCurrentContent());
                    const blockMap = currEditorState.getCurrentContent().getBlockMap();
                    const key = blockMap.last().getKey();
                    const length = blockMap.last().getLength();
                    const selection = new SelectionState({
                        anchorKey: key,
                        anchorOffset: length,
                        focusKey: key,
                        focusOffset: length,
                    });

                    console.log("current everytime:", convertToRaw(currEditorState.getCurrentContent()));
                    const processedTranscript = replacePunctuationWords(transcript);
                    const nextContentState = Modifier.insertText(currEditorState.getCurrentContent(), selection, processedTranscript);

                    let nextEditorState = EditorState.createWithContent(currEditorState.getCurrentContent());
                    nextEditorState = EditorState.push(state, nextContentState, 'voice-to-text');
                    const newEditorState = EditorState.moveSelectionToEnd(nextEditorState, nextContentState.getSelectionAfter());
                    setEditorState(newEditorState);
                    setDefaultRteValue(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())));
                    console.log(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())), convertToRaw(newEditorState.getCurrentContent()))
                    resolve();
                }
                updateIt();
            })
        }
        updateTranscript();
    }, [transcript])

    const createNote = async () => {
        try {
            props.checkTokenExpiry()
        } catch (err) {
            console.log(err)
        }
        let errorWhileSaving = false;

        if (selectedCustomer && editorState.getCurrentContent().hasText()) {
            let assignedTaskId = null;
            if (selectedTask && selectedTask.tas_id === -1) {
                if (taskDetsValidator()) {
                    await axios.post(APIURL + '/task', {
                        empId: props.user.emp_id,
                        empName: props.user.emp_first_name + ' ' + props.user.emp_last_name,
                        company_id: props.user.comp_id,
                        tTitle: taskTitle,
                        tDesc: 'Time Sheet Entry',
                        tProject: selectedProject.pro_id,
                        tPreReq: 'NULL',
                        tType: selectedTaskType,
                        tStrtDate: moment(startTime).format('YYYY-MM-DD'),
                        tEndDate: moment(endTime).format('YYYY-MM-DD'),
                        tStartTime: moment(startTime).format('YYYY-MM-DDTHH:mm:ss'),
                        tEndTime: moment(endTime).format('YYYY-MM-DDTHH:mm:ss'),
                        tLoc: selectedProjectLocation.loc_id,
                        tActive: 1,
                        tAssTo: props.user.emp_id,
                        tPriority: 'Low',
                        tIsBillable: true,
                        tNotes: 'N/A',
                        user_id: props.user.emp_id
                    }).then(async (response) => {
                        console.log(response);
                        assignedTaskId = response.data.id;
                        await axios.post(APIURL + '/timesheets', {
                            assignedById: props.user.emp_id,
                            projId: selectedProject.pro_id,
                            empId: props.user.emp_id,
                            empName: props.user.emp_first_name + ' ' + props.user.emp_last_name,
                            companyId: props.user.comp_id,
                            taskName: taskTitle,
                            locId: selectedProjectLocation.loc_id,
                            taskId: response.data.id,
                            emploc_lat: parseFloat(selectedProjectLocation.location_lat),
                            emploc_lon: parseFloat(selectedProjectLocation.location_lon),
                            start_time: startTime,
                            end_time: endTime,
                            timesheet_status: 1
                        }).then((resp) => {
                            console.log(resp);
                        }).catch((err) => {
                            console.log(err);
                            errorWhileSaving = true;
                            setErrorAlert('An error occured while trying to create the timesheet')
                        })

                    }).catch((err) => {
                        console.log(err);
                        errorWhileSaving = true;
                        setErrorAlert('An error occured while trying to create the task')
                    })

                } else {
                    errorWhileSaving = true;
                }
            }
            console.log(errorWhileSaving);
            if (!errorWhileSaving) {
                await axios.post(APIURL + `/comments`, {
                    cus_id: selectedCustomer.cus_id,
                    pro_id: selectedProject ? selectedProject.pro_id : null,
                    tas_id: selectedTask ? (selectedTask.tas_id === -1 ? assignedTaskId : selectedTask.tas_id) : null,
                    emp_id: props.user.emp_id,
                    comment_text: noteText,
                    note_title: noteTitle,
                    companyId: props.user.comp_id,
                    empName: props.user.emp_name,
                }).then(function (response) {
                    console.log(response);
                    setSuccessAlert('Note successfully created')
                }).catch(function (error) {
                    console.log(error);
                    setErrorAlert('An error occured while trying to create the note')
                });
            }
        } else {
            errorWhileSaving = true;
            if (!selectedCustomer) {
                setErrorAlert(`A selected customer is required to create a note`)
            }
            else {
                setErrorAlert(`Note cannot be empty, please try again.`)
            }
        }
        if (!errorWhileSaving) {
            props.onSubmit();
        }
    }

    const updateNote = async () => {
        props.checkTokenExpiry()
        await axios.put(APIURL + `/comments`, {
            cus_id: selectedCustomer.cus_id,
            pro_id: selectedProject ? selectedProject.pro_id : null,
            tas_id: selectedTask ? selectedTask.tas_id : null,
            emp_id: props.user.emp_id,
            companyId: props.user.comp_id,
            empName: props.user.emp_name,
            comment_id: props.note_id,
            comment_text: noteText,
            note_title: noteTitle,
            updated_by: props.user.emp_id,
        })
            .then(function (response) {
                setSuccessAlert('Note successfully updated')
            })
            .catch(function (error) {
                console.log(error);
                setErrorAlert('An error occured while trying to update the note')
            });

        props.onSubmit()
    }

    const replacePunctuationWords = (text) => {
        const punctuationMap = {
            'comma': ',',
            'period': '.',
            'question mark': '?',
            'exclamation mark': '!',
            'colon': ':',
            'semicolon': ';',
            'dash': '-',
            'hyphen': '-',
            'new line': '\n',
            'new paragraph': '\n\n'
        };

        // Replace punctuation words
        let processedText = text.replace(/\b(comma|period|question mark|exclamation mark|colon|semicolon|dash|hyphen|new line|new paragraph)\b/gi, match => {
            return punctuationMap[match.toLowerCase()] || match;
        });

        // Remove spaces before punctuation
        processedText = processedText.replace(/\s+([,\.?!:;\-\n])/g, '$1');

        return processedText;
    };

    const changeTheEditorState = (state) => {
        console.log(JSON.stringify(convertToRaw(EditorState.createWithContent(state.getCurrentContent()).getCurrentContent())))
        return new Promise((resolve) => {
            function checkIfDoneListening() {
                console.log("donelistening");
                if (!engaged.current) {
                    let currEditorState = EditorState.createWithContent(state.getCurrentContent());
                    const blockMap = currEditorState.getCurrentContent().getBlockMap();
                    const key = blockMap.last().getKey();
                    const length = blockMap.last().getLength();
                    const selection = new SelectionState({
                        anchorKey: key,
                        anchorOffset: length,
                        focusKey: key,
                        focusOffset: length,
                    });

                    const processedTranscript = replacePunctuationWords(voicedTranscript.current);

                    const nextContentState = Modifier.insertText(currEditorState.getCurrentContent(), selection, processedTranscript);

                    let nextEditorState = EditorState.createWithContent(state.getCurrentContent());
                    nextEditorState = EditorState.push(state, nextContentState, 'voice-to-text');
                    const newEditorState = EditorState.moveSelectionToEnd(nextEditorState, nextContentState.getSelectionAfter());
                    setEditorState(newEditorState);
                    setDefaultRteValue(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())));
                    setNoteText(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())));
                    setNoteTextEditorState(nextEditorState);
                    voicedTranscript.current = ''
                    resolve();
                } else {
                    setTimeout(checkIfDoneListening, 300);
                }
            }
            checkIfDoneListening();
        });
    }

    const onEditorChange = (state) => {
        // More info about EditorState object at
        // https://draftjs.org/docs/api-reference-editor-state
        //

        // Get current selection
        // console.log(state.getSelection());

        // Get current content
        // console.log(JSON.stringify(convertToRaw(state.getCurrentContent())));
        setEditorState(state);
        if (!engaged.current) {
            setNoteTextEditorState(state);
        }
        const content = convertToRaw(state.getCurrentContent());
        setNoteText(JSON.stringify(content));
        // Get current text
        // console.log(state.getCurrentContent().getPlainText());

        // Check if editor is empty
        // if (!state.getCurrentContent().hasText()) {
        //     console.log("empty");
        // }

        const textLength = content.blocks.reduce((acc, block) => acc + block.text.length + 1, 0) - 1;
        setCharCount(textLength);
    };

    function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    return (
        (<div className='noteForm-mainDiv'>
            {props.mode == 'edit' ? <h4>Edit Note</h4> : !props.isVoiceNote ? <h4>New Note</h4> : <h4>New Voice Note</h4>}
            <br></br>
            <div>
                <Autocomplete
                    noOptionsText='Loading Customers...'
                    disabled={props.mode === 'edit' || props.project_id != null}
                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                    value={selectedCustomer}
                    onChange={async (e, value, reason) => {
                        if (reason == 'clear') {
                            setSelectedCustomer(null);
                        }
                        else if (value) {
                            setSelectedCustomer(value);
                        }
                    }}
                    options={customerList}
                    getOptionLabel={(option) => option.cus_name}
                    renderInput={(params) => (
                        <CssTextField {...params} label="Customer" variant="outlined" />
                    )}
                />
                <Autocomplete
                    noOptionsText='Loading Projects...'
                    disabled={props.mode === 'edit' || props.project_id != null || selectedCustomer == null}
                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                    value={selectedProject}
                    onChange={(e, value, reason) => {
                        if (reason == 'clear') {
                            setSelectedProject(null);
                        }
                        else if (value) {
                            setSelectedProject(value);
                        }
                    }}
                    options={projectList}
                    getOptionLabel={(option) => option.pro_title}
                    renderInput={(params) => (
                        <CssTextField {...params} label="Project" variant="outlined" />
                    )}
                />
                <Autocomplete
                    noOptionsText='No Tasks Found'
                    disabled={props.mode === 'edit' || props.task_id != null || selectedProject == null}
                    style={{ margin: '5px', marginBottom: '20px', width: '100%', fontWeight: 'bold' }}
                    value={selectedTask}
                    disablePortal
                    sx={{
                        '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                            fontWeight: 'bold',
                        }
                    }}
                    onChange={(e, value, reason) => {
                        if (reason == 'clear') {
                            setSelectedTask(null);
                        }
                        else if (value) {
                            setSelectedTask(value);
                            console.log();
                        }
                    }}
                    options={taskList}
                    getOptionLabel={(option) => `${option.tas_title}`} //TODO add PROPER square bracketed date before the task title like '[10-10-2024] My Task Title' 
                    renderInput={(params) => (
                        <CssTextField {...params} label="Task" variant="outlined" />
                    )}
                />

                {
                    selectedTask !== undefined && selectedTask !== null && props.mode !== 'edit' && selectedTask.tas_id === -1 &&
                    <div>
                        <CssTextField
                            label="Task Title"
                            value={taskTitle}
                            onChange={(e) => setTaskTitle(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                        />

                        <Autocomplete
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            options={taskTypes}
                            value={selectedTaskType}
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) => setSelectedTaskType(newValue)}
                            renderInput={(params) => <CssTextField {...params} label="Task Type" />}
                        />

                        <div style={{ display: 'flex', flexDirection: 'row', margin: '1px', width: '100%', justifyContent: 'space-between' }}>

                            <LocalizationProvider class='DatePickerParent' dateAdapter={AdapterDateFns}>

                                <DatePicker
                                    label="Task Date"
                                    className={`mx-1 my-3`}
                                    // style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                                    value={moment(selectedDate).isValid() ? new Date(moment(selectedDate)) : null}
                                    onChange={(d) => { setSelectedDate(dayjs(d).format("YYYY-MM-DD")) }}
                                    slotProps={{ textField: { variant: 'outlined' } }}
                                />

                            </LocalizationProvider>

                            <FormControl sx={{ marginTop: "17px", width: "40%" }}>
                                <InputLabel id="duration-label">Duration</InputLabel>
                                <Select
                                    labelId="duration-label"
                                    value={duration}
                                    onChange={(e) => {
                                        setDuration(e.target.value);
                                        const [hours, minutes] = e.target.value.split(':').map(Number);
                                        setStartTime(moment(selectedDate).set({ hour: 9, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss'));
                                        setEndTime(moment(selectedDate).set({ hour: 9 + hours, minute: minutes }).format('YYYY-MM-DDTHH:mm:ss'));
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 8,
                                            },
                                        },
                                    }}
                                >
                                    {durations.map((duration) => (
                                        <MenuItem key={duration} value={duration}>{duration}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }

                <CssTextField
                    value={noteTitle}
                    onChange={(event) => {
                        setNoteTitle(event.target.value)
                    }}
                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                    inputProps={{ maxLength: 50 }}
                    disabled={props.mode === 'edit'}
                    label="Note Title"
                    id="nTitle"
                />
                {/* {!props.isVoiceNote && 
                    <CssTextField
                        multiline
                        value={noteText}
                        onChange={(event) => {
                            setNoteText(event.target.value)
                        }}
                        style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                        inputProps={{ maxLength: 8000 }}
                        minRows={4}
                        label="Notes"
                        variant="outlined"
                        id="aNote"
                    />
                } */}
                {!props.isVoiceNote &&
                    <ThemeProvider theme={myTheme}>
                        <MUIRichTextEditor
                            label="Click here to start typing..."
                            defaultValue={defaultRteValue}
                            onChange={onEditorChange}
                            maxLength={MAX_NOTE_LENGTH}
                            controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "media", "bulletList", "numberList", "quote", "code", "clear", "save", "voice-to-text"]}
                            customControls={[
                                {
                                    name: "voice-to-text",
                                    icon: engaged.current ? <StopIcon color={"error"} /> : <MicIcon color={"action"} />,
                                    type: "callback",
                                    onClick: (_editorState, name, _anchor) => {
                                        if (!listening) {
                                            engaged.current = true;
                                            SpeechRecognition.startListening({ continuous: true });
                                        } else {
                                            engaged.current = false;
                                            SpeechRecognition.stopListening();
                                        }
                                        changeTheEditorState(_editorState);
                                        // let currEditorState = EditorState.createWithContent(_editorState.getCurrentContent())
                                        // let selection = currEditorState.getSelection().merge({
                                        //     anchorKey: currEditorState.getCurrentContent().getFirstBlock().getKey(),
                                        //     anchorOffset: 0,  

                                        //     focusOffset: currEditorState.getCurrentContent().getLastBlock().getText().length, 
                                        //     focusKey: currEditorState.getCurrentContent().getLastBlock().getKey(),
                                        // })
                                        // let newEditorState = EditorState.forceSelection(currEditorState, selection);
                                        // console.log(newEditorState.getSelection().getStartKey(), newEditorState.getSelection().getEndKey());

                                        // const nextContentState = Modifier.replaceText(currEditorState.getCurrentContent(), selection, currEditorState.getCurrentContent().getPlainText() + "inserted this here");
                                        // console.log(nextContentState.getPlainText());
                                        // let nextEditorState = EditorState.createEmpty();
                                        // nextEditorState = EditorState.push(editorState, nextContentState, 'insert-characters');
                                        // setEditorState(nextEditorState);
                                        // setNoteText(JSON.stringify(convertToRaw(nextEditorState.getCurrentContent())));
                                        // onEditorChange(nextEditorState);
                                        // return nextEditorState;
                                    }
                                }
                            ]}
                        />
                        <div style={{
                            float: 'right',
                            fontSize: "12px",
                            color: charCount === MAX_NOTE_LENGTH ? "red" : "gray",
                        }}>
                            {charCount} / {MAX_NOTE_LENGTH}
                        </div>
                    </ThemeProvider>
                }
                {props.isVoiceNote &&
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {!isRecording && (
                                <Button onClick={onStartRecording}><i className="fas fa-microphone"></i></Button>
                            )}
                            {isRecording && (
                                <div>
                                    <Button style={{ marginRight: '10px' }} onClick={onStopRecording}><i className="fas fa-stop-circle"></i></Button>
                                    <span>Recording Time: {timer} seconds</span>
                                </div>
                            )}
                            {!isRecording && recordedAudio && !audioURL && <Button style={{ marginLeft: '10px' }} onClick={onPlayRecording}><i className="fas fa-play-circle"></i></Button>}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            {audioURL && (
                                <audio controls autoPlay src={audioURL} />
                            )}
                        </div>
                    </div>
                }
                <Button
                    style={{
                        width: '100%',
                        padding: '5px',
                        margin: '5px',
                    }}
                    onClick={props.mode == 'edit' ? () => updateNote() : !props.isVoiceNote ? () => createNote() : () => createVoiceNote()}
                    disabled={props.isVoiceNote ? (recordedAudio ? false : true) : (editorState?.getCurrentContent().hasText() ? false : true)}
                >
                    {props.mode === 'edit' ? 'Update Note' : !props.isVoiceNote ? 'Create Note' : 'Create Voice Note'}
                </Button>
            </div>
            <Snackbar open={successAlert != ''} autoHideDuration={5000} onClose={handleSuccessAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"success"} elevation={6} variant="filled" {...props}>
                    {successAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleSuccessAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
            <Snackbar open={errorAlert != ''} autoHideDuration={5000} onClose={handleErrorAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"error"} elevation={6} variant="filled" {...props}>
                    {errorAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleErrorAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
        </div>)
    );
}