import React, { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import TaskForm from '../../../components/forms/TaskForm/TaskForm';
import './Tasks.css';
import axios from 'axios';
import moment from "moment";
import { APIURL } from '../../../config'
import { Card, CardActions, CardContent, IconButton, Dialog, DialogActions, DialogContent, Button, TextField, InputAdornment } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import GestureIcon from '@mui/icons-material/Gesture';
import ArticleIcon from '@mui/icons-material/Article';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Tooltip from '@mui/material/Tooltip';
import ViewMTModal from '../../SupportCoordinator/Modals/ViewMTModal';
import ViewFormModal from '../../../components/forms/JsonForm/ViewFormModal';
import TaskCalendar from './TaskCalendar';
import SearchIcon from '@mui/icons-material/Search';

class Tasks extends Component {
    state = {
        project: null,
        task: null,
        taskType: [],
        currentTask: null,
        currentTaskID: null,
        deletedTask: null,
        taskList_display: null,
        searchTerm: '',
        timer: null,
        taskList: null,
        locList: [],
        empList: [],
        project_tasks: [],
        selectedTab: 0,
        tabs: ['Ongoing', 'Completed'],
        show: false,
        showInfo: false,
        showForm: false,
        showCusForms: false,
        onGoingTasks: [],
        completedTasks: [],
        loading: true,
        content: [],
        newTaskLoading: true,
        editTaskLoading: true,
        contextMenu: 0,
        employees: {
            list: [{}],
            empType: [],
            empLevel: [],
            empStatus: [],
            currency: [],
            frequency: [],
        },
        projects: { list: [] },
        tasks: { list: [{}], taskType: [] },
        locations: { list: [{}], states: [], locType: [], locStatus: [] },
        mapRender: "task",
        showSignPopover: false,
        signUrl: "",
        signLoading: false,
        location: "_",
        filteredTasks: [],
        openingTime: new Date(1972, 0, 1, 0, 0, 0, 0),
        closingTime: new Date(1972, 0, 1, 23, 59, 59, 999),
        selectedDays: [],
        calendarView: 'month',
    }

    isSupport = this.props.user.comp_settings.find(setting => setting.feature_id === 101 && setting.feature_value === 'primaryBusinessType' && setting.state_id === 'NJ');

    handleClose = () => { this.setState({ show: false, showInfo: false, contextMenu: 0 }) };
    handleShow = (task) => {
        this.setState({ currentTask: task, show: true });
    };

    handleCusFormsClose = () => { this.setState({ showCusForms: false }) };
    handleCusFormsShow = (task) => { this.setState({ currentTask: task, showCusForms: true }) };

    handleFormClose = () => { this.setState({ showForm: false }) };
    handleFormShow = (task) => { console.log(task); this.setState({ currentTask: task, showForm: true }) };

    handleShowInfo = (id) => { this.setState({ showInfo: true, currentTaskID: id }) };

    handleShowSignPopover = async (task) => {
        if (this.state.showSignPopover) {
            this.setState({ showSignPopover: false });
        } else {
            this.setState({ showSignPopover: true, signLoading: true, currentTask: task });
            let signature = await axios
                .get(APIURL + "/signature", {
                    params: { task_id: task.tas_id },
                    responseType: "arraybuffer",
                })
                .then((response) => {
                    const blob = new Blob([response.data], { type: "image/png" });
                    const url = URL.createObjectURL(blob);
                    if (task.checkoutLocId) {
                        let loc = this.state.locList.find((loc) => loc.loc_id === task.checkoutLocId);
                        this.setState({ location: loc.loc_addr_line1 + ", " + (loc.loc_addr_line2 ? loc.loc_addr_line2 + ", " : "") + loc.loc_city + ", " + loc.loc_state + ", " + loc.loc_zip });
                    }
                    this.setState({ signUrl: url, signLoading: false });
                }).catch((err) => {
                    this.setState({ signUrl: "Something went wrong. Please try again later.", signLoading: false });
                });
        }
    }

    showTaskList = (taskList) => {
        if (taskList.length !== 0) {
            console.log(taskList)
            return taskList.map((task) => (
                <Card sx={{ marginTop: '10px', minHeight: "150px" }} key={task.tas_id}>
                    <CardContent sx={{}}>
                        <div style={{ fontSize: '20px', display: 'flex', justifyContent: 'space-between' }}>
                            {task.tas_title}
                            <span>
                                Scheduled: {moment(task.tas_start_time.replace(/Z/g, "")).format("MMMM DD, hh:mm A")} - {moment(task.tas_end_time.replace(/Z/g, "")).format("MMMM DD, hh:mm A")}
                            </span>
                        </div>
                        <div style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between' }}>
                            <span>
                                {task.tas_type}
                            </span>
                            <div style={{ fontSize: '18px', float: 'right' }}>
                                <span>{!!task.empLocInfo ? 'Actual: ' + moment(task.empLocInfo[0].empLocdate?.replace(/Z/g, "")).format("MMMM DD, hh:mm A") + ' - ' + moment(task.empLocInfo[0].checkoutEmpDate?.replace(/Z/g, "")).format("MMMM DD, hh:mm A") : ''} </span>
                            </div>
                        </div>
                    </CardContent>
                    <CardActions sx={{ width: '100%' }}>
                        {task.tas_is_active === 1 &&
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: "8px" }}>
                                <Button size='sm' style={{ marginBottom: '10px', width: '100px' }}
                                    onClick={() => { this.handleShow(task) }}
                                >View details</Button>
                            </div>}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: "8px" }}>
                            {moment().isAfter(moment(task.tas_end_date).add(1, "d")) &&
                                task.tas_is_active === 0 && <div className="overDue">Overdue</div>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <span style={{ float: 'right', display: 'inline-flex' }}>
                                {task.tas_required_sig === 1 && task.tas_is_active === 1 &&
                                    <Tooltip title={"Signed on " + moment(task.checkoutEmpDate).utc().format("MMM DD, hh:mm A")} >
                                        <IconButton onClick={() => this.handleShowSignPopover(task)} size="small">
                                            <GestureIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {task.customerForm &&
                                    <Tooltip title="Forms" >
                                        <IconButton size="small" onClick={() => this.handleCusFormsShow(task)}>
                                            <FormatListBulletedIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {task.mtInfo && this.isSupport &&
                                    <Tooltip title="MT Task" >
                                        <IconButton size="small" onClick={() => this.handleFormShow(task)}>
                                            <ArticleIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {task.tas_is_active !== 1 &&
                                    <IconButton size="small" onClick={() => this.handleShowInfo(task.tas_id)}>
                                        <InfoIcon />
                                    </IconButton>
                                }
                                {task.tas_is_active !== 1 && !task.checkin && (
                                    <IconButton size="small" onClick={() => this.handleShow(task)}>
                                        <EditIcon />
                                    </IconButton>
                                )}
                                {(task.tas_is_active !== 1 || task.tas_is_active === 1) &&
                                    <IconButton size="small" onClick={() => this.deleteTask(task.tas_id, task.tas_title)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            </span>
                            <Modal show={this.state.showInfo && task.tas_id === this.state.currentTaskID} onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{task.tas_title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <small className="text-muted">Task</small>
                                    <p>{task.tas_title}</p>
                                    <small className="text-muted">Task Type</small>
                                    <p>{task.tas_type}</p>
                                    <small className="text-muted">Project</small>
                                    <p>{task.pro_title}</p>
                                    <small className="text-muted">Employee</small>
                                    <p>{task.empInfo[0].emp_first_name}{" "}{task.empInfo[0].emp_last_name}</p>
                                    <small className="text-muted">Location</small>
                                    <p>
                                        {(!!task.locInfo ? task.locInfo[0].loc_addr_line1 : '') +
                                            " " +
                                            (!!task.locInfo ? task.locInfo[0].loc_addr_line2 : '') +
                                            " " +
                                            (!!task.locInfo ? task.locInfo[0].loc_city : '') +
                                            " " +
                                            (!!task.locInfo ? task.locInfo[0].loc_state : '') +
                                            " " +
                                            (!!task.locInfo ? task.locInfo[0].loc_zip : '')}
                                    </p>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </CardActions>
                </Card>
            ));
        } else {
            return (
                <div>
                    {!this.state.loading && <div className='d-flex flex-column justify-content-center align-items-center mt-4' style={{ height: '300px', margin: 5 }}>
                        <img src='./pie.png' alt='taskTab' style={{ width: '10rem', height: '10rem' }} />
                        <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 48, marginBottom: 28 }}>No tasks found </h5>
                        <p style={{ fontFamily: 'Lato', fontSize: 14 }} className='text-muted text-center w-50'>We could not find any tasks in this projects. Please add a task and assign them to you employees.</p>
                    </div>}
                </div>
            )
        }
    }

    async setTasks() {
        const projResponse = await axios.get(APIURL + `/project`, { params: { cus_id: this.props.cus_id } })
        let projects = projResponse.data
        if (projects.length !== 0) {
            let taskData = await axios.get(APIURL + '/task', { params: { com_id: this.props.comp_id, cust_id: this.props.cus_id } })
            console.log(taskData);
            let tasks = taskData.data.filter((task) => !moment(task.pro_ends_on).isBefore(moment())).sort((a, b) => parseInt(moment(b.tas_start_date).format('YYYYMMDDHHmm')) - parseInt(moment(a.tas_start_date).format('YYYYMMDDHHmm')))
            let onGoing = tasks.filter((task) => (task.tas_is_active === 0))
            let completed = tasks.filter((task) => (task.tas_is_active === 1))

            this.setState({
                project: projects[0],
                taskList: tasks,
                taskList_display: tasks,
                onGoingTasks: onGoing,
                completedTasks: completed,
                loading: false
            })
        }

        this.setState({ loading: false })
    }

    async fetchNewTaskData() {
        let data = {
            employees: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
                empType: [],
                empLevel: [],
                empStatus: [],
                currency: [],
                frequency: [],
            },
            projects: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/projectslist", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
            },
            tasks: {
                // list: (
                //     await axios.get(APIURL + "/dropdowns/dropdownType/taskslist", {
                //         params: { comp_id: this.props.comp_id },
                //     })
                // ).data,
                taskType: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/taskType", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
            },
            locations: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/locationslist", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
                states: [],
                locType: [],
                locStatus: [],
            },
        };

        this.setState({ ...data });
        this.setState({ newTaskLoading: false });
        console.log(data);
    }

    async fetchEditTaskData() {
        let taskType = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.comp_id } })
        this.setState({ taskType: taskType.data })

        let currProject = await axios.get(APIURL + "/project", { params: { proj_id: this.state.project.pro_id, comp_id: this.props.comp_id } })
        this.setState({ empList: currProject.data.employees, locList: currProject.data.locations, project_tasks: currProject.data.tasks })

        this.setState({ editTaskLoading: false })
    }

    async componentDidMount() {
        await this.setTasks()
        this.fetchNewTaskData()
        this.fetchEditTaskData()
        this.selectTaskTab(0); // Initialize with ongoing tasks

        // Fetch opening and closing times and selected days
        const schedulerSettings = this.props.user.comp_settings.find(setting => setting.feature_id === 80);
        if (schedulerSettings) {
            const schedulerHours = JSON.parse(schedulerSettings.feature_value);
            this.setState({
                openingTime: new Date(schedulerHours.openingTime || '1972-01-01T00:00:00.000Z'),
                closingTime: new Date(schedulerHours.closingTime || '1972-01-01T23:59:59.999Z'),
                selectedDays: schedulerHours.days || []
            });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.deletedTask !== this.state.deletedTask) {
            this.setTasks()
        }

        // if (this.state.contextMenu === 1 && !!this.state.newTaskLoading) {
        //     await this.fetchNewTaskData()
        // }

        // if (this.state.contextMenu === 2 && !!this.state.editTaskLoading) {
        //     await this.fetchEditTaskData()
        // }
    }

    selectTaskTab(tabIndex) {
        const { onGoingTasks, completedTasks } = this.state;
        const filteredTasks = tabIndex === 0 ? onGoingTasks : completedTasks;
        this.setState({
            selectedTab: tabIndex,
            filteredTasks: filteredTasks
        });
    }

    handleViewChange = (view) => {
        this.setState({ calendarView: view });
    };

    viewEditForm = (id) => {
        const task = this.state.taskList.filter(task => id === task.tas_id)
        this.setState({
            task: task[0],
            contextMenu: 2,
            show: false
        })
    }

    viewTasks = () => {
        this.setState({
            task: ""
        })
    }

    filterOutTask = () => {
        let task = { ...this.state.task }
        if (this.state.task.hasOwnProperty("tas_prequisites_task")) {
            const tas_name = this.state.taskList.filter(item => item.tas_id === this.state.task.tas_prequisites_task)[0]
            if (tas_name) {
                task["tas_prequisites_name"] = tas_name.tas_title

            }
        }
        return task
    }

    filterOutTaskLocation = () => {
        if (this.state.task.locInfo) {
            return this.state.task.locInfo
        }
        else {
            return []
        }
        // return this.props.location_list.filter(loc => loc.loc_id === this.state.task.tas_loc_id)[0]
    }

    filterOutEmp = () => {
        return this.state.task.empInfo[0]
        // return this.props.employees.find(emp => emp.emp_id === this.state.task.tas_emp_id).emp_id
    }

    deleteTask = (taskId, taskTitle) => {
        if (this.state.timer) {
            alert("Another delete is in process, wait for the undo dailogue to disappear.")
        } else {
            let oldTaskList = [...this.state.taskList_display]
            oldTaskList = oldTaskList.filter(task => task.tas_id !== taskId)
            this.setState({
                deletedTask: this.state.taskList_display.find(task => task.tas_id === taskId),
                timer: setTimeout(() => {
                    this.deleteRequest(this.state.deletedTask.tas_id, taskTitle)
                    clearTimeout(this.state.timer)
                    this.setState({
                        timer: null,
                        deletedTask: null
                    })
                }, 5000),
                taskList_display: oldTaskList,
                taskList: oldTaskList
            })
        }
    }

    deleteRequest = (id, taskTitle) => {
        console.log(this.props)
        axios.delete(APIURL + "/task", { data: { id: id, empId: this.props.user.emp_id, empName: this.props.user.emp_name, companyId: this.props.comp_id, taskName: taskTitle, projectId: this.state.deletedTask.tas_pro_id, timesheetEntry: false } })
            .then(res => {
                if (res.status === 200) {
                    alert("Task Deleted")
                } else {
                    alert("Something went wrong.")
                }
            })
            .catch(error => console.log(error))
    }

    undoDelete = () => {
        let oldTaskList = [...this.state.taskList_display]
        oldTaskList.push(this.state.deletedTask)
        clearTimeout(this.state.timer)
        this.setState({
            taskList: oldTaskList,
            taskList_display: oldTaskList,
            deletedTask: null,
            timer: null,
        })
    }

    showAlert = () => {
        if (this.state.timer) {
            return (
                <Alert variant="info" onClose={() => this.onDismissAlert()} style={{ marginTop: "10px" }} dismissible>
                    Task Deleted. <span className="undo" onClick={() => this.undoDelete()}>Undo?</span>
                </Alert>
            )
        }
    }

    onDismissAlert = () => {
        this.deleteRequest(this.state.deletedTask.tas_id, this.state.deletedTask.tas_title)
        clearTimeout(this.state.timer)
        this.setState({
            timer: null,
            deletedTask: null
        })
    }

    searchTaskHandler = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        this.setState({ searchTerm }, this.filterTasks);
    }

    filterTasks = () => {
        const { searchTerm, taskList } = this.state;
        const filteredTasks = taskList.filter(task =>
            task.tas_title.toLowerCase().includes(searchTerm) ||
            task.tas_type.toLowerCase().includes(searchTerm) ||
            (task.tas_prequisites_name && task.tas_prequisites_name.toLowerCase().includes(searchTerm))
        );
        this.setState({ filteredTasks });
    }

    navigationHandler = (contextMenu) => {
        this.setState({ contextMenu })
    }

    displayLoc = () => {
        if (!!this.state.currentTask.locInfo) {
            return this.state.currentTask.locInfo.map((loc, i) => {
                let address = loc.loc_addr_line1 + " " + loc.loc_addr_line2 + " " + loc.loc_city + " " + loc.loc_state + " " + loc.loc_zip;
                return (
                    <div className="customer-info-block row">
                        <div className="col-sm-5 pt-3 text-muted">LOCATION {this.state.currentTask.locInfo.length > 1 ? i + 1 : ''}</div>
                        <div className="col-sm-7 pt-3">
                            {address}
                        </div>
                    </div>)
            })
        }
        else {
            return ''
        }
    }

    displayEmployee = () => {
        let emp = this.state.currentTask.empInfo[0]
        return emp.emp_first_name + " " + emp.emp_last_name;
    }

    handleTaskClick = (task) => {
        this.setState({ currentTask: task, show: true });
    };

    render() {
        let content = null;
        if (this.state.contextMenu === 2) {
            content = (
                <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.contextMenu === 2} onClose={() => this.navigationHandler(0)}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>Edit Task</h4>
                        <hr style={{ marginBottom: '10px', marginTop: '10px', backgroundColor: '#e2ecfa' }}></hr>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.navigationHandler(0)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        {!!this.state.editTaskLoading &&
                            <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!this.state.editTaskLoading && <TaskForm
                            edit
                  S          mapRender={"task"}
                            history={this.props.history}
                            formVals={this.filterOutTask()}
                            projList={[{
                                pname: this.state.task.pro_title,
                                pid: this.state.task.pro_id,
                                start_date: this.state.task.pro_starts_on,
                                end_date: this.state.task.pro_ends_on,
                                cus_id: this.state.task.pro_customer_id
                            }]}
                            taskType={this.state.taskType}
                            taskList={this.state.project_tasks}
                            isFormValidated={this.props.isFormValidated}
                            locList={this.state.locList}
                            empList={this.state.empList}
                            task_location={this.filterOutTaskLocation()}
                            task_emp={this.filterOutEmp()}
                            taskPriority={this.state.task.tas_priority}
                            taskIsBillable={this.state.task.tas_billable_by_default}
                            tSigReq={this.state.task.tas_required_sig}
                            taskComment={this.state.task.tas_notes}
                            taskContacts={this.state.task.taskContacts}
                            taskId={this.state.task.tas_id}
                            comp_id={this.props.comp_id}
                            user={this.props.user}
                        />}
                    </DialogContent>
                </Dialog>
            )
        }
        else if (this.state.contextMenu === 1) {
            content =
                <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.contextMenu === 1} onClose={() => this.navigationHandler(0)} style={{ zIndex: 1 }}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>New Task</h4>
                        <hr style={{ marginBottom: '10px', marginTop: '10px', backgroundColor: '#e2ecfa' }}></hr>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.navigationHandler(0)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        {!!this.state.newTaskLoading &&
                            <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!this.state.newTaskLoading && <TaskForm
                            {...this.props}
                            isFormValidated={this.props.isNewFormValidated}
                            mapRender={this.state.mapRender}
                            // taskList={this.state.tasks.list}
                            empList={this.state.employees.list}
                            locList={this.state.locations.list}
                            projList={this.state.projects.list}
                            taskType={this.state.tasks.taskType}
                            updateMapRender={this.state.mapRender}
                            comp_id={this.props.comp_id}
                            customerPage={true}
                        />}
                    </DialogContent>
                </Dialog>
        }
        else if (this.state.contextMenu === 0) {
            content = (
                <div className="TasksPage">
                    <div className="TopPanel">
                        <div style={{ marginRight: '10px' }}>
                            <TextField
                                onChange={this.searchTaskHandler}
                                value={this.state.searchTerm}
                                placeholder="Search"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div>
                            <Button onClick={() => this.navigationHandler(1)} variant="contained" color="primary" sx={{ height: '40px' }}>New Task</Button>
                        </div>
                    </div>
                    <div className='tasks_info'>
                        <div>
                            <h5>Tasks</h5>
                            {this.showAlert()}
                        </div>
                        <div className='task_button_group'>
                            <div style={{ opacity: '90%' }} className={`project_button ${this.state.tabs[this.state.selectedTab] === 'Ongoing' ? 'active' : 'inactive'}`} onClick={() => this.selectTaskTab(0)}>
                                <div>On Going</div>
                                {this.state.tabs[this.state.selectedTab] === 'Ongoing' && <div className='project_button_number'>{this.state.taskList && this.state.onGoingTasks.length}</div>}
                            </div>
                            <div style={{ opacity: '90%' }} className={`project_button ${this.state.tabs[this.state.selectedTab] === 'Completed' ? 'active' : 'inactive'}`} onClick={() => this.selectTaskTab(1)}>
                                <div>Completed</div>
                                {this.state.tabs[this.state.selectedTab] === 'Completed' && <div className='project_button_number'>{this.state.taskList && this.state.completedTasks.length}</div>}
                            </div>
                        </div>
                        {!!this.state.loading &&
                            <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!this.state.loading &&
                            <div className="taskCardList" style={{ marginTop: '20px' }}>
                                <TaskCalendar 
                                    tasks={this.state.filteredTasks} 
                                    openingTime={this.state.openingTime} 
                                    closingTime={this.state.closingTime} 
                                    selectedDays={this.state.selectedDays} 
                                    onTaskClick={this.handleTaskClick}
                                    onEditTask={this.viewEditForm}
                                    view={this.state.calendarView}
                                    onViewChange={this.handleViewChange}
                                    onShowSignPopover={this.handleShowSignPopover}
                                    onShowCusForms={this.handleCusFormsShow}
                                    onShowForm={this.handleFormShow}
                                    isSupport={this.isSupport}
                                    onDeleteTask={this.deleteTask}
                                    onUndoDelete={this.undoDelete}
                                />
                            </div>
                        }
                    </div>
                </div>
            )
        }
        return (
            (<div>
                {content}
                {this.state.currentTask &&
                    <div>
                        <ViewFormModal
                            forms={this.state.currentTask.customerForm}
                            cus_id={this.props.cus_id}
                            customer_name={this.props.cust_details.cus_name}
                            task_id={this.state.currentTask.tas_id}
                            taskDetails={this.state.currentTask ? this.state.currentTask : ''}
                            user_id={this.props.user.emp_id}
                            readOnly={true}
                            show={this.state.showCusForms}
                            hide={this.handleCusFormsClose}
                            read={true}
                        />
                        <ViewMTModal
                            user={this.props.user}
                            show={this.state.showForm}
                            handleClose={this.handleFormClose}
                            type={this.state.currentTask?.mtInfo ? this.state.currentTask.mtInfo[0].mt_type : ''}
                            data={this.state.currentTask?.mtInfo ? JSON.parse(this.state.currentTask.mtInfo[0].data) : {}}
                            mm_id={this.state.currentTask?.mtInfo ? this.state.currentTask.mtInfo[0].monthly_monitoring_id : ''}
                            cus_name={this.state.currentTask?.cusInfo ? this.state.currentTask.cusInfo[0].cus_name : ''}
                            checkoutEmpDate={this.state.currentTask?.empLocInfo ? this.state.currentTask.empLocInfo[0].checkoutEmpDate : ''}
                        />
                        <Modal style={{ marginTop: '5%' }} show={this.state.show} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Task Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ fontSize: '1.1rem' }}>
                                <div className="d-flex justify-content-between">
                                    <div>{this.state.currentTask.tas_title}</div>
                                    {this.state?.currentTask?.tas_is_active !== 1 && <i className="fas fa-edit" style={{ cursor: "pointer" }} onClick={() => this.viewEditForm(this.state.currentTask.tas_id)}></i>}
                                </div>
                                <div>{this.state.currentTask.tas_description}</div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">PROJECT</div>
                                    <div className="col-sm-7 pt-3">{this.state.currentTask.pro_title}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">TASK PREREQUISITE</div>
                                    <div className="col-sm-7 pt-3">{this.state.currentTask.tas_prequisites_task}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">TASK TYPE</div>
                                    <div className="col-sm-7 pt-3">{this.state.currentTask.tas_type}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">START TIME</div>
                                    <div className="col-sm-7 pt-3">{moment(this.state.currentTask.tas_start_time.replace(/Z/g, "")).format("MM/DD/YY hh:mm A")}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">END TIME</div>
                                    <div className="col-sm-7 pt-3">{moment(this.state.currentTask.tas_end_time.replace(/Z/g, "")).format("MM/DD/YY hh:mm A")}</div>
                                </div>
                                {this.displayLoc()}
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">ASSIGNED EMPLOYEE</div>
                                    <div className="col-sm-7 pt-3">{this.displayEmployee()}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">TASK PRIORITY</div>
                                    <div className="col-sm-7 pt-3">{this.state.currentTask.tas_priority}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">BILLABLE TO CUSTOMER</div>
                                    <div className="col-sm-7 pt-3">{this.state.currentTask.tas_billable_by_default ? 'Yes' : 'No'}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">CONTACTS</div>
                                    <div class="col-sm-7 pt-3">{this.state.currentTask.taskContacts ? this.state.currentTask.taskContacts.map((contact) => contact.con_first_name + " " + contact.con_last_name).join(", ") : ''}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">NOTES/COMMENTS</div>
                                    <div class="col-sm-7 pt-3">{this.state.currentTask.tas_notes}</div>
                                </div>
                                {this.state.currentTask.tas_is_active === 1 && this.state.currentTask.tas_required_sig === 1 && <div className="customer-info-block row">
                                    <div className="col-sm-5 pt-3 text-muted">SIGNATURE</div>
                                    <div className="col-sm-7 pt-3">
                                        {this.state.currentTask.signatureDets ? <img src={`data:image/png;base64,${this.state.currentTask.signatureDets[0].sig_pad}`} style={{ width: 150, height: 75 }} /> : <div></div>}
                                    </div>
                                </div>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="w-100" variant="primary" onClick={this.handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            dialogClassName={"signature-display-modal"}
                            show={this.state.showSignPopover}
                            onHide={() => this.setState({ showSignPopover: false })}
                            size="l"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Signature details for {this.state.currentTask.tas_title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body dialogClassName={"signature-display-modal"}>
                                {this.state.signLoading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                        <span class="spinner"></span>
                                    </div>
                                ) : (
                                    <div className="signature-info">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><strong>Customer Name:</strong></td>
                                                    <td>{this.state.currentTask.cusInfo[0].cus_name}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Signed at Location:</strong></td>
                                                    <td>{this.state.location}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Signed on:</strong></td>
                                                    <td>{moment(this.state.currentTask.checkoutEmpDate).utc().format("MMM DD, hh:mm a")}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Signature:</strong></td>
                                                    <td>{this.state.signUrl === 'Something went wrong. Please try again later.' ? this.state.signUrl : <img src={this.state.signUrl} alt="Signature" className="signature-image" />}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </Modal.Body>
                        </Modal>
                    </div>
                }
            </div>)
        );
    }
}

export default Tasks;