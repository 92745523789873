import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Autocomplete, Typography, TextField, FormControl, Box, Snackbar, IconButton, Alert, TablePagination, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReportCaseManagerCard from "./ReportCaseManagerCard";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";
import dayjs from "dayjs";

var sha1 = require("sha1");

export default function CMCustomerReports(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataLength, setDataLength] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [CMFilter, setCMFilter] = useState({
        sort_order: "asc",
        sort_by: "cus_name",
        page: 0,
        page_size: 10,
        customer_id: null,
        dateRange: [moment().startOf('month'), moment().endOf('month')],
        dateForType: null,
        quarter: null,
        reportType: "",
        customer: {},
    });
    const [page, setPage] = useState(CMFilter?.page);
    const [rowsPerPage, setRowsPerPage] = useState(CMFilter?.page_size);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    const typesList = ["Monthly", "Quarterly", "Yearly"];
    const quarterList = [`Q1 ${moment().year()}`, `Q2 ${moment().year()}`, `Q3 ${moment().year()}`, `Q4 ${moment().year()}`];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        // const filterForPayload = {
        //     company_id: props.user.comp_id,
        //     emp_id: props.user.emp_id,
        //     sort_order: CMFilter?.sort_order,
        //     sort_by: CMFilter?.sort_by,
        //     page_size: CMFilter?.page_size,
        //     page: CMFilter?.page,
        //     customer_id: CMFilter?.customer_id,
        //     report_type: CMFilter.reportType,
        //     start_date: moment(new Date(CMFilter.dateRange[0])).format("YYYY-MM-DD"),
        //     end_date: moment(new Date(CMFilter.dateRange[1])).format("YYYY-MM-DD"),
        //     reports_filter_date: moment(new Date(CMFilter.dateForType)).format("YYYY-MM-DD"),
        // };

        // axios
        //     .get(APIURL + "/customers/customersForReportsCentre", {
        //         params: filterForPayload,
        //     })
        //     .then((response) => {
        //         if (response && response?.data) {
        //             setData(response?.data?.customers);
        //             setDataLength(response?.data?.count);
        //             setIsLoading(false);
        //         }
        //     });

        axios
            .get(APIURL + "/customers/v2/associated", {
                params: {
                    company_id: props.user.comp_id,
                    employee_id: props.user.emp_id
                },
            })
            .then((response) => {
                setCustomerList(response?.data.filter((cus) => cus.cus_status === 'Active') || []);
                setIsLoading(false);
            });

    };

    const getReport = async (data, type) => {
        let dateType = CMFilter.reportType;
        let dateFilter;
        let fromDate;
        let toDate;

        if (dateType === 'Monthly') {
            if (CMFilter.dateForType) {
                fromDate = moment(new Date(CMFilter.dateForType)).startOf('month');
                toDate = moment(new Date(CMFilter.dateForType)).endOf('month');

                let newDate = new Date(CMFilter.dateForType);
                dateFilter = newDate.getMonth() + 1;
            }
            else {
                fromDate = moment().startOf('month');
                toDate = moment().endOf('month');

                let newDate = new Date();
                dateFilter = newDate.getMonth() + 1;
            }
        } else if (dateType === 'Quarterly') {
            const newDate = new Date();
            const year = newDate.getFullYear();

            let quarterStartMonth, quarterEndMonth;

            switch (CMFilter.quarter) {
                case `Q1 ${moment().year()}`:
                    quarterStartMonth = 0;  // January
                    quarterEndMonth = 2;    // March
                    break;
                case `Q2 ${moment().year()}`:
                    quarterStartMonth = 3;  // April
                    quarterEndMonth = 5;    // June
                    break;
                case `Q3 ${moment().year()}`:
                    quarterStartMonth = 6;  // July
                    quarterEndMonth = 8;    // September
                    break;
                case `Q4 ${moment().year()}`:
                    quarterStartMonth = 9;  // October
                    quarterEndMonth = 11;   // December
                    break;
                default:
                    quarterStartMonth = 0;  // January
                    quarterEndMonth = 2;    // March
                    return;
            }

            const quarterStart = new Date(year, quarterStartMonth, 1);
            const quarterEnd = new Date(year, quarterEndMonth + 1, 0);

            dateFilter = {
                quarterStart: quarterStart,
                quarterEnd: quarterEnd
            }

            fromDate = moment(quarterStart);
            toDate = moment(quarterEnd);
        } else if (dateType === 'Yearly') {
            if (CMFilter.dateForType) {
                fromDate = moment(new Date(CMFilter.dateForType)).startOf('year');
                toDate = moment(new Date(CMFilter.dateForType)).endOf('year');

                let newDate = new Date(CMFilter.dateForType);
                dateFilter = newDate.getFullYear();
            }
            else {
                fromDate = moment().startOf('year');
                toDate = moment().endOf('year');

                let newDate = new Date();
                dateFilter = newDate.getFullYear();
            }
        } else if (!dateType || dateType === '') {
            dateType = 'None';
            if (CMFilter.dateRange[0] && CMFilter.dateRange[1]) {
                fromDate = moment(new Date(CMFilter.dateRange[0]));
                toDate = moment(new Date(CMFilter.dateRange[1]));

                dateFilter = {
                    start: new Date(CMFilter.dateRange[0]),
                    end: new Date(CMFilter.dateRange[1])
                }
            } else {
                fromDate = moment().startOf('month');
                toDate = moment().endOf('month');

                dateType = 'Monthly';
                let newDate = new Date();
                dateFilter = newDate.getMonth() + 1;
            }
        } else {
            fromDate = moment().startOf('month');
            toDate = moment().endOf('month');

            dateType = 'Monthly';
            let newDate = new Date();
            dateFilter = newDate.getMonth() + 1;
        }

        axios
            .get(APIURL + "/jsreport/cm_invoice", {
                params: {
                    com_id: props.user.comp_id,
                    cus_id: CMFilter.customer.cus_id,
                    cus_name: CMFilter.customer.cus_name,
                    dateFilter: dateFilter,
                    dateType: dateType,
                    emp_id: props.user.emp_id,
                    from: fromDate,
                    to: toDate,
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        // let sessionID = window.localStorage.getItem("Fw4_access_Token");
        // var hash = sha1(sessionID);
        // var log_data = {
        //     email: props.user.email,
        //     actionType: "Report Generated",
        //     actionSubType: "Payroll Report",
        //     sessionID: hash,
        // };
        // let msg = axios.post(APIURL + "/activity_log", log_data);
    }

    const openPDF = (response, reportAction = "open") => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        if (reportAction === "download") {
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = "Report-" + new Date() + ".pdf";
            link.click();
        } else {
            window.open(fileURL, "_blank");
        }
    };

    // const markFavourite = async (data, type) => {
    //     const payload = {
    //         report_id: data?.report_id,
    //         status: type,
    //     };

    //     axios
    //         .put(APIURL + "/jsreport/markFavorite", payload)
    //         .then((response) => {
    //             if (response?.status === 200 && response?.data?.code === 200) {
    //                 setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
    //             } else {
    //                 setErrorText("Something went wrong");
    //             }

    //             fetchData();
    //         });
    // }

    const handleFilterData = (value, key) => {
        if (key === "client") {
            setCMFilter({
                ...CMFilter,
                customer_id: value ? value.cus_id : null,
                customer: value,
            });
        } else if (key === "type") {
            setCMFilter({
                ...CMFilter,
                reportType: value,
            });
        } else if (key === "quarter") {
            setCMFilter({
                ...CMFilter,
                quarter: value,
            });
        }
    };

    const searchFilter = () => {
        setCMFilter({
            ...CMFilter,
            page: 0
        });
    };

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    //     setCMFilter({
    //         ...CMFilter,
    //         page: newPage,
    //         page_size: rowsPerPage,
    //     });
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    //     setCMFilter({
    //         ...CMFilter,
    //         page: 0,
    //         page_size: parseInt(event.target.value, 10),
    //     });
    // };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ margin: 10, width: '75vw' }}>
                <div>
                    <Typography
                        style={{
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            fontFamily: "Lato",
                            marginBottom: 30,
                        }}
                    >
                        Case Management Reports
                    </Typography>
                </div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <Autocomplete
                            id="client-select"
                            options={customerList}
                            getOptionLabel={(option) => option.cus_name}
                            onChange={(event, value) => handleFilterData(value, "client")}
                            renderInput={(params) => <TextField {...params} label="Select Client" />}
                            sx={{ width: 250 }}
                            size="small"
                        />
                    </div>
                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                        <Autocomplete
                            id="type-select"
                            value={CMFilter.reportType}
                            options={typesList}
                            getOptionLabel={(option) => option}
                            onChange={(event, value) => handleFilterData(value, "type")}
                            renderInput={(params) => <TextField {...params} label="Select Type" />}
                            sx={{ width: 250 }}
                            size="small"
                        />
                    </div>
                    {CMFilter?.reportType === "Quarterly" &&
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            <Autocomplete
                                id="type-select"
                                value={CMFilter.quarter}
                                options={quarterList}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => handleFilterData(value, "quarter")}
                                renderInput={(params) => <TextField {...params} label="Select Quarter" />}
                                sx={{ width: 250 }}
                                size="small"
                            />
                        </div>
                    }

                    <div>
                        <FormControl size="small" fullWidth>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                localeText={{ start: "From Date", end: "To Date" }}
                            >
                                {!CMFilter?.reportType ? (
                                    <DateRangePicker
                                        value={dayjs(CMFilter?.dateRange)}
                                        onChange={(newValue) => {
                                            setCMFilter({
                                                ...CMFilter,
                                                page: 0,
                                                dateRange: newValue,
                                            });
                                        }}
                                        slotProps={{ fieldSeparator: { children: 'to' }, textField: { variant: 'outlined', size: 'small' } }}
                                    />
                                ) : null}
                                {CMFilter?.reportType === "Yearly" ? (
                                    <DatePicker
                                        label="Select year"
                                        openTo="year"
                                        views={['year']}
                                        value={moment(CMFilter?.dateForType).isValid() ? dayjs(CMFilter?.dateForType) : null}
                                        maxDate={dayjs(new Date())}
                                        onChange={(newValue) => {
                                            setCMFilter({
                                                ...CMFilter,
                                                page: 0,
                                                dateForType: newValue,
                                            });
                                        }}
                                        slotProps={{textField: {variant: 'outlined', size: 'small'}}}
                                    />
                                ) : null}
                                {CMFilter?.reportType === "Monthly" ? (
                                    <DatePicker
                                        label="Select month"
                                        openTo="month"
                                        views={['month', 'year']}
                                        maxDate={dayjs(new Date())}
                                        value={moment(CMFilter?.dateForType).isValid() ? dayjs(CMFilter?.dateForType) : null}
                                        onChange={(newValue) => {
                                            setCMFilter({
                                                ...CMFilter,
                                                page: 0,
                                                dateForType: newValue,
                                            });
                                        }}
                                        slotProps={{textField: {variant: 'outlined', size: 'small'}}}
                                    />
                                ) : null}
                            </LocalizationProvider>
                        </FormControl>
                    </div>

                    <div style={{ marginLeft: 10 }}>
                        <Button
                            onClick={(e) => {
                                getReport({}, 'open');
                            }}
                            variant="primary"
                            size="lg"
                            className="font-weight-bold"
                            style={{ fontSize: 14, fontFamily: "Lato" }}
                            disabled={!CMFilter.customer_id}
                        >
                            VIEW REPORT
                        </Button>
                    </div>

                    <div style={{ marginLeft: 10 }}>
                        <Button
                            onClick={(e) => {
                                getReport({}, 'download');
                            }}
                            variant="primary"
                            size="lg"
                            className="font-weight-bold"
                            style={{ fontSize: 14, fontFamily: "Lato" }}
                            disabled={!CMFilter.customer_id}
                        >
                            PDF
                        </Button>
                    </div>
                </div>

                {/* <div style={{ marginTop: 20, marginBottom: 10, width: '70vw' }}>
                    <div>
                        {data.map((e, index) => (
                            <ReportCaseManagerCard
                                key={index}
                                data={e}
                                openReport={(type, data) => getReport(type, data)}
                                markFavourite={(type, data) => markFavourite(type, data)}
                            />
                        ))}
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 100 }}>
                    <div></div>
                    <Stack direction="row" spacing={2}>
                        <TablePagination
                            component="div"
                            count={dataLength || 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack>
                </div> */}

                <Snackbar
                    open={successText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setSuccessText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"success"}
                        elevation={6}
                        variant="filled"
                    >
                        {successText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setSuccessText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setErrorText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"error"}
                        elevation={6}
                        variant="filled"
                    >
                        {errorText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setErrorText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
            </div >
        );
    };
}