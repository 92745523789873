import React, { createRef, useImperativeHandle } from "react";
import { Form, Button } from "react-bootstrap";
import { Snackbar, IconButton, Alert, TextField, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from 'dayjs';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { jsonToCSV } from "react-papaparse";
import { CSVDownloader } from "react-papaparse";
import EVVFilter from "./EVVFilter";
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

const columns = [
  {
    field: "Provider",
    headerName: "Service Provider",
    width: 160,
    renderCell: (params) => {
      return (
        <div>
          <p
            className="text-wrap"
            style={{ lineHeight: "1", marginTop: "12px" }}
          >
            {params.value}{" "}
          </p>
        </div>
      );
    },
  },
  {
    field: "Receiver",
    headerName: "Service Receiver",
    width: 160,
    renderCell: (params) => {
      return (
        <div>
          <p
            className="text-wrap"
            style={{ lineHeight: "1", marginTop: "12px" }}
          >
            {params.value}{" "}
          </p>
        </div>
      );
    },
  },
  {
    field: "TaskType",
    headerName: "Type",
    width: 105,
  },

  {
    field: "DateofService",
    headerName: "Date",
    width: 108,
  },

  {
    field: "StarttoEnd",
    headerName: "Time",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <p
            className="text-wrap"
            style={{ lineHeight: "1", marginTop: "12px" }}
          >
            {params.value}{" "}
          </p>
        </div>
      );
    },
  },

  {
    field: "Location",
    headerName: "Location",
    flex: 2,
    renderCell: (params) => {
      let loc_1 = params.value[0] ? params.value[0].loc_addr_line1 + ", " + params.value[0].loc_addr_line2 + ", " + params.value[0].loc_city + ", " + params.value[0].loc_state + ", " + params.value[0].loc_zip : '-';
      let loc_2 = params.value[1] ? params.value[1].loc_addr_line1 + ", " + params.value[1].loc_addr_line2 + ", " + params.value[1].loc_city + ", " + params.value[1].loc_state + ", " + params.value[1].loc_zip : '';
      return (
        <div>
          <div>
            {loc_1}
          </div>
          {loc_2 &&
            <div>
              {loc_2}
            </div>
          }
        </div>
      );
    },
  },
];

class Evv_Report extends React.Component {
  constructor(props) {
    super(props);
    this.apiRef = createRef();
  }
  state = {
    gridId: 43,
    loading: true,
    EvvReports: [],
    searchText: "",
    isCustomReportOpen: false,
    fromDate: null,
    toDate: null,
    serviceProvider: null,
    servieReciever: null,
    taskType: null,
    locations: null,
    filterResultsOutput: "",
    pageSize: 10,
    pageNo: 0,
    reportDetails: {},
    isFavorite: false,
    successText: "",
    errorText: "",
    evvFilter: {
      dateRange: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
    }
  };

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    axios
      .get(APIURL + "/jsreport/getAllReports", {
        params: { empID: this.props.user.emp_id },
      })
      .then((response) => {
        const report = response?.data?.reports.find((ele) => ele.report_name === "EVV Report");
        this.setState({
          reportDetails: report,
          isFavorite: report ? (report.status === 1 ? true : false) : false,
        });
      });

    let evv_reports = [];
    let customer_det = [];

    if (this.props.user.type === "Admin") {
      evv_reports = await axios.get(APIURL + "/timesheets", {
        params: {
          comp_id: this.props.comp_id,
          employeeId: this.props.user.emp_id,
          companyId: this.props.comp_id,
          start: moment(this.state.evvFilter?.dateRange[0]).format("YYYY-MM-DD"),
          end: moment(this.state.evvFilter?.dateRange[1]).format("YYYY-MM-DD"),
        },
      });
      customer_det = await axios.get(APIURL + "/customers/v2/associated", {
        params: { company_id: this.props.comp_id, employee_id: this.props.user.emp_id },
      });
    } else if (this.props.user.type === "Manager") {
      evv_reports = await axios.get(APIURL + "/timesheets", {
        params: {
          comp_id: this.props.comp_id,
          emp_id: this.props.user.emp_id,
          employeeId: this.props.user.emp_id,
          companyId: this.props.comp_id,
          start: moment(this.state.evvFilter?.dateRange[0]).format("YYYY-MM-DD"),
          end: moment(this.state.evvFilter?.dateRange[1]).format("YYYY-MM-DD"),
        },
      });
      customer_det = await axios.get(APIURL + "/customers/v2/associated", {
        params: { company_id: this.props.comp_id, employee_id: this.props.user.emp_id },
      });
    } else {
      evv_reports = await axios.get(APIURL + "/timesheets", {
        params: {
          emp_id: this.props.user.emp_id,
          employeeId: this.props.user.emp_id,
          companyId: this.props.comp_id,
          start: moment(this.state.evvFilter?.dateRange[0]).format("YYYY-MM-DD"),
          end: moment(this.state.evvFilter?.dateRange[1]).format("YYYY-MM-DD"),
        },
      });
      customer_det = await axios.get(APIURL + "/customers/v2/associated", {
        params: { company_id: this.props.comp_id, employee_id: this.props.user.emp_id },
      });
    }

    const regex = /visit/i; // Case-insensitive regular expression to check for "Visit"
    evv_reports = evv_reports.data.filter(
      (loop1) =>
        !!loop1.taskDets &&
        (regex.test(loop1.taskDets.tas_type))
    );

    let evv = evv_reports.map((evvloop) => {
      let addCusName = evvloop;
      let found_customer = !!evvloop.projDets
        ? customer_det.data.find(
          (loop) => loop.cus_id === evvloop.projDets.pro_customer_id
        )
        : null;
      addCusName = {
        ...addCusName,
        customer_name: !!found_customer ? found_customer.cus_name : "",
      };
      return addCusName;
    });
    this.setState({ EvvReports: evv, loading: false });
    localStorage.removeItem("evvFilterData");
  }

  markFavourite = async (data, type) => {
    const payload = {
      report_id: data?.report_id,
      status: type,
      empID: this.props.user.emp_id
    };

    axios
      .put(APIURL + "/jsreport/markFavorite", payload)
      .then((response) => {
        if (response?.status === 200 && response?.data?.code === 200) {
          this.setState({
            successText: response?.data?.status_message || "Favorite Modifed Succesfully",
          });
        } else {
          this.setState({
            successText: "Something went wrong",
          });
        }

        this.fetchData();
      });
  }

  handlePaginationModelChange = (paginationModel) => {
    console.log("paginationModel", paginationModel);
    if (paginationModel.pageSize !== this.state.pageSize) {
      this.setState({
        ...this.state,
        pageSize: paginationModel.pageSize,
        pageNo: 0,
      });
    } else {
      this.setState({
        ...this.state,
        pageNo: paginationModel.page,
      });
    }
  };

  onChangeSearch = (e) => {
    this.setState({
      ...this.state,
      searchText: e.target.value,
    });
  };

  openCustomReport = (e) => {
    e.preventDefault();
    console.log("Custom Report");
    if (this.state.isCustomReportOpen) {
      this.setState({
        ...this.state,
        isCustomReportOpen: !this.state.isCustomReportOpen,
        filterResultsOutput: "",
      });
      localStorage.removeItem("evvFilterData");
    } else {
      this.setState({
        ...this.state,
        isCustomReportOpen: !this.state.isCustomReportOpen,
      });
    }
  };

  closeCustomReport = (e) => {
    e.preventDefault();
    console.log("Custom Report", e);
    let val;
    val = !this.state.isCustomReportOpen;
    this.setState({
      ...this.state,
      isCustomReportOpen: val,
    });
  };

  onSubmitCustomReport = () => {
    console.log("Submit Filter");
  };

  handleFilterForm = (finalObj) => {
    console.log("Final object", finalObj);

    const objectValues = Object.values(finalObj);
    const objectKeys = Object.keys(finalObj);
    let output = [];
    objectValues.forEach((element, index) => {
      if (element) {
        output.push(element);
      }
    });
    output = output.join(", ");
    console.log("output", output);
    this.setState({
      ...this.state,
      filterResults: finalObj,
      filterResultsOutput: output,
    });
  };

  render() {
    let evv_rows = this.state.EvvReports.map((EvvReportsarray) => {
      console.log(EvvReportsarray);
      let row = {
        id: EvvReportsarray.id,
        Provider: !!EvvReportsarray.empDets
          ? EvvReportsarray.empDets.emp_first_name +
          " " +
          EvvReportsarray.empDets.emp_last_name
          : "-",
        Receiver: EvvReportsarray.customer_name,
        TaskType: !!EvvReportsarray.taskDets
          ? EvvReportsarray.taskDets.tas_type
          : "-",
        Location: !!EvvReportsarray.locDets
          ? EvvReportsarray.locDets
          : "-",
        StarttoEnd: !!EvvReportsarray
          ? moment(EvvReportsarray.start_time.replace("Z", "")).format("h:mm a") +
          " - " +
          moment(EvvReportsarray.end_time.replace("Z", "")).format("h:mm a")
          : "-",
        DateofService: !!EvvReportsarray.taskDets
          ? !!moment(EvvReportsarray.taskDets.tas_end_time).isValid()
            ? moment(EvvReportsarray.taskDets.tas_end_time)
              .utc()
              .format("MM/DD/YYYY")
            : moment(EvvReportsarray.taskDets.tas_end_date)
              .utc()
              .format("MM/DD/YYYY h:mm a")
          : "-",
      };

      return row;
    });

    let row_csv_evv = evv_rows.map((repevv) => {
      let new_repevv = {
        ...repevv,
        ["Service Provider"]: repevv.Provider,
        ["Service Receiver"]: repevv.Receiver,
        ["Type of Service"]: repevv.TaskType,
        ["Date of the Service"]: repevv.DateofService,
        ["Time the service starts and ends"]: repevv.StarttoEnd,
        ["Location of the Service"]: repevv.Location,
      };

      delete new_repevv.id;
      delete new_repevv.Provider;
      delete new_repevv.Receiver;
      delete new_repevv.TaskType;
      delete new_repevv.DateofService;
      delete new_repevv.StarttoEnd;
      delete new_repevv.Location;

      return new_repevv;
    });

    const csv_evv = jsonToCSV(row_csv_evv);

    let tempObj = localStorage.getItem("evvFilterData");
    if (!tempObj && this.state.filterResultsOutput != "") {
      this.setState({ filterResultsOutput: "" });
    }

    return (
      <div style={{ margin: 10, width: '75vw' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <div>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: "From Date", end: "To Date" }}
              >
                <DateRangePicker
                  value={dayjs(this.state.evvFilter.dateRange)}
                  onChange={(newValue) => {
                    newValue = newValue.map((d) => dayjs(d).format('YYYY-MM-DD'))
                    this.setState({
                      evvFilter: { dateRange: newValue }
                    });
                  }}
                  slotProps={{ fieldSeparator: { children: 'to' } }}
                />
              </LocalizationProvider>

              <Button
                onClick={() => this.fetchData()}
                variant="primary"
                size="lg"
                className="font-weight-bold"
                style={{ marginLeft: 10, fontSize: 14, fontFamily: "Lato" }}
              >
                SEARCH
              </Button>
            </div>

            {/* <div>
                <Form inline>
                  <Button
                    type="submit"
                    onClick={(e) => this.openCustomReport(e)}
                    variant="primary"
                    className="mb-4"
                  >
                    {this.state.isCustomReportOpen
                      ? "Reset"
                      : "Custom Report"}
                  </Button>
                </Form>
              </div>
              <div>
                {this.state.filterResultsOutput !== "" && (
                  <Typography
                    style={{
                      fontStyle: "italic",
                      fontFamily: "Lato",
                      color: "#626262",
                      top: " 7px",
                      position: "relative",
                      fontSize: "14px",
                    }}
                  >
                    Reports results: {this.state.filterResultsOutput}
                  </Typography>
                )}
              </div> */}
          </div>
          <div>
            <CSVDownloader
              data={csv_evv}
              filename={"EVV report"}
              bom={true}
            >
              <button
                type="button"
                className="btn btn-sm btn-outline-info m-1"
              >
                CSV
              </button>
            </CSVDownloader>

            <button
              type="button"
              className="btn btn-sm btn-outline-info m-1"
              onClick={this.handlePrint}
            >
              PDF
            </button>
            <Tip title={this.state.isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
              <img
                src={this.state.isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                alt="barchart"
                style={{
                  fontFamily: "Lato",
                  width: "1.5rem",
                  height: "1.5rem",
                  cursor: "pointer",
                  margin: 5
                }}
                onClick={() => this.markFavourite(this.state.reportDetails, this.state.isFavorite ? 0 : 1)}
              />
            </Tip>
          </div>
        </div>
        <div>
          <div>
            {this.state.isCustomReportOpen ? (
              <EVVFilter
                openCustomReport={this.openCustomReport}
                closeCustomReport={this.closeCustomReport}
                onFormSubmission={this.handleFilterForm}
                filterResultsOutput={this.state.filterResultsOutput}
              />
            ) : null}
          </div>
          <div style={{ height: '70vh' }}>
            <ControlledDataGrid
              gridId={this.state.gridId}
              user={this.props.user}
              initialState={{ sorting: { sortModel: [{ field: "DateofService", sort: "desc" }] } }}
              apiRef={this.apiRef}
              rows={evv_rows}
              columns={columns}
              autoHeight={true}
              pagination
              rowsPerPageOptions={[5, 10, 20]}
              paginationModel={{ page: this.state.pageNo, pageSize: this.state.pageSize }}
              onPaginationModelChange={(paginationModel) => this.handlePaginationModelChange(paginationModel)}
              loading={this.state.loading}
            />
          </div>
        </div>
        <Snackbar
          open={this.state.successText !== ""}
          autoHideDuration={30000}
          onClose={() => this.setState({ successText: "" })}
        >
          <Alert
            sx={{ width: "100%" }}
            severity={"success"}
            elevation={6}
            variant="filled"
          >
            {this.state.successText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => this.setState({ successText: "" })}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.errorText !== ""}
          autoHideDuration={30000}
          onClose={() => this.setState({ errorText: "" })}
        >
          <Alert
            sx={{ width: "100%" }}
            severity={"error"}
            elevation={6}
            variant="filled"
          >
            {this.state.errorText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => this.setState({ errorText: "" })}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
      </div>
    )
  }
}
const EvvReport = React.forwardRef((props, ref) => {
  const apiRef = useGridApiRef();
  useImperativeHandle(ref, () => apiRef.current);

  return <Evv_Report {...props} apiRef={apiRef} />;
});

export default EvvReport;