var env = 'dev';

var BINGAPIKEY = process.env.REACT_APP_BINGAPIKEY;
var APIURL;
var ONBOARDAPIURL;
var SCOPE;
var CLIENT_ID;
var SEGMENTAPIKEY;

if (env === "prod") {
  APIURL = process.env.REACT_APP_APIURL_PROD;
  SCOPE = process.env.REACT_APP_SCOPE_PROD;
  CLIENT_ID = process.env.REACT_APP_CLIENT_ID_PROD;
  ONBOARDAPIURL = process.env.REACT_APP_ONBOARDAPIURL_PROD;
  SEGMENTAPIKEY = process.env.REACT_APP_SEGMENTAPIKEY_PROD;
} else if (env === "test") {
  APIURL = process.env.REACT_APP_APIURL_TEST;
  SCOPE = process.env.REACT_APP_SCOPE_TEST;
  CLIENT_ID = process.env.REACT_APP_CLIENT_ID_TEST;
  ONBOARDAPIURL = process.env.REACT_APP_ONBOARDAPIURL_TEST;
  SEGMENTAPIKEY = process.env.REACT_APP_SEGMENTAPIKEY_DEVTEST;
} else if (env === "dev") {
  APIURL = process.env.REACT_APP_APIURL_DEV;
  SCOPE = process.env.REACT_APP_SCOPE_DEV;
  CLIENT_ID = process.env.REACT_APP_CLIENT_ID_DEV;
  ONBOARDAPIURL = process.env.REACT_APP_ONBOARDAPIURL_DEV;
  SEGMENTAPIKEY = process.env.REACT_APP_SEGMENTAPIKEY_DEVTEST;
} else {
  APIURL = process.env.REACT_APP_APIURL_LOCAL;
  SCOPE = process.env.REACT_APP_SCOPE_DEV;
  CLIENT_ID = process.env.REACT_APP_CLIENT_ID_DEV;
  ONBOARDAPIURL = process.env.REACT_APP_ONBOARDAPIURL_DEV;
  SEGMENTAPIKEY = process.env.REACT_APP_SEGMENTAPIKEY_DEVTEST;
}

var MUI_KEY=process.env.REACT_APP_MATERIAL_UI_LICENSE_KEY;
var MSTENANT = process.env.REACT_APP_MSTENANT;

var VERSION = '3.0.7';
var VERSION_DATE = '2024-02-25';

export { APIURL, BINGAPIKEY, SEGMENTAPIKEY, CLIENT_ID, MSTENANT, MUI_KEY, SCOPE, ONBOARDAPIURL, VERSION, VERSION_DATE, env};
