import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, List, ListItem, IconButton, Paper, Button, MenuItem, Select, FormControl, InputLabel, TextField, Tooltip } from '@mui/material';
import { TaskAlt, AddTask } from '@mui/icons-material';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'; // Adapter for Moment.js
import axios from 'axios';
import { APIURL } from '../../../config';
import moment from 'moment';

function TaskAttendanceModal(props) {
    const [attendance, setAttendance] = useState([]);
    const [taskModalOpen, setTaskModalOpen] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [taskType, setTaskType] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    useEffect(() => {
        if (props.open) {
            let sessions = props.sessions.filter(session => session.schedule_id === props.schedule?.schedule_id);
            setAttendance(sessions);
            setTaskType(props.schedule.task_type);
            setStartTime(moment(props.schedule.start_time));
            setEndTime(moment(props.schedule.end_time));
        } else {
            setAttendance([]);
        }
    }, [props.open, props.sessions, props.schedule]);

    const handleTaskAction = async (session, useNewValues = false, checkin = true) => {
        if (useNewValues && endTime && startTime && moment(endTime).isBefore(moment(startTime))) {
            props.onError("End time must be after start time");
            return;
        }

        try {
            const checkInData = {
                check_in_time: checkin ? props.schedule.start_time : null,
                check_out_time: checkin ? props.schedule.end_time : null,
                cus_id: session.customer_id,
                emp_id: props.user.emp_id,
                company_id: props.user.comp_id
            };

            await axios.put(APIURL + '/sessions/attendance/' + session.session_id, checkInData);
            const existingTask = await axios.get(APIURL + '/task/session', {
                params: {
                    tas_type: props.schedule.task_type,
                    tas_title: props.schedule.schedule_name,
                    tas_start_date: moment(props.schedule.start_time).format('YYYY-MM-DD HH:mm:ss'),
                    tas_end_date: moment(props.schedule.end_time).format('YYYY-MM-DD HH:mm:ss'),
                    tas_com_id: props.comp_id,
                    cus_id: session.customer_id
                }
            });

            if (useNewValues) {
                await checkInOut(session, existingTask);
            } else {
                if (existingTask.data.data) {
                    await axios.put(APIURL + '/task/active', {
                        tas_id: existingTask.data.data.tas_id,
                        tas_is_active: checkin ? 1 : 0
                    });
                } else {
                    await checkInOut(session, existingTask);
                }
            }

            if(useNewValues) {
                setTaskModalOpen(false);
                setSelectedSession(null);
                setTaskType('');
                setStartTime(null);
                setEndTime(null);
            }

            props.reloadSessionData();
            props.onSuccess("Attendance marked successfully");

        } catch (error) {
            console.error(error);
            props.onError("An error occurred while processing the task");
        }
    };

    const checkInOut = async (session, existingTask) => {
        try {
            const projData = await axios.get(APIURL + '/project', { params: { cus_id: session.customer_id } });
            const proj = projData.data.filter(project => project.pro_is_active === true)[0] || projData.data[0];
            const loc = props.resources.find(resource => resource.resource_id === props.schedule.resource_id);

            let data = { 
                projId: proj.pro_id,
                empName: props.user.emp_name,
                empId: props.user.emp_id,
                taskTitle: props.schedule.schedule_name,
                companyId: props.comp_id,
                taskId: existingTask.data.data ? existingTask.data.data.tas_id : null,
                locId: loc.location_id,
                emploc_lat: loc.loc_lat,
                emploc_lon: loc.loc_lon,
                date: moment(props.schedule.start_time).format('YYYY-MM-DDTHH:mm:ss')
            };
            let result = await axios.post(APIURL + '/locate/empLoc', { ...data })
            
            data = { 
                projId: proj.pro_id,
                checkoutLocId: loc.location_id,
                empName: props.user.emp_name,
                taskTitle: props.schedule.schedule_name,
                taskId: existingTask.data.data.tas_id,
                date: moment(props.schedule.end_time).format('YYYY-MM-DDTHH:mm:ss')
            }
            result = await axios.put(APIURL + '/locate/empLoc', { ...data });
            console.log(result);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenTaskModal = (session) => {
        setSelectedSession(session);
        setTaskModalOpen(true);
    };

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="attendance-modal-title"
                aria-describedby="attendance-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: '80vh',
                    overflow: 'hidden',
                }}>
                    <Typography id="attendance-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Attendance
                    </Typography>
                    <Paper sx={{
                        maxHeight: '60vh',
                        overflowY: 'auto',
                        pr: 1,
                    }}>
                        <List dense>
                            {attendance.length > 0 ? attendance.map((session, index) => (
                                <ListItem key={index} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Tooltip title={"Mark Attendance"}>
                                        <IconButton onClick={() => handleTaskAction(session, false, !session.check_in_time)} disabled={session.check_in_time}>
                                            {session.check_in_time !== null ? (
                                                <TaskAlt sx={{ color: 'rgb(63, 167, 214)' }} />
                                            ) : (
                                                <TaskAlt />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Modify Task"}>
                                        <IconButton onClick={() => handleOpenTaskModal(session)} disabled={session.check_in_time}>
                                            <AddTask />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography sx={{ flex: 1, textAlign: 'right', pr: 2 }}>
                                        {session.cus_name}
                                    </Typography>
                                </ListItem>
                            )) : (
                                <ListItem>
                                    <Typography variant="body2" color="text.secondary">
                                        No Customers Assigned
                                    </Typography>
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Box>
            </Modal>

            {/* Task Modification Modal */}
            <Modal
                open={taskModalOpen}
                onClose={() => {
                    setTaskModalOpen(false);
                    setSelectedSession(null);
                    setTaskType('');
                    setStartTime(null);
                    setEndTime(null);
                }}
                aria-labelledby="task-modal-title"
                aria-describedby="task-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="task-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Modify Task
                    </Typography>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="task-type-label">Task Type</InputLabel>
                        <Select
                            labelId="task-type-label"
                            value={taskType}
                            label="Task Type"
                            onChange={(e) => setTaskType(e.target.value)}
                        >
                        {props.taskTypes?.map((taskType, index) => (
                            <MenuItem key={index} value={taskType}>
                                {taskType}
                            </MenuItem>                        
                        ))}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                            label="Start Time"
                            value={moment(startTime).isValid() ? moment(startTime) : null}
                            onChange={setStartTime}
                            sx={{ mb: 2 }}
                            slotProps={{ textField: { fullWidth: true } }}
                        />
                        <TimePicker
                            label="End Time"
                            value={moment(endTime).isValid() ? moment(endTime) : null}
                            onChange={setEndTime}
                            sx={{ mb: 2 }}
                            slotProps={{ textField: { fullWidth: true } }}
                        />
                    </LocalizationProvider>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleTaskAction(selectedSession, true)}
                        disabled={!taskType && (!startTime || !endTime)}
                    >
                        Mark Attendance
                    </Button>
                </Box>
            </Modal>
        </>
    );
}

export default TaskAttendanceModal;
