import React, { useState, useEffect } from 'react';
import { Box, Typography, Chip, Radio, Stack, FormControlLabel, FormGroup, RadioGroup, Checkbox } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const PayrollFrequency = (props) => {
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [originalFrequency, setOriginalFrequency] = useState(null);
    const [enableAutomaticPayroll, setEnableAutomaticPayroll] = useState(props.enableAutomaticPayroll);
    const [mileagePayoutRate, setMileagePayoutRate] = useState(null);

    const handleRadioChange = (event) => {
        setSelectedFrequency(event.target.value);
    }

    const handleCheckBoxChange = async (field, feature_id) => {
        const newValue = !eval(field);
        eval(`set${field.charAt(0).toUpperCase() + field.slice(1)}(newValue)`);

        try {
            let settingsData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: feature_id } })).data;
            if (settingsData.length > 0) {
                let settingsPayload = {
                    settings_id: settingsData[0].settings_id, feature_value: newValue ? 'true' : 'false', feature_id: feature_id,
                }
                let result = await axios.put(APIURL + `/config/settings`, { ...settingsPayload });
            } else {
                let settingsPayload = {
                    feature_value: newValue ? 'true' : 'false',
                    feature_id: feature_id,
                    company_id: props.comp_id,
                    added_by: props.user_id
                }
                let result = await axios.post(APIURL + `/config/settings`, { ...settingsPayload });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSave = async () => {
        try {
            if (JSON.stringify(selectedFrequency) !== JSON.stringify(originalFrequency)) {
                let data = { ...props.companyDetail, payCycle: selectedFrequency, company_id: props.comp_id, emp_id: props.user_id };
                let apiCompanyRes = await axios.put(APIURL + '/company', { ...data })

                setOriginalFrequency(selectedFrequency);
            }

            if (props.mileagePayoutRate !== mileagePayoutRate) {
                let settingsData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 69 } })).data;

                if (settingsData.length > 0) {
                    let settingsPayload = {
                        settings_id: settingsData[0].settings_id, feature_value: mileagePayoutRate, feature_id: 69,
                    }
                    let result = await axios.put(APIURL + `/config/settings`, { ...settingsPayload });
                } else {
                    let settingsPayload = {
                        feature_value: mileagePayoutRate,
                        feature_id: 69,
                        company_id: props.comp_id,
                        added_by: props.user_id
                    }
                    let result = await axios.post(APIURL + `/config/settings`, { ...settingsPayload });
                }

                props.setMileagePayoutRate(mileagePayoutRate);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        setSelectedFrequency(props.selectedFrequency);
        setOriginalFrequency(props.selectedFrequency);
    }, [props.selectedFrequency]);

    useEffect(() => {
        setEnableAutomaticPayroll(props.enableAutomaticPayroll);
    }, [props.enableAutomaticPayroll]);

    useEffect(() => {
        setMileagePayoutRate(props.mileagePayoutRate);
    }, [props.mileagePayoutRate]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Payroll Settings
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Configure your payroll settings
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={3}>
                        <FormGroup>
                            <Typography variant="h6">
                                Payroll Frequency
                            </Typography>
                            <RadioGroup name="payrollFrequency" value={selectedFrequency || ''} onChange={handleRadioChange}>
                                <Grid container spacing={0}>
                                    {props.frequencyList.map((frequency, index) => {
                                        return (
                                            <Grid item xs={3} key={index}>
                                                <FormControlLabel sx={{ height: 30 }} control={<Radio value={frequency.feature_value} />} label={frequency.feature_value} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </RadioGroup>
                        </FormGroup>
                        <Box>
                            <Typography variant="h6">
                                Mileage Payout Rate
                            </Typography>
                            <FormControl sx={{ marginTop: 1 }} size="small">
                                <InputLabel htmlFor="outlined-adornment-rate">Rate</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-rate"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Rate"
                                    value={mileagePayoutRate}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === '' || /^\d*\.?\d*$/.test(value)) {
                                            setMileagePayoutRate(value);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <SaveButton onClick={handleSave} disabled={JSON.stringify(selectedFrequency) === JSON.stringify(originalFrequency) && props.mileagePayoutRate === mileagePayoutRate} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6">
                                Enable Automatic Payroll
                            </Typography>
                            <Checkbox
                                checked={enableAutomaticPayroll}
                                onChange={() => handleCheckBoxChange('enableAutomaticPayroll', 55)}
                            />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PayrollFrequency;
