import React, { useState, useEffect, useMemo } from "react";
import { Typography, Button, TextField } from "@mui/material";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { APIURL } from "../../config";
import moment from "moment";

export default function TimeAndExpensesReport(props) {
    const mileagePayoutRate = props.user?.comp_settings.find(setting => setting.feature_id === 69)?.feature_value;

    const [isLoading, setLoading] = useState(true);
    const [empTaskData, setEmpTaskData] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);
    const [totals, setTotals] = useState({});
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));

    useEffect(() => {
        async function fetchData() {
            const response = await axios.get(APIURL + "/reports/getTimeAndExpenses", {
                params: {
                    companyID: props.comp_id,
                    month: moment(currentMonth).format('YYYYMM')
                },
            });

            const taskType = await axios.get(APIURL + "/dropdowns/dropdownType/taskTypeList", {
                params: {
                    comp_id: props.comp_id
                },
            });

            setTaskTypes(taskType.data.filter((taskType) => [0, 3].includes(taskType.is_Active)));
            setEmpTaskData(response.data)
            setLoading(false);
        }
        fetchData();
    }, [currentMonth]);

    const columns = useMemo(() => {
        let cols = [
            { field: "emp_id", headerName: "Employee ID", hide: true },
            {
                field: "emp_name",
                headerName: "Employee Name",
                width: 200,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "timesheetStatus",
                headerName: "Timesheet Status",
                width: 130,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "compensation_rate",
                headerName: "Hourly Rate",
                width: 100,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "totalTaskHrs",
                headerName: "Total Hours",
                width: 100,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "empAmount",
                headerName: "Total Amount",
                width: 100,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "mileage",
                headerName: "Mileage",
                width: 70,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "mileagePayoutRate",
                headerName: "Mileage Payout Rate",
                width: 100,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "mileageExpense",
                headerName: "Mileage Expense",
                width: 125,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "tolls",
                headerName: "Tolls",
                width: 50,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "additionalExpense",
                headerName: "Additional Expense",
                width: 150,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
            {
                field: "totalIncludingExpense",
                headerName: "Total (including expenses)",
                width: 150,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            },
        ];

        taskTypes.forEach((taskType) => {
            cols.push({
                field: taskType.EntityValue,
                headerName: taskType.EntityValue,
                cellClassName: (params) => params.id === 'TOTAL' ? 'total-row' : '',
            });
        });

        return cols;
    }, [taskTypes]);

    const rows = useMemo(() => {
        const validatedMileageRate = (rate) => {
            if (typeof rate === 'string') {
                rate = parseFloat(rate);
            }

            if (typeof rate === 'number' && !isNaN(rate) && rate > 0) {
                return rate;
            } else {
                return 0;
            }
        }

        return empTaskData.map((data) => ({
            emp_id: data.emp_id,
            emp_name: data.emp_first_name ? `${data.emp_first_name} ${data.emp_last_name}` : '',
            timesheetStatus: data.timesheetStatus,
            compensation_rate: data.compensation_rate,
            totalTaskHrs: data.totalTaskHrs.toFixed(2),
            empAmount: (data.totalTaskHrs * data.compensation_rate).toFixed(2),
            mileage: data.mileage,
            mileagePayoutRate: validatedMileageRate(mileagePayoutRate),
            mileageExpense: (data.mileage * validatedMileageRate(mileagePayoutRate)).toFixed(2),
            tolls: data.tolls,
            additionalExpense: data.additionalExpense,
            totalIncludingExpense: ((data.totalTaskHrs * data.compensation_rate) + (data.mileage * validatedMileageRate(mileagePayoutRate)) + data.tolls + data.additionalExpense).toFixed(2),
            ...data.taskTypeHrs
        }));
    }, [empTaskData]);

    useEffect(() => {
        const newTotals = rows.reduce((acc, row) => {
            columns.forEach(col => {
                if (!['emp_id', 'emp_name', 'compensation_rate', 'mileagePayoutRate', 'timesheetStatus'].includes(col.field)) {
                    acc[col.field] = (acc[col.field] || 0) + (parseFloat(row[col.field]) || 0);
                }
            });
            return acc;
        }, {});
        setTotals(Object.fromEntries(
            Object.entries(newTotals).map(([key, value]) => [key, value.toFixed(2)])
        ));
    }, [rows, columns]);

    const footerRow = useMemo(() => ({
        emp_id: 'TOTAL',
        emp_name: 'TOTAL',
        compensation_rate: '',
        ...totals
    }), [totals]);

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            (<div>
                <div>
                    <Typography
                        style={{
                            // fontWeight: "bold",
                            fontSize: "1.5rem",
                            fontFamily: "Lato",
                            marginBottom: 10,
                        }}
                    >
                        Time & Expenses Report
                    </Typography>
                </div>
                <div id="report-main" style={{ marginBottom: 20 }}>
                    <Button
                        size="large"
                        className="DayButton"
                        onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                    >
                        <i className="fas fa-arrow-left"></i>
                    </Button>
                    <LocalizationProvider
                        class="DatePickerParent"
                        dateAdapter={AdapterDateFns}
                    >
                        <DatePicker
                            label="Select Date"
                            views={["year", "month"]}
                            value={new Date(currentMonth)}
                            onChange={(newValue) => {
                                onDateChange(newValue);
                            }}
                            slotProps={{ textField: { variant: 'standard' } }}
                        />
                    </LocalizationProvider>
                    <Button
                        size="large"
                        className="DayButton"
                        onClick={() => onDateChange(currentMonth.add(1, "month"))}
                    >
                        <i className="fas fa-arrow-right"></i>
                    </Button>
                </div>
                <div>
                    <div style={{ height: '80vh' }}>
                        <DataGridPro
                            getRowId={(row) => row.emp_id}
                            initialState={{ sorting: { sortModel: [{ field: "emp_name", sort: "asc" }] } }}
                            rows={[...rows, footerRow]}
                            columns={columns}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            sx={{
                                '& .total-row': {
                                    fontWeight: 'bold',
                                    fontSize: "1.1rem",
                                    fontFamily: "Lato",
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>)
        );
    }
}