import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

const useStyles = makeStyles((theme) => ({
  GridTableBox: {
    "& .MuiDataGrid-columnHeader:first-child": {
      width: "300px !important",
      maxWidth: "300px !important",
    },
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:first-child":
    {
      width: "300px !important",
      maxWidth: "300px !important",
    },
  },
}));

export default function MTCompletionDetailReport(props) {
  const classes = useStyles();
  const [gridId, setGridId] = useState(39);
  const apiRef = useGridApiRef();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(0);
  const [reportDetails, setReportDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    axios
      .get(APIURL + "/jsreport/getAllReports", {
        params: { empID: props.user.emp_id },
      })
      .then((response) => {
        const report = response?.data?.reports.find((ele) => ele.report_name === "MT Completion Detail Report");
        setReportDetails(report);
        setIsFavorite(report ? (report.status === 1 ? true : false) : false);
      });

    axios
      .get(APIURL + "/reports/getMTCompletionDetail", {
        params: { company_id: props.user.comp_id, emp_id: props.user.emp_id, type: props.user.type },
      })
      .then((response) => {
        setData(response?.data?.managersData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const columns = [
    { field: "previous_month", headerName: moment().subtract(1, "month").startOf("month").format("MMM"), },
    { field: "current_month", headerName: moment().format("MMM") },
    { field: "next_month", headerName: moment().add(1, "month").endOf("month").format("MMM"), },
    { field: "local_id", headerName: "Local ID" },
    { field: "ddd_id", headerName: "DDD ID" },
    { field: "task_type", headerName: "Task Type", width: 200 },
    { field: "task_location", headerName: "Task Location", width: 350 },
    { field: "contact_date", headerName: "Contact Date" },
    { field: "status", headerName: "MT Status", width: 150 },
    { field: "emp_name", headerName: "Employee Name", width: 150 },
  ]

  let rows = [];

  /* Supervisor => Admins (or Managers) */
  data?.map((sv, index) => {
    let svData = {
      hierarchy: [
        sv?.emp_first_name + " " + sv?.emp_last_name + " " + sv?.emp_id,
      ],
      rowId: sv?.emp_id + index + 1 + Math.random().toString(36).substring(2, 12) + index,
      previous_month: sv.previous_month,
      current_month: sv.current_month,
      next_month: sv.next_month,
      contact_date: '',
      emp_name: sv?.emp_first_name + " " + sv?.emp_last_name,
    };
    rows.push(svData);

    /* Customers related to supervisor */
    if (sv?.customers?.length > 0) {
      sv.customers.map((cus, ind) => {
        let sortedTasks = cus?.tasks.sort((a, b) => moment(b.tas_start_date) - moment(a.tas_start_date))
        let svCustomers = {
          hierarchy: [
            sv?.emp_first_name + " " + sv?.emp_last_name + " " + sv?.emp_id,
            cus?.cus_name + " " + cus?.cus_id + '(' + ind + ')',
          ],
          rowId: cus?.cus_id + ind + Math.random().toString(36).substring(2, 12) + index,
          ddd_id: cus?.DDDID,
          local_id: cus?.cus_local_id,
          task_type: sortedTasks[0]?.tas_type,
          task_location: sortedTasks[0]?.location_full_address,
          contact_date: sortedTasks[0]?.tas_start_date ? moment(sortedTasks[0]?.tas_start_date).utc().format('MM/DD/YYYY') : '-',
          status: sortedTasks[0]?.tas_mm_status,
          emp_name: sv?.emp_first_name + " " + sv?.emp_last_name,
        };
        rows.push(svCustomers);
      });
    }

    /* Support Coordinators */
    sv?.suppport_coordinators.map((sc, ind) => {
      let scData = {
        hierarchy: [
          sv?.emp_first_name + " " + sv?.emp_last_name + " " + sv?.emp_id,
          sc?.emp_first_name + " " + sc?.emp_last_name + " " + sc?.emp_id,
        ],
        rowId: sc?.emp_id + ind + Math.random().toString(36).substring(2, 12) + ind,
        previous_month: sc.previous_month,
        current_month: sc.current_month,
        next_month: sc.next_month,
        contact_date: '',
        emp_name: sc?.emp_first_name + " " + sc?.emp_last_name,
      };
      rows.push(scData);

      /* Customers related to Support Coordinators */
      sc?.customers.map((cus, inx) => {
        let sortedTasks = cus?.tasks.sort((a, b) => moment(b.tas_start_date) - moment(a.tas_start_date))
        let scCustomers = {
          hierarchy: [
            sv?.emp_first_name + " " + sv?.emp_last_name + " " + sv?.emp_id,
            sc?.emp_first_name + " " + sc?.emp_last_name + " " + sc?.emp_id,
            cus?.cus_name + " " + cus?.cus_id + " " + cus?.pro_id,
          ],
          rowId: cus?.cus_id + ind + Math.random().toString(36).substring(2, 12) + index,
          ddd_id: cus?.DDDID,
          local_id: cus?.cus_local_id,
          task_type: sortedTasks[0]?.tas_type,
          task_location: sortedTasks[0]?.location_full_address,
          contact_date: sortedTasks[0]?.tas_start_date ? moment(sortedTasks[0]?.tas_start_date).utc().format('MM/DD/YYYY') : '-',
          status: sortedTasks[0]?.tas_mm_status,
          emp_name: sc?.emp_first_name + " " + sc?.emp_last_name,
        };
        rows.push(scCustomers);
      });
    });
  });

  const groupingColDef = {
    headerName: 'Employees',
    valueFormatter: (value, row, column, apiRef) => typeof row.id === 'number' ? '' : value
  };

  const markFavourite = async (data, type) => {
    const payload = {
      report_id: data?.report_id,
      status: type,
      empID: props.user.emp_id
    };

    axios
      .put(APIURL + "/jsreport/markFavorite", payload)
      .then((response) => {
        if (response?.status === 200 && response?.data?.code === 200) {
          setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
        } else {
          setErrorText("Something went wrong");
        }

        fetchData();
      });
  }

  const handlePaginationModelChange = (paginationModel) => {
    console.log("paginationModel", paginationModel);
    if (paginationModel.pageSize !== pageSize) {
      setPageSize(paginationModel.pageSize);
      setPageNo(0);
    } else {
      setPageNo(paginationModel.page);
    }
  };

  return (
    <div style={{ margin: 10, width: '75vw' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div />
          <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
            <img
              src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
              alt="barchart"
              style={{
                fontFamily: "Lato",
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
                margin: 5
              }}
              onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
            />
          </Tip>
        </div>
        <div style={{ height: '75vh' }}>
          <ControlledDataGrid
            gridId={gridId}
            user={props.user}
            initialState={{ sorting: { sortModel: [{ field: "contact_date", sort: "desc" }] } }}
            apiRef={apiRef}
            className={classes.GridTableBox}
            rows={rows}
            columns={columns}
            pagination
            rowsPerPageOptions={[20, 50, 100]}
            paginationModel={{ page: pageNo, pageSize: pageSize }}
            onPaginationModelChange={(paginationModel) => handlePaginationModelChange(paginationModel)}
            disableRowSelectionOnClick
            treeData
            getTreeDataPath={(row) => row.hierarchy}
            getRowId={(row) => row.rowId}
            scrollbarSize={1}
            groupingColDef={groupingColDef}
            columnVisibilityModel={{
              emp_name: false,
            }}
            loading={isLoading}
          />
        </div>
      </div>

      <Snackbar
        open={successText !== ""}
        autoHideDuration={30000}
        onClose={() => setSuccessText("")}
      >
        <Alert
          sx={{ width: "100%" }}
          severity={"success"}
          elevation={6}
          variant="filled"
        >
          {successText}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={() => setSuccessText("")}
          >
            <CloseIcon />
          </IconButton>
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorText !== ""}
        autoHideDuration={30000}
        onClose={() => setErrorText("")}
      >
        <Alert
          sx={{ width: "100%" }}
          severity={"error"}
          elevation={6}
          variant="filled"
        >
          {errorText}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={() => setErrorText("")}
          >
            <CloseIcon />
          </IconButton>
        </Alert>
      </Snackbar>
    </div>
  );
}