import React, { useState, useEffect } from "react";
import { Button as RBButton } from "react-bootstrap";
import { Snackbar, IconButton, Alert, Button, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import DownloadIcon from '@mui/icons-material/Download';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";
import dayjs from "dayjs";

export default function TasksReport(props) {
    const [gridId, setGridId] = useState(42);
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [reportFilter, setReportFilter] = useState({
        dateRange: [
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
        ],
    });
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports", {
                params: { empID: props.user.emp_id },
            })
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "Tasks Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        axios
            .get(APIURL + "/reports/getTasks", {
                params: {
                    com_id: props.comp_id,
                    emp_id: props.user.emp_id,
                    type: props.user.type,
                    start: moment(reportFilter?.dateRange[0]).startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
                    end: moment(reportFilter?.dateRange[1]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
                },
            })
            .then((response) => {
                setData(response?.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    let rows = data.map((data, idx) => {
        let cusName = data.cus_name.split(/\s+/)
        let taskDuration = moment(data.empLocdate).isValid() && moment(data.checkoutEmpDate).isValid()
            ? (
                moment(data.checkoutEmpDate).diff(
                    moment(data.empLocdate),
                    "m"
                ) / 60
            ).toFixed(2)
            : 0
        let taskData = {
            id: idx,
            tas_id: data.tas_id,
            tas_start_date: data.empLocdate,
            emp_id: data.emp_id,
            emp_last_name: data.emp_last_name,
            emp_first_name: data.emp_first_name,
            emp_local_id: data.emp_local_id,
            cus_id: data.cus_id,
            cus_last_name: cusName[1],
            cus_first_name: cusName[0],
            cus_local_id: data.cus_local_id,
            tas_type: data.tas_type,
            tas_duration: taskDuration,
            compensation_rate: data.compensation_rate
        };
        return taskData;
    });

    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "tas_id", headerName: "Task ID", hide: true },
        {
            field: "tas_start_date",
            headerName: "Task Date",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('M/D/YYYY') : params.value}</div>
                )
            },
            valueFormatter: (value, row, column, apiRef) => moment(value).isValid() ? moment(value).utc().format('M/D/YYYY') : value,
        },
        { field: "emp_id", headerName: "Employee ID", hide: true },
        { field: "emp_last_name", headerName: "Employee Last Name", width: 150 },
        { field: "emp_first_name", headerName: "Employee First Name", width: 150 },
        { field: "emp_local_id", headerName: "Employee Local ID", width: 130 },
        { field: "cus_id", headerName: "Customer ID", hide: true },
        { field: "cus_last_name", headerName: "Customer Last Name", width: 150 },
        { field: "cus_first_name", headerName: "Customer First Name", width: 150 },
        { field: "cus_local_id", headerName: "Customer Local ID", width: 130 },
        { field: "tas_type", headerName: "Task Type", width: 200 },
        { field: "tas_duration", headerName: "Task Duration (Hrs)", width: 140 },
        { field: "compensation_rate", headerName: "Rate", width: 100 },
        { field: "", headerName: "", hide: true },
    ]

    const handleExportCsv = () => {
        const csvData = (['Date', 'Last Name', 'First Name', 'Employee Number', 'Project', '', '', 'Account Code', '', '', 'Hours', 'Cost Rate', 'ot h', 'ot p', 'tot', 'pid']).join(',') + '\n';
        const csvRows = rows.map((row) => [moment(row['tas_start_date']).utc().format('M/D/YYYY'), row['emp_last_name'], row['emp_first_name'], row['emp_local_id'], row['cus_last_name'], row['cus_first_name'], row['cus_local_id'], row['tas_type'], row[''], row[''], row['tas_duration'], row['compensation_rate'], row[''], row[''], row[''], row['']].join(',')).join('\n');
        const csvString = csvData + csvRows;
        const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const csvUrl = URL.createObjectURL(csvBlob);
        const tempLink = document.createElement('a');
        tempLink.href = csvUrl;
        tempLink.setAttribute('download', 'FW_PayrollDataFeed.csv');
        tempLink.click();
    };

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
            empID: props.user.emp_id
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    return (
        <div style={{ margin: 10, width: '75vw' }}>
            <div style={{ display: 'flex', marginTop: 20, marginBottom: 20 }}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{ start: "From Date", end: "To Date" }}
                >
                    <DateRangePicker
                        value={dayjs(reportFilter?.dateRange)}
                        onChange={(newValue) => {
                            setReportFilter({
                                ...reportFilter,
                                dateRange: [new Date(newValue[0]), new Date(newValue[1])]
                            });
                        }}
                        slotProps={{ fieldSeparator: { children: 'to' } }}
                    />
                </LocalizationProvider>

                <RBButton
                    onClick={() => fetchData()}
                    variant="primary"
                    size="lg"
                    className="font-weight-bold"
                    style={{ marginLeft: 10, fontSize: 14, fontFamily: "Lato" }}
                >
                    SEARCH
                </RBButton>
            </div>
            <div style={{ marginTop: '-55px', float: 'right' }}>
                <Button variant="contained" onClick={handleExportCsv} endIcon={<DownloadIcon />}>Export CSV</Button>
                <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                    <img
                        src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                        alt="barchart"
                        style={{
                            fontFamily: "Lato",
                            width: "1.5rem",
                            height: "1.5rem",
                            cursor: "pointer",
                            margin: 5
                        }}
                        onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                    />
                </Tip>
            </div>
            <div>
                <div style={{ height: '70vh' }}>
                    <ControlledDataGrid
                        gridId={gridId}
                        user={props.user}
                        initialState={{ sorting: { sortModel: [{ field: "tas_start_date", sort: "desc" }] } }}
                        apiRef={apiRef}
                        getRowId={(row) => row.id}
                        rows={rows}
                        columns={columns}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        rowsPerPageOptions={[]}
                        loading={isLoading}
                    />
                </div>
            </div>
            <Snackbar
                open={successText !== ""}
                autoHideDuration={30000}
                onClose={() => setSuccessText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"success"}
                    elevation={6}
                    variant="filled"
                >
                    {successText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setSuccessText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorText !== ""}
                autoHideDuration={30000}
                onClose={() => setErrorText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"error"}
                    elevation={6}
                    variant="filled"
                >
                    {errorText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setErrorText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
        </div>
    );
}