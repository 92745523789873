import React, { Component } from 'react';
import TaskInfo from './TaskInfo/TaskInfo';
import './DetailCard.css';
import { Collapse } from 'react-bootstrap';
import 'moment-timezone';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../../../config';
import ArtInfo from './TaskInfo/ArtInfo';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class DetailCard extends Component {
    state = {
        open: false,
        completedTaskData: [],
        artifacts: []
    }

    async componentDidMount() {
        if (this.props.task) {
            let completedTaskData = await axios(APIURL + '/locate/TimeSpent', { params: { projId: this.props.projId, comp_id: this.props.comp_id } })
            var taskTimeSpent = this.getTimings(completedTaskData.data)
            this.setState({ completedTaskData: taskTimeSpent })
        } else if (this.props.art) {
            let artifactsData = await axios.get(APIURL + "/artifacts?id=" + this.props.projId)

            let artifacts = artifactsData.data.map((art) => {
                const task = this.props.tasks.find(task => art.task_id === task.tas_id)
                const employee = this.props.employee.find(emp => art.employee_id === emp.id)
                if (!employee) {
                    return { ...art, taskName: '', taskType: '', empName: " " }
                }
                if (!!task) {
                    return { ...art, taskName: task.tas_title, taskType: task.tas_type, empName: employee.employee_name }
                } else {
                    return { ...art, taskName: '', taskType: '', empName: employee.employee_name }
                }
            })
            this.setState({
                artifacts: artifacts.filter((art) => !!art.show)
            })
        }
    }

    setOpen = (open) => {
        this.setState({ open })
    }

    showTaskList = () => {
        if (this.props.tasks && this.props.tasks.length > 0) {
            return this.props.tasks.filter((task) => (task.tas_is_active !== 2)).map(
                task => <TaskInfo
                    key={task.tas_id}
                    name={task.tas_title}
                    momentCode={this.state.completedTaskData.find((completedTask) => (completedTask.taskId === task.tas_id))}
                    start_date={task.tas_start_date}
                    end_date={task.tas_end_date}
                    is_active={task.tas_is_active}
                />
            )
        } else {
            return <div className='d-flex flex-column justify-content-center align-items-center mt-4' style={{ height: '300px', margin: 5 }}>
                <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 28, marginBottom: 28 }}>No Tasks found </h5>
            </div>
        }
    }

    showArtList = () => {
        if (this.state.artifacts.length > 0) {
            return this.state.artifacts.map(
                art => <ArtInfo
                    key={art.id}
                    data={art}
                />
            )
        } else if (this.state.artifacts.length === 0) {
            return <div className='d-flex flex-column justify-content-center align-items-center mt-4' style={{ height: '300px', margin: 5 }}>
                <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 28, marginBottom: 28 }}>No Artifacts found </h5>
            </div>
        }
    }

    calCumulativeTimeSpent = () => {
        var TotalTime = moment()
        this.state.completedTaskData.forEach((taskData) => {
            var CheckInTime = moment(taskData.CheckInTime)
            var CheckoutTime = moment(taskData.CheckoutTime)
            var taskTime = moment.duration(CheckoutTime.diff(CheckInTime))
            TotalTime.add(taskTime)
        })
        return moment.duration(TotalTime.diff(moment())).humanize()
    }

    getTimings = (timedata) => {
        let taskdataTimeSpent = !this.props.tasks ? [] : this.props.tasks.filter((item) => (item.tas_is_active !== 2)).filter((task) => (!(task.tas_is_active === 0 && (moment(task.tas_start_date).isAfter(new Date()) || moment(task.tas_end_date).isBefore(new Date()))))).map((task) => {
            let foundTask = timedata.find((fTask) => (fTask.taskId === task.tas_id))
            if (!foundTask) {
                let timeStartHours = parseInt(task.tas_start_time.slice(0, 2));
                let timeEndHours = parseInt(task.tas_end_time.slice(0, 2));
                if (task.tas_start_time.slice(-2) === 'PM' && timeStartHours !== 12) {
                    timeStartHours += 12
                } else if (task.tas_start_time.slice(-2) === 'AM' && timeStartHours === 12) {
                    timeStartHours = 0
                }
                if (task.tas_end_time.slice(-2) === 'PM' && timeEndHours !== 12) {
                    timeEndHours += 12
                } else if (task.tas_end_time.slice(-2) === 'AM' && timeEndHours === 12) {
                    timeEndHours += 12
                }
                if (task.tas_is_active === 0 && moment(task.tas_start_date).isSameOrBefore(new Date())) {
                    if (!timedata.find((Ctask) => (Ctask.taskId === task.tas_id))) {
                        return null
                    } else {
                        return { CheckInTime: moment(task.tas_start_date).add(timeStartHours, 'h'), CheckoutTime: moment(), taskId: task.tas_id }
                    }
                } else {
                    return { CheckInTime: moment(task.tas_start_date).add(timeStartHours, 'h'), CheckoutTime: moment(task.tas_end_date).add(timeEndHours, 'h'), taskId: task.tas_id }
                }
            } else {
                return { CheckInTime: moment(foundTask.CheckInTime), CheckoutTime: moment(foundTask.CheckoutTime), taskId: task.tas_id }
            }
        }).filter((task) => (!!task))
        return taskdataTimeSpent
    }

    render() {
        return (
            <Accordion style={{ marginTop: "10px" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div id='contact_call_icon'>
                        <i className={this.props.task ? "far fa-clock fa-sm" : "fas fa-briefcase fa-sm"}></i>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                        <Typography style={{ fontSize: '1rem' }} className="text-muted">{this.props.task ? "TIME SPENT ON TASKS" : "ARTIFACTS"}</Typography>
                        {this.props.task && <Typography style={{ fontSize: '1rem' }}>{this.calCumulativeTimeSpent()}</Typography>}
                        {this.props.task && <Typography style={{ fontSize: '1rem' }}>Spent over {this.state.completedTaskData.length} tasks</Typography>}
                        {this.props.art && <Typography style={{ fontSize: '1rem' }}>{this.state.artifacts.length} uploads</Typography>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="d-flex flex-column">
                        {this.props.task && this.showTaskList()}
                        {this.props.art && this.showArtList()}
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default DetailCard;