import React, { createRef, useImperativeHandle } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { jsonToCSV } from "react-papaparse";
import { CSVDownloader } from "react-papaparse";
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

const columns = [
  {
    field: "id",
    headerName: "INVOICE ID",
    width: 200,
    hide: true,
    csv_field: "Id",
  },
  {
    field: "invoice_date",
    headerName: "DATE OF INVOICE",
    width: 200,
    csv_field: "DateofInvoice",
  },
  {
    field: "cus_name",
    headerName: "CUSTOMER NAME",
    width: 200,
    csv_field: "CutomerName",
  },
  {
    field: "procProcCode",
    headerName: "PROCEDURE CODE",
    align: "center",
    width: 200,
    csv_field: "ProcedureName",
  },
  {
    field: "invoice_number",
    headerName: "INVOICE NUMBER",
    width: 200,
    csv_field: "InvoiceNumber",
  },
  {
    field: "line_item_no",
    headerName: "LINE ITEM NUMBER",
    width: 200,
    csv_field: "LineItemNumber",
  },
  {
    field: "amount",
    headerName: "AMOUNT",
    width: 200,
    csv_field: "Amount",
  },
];

class Evv_Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.apiRef = createRef();
  }
  state = {
    gridId: 44,
    loading: true,
    searchText: "",
    isCustomReportOpen: false,
    filterResultsOutput: "",
    invoiceReports: [],
    columns: columns,
    pageSize: 10,
    pageNo: 0,
    reportDetails: {},
    isFavorite: false,
    successText: "",
    errorText: ""
  };

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    axios
      .get(APIURL + "/jsreport/getAllReports", {
        params: { empID: this.props.user.emp_id },
      })
      .then((response) => {
        const report = response?.data?.reports.find((ele) => ele.report_name === "EVV Invoice");
        this.setState({
          reportDetails: report,
          isFavorite: report ? (report.status === 1 ? true : false) : false,
        });
      });

    let invoice_reports = await axios.get(APIURL + "/invoice", {
      params: { comp_id: this.props.comp_id, emp_id: this.props.user.emp_id },
    });
    let sortedData = invoice_reports.data.sort((a, b) =>
      a.invoice_number > b.invoice_number
        ? 1
        : b.invoice_number > a.invoice_number
          ? -1
          : 0
    );

    this.setState({ invoiceReports: sortedData, loading: false });
  }

  markFavourite = async (data, type) => {
    const payload = {
      report_id: data?.report_id,
      status: type,
      empID: this.props.user.emp_id
    };

    axios
      .put(APIURL + "/jsreport/markFavorite", payload)
      .then((response) => {
        if (response?.status === 200 && response?.data?.code === 200) {
          this.setState({
            successText: response?.data?.status_message || "Favorite Modifed Succesfully",
          });
        } else {
          this.setState({
            successText: "Something went wrong",
          });
        }

        this.fetchData();
      });
  }

  onChangeSearch = (e) => {
    this.setState({
      ...this.state,
      searchText: e.target.value,
    });
  };

  handlePaginationModelChange = (paginationModel) => {
    console.log("paginationModel", paginationModel);
    if (paginationModel.pageSize !== this.state.pageSize) {
      this.setState({
        ...this.state,
        pageSize: paginationModel.pageSize,
        pageNo: 0,
      });
    } else {
      this.setState({
        ...this.state,
        pageNo: paginationModel.page,
      });
    }
  };

  openCustomReport = (e) => {
    e.preventDefault();
    console.log("Custom Report");
    if (this.state.isCustomReportOpen) {
      this.setState({
        ...this.state,
        isCustomReportOpen: !this.state.isCustomReportOpen,
        filterResultsOutput: "",
      });
      localStorage.removeItem("evvFilterData");
    } else {
      this.setState({
        ...this.state,
        isCustomReportOpen: !this.state.isCustomReportOpen,
      });
    }
  };

  closeCustomReport = (e) => {
    e.preventDefault();
    console.log("Custom Report", e);
    let val;
    val = !this.state.isCustomReportOpen;
    this.setState({
      ...this.state,
      isCustomReportOpen: val,
    });
  };

  onSubmitCustomReport = () => {
    console.log("Submit Filter");
  };

  handleFilterForm = (finalObj) => {
    console.log("Final object", finalObj);

    const objectValues = Object.values(finalObj);
    const objectKeys = Object.keys(finalObj);
    let output = [];
    objectValues.forEach((element, index) => {
      if (element) {
        output.push(element);
      }
    });
    output = output.join(", ");
    console.log("output", output);
    this.setState({
      ...this.state,
      filterResults: finalObj,
      filterResultsOutput: output,
    });
  };

  makeTableHTML = (myArray) => {
    var result = "<table>  ";
    let limit = myArray?.length;

    result += "<tr>";
    for (var c = 0; c < this.state.columns?.length; c++) {
      result +=
        "<th style='width:100%; border-bottom: 1px solid black'>" +
        this.state.columns[c].headerName +
        "</th>";
    }
    result += "</tr>";
    for (var i = 0; i < limit; i++) {
      result += "<tr>";
      for (var j = 0; j < this.state.columns?.length; j++) {
        result +=
          "<td style='width:100%; border-bottom: 1px solid black'>" +
          myArray[i][this.state.columns[j].csv_field] +
          "</td>";
      }
      result += "</tr>";
    }
    result += "</table>";
    let newWin = window.open("");
    newWin.document.write(result);
    newWin.print();
    newWin.close();
  };

  getRowsData = () => {
    let arr = [];
    this.state.invoiceReports.forEach((EvvReportsarray) => {
      let row = {
        Id: EvvReportsarray?.id,
        DateofInvoice: EvvReportsarray?.invoice_date ?? "-",
        CutomerName: EvvReportsarray?.cus_name ?? "-",
        ProcedureName: EvvReportsarray?.procProcCode ?? "-",
        InvoiceNumber: EvvReportsarray?.invoice_number ?? "-",
        LineItemNumber: EvvReportsarray?.line_item_no ?? "-",
        Amount: EvvReportsarray?.amount ?? "-",
      };

      arr.push(row);
    });
    this.makeTableHTML(arr);
  };

  handlePrint = () => {
    this.getRowsData();
  };

  render() {
    let evv_rows = this.state.invoiceReports.map((EvvReportsarray) => {
      let row = {
        Id: EvvReportsarray?.id,
        DateofInvoice: EvvReportsarray?.invoice_date ?? "-",
        CutomerName: EvvReportsarray?.cus_name ?? "-",
        ProcedureName: EvvReportsarray?.procProcCode ?? "-",
        InvoiceNumber: EvvReportsarray?.invoice_number ?? "-",
        LineItemNumber: EvvReportsarray?.line_item_no ?? "-",
        Amount: EvvReportsarray?.amount ?? "-",
      };

      return row;
    });

    let row_csv_evv = evv_rows.map((repevv) => {
      let new_repevv = {
        Id: repevv.Id,
        "Date Of Invoice": repevv.DateofInvoice,
        "Cutomer Name": repevv.CutomerName,
        "Procedure Name": repevv.ProcedureName,
        "Invoice Number": repevv.InvoiceNumber,
        "Line Item Number": repevv.LineItemNumber,
        Amount: repevv.Amount,
      };
      return new_repevv;
    });

    const csv_evv = jsonToCSV(row_csv_evv);

    let rows = this.state.invoiceReports.map((inv) => {
      let invDets = {
        id: inv.id,
        invoice_date: inv.invoice_date,
        cus_name: inv.cus_name,
        procProcCode: inv.procProcCode,
        invoice_number: inv.invoice_number,
        line_item_no: inv.line_item_no,
        amount: inv.amount,
      };
      return invDets;
    });

    return (
      <div style={{ margin: 10, width: '75vw' }}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div />
            <div>
              <CSVDownloader
                data={csv_evv}
                filename={"EVV report"}
                bom={true}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-outline-info m-1"
                >
                  CSV
                </button>
              </CSVDownloader>

              <button
                type="button"
                className="btn btn-sm btn-outline-info m-1"
                onClick={this.handlePrint}
              >
                PDF
              </button>
              <Tip title={this.state.isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                <img
                  src={this.state.isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                  alt="barchart"
                  style={{
                    fontFamily: "Lato",
                    width: "1.5rem",
                    height: "1.5rem",
                    cursor: "pointer",
                    margin: 5
                  }}
                  onClick={() => this.markFavourite(this.state.reportDetails, this.state.isFavorite ? 0 : 1)}
                />
              </Tip>
            </div>
          </div>
          <div style={{ height: '70vh' }}>
            <ControlledDataGrid
              gridId={this.state.gridId}
              user={this.props.user}
              initialState={{ sorting: { sortModel: [{ field: "invoice_date", sort: "desc" }] } }}
              apiRef={this.apiRef}
              rows={rows}
              columns={columns}
              autoHeight={true}
              checkboxSelection={true}
              pagination
              rowsPerPageOptions={[5, 10, 20]}
              paginationModel={{ page: this.state.pageNo, pageSize: this.state.pageSize }}
              onPaginationModelChange={(paginationModel) => this.handlePaginationModelChange(paginationModel)}
              loading={this.state.loading}
            />
          </div>
        </div>
        <Snackbar
          open={this.state.successText !== ""}
          autoHideDuration={30000}
          onClose={() => this.setState({ successText: "" })}
        >
          <Alert
            sx={{ width: "100%" }}
            severity={"success"}
            elevation={6}
            variant="filled"
          >
            {this.state.successText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => this.setState({ successText: "" })}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.errorText !== ""}
          autoHideDuration={30000}
          onClose={() => this.setState({ errorText: "" })}
        >
          <Alert
            sx={{ width: "100%" }}
            severity={"error"}
            elevation={6}
            variant="filled"
          >
            {this.state.errorText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => this.setState({ errorText: "" })}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
      </div>
    )
  }
}
const EvvInvoice = React.forwardRef((props, ref) => {
  const apiRef = useGridApiRef();
  useImperativeHandle(ref, () => apiRef.current);

  return <Evv_Invoice {...props} apiRef={apiRef} />;
});

export default EvvInvoice;