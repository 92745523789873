import React, { Component } from 'react';
import DetailCard from './DetailCard/DetailCard';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import CustomerForm from '../../../components/forms/CustomerForm/CustomerForm';
import ProjectForm from '../../../components/forms/ProjectForm/ProjectForm';
import './Home.css';
import 'moment-timezone';
import { APIURL } from '../../../config';
import axios from 'axios';
import moment from 'moment';
import { Card, CardActions, CardContent, Icon, IconButton, Dialog, DialogActions, DialogContent, Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';

class Home extends Component {
    // 0 - indicates show home page
    // 1 - indicates show customer details
    // 2 - indicates edit form for customer details
    // 3 - indicates edit project form
    state = {
        contextMenu: 0,
        customerStatus: [],
        customerType: [],
        loc_list: [],
        employee_list: [],
        project_tasks: [],
        loading: true,
    }

    async fetchData() {
        let project_tasks = await axios.get(APIURL + "/project/projTask", { params: { proj_id: this.props.id } })
        let customerStatus = await axios.get(APIURL + '/dropdowns/dropdownType/customerStatus', { params: { comp_id: this.props.comp_id } });
        let customerType = await axios.get(APIURL + '/dropdowns/dropdownType/customerType', { params: { comp_id: this.props.comp_id } });
        let loc_list = await axios.get(APIURL + '/dropdowns/dropdownType/locationslist', { params: { comp_id: this.props.comp_id } });
        let employee_list = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: this.props.comp_id } });

        let payerDetails = await axios.get(APIURL + `/associate_payer`, {
            params: {
                compId: this.props.comp_id,
            }
        });
        payerDetails = payerDetails.data.filter((p) => p.id);

        this.setState({
            customerStatus: customerStatus.data,
            customerType: customerType.data,
            loc_list: loc_list.data,
            employee_list: employee_list.data,
            project_tasks: project_tasks.data,
            payerList: payerDetails,
            loading: false
        })
    }

    async componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.setState({ loading: true })
            this.fetchData()
        }
    }

    navigationHandler = (contextMenu) => {
        this.setState({ contextMenu })
    }

    showProjectStatus = () => {
        if (this.props.status) {
            return "Ongoing"
        } else {
            return "Completed"
        }
    }

    getProjectLocation = () => {
        let proj_loc_list = this.props.locations
        let filtered_loc_list = []
        proj_loc_list.forEach((loc) => {
            filtered_loc_list.push(this.state.loc_list.filter((loc1) => loc1.id === loc.loc_id)[0])
        })
        console.log(this.props.project_details.locations, filtered_loc_list)
        return filtered_loc_list
    }

    calCumulativeTimeSpent = () => {
        var TotalTime = moment();
        this.state.project_tasks.forEach((taskData) => {
            var CheckInTime = moment(taskData.CheckInTime);
            var CheckoutTime = moment(taskData.CheckoutTime);
            var taskTime = moment.duration(CheckoutTime.diff(CheckInTime));
            TotalTime.add(taskTime);
        });
        return moment.duration(TotalTime.diff(moment())).humanize();
    }

    render() {
        let content = null;
        if (this.state.contextMenu === 1) {
            content = (
                <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.contextMenu === 1} onClose={() => this.navigationHandler(0)}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>Customer Details</h4>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.navigationHandler(0)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        <CustomerDetails
                            history={this.props.history}
                            onClickBack={this.navigationHandler}
                            onClickEdit={this.navigationHandler}
                            customer_details={this.props.customer_details[0]}
                            projectTitle={this.props.title}
                        />
                    </DialogContent>
                </Dialog>
            );
        } else if (this.state.contextMenu === 0) {
            let timeSpent = this.calCumulativeTimeSpent();
            content = (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className="overview-top">
                        <div className='home_info'>{this.props.title}
                            <Tooltip title="Edit Project">
                                <IconButton
                                    style={{ float: 'right' }}
                                    onClick={() => this.navigationHandler(3)}
                                >
                                    <Icon style={{ fontSize: '16px' }} baseClassName="fas" className="fa-edit" />
                                </IconButton>
                            </Tooltip>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={9}>
                                        <Grid container rowSpacing={1}>
                                            <Grid item xs={5} className="text-muted" style={{ fontSize: '1rem' }}>PROJECT STATUS</Grid>
                                            <Grid item xs={7} className="" style={{ fontSize: '1rem' }}>{this.showProjectStatus()}</Grid>
                                            <Grid item xs={5} className="text-muted" style={{ fontSize: '1rem' }}>START DATE</Grid>
                                            <Grid item xs={7} className="" style={{ fontSize: '1rem' }}>{moment(this.props.start_date.replace(/Z/g, "")).format('D MMM YYYY')}</Grid>
                                            <Grid item xs={5} className="text-muted" style={{ fontSize: '1rem' }}>END DATE</Grid>
                                            <Grid item xs={7} className="" style={{ fontSize: '1rem' }}>{moment(this.props.end_date.replace(/Z/g, "")).format('D MMM YYYY')}</Grid>
                                            <Grid item xs={5} className="text-muted" style={{ fontSize: '1rem' }}>DESCRIPTION</Grid>
                                            <Grid item xs={7} className="" style={{ fontSize: '1rem' }}>{this.props.project_details.pro_description}</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className="overview-bottom">
                        <div className='projects-overview'>Project Overview
                            <div>
                                <Accordion style={{ marginTop: "10px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div id='contact_call_icon'>
                                            <i className="fas fa-map-marker-alt fa-sm"></i>
                                        </div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <Typography style={{ fontSize: '1rem' }} className="text-muted">LOCATIONS</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="d-flex flex-column">
                                            {this.props.locations?.map((loc, index) => (
                                                <Typography key={index}>
                                                    <i style={{ paddingRight: "10px", paddingLeft: "15px" }} className="fas fa-map-marker-alt pb-3"></i>
                                                    <span>{loc.loc_addr_line1 ? loc.loc_addr_line1 : ''} {loc.loc_addr_line2 ? loc.loc_addr_line2 : ''} {loc.loc_city ? loc.loc_city : ''} {loc.loc_state ? loc.loc_state : ''} {loc.loc_zip ? loc.loc_zip : ''}</span>
                                                </Typography>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <DetailCard task comp_id={this.props.comp_id} projId={this.props.id} tasks={(this.props.user.type === 'Admin' || this.props.user.type === 'Manager') ? this.state.project_tasks : !this.state.project_tasks ? this.state.project_tasks : this.state.project_tasks.filter((tas) => tas.tas_emp_id === this.props.user.emp_id)} />
                                <DetailCard art comp_id={this.props.comp_id} projId={this.props.id} employee={this.state.employee_list} tasks={this.state.project_tasks} />

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.contextMenu === 2) {
            content = (
                <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.contextMenu === 2} onClose={() => this.navigationHandler(0)}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>Edit Customer Details</h4>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.navigationHandler(0)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        <CustomerForm
                            history={this.props.history}
                            isFormValidated={this.props.isFormValidated}
                            customerStatus={this.state.customerStatus}
                            customerType={this.state.customerType}
                            states={this.props.states}
                            empList={this.props.empList}
                            tasklist={this.state.project_tasks}
                            loc_list={this.state.loc_list}
                            edit
                            comp_id={this.props.comp_id}
                            cusDets={this.props.customer_details[0]}
                            pId={this.props.id}
                            pManagerId={this.props.pManagerId}
                            user={this.props.user}
                        />
                    </DialogContent>
                </Dialog>
            );
        } else if (this.state.contextMenu === 3) {
            content = (
                <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.contextMenu === 3} onClose={() => this.navigationHandler(0)}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>Edit Project</h4>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.navigationHandler(0)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        <ProjectForm
                            user={this.props.user}
                            edit
                            comp_id={this.props.comp_id}
                            history={this.props.history}
                            mapRender={"proj"}
                            isFormValidated={this.props.isFormValidated}
                            project_details={{
                                ddd_id: this.props.project_details.DDD_ID,
                                active_plan_version: this.props.project_details.active_plan_version,
                                projId: this.props.project_details.pro_id,
                                pTitle: this.props.project_details.pro_title,
                                pDesc: this.props.project_details.pro_description,
                                pCustomer: this.props.project_details.pro_customer_id,
                                pManager: this.props.project_details.pro_manager_id,
                                pSponsor: this.props.project_details.pro_sponsors_id,
                                pstrtDate: this.props.project_details.pro_starts_on?.split("T")[0],
                                pEndDate: this.props.project_details.pro_ends_on?.split("T")[0],
                                pPrice: this.props.project_details.pro_price,
                                pCurrency: "USD",
                                pLoc: !!this.props.project_details.locations ? this.getProjectLocation() : [],
                                pSelfPay: this.props.project_details.pro_self_pay,
                                pPrimaryPayer: this.props.project_details.pro_primary_payer,
                                pSecondaryPayer: this.props.project_details.pro_secondary_payer
                            }}
                            customerName={this.props.project_details.customer_details[0].cus_name}
                            customerList={this.props.project_details.customer_details}
                            proj_Employees={this.props.empList}
                            empList={this.state.employee_list}
                            contactList={this.props.project_details.contacts}
                            locList={this.state.loc_list}
                            taskList={this.state.project_tasks}
                            updateProject={() => {
                                this.navigationHandler(0);
                                this.props.updateProject();
                            }}
                            empStatus
                            currency
                            frequency
                            payerList={this.state.payerList}
                        />
                    </DialogContent>
                </Dialog>
            );
        }
        return (
            <div style={{ overflowY: "auto", height: "100%" }}>
                {this.state.loading && (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!this.state.loading && content}
            </div>
        );
    }
}

export default Home;