import React, { useState, useEffect } from "react"
import HandyCard from '../../HandyCard/HandyCard';
import { Button, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios'
import moment from 'moment'
import { APIURL } from '../../../config'
import dd from '../../../manifestdd.json'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import Modal from '@mui/material/Modal';
import NoteFormv2 from '../../../components/forms/NoteForm/NoteFormv2'
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import './Notesv2.css'
import { Component } from 'react';
import { ConstructionOutlined } from "@mui/icons-material";
import { display } from "@mui/system";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIRichTextEditor from 'mui-rte';

const myTheme = createTheme({
    overrides: {
        MUIRichTextEditor: {
            anchorLink: {
                color: "lightblue",
                textDecoration: "underline",
            },
        },
    },
});

export default function Notesv2(props) {
    var Buffer = require('buffer/').Buffer;

    const [noteList, setNoteList] = useState([])
    const [displayNotes, setDisplayList] = useState([])
    const [filter, setFilter] = useState([])
    const [editNote, setEditNote] = useState(null)
    const [showAddNote, setShowAddNote] = useState(false)
    const [showAddVoiceNote, setShowAddVoiceNote] = useState(false)
    const [empList, setEmpList] = useState([])
    const [typeList, setTypeList] = useState([])
    const [content, setContent] = useState([])

    const [errorAlert, setErrorAlert] = useState('')
    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorAlert('')
    }

    const [successAlert, setSuccessAlert] = useState('')
    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessAlert('')
    }

    const [searchTerm, setSearchTerm] = useState('');

    const searchNoteHandler = (e) => {
        setSearchTerm(e.target.value);
    }

    const searchNoteResults = (e) => {
        e.preventDefault();
        const regex = new RegExp(searchTerm, 'i');
        let results = noteList.filter(note =>
            regex.test(note.note_title || '') ||
            regex.test(note.tas_title || '') ||
            regex.test(note.tas_type || '') ||
            regex.test(note.emp_first_name || '') ||
            regex.test(note.emp_last_name || '')
        );
        setDisplayList(results);
        generateNoteCards(results);
    }

    async function fetchData() {
        const response = await axios.get(APIURL + `/comments?pro_id=${props.pro_id}`)
        let notes = response.data

        notes = await Promise.all(notes.map(async (note) => {
            const noteDataResponse = await axios.get(APIURL + `/comments/details?comment_id=${note.comment_id}`)
            const noteData = noteDataResponse.data[0]
            return noteData
        }))

        notes = notes.filter((note) => note !== undefined);

        const audioResp = await axios.get(APIURL + `/audio_note`, {
            params: { project_id: props.pro_id },
        });

        let audioNotes = audioResp.data.map((audioNote) => ({
            ...audioNote,
            created_at: audioNote.submitted,
        }));

        notes.push(...audioNotes)

        notes.sort((a, b) => {
            return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
        })
        setFilter({ class: null, specifier: null })
        setNoteList(notes)
        setDisplayList(notes)
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchData();
    }, [showAddNote, editNote]);

    useEffect(async () => {
        const allEmployees = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: props.user.comp_id } });
        let empLs = allEmployees.data.map((emp) => <option key={emp.id} value={emp.id}>{emp.employee_name}</option>)
        let typeLs = dd.generic.employeeType.map((type) => <option key={type.value} value={type.value}>{type.value}</option>)
        setEmpList(empLs)
        setTypeList(typeLs)
        generateNoteCards(displayNotes)
    }, [displayNotes])

    useEffect(() => {
        async function fetchData() {
            const response = await axios.get(APIURL + `/comments?pro_id=${props.pro_id}`)
            let notes = response.data
            // console.log(notes)
            notes = await Promise.all(notes.map(async (note) => {
                const noteDataResponse = await axios.get(APIURL + `/comments/details?comment_id=${note.comment_id}`)
                const noteData = noteDataResponse.data[0]
                return noteData
            }))
            notes = notes.filter((note) => note !== undefined);
            notes.sort((a, b) => {
                return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
            })
            setFilter({ class: null, specifier: null })
            setNoteList(notes)
            setDisplayList(notes)
        }
        fetchData();
    }, [editNote])

    function filterBy() {
        let noteArr = noteList
        if (filter.class === 'Employee Type') {
            noteArr = noteArr.filter((note) => note.emp_type === filter.specifier)
        } else if (filter.class === 'Employee') {
            noteArr = noteArr.filter((note) => Number(filter.specifier) === note.emp_id[0])
        } else if (filter.class === 'All') {
            noteArr = noteArr.filter((note) => !note.isDeleted)
        }
        setDisplayList(noteArr)
        setFilter({ class: null, specifier: null })
    }

    function filterHandler(e) {
        let id = e.target.id
        let val = e.target.value
        if (id === 'filter1') {
            setFilter({ class: val, specifier: null })
        } else {
            setFilter({ class: filter.class, specifier: val })
        }
    }

    // sort function	
    function sortBy(param) {
        const listToSort = [...displayNotes];
        let sortedList;
        if (param === 'dateAsce') {
            sortedList = listToSort.sort((a, b) => moment(a.created_at) - moment(b.created_at));
        } else if (param === 'dateDsce') {
            sortedList = listToSort.sort((a, b) => moment(b.created_at) - moment(a.created_at));
        } else {
            sortedList = listToSort;
        }
        setDisplayList(sortedList);
        generateNoteCards(sortedList);
    }

    function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    const generateNoteCard = (note) => {
        let audioSrc;

        if (note.audio_note) {
            const decodedData = Buffer.from(note.audioNote[0].audio_note, 'base64');

            const blob = new Blob([decodedData], { type: 'audio/wav' });

            audioSrc = URL.createObjectURL(blob);
        }

        return (
            (<Card sx={{ marginBottom: '20px', border: '2px solid #bcd5f4' }}>
                <CardActions sx={{ paddingLeft: '16px' }}>
                    <div style={{ width: '80%' }}>
                        {note.note_title && <h6 style={{ marginBottom: '0px' }}>{note.note_title}</h6>}
                        {!note.note_title ?
                            <h6 style={{ marginBottom: '0px' }}>{note.tas_title ? note.tas_title : note.pro_title ? note.pro_title : note.cus_name}</h6> :
                            <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{note.tas_title ? note.tas_title : note.pro_title ? note.pro_title : note.cus_name}</p>
                        }
                        <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{note.tas_type}</p>
                        <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{moment(note.created_at).format('MM/DD/YYYY hh:mm A')} created by {note.emp_first_name + ' ' + note.emp_last_name} ({note.emp_type})</p>
                        {!!note.updated_by && !!note.updated_at &&
                            <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{moment(note.updated_at).format('MM/DD/YYYY hh:mm A')} updated by {note.updated_emp_first_name + ' ' + note.updated_emp_last_name}</p>
                        }
                    </div>
                    {!note.audioNote &&
                        <div style={note.tas_id != null ? { width: '20%', paddingBottom: '40px' } : { width: '20%', paddingBottom: '0px' }}>
                            <IconButton
                                style={{ float: 'right' }}
                                onClick={() => {
                                    setEditNote(note)
                                }}
                            >
                                <Icon style={{ fontSize: '16px' }} baseClassName="fas" className="fa-edit" />
                            </IconButton>
                        </div>
                    }
                </CardActions>
                <CardContent sx={{ paddingBottom: '0px', paddingTop: '0px' }}>
                    <hr style={{ marginBottom: '10px', marginTop: '0px', backgroundColor: '#e2ecfa' }}></hr>
                    {!note.audioNote &&
                        <ThemeProvider theme={myTheme}>
                            <MUIRichTextEditor
                                defaultValue={isJSON(note.comment_text) ? note.comment_text : `{"blocks":[{"key":"block1","text":"${note.comment_text.replace(/\n/g, ' ').replace(/\t/g, ' ').replace(/"/g, '\\"')}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`}
                                readOnly={true}
                                toolbar={false}
                            />
                        </ThemeProvider>}
                    {/* <p style={{ marginBottom: '0px' }}>{note.comment_text}</p> */}
                    {note.audioNote && <audio controls src={audioSrc} />}
                </CardContent>
            </Card>)
        );
    }

    const generateNoteCards = (noteList) => {
        let nonEmptyNoteList = noteList.filter((note) => note.comment_text !== "")
        let cards = []
        cards = nonEmptyNoteList.map((note) => {
            return generateNoteCard(note)
        })
        setContent(cards)
    }

    return (
        <div className='projectNotes-mainDiv'>
            {/* Updated header with grouped search/add (left) and filter/sort (right) */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <form onSubmit={searchNoteResults}>
                        <TextField
                            onChange={searchNoteHandler}
                            value={searchTerm}
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton type="submit">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form>
                    <Button variant="contained" color="primary" onClick={() => setShowAddNote(true)}>
                        Add Note
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => { setShowAddNote(true); setShowAddVoiceNote(true); }}>
                        Add Voice Note
                    </Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <button
                        style={{ outline: 'none', color: '#1976D2', border: '1px solid #1976D2', height: '40px' }}
                        type="button" className="btn btn-sm bg-white" data-toggle="modal" data-target="#staticBackdropA">
                        <i className="fas fa-filter"></i> FILTER
                    </button>
                    <div className="dropdown">
                        <button
                            style={{ outline: 'none', color: '#1976D2', border: '1px solid #1976D2', height: '40px' }}
                            className="btn btn-sm dropdown-toggle bg-white" type="button" id="dropdownSortBy" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span><i className="fas fa-sort-amount-up"></i></span> SORT BY
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownSortBy">
                            <button onClick={() => sortBy('dateDsce')} className="dropdown-item" type="button">Latest</button>
                            <button onClick={() => sortBy('dateAsce')} className="dropdown-item" type="button">Oldest</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="notes_info" >
                <div style={{ marginBottom: '10px' }}>
                    <h5 style={{ margin: 0 }}>Notes</h5>
                </div>
                <p>{displayNotes.length < 1 ? 'No Notes were found' : ''}</p>
                {content}
            </div>
            <div className="modal fade" id="staticBackdropA" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Filter By</h5>
                            <IconButton data-dismiss="modal">
                                <i className="fas fa-times"></i>
                            </IconButton>
                        </div>
                        <div className="modal-body">
                            <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: '16px' }}>
                                <InputLabel id="filter1-label">Class</InputLabel>
                                <Select
                                    labelId="filter1-label"
                                    label="Class"
                                    value={!filter.class ? '' : filter.class}
                                    onChange={(e) => setFilter({ class: e.target.value, specifier: null })}
                                >
                                    <MenuItem value="">Select Class</MenuItem>
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Employee Type">Employee Type</MenuItem>
                                    <MenuItem value="Employee">Employee</MenuItem>
                                </Select>
                            </FormControl>
                            {!!filter.class && filter.class !== 'All' && (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel id="filter2-label">Specify</InputLabel>
                                    <Select
                                        labelId="filter2-label"
                                        label="Specify"
                                        value={!filter.specifier ? '' : filter.specifier}
                                        onChange={(e) => setFilter({ ...filter, specifier: e.target.value })}
                                    >
                                        <MenuItem value="">Select Specifier</MenuItem>
                                        {filter.class === 'Employee Type' && typeList}
                                        {filter.class === 'Employee' && empList}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                        <div className="modal-footer">
                            <Button variant="contained" color="info" onClick={filterBy}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog fullWidth={true} maxWidth={'sm'} open={editNote != null}>
                <DialogActions>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setEditNote(null)}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ paddingTop: '0px' }}>
                    <NoteFormv2 mode='edit' checkTokenExpiry={props.checkTokenExpiry} task_id={editNote?.tas_id} note_id={editNote?.comment_id} user={props.user} project_id={props.pro_id} company_id={props.user.comp_id} onSubmit={() => setEditNote(null)}></NoteFormv2>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth={true} maxWidth={'sm'} open={showAddNote} >
                <DialogActions>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => { setShowAddNote(false); setShowAddVoiceNote(false); }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ paddingTop: '0px' }}>
                    <NoteFormv2 checkTokenExpiry={props.checkTokenExpiry} company_id={props.user.comp_id} mode='add' isVoiceNote={showAddVoiceNote ? true : false} user={props.user} project_id={props.pro_id} onSubmit={() => { setShowAddNote(false); setShowAddVoiceNote(false); }}></NoteFormv2>
                </DialogContent>
            </Dialog>
        </div>
    );
}