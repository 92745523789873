import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import axios from 'axios';
import { APIURL } from '../../config'
import { Form, FormControl } from 'react-bootstrap';
import CustomerCard from './CustomerCard/CustomerCard';
import DetailsPane from './DetailsPane'
import CustomerForm from 'src/components/forms/CustomerForm/CustomerForm';
import './Customers.css';
import makeStyles from "@mui/styles/makeStyles";
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';
import { TextField, InputAdornment, IconButton, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const useStyles = makeStyles((theme) => ({
    projList1: {
        height: 'calc(100vh - 275px)',
        width: "100%",
        overflowY: 'auto',
        scrollbarGutter: 'stable',
    }
}));

export default function Customer(props) {
    const classes = useStyles();
    const [state, setState] = useState({
        customers: [],
        active: [],
        inactive: [],
        prospect: [],
        dormant: [],
        activeList: 'active',
        searchTerm: '',
        customerSearchResults: [],
        isLoading: true,
        current_customer: null,
        edit: false,
        selectedMenu: '',
        openCustomerForm: false,
        newCustLoading: true,
        customerFormData: {}
    })
    const [loggedToSegment, setLoggedToSegment] = useState(false);

    const location = useLocation()

    const logToSegment = () => {
        if (loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page, window.location.href)
        if (page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page, {
            title: page,
            path: page,
        }, {
            userId: String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }

    const getData = async () => {
        props.checkTokenExpiry()
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }

        let cusData = await axios.get(APIURL + "/customers/v2/associated", { params: { company_id: props.comp_id, employee_id: props.user.emp_id } })

        let cusList = cusData.data

        let active = cusList.filter((cus) => cus.cus_status === 'Active');
        let inactive = cusList.filter((cus) => cus.cus_status === 'Inactive');
        let prospect = cusList.filter((cus) => cus.cus_status === "Prospect");
        let dormant = cusList.filter((cus) => cus.cus_status === "Dormant");

        let current_customer;
        current_customer = active.length > 0 ? current_customer = active[0].cus_id : current_customer = null;
        let customerSearchResults = active;
        let searchTerm = '';

        if (location.customersProps) {
            current_customer = location.customersProps.cus_id;
            customerSearchResults = cusList.filter(cus => {
                if (cus.cus_id === location.customersProps.cus_id) {
                    return true;
                }
                return false;
            })
            searchTerm = customerSearchResults[0].cus_name;
            setState(prev => ({ ...prev, edit: location.customersProps.edit }));

        }

        setState(prev => ({
            ...prev,
            customers: cusList,
            active: active,
            inactive: inactive,
            prospect: prospect,
            dormant: dormant,
            customerSearchResults: customerSearchResults,
            isLoading: false,
            current_customer: current_customer,
            searchTerm: searchTerm
        }))
    }

    useEffect(() => {
        getData();
        logToSegment();
        let text = props?.user?.comp_settings.filter(setting => [101, 102, 104].includes(setting.feature_id)).length > 0 ? 'Clients'
            : props?.user?.comp_settings.filter(setting => setting.feature_id == 105).length > 0 ? 'Members' : 'Customers'
        setState(prev => ({
            ...prev,
            selectedMenu: text
        }))
    }, [])

    const showCustomerDetails = (key) => {
        setState(prev => ({
            ...prev,
            current_customer: key
        }))
    }

    const showNewCustomer = async () => {
        setState(prev => ({
            ...prev,
            openCustomerForm: true
        }))
        await fetchNewCustData()
    }

    const showCustomerList = () => {
        if (state.customerSearchResults.length > 0) {
            return state.customerSearchResults.map(
                customers => {
                    return <CustomerCard
                        {...props}
                        key={customers.cus_id}
                        id={customers.cus_id}
                        customer={customers}
                        onClick={showCustomerDetails}
                        selected={state.current_customer === customers.cus_id ? 'active' : 'inactive'}
                    />
                }
            )
        }
    }

    const searchProjHandler = (e) => {
        var searchQuery = e.target.value
        setState(prev => ({
            ...prev,
            searchTerm: searchQuery
        }))
    }

    const searchProjResults = (e) => {
        e.preventDefault()
        var searchQuery = e.target.value
        var searchTerm = new RegExp(searchQuery, 'i')
        let customerSearchResults;

        const filterCustomer = (cusData) => {
            return cusData.filter((proj) => {
                if (searchTerm.exec(proj.cus_name) || searchTerm.exec(proj.DDDID) || searchTerm.exec(proj.cus_local_id)) {
                    return proj
                }
                else {
                    return null
                }
            }).filter((proj) => !!proj)
        }

        if (state.activeList === 'active') {
            customerSearchResults = filterCustomer(state.active)
        } else if (state.activeList === 'inactive') {
            customerSearchResults = filterCustomer(state.inactive)
        } else if (state.activeList === 'prospect') {
            customerSearchResults = filterCustomer(state.prospect)
        } else if (state.activeList === 'dormant') {
            customerSearchResults = filterCustomer(state.dormant)
        }

        setState(prev => ({
            ...prev,
            searchTerm: searchQuery,
            customerSearchResults: customerSearchResults
        }))
    }

    const toggleActive = (screen) => {
        if ((screen === 'active' && state.activeList === 'active') || (screen === 'inactive' && state.activeList === 'inactive') || (screen === 'prospect' && state.activeList === 'prospect') || (screen === 'dormant' && state.activeList === 'dormant')) {

            return
        } else {
            let cusData = [];
            if (screen === 'active') {
                ;
                cusData = state.active;
            } else if (screen === 'inactive') {
                cusData = state.inactive;
            } else if (screen === 'prospect') {
                cusData = state.prospect;
            } else if (screen === 'dormant') {
                cusData = state.dormant;
            }

            let filtered_customer = cusData;
            setState(prev => ({
                ...prev,
                searchTerm: '', activeList: screen, customerSearchResults: filtered_customer, current_customer: filtered_customer.length > 0 ? filtered_customer[0].cus_id : null
            }))

        }
    }

    const sortBy = (param) => {
        let cusData = state.customerSearchResults
        // debugger
        if (param === 'name') {
            // sort by name
            cusData.sort((cusA, cusB) => ('' + cusA.cus_name).localeCompare(cusB.cus_name))
        } else {
            // sort by date
            cusData.sort((cusA, cusB) => moment(cusB.cus_acquired_date) - moment(cusA.cus_acquired_date))
        }
        // debugger
        setState(prev => ({
            ...prev,
            customerSearchResults: cusData
        }))
    }

    const isNewFormValidated = (objForVal) => {
        console.log('>>>>>>>>>>> ', objForVal)
        var missingField = {
            isValidated: true
        }
        let temp = Object.keys(objForVal)
        if (temp[0] === 'selectedEmp') {

            let selectedEm = objForVal.selectedEmp
            for (let i = 0; i < selectedEm.length; i++) {
                for (let objKey in selectedEm[i]) {
                    if (
                        selectedEm[i][objKey] === "" ||
                        selectedEm[i][objKey] === undefined ||
                        selectedEm[i][objKey].length === 0
                    ) {
                        missingField.isValidated = false;
                        missingField.missingKey = objKey
                        return missingField
                    }
                }
            }
        }




        for (let objKey in objForVal) {
            if ((objKey !== "Cemail" && objKey !== "designation") &&
                (objForVal[objKey] === "" ||
                    objForVal[objKey] === undefined ||
                    objForVal[objKey].length === 0)
            ) {
                if (
                    objKey === "address2" ||
                    objKey === "location_addr2" ||
                    objKey === "tComment" ||
                    objKey === "cus_local_id" ||
                    //objKey === "ddd_id" ||
                    objKey === "address_line_2" ||
                    // objKey === "email" ||
                    objKey === "pSelfPay" ||
                    objKey === "pPrimaryPayer" ||
                    objKey === "pSecondaryPayer" ||
                    objKey === "medicaid_id" ||
                    objKey === "medicaid_eligible" ||
                    objKey === "sign_page_date" ||
                    objKey === "last_plan_approval_date" ||
                    objKey === "last_assess_date" ||
                    objKey === "renewal_date" ||
                    //objKey === "DDDID" ||
                    objKey === "personal_days_off" ||
                    objKey === "vacation_days" ||
                    objKey === "DDD_tier" ||
                    objKey === "diagnose_code" ||
                    objKey === "func_criteria_met" ||
                    objKey === "waiver_program" ||
                    objKey === "NJCATSelfcare" ||
                    objKey === "NJCATBehavioral" ||
                    objKey === "NJCATMedical" ||
                    objKey === "NJCATFCA" ||
                    objKey === "MedicaidEndDate" ||
                    objKey === "AgencyAssignDate" ||
                    objKey === "County" ||
                    objKey === "Race" ||
                    objKey === "Ethnicity" ||
                    objKey === "TransferOutReason" ||
                    objKey === "TransferOutDate" ||
                    objKey === "tContacts"
                ) {
                    // return true
                    continue;
                } else if (objForVal["cus_is_company"] === true && objForVal["company_name"] === "") {
                    missingField.isValidated = false;
                    missingField.missingKey = "company_name"
                    return missingField;
                }
                else {
                    console.log(objKey);

                    if (
                        (objKey === "company_name" &&
                            objForVal["first_name"] !== "" &&
                            objForVal["last_name"] !== "") ||
                        ((objKey === "first_name" || objKey === "last_name") &&
                            objForVal["company_name"] !== "" && objForVal["company_name"] !== undefined) || objKey === "address_maps"
                    ) {
                        continue;
                    } else {
                        missingField.isValidated = false;
                        missingField.missingKey = objKey
                        return missingField;
                    }
                }
            } else if (Array.isArray(objForVal[objKey])) {
                let arrValid = objForVal[objKey].every(function (objTesting) {
                    for (let objTestKey in objTesting) {
                        console.log(objTestKey);
                        if (
                            objTesting[objTestKey] === "" ||
                            objTesting[objTestKey] === undefined
                        ) {
                            if (
                                objTestKey === "address_line_2" ||
                                objTestKey === "location_addr2"
                            ) {
                                // return true
                                continue;
                            } else {
                                missingField.isValidated = false;
                                missingField.missingKey = objKey
                                return missingField;
                            }
                        }
                    }
                    return true;
                });
                if (!arrValid) return false;
            } else if (objKey === "active_plan_version") {
                for (const digit of objForVal[objKey]) {
                    if (Number.isNaN(parseInt(digit)) && digit !== ".") {
                        alert("PROJECT ACTIVE PLAN VERSION has to be a number");
                        objForVal[objKey] = "";
                        return false;
                    }
                }
            }
        }

        return missingField;
    };

    const fetchNewCustData = async () => {
        let data = {
            employees: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
                        params: { comp_id: props.comp_id },
                    })
                ).data,
            },
            customers: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/customerslist", {
                        params: { comp_id: props.comp_id },
                    })
                ).data,
                customerType: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/customerType", {
                        params: { comp_id: props.comp_id },
                    })
                ).data,
                customerStatus: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/customerStatus", {
                        params: { comp_id: props.comp_id },
                    })
                ).data,
            },
        };

        setState(prev => ({
            ...prev,
            customerFormData: data,
            newCustLoading: false
        }))
    }

    let dashBoard = null
    if (!state.isLoading) {
        dashBoard = (
            <div className="customersPage-mainDiv" style={{ display: 'flex', justifyContent: 'start', height: '100%', width: '100%' }}>
                <div style={{ height: '100%', width: '25%' }}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <TextField
                                onChange={searchProjResults}
                                value={state.searchTerm}
                                placeholder="Search"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ width: '200%' }}
                            />
                        </div>
                        <Form inline style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="dropdown" style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <button style={{ outline: 'none', color: '#1976D2', border: '1px solid #1976D2', height: '38px' }} className="btn dropdown-toggle bg-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span><i className="fas fa-sort-amount-up"></i></span> SORT BY
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <button onClick={() => sortBy('name')} className="dropdown-item" type="button">Name</button>
                                    <button onClick={() => sortBy('date')} className="dropdown-item" type="button">Acquire Date</button>
                                </div>
                            </div>
                            <Button style={{ marginTop: '20px', marginBottom: '10px' }} onClick={showNewCustomer} variant="contained" color="primary">New Customer</Button>
                        </Form>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='customer_button_group'>
                                <div style={{ opacity: '90%' }} className={`customer_button ${state.activeList === 'active' ? 'active' : 'inactive'}`} onClick={(e) => toggleActive('active')}>
                                    <div>Active</div>
                                    {state.activeList === 'active' && <div className='customer_button_number'>{state.customerSearchResults.length}</div>}
                                </div>
                                <div style={{ opacity: '90%', paddingRight: '5px' }} className={`customer_button ${state.activeList === 'inactive' ? 'active' : 'inactive'}`} onClick={(e) => toggleActive('inactive')}>
                                    <div>Inactive</div>
                                    {state.activeList === 'inactive' && <div className='customer_button_number'>{state.customerSearchResults.length}</div>}
                                </div>
                                <div style={{ opacity: '90%' }} className={`customer_button ${state.activeList === 'prospect' ? 'active' : 'inactive'}`} onClick={(e) => toggleActive('prospect')}>
                                    <div>Prospects</div>
                                    {state.activeList === 'prospect' && <div className='customer_button_number'>{state.customerSearchResults.length}</div>}
                                </div>
                                <div style={{ opacity: '90%' }} className={`customer_button ${state.activeList === 'dormant' ? 'active' : 'inactive'}`} onClick={(e) => toggleActive('dormant')}>
                                    <div>Dormant</div>
                                    {state.activeList === 'dormant' && <div className='customer_button_number'>{state.customerSearchResults.length}</div>}
                                </div>
                            </div>
                            <div style={{ marginRight: '-12px' }}>
                                <div
                                    className={classes.projList1}
                                >
                                    {showCustomerList()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '75%', height: 'calc(100vh - 100px)' }}>
                    {state.customerSearchResults.length !== 0 && <DetailsPane
                        status={state.viewOld ? 'Older' : 'Ongoing'}
                        comp_id={props.comp_id}
                        user={props.user}
                        history={props.history}
                        cus_id={state.current_customer}
                        cust_details={state.customers.find(customer => customer.cus_id === state.current_customer)}
                        project_details={state.customerSearchResults.find(customer => customer.cus_id === state.current_customer)}
                        edit={state.edit}
                        selectedMenu={state.selectedMenu}
                        checkTokenExpiry={props.checkTokenExpiry}
                    />}
                    {state.customerSearchResults.length === 0 &&
                        <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '500px', marginTop: 10 }}>
                            <img src={state.customerSearchResults.length === 0 ? './nosearchresult.png' : './projectgraphic.png'} alt='projectgraphic' style={{ width: '15rem', height: '10rem', margin: 10 }} />
                            <h5 style={{ fontFamily: 'Lato', marginTop: 48, marginBottom: 30 }} className='text-center'>{state.customers.length !== 0 ? 'No Customers Found' : props.user.type === 'Fieldworker' || props.user.type === 'Support Coordinator' ? 'You are not assigned any project yet. Please contact your manager.' : 'You dont have any Customers yet'} </h5>
                            <p style={{ fontFamily: 'Lato', fontSize: 12, width: '70%' }} className='text-muted text-center text-wrap'>Customer's related information includes Overviews, Tasks , Employees, Artifacts, Comments and Contacts will show here once you add customers. <br />On the left side, you will see a list of your Ongoing or Previous projects.</p>
                        </div>
                    }
                </div>

                {
                    <Dialog fullWidth={true} maxWidth={'sm'} open={state.openCustomerForm} onClose={() => { setState(prev => ({ ...prev, openCustomerForm: false })); }}>
                        <DialogActions>
                            <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>New Customer</h4>
                            <hr style={{ marginBottom: '10px', marginTop: '10px', backgroundColor: '#e2ecfa' }}></hr>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.25 }}
                                onClick={() => { setState(prev => ({ ...prev, openCustomerForm: false })); }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogActions>
                        <DialogContent sx={{ paddingTop: '0px' }}>
                            {state.newCustLoading &&
                                <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!state.newCustLoading && <CustomerForm
                                {...props}
                                isFormValidated={isNewFormValidated}
                                customerType={state.customerFormData.customers.customerType}
                                customerStatus={state.customerFormData.customers.customerStatus}
                                empList={state.customerFormData.employees.list}
                                customerList={state.customerFormData.customers.list}
                                comp_id={props.comp_id}
                                customerPage={true}
                                user={props.user}
                            />}
                        </DialogContent>
                    </Dialog>
                }
            </div>
        )
    }
    else {
        dashBoard = (
            <div className="d-flex flex-row justify-content-center">
                <div style={{ color: '#3FA7D6', height: '10rem', width: '10rem', marginTop: 120 }} className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div style={{ height: 'calc(100vh - 90px)', width: '100%', overflow: 'hidden' }}>
            {dashBoard}
        </div>
    )
}