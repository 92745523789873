import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ListItemText from '@mui/material/ListItemText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { APIURL } from '../../../config';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);

  const IntakeForm = (props) => {
    const [title, setTitle] = useState('Intake Form');
    const [customer, setCustomer] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [signature, setSignature] = useState(false);
    const [notes, setNotes] = useState('');

    const [successAlert, setSuccessAlert] = useState('');
    const [errorAlert, setErrorAlert] = useState('');

    useEffect(() => {
        setCustomerList(props.customerList.filter(c => c.cus_status === 'Prospect'));
        console.log(props.customerList);
    }, [props.customerList]);

    const createTask = async () => {
        try {
        let intakeProject = await axios.get(APIURL+"/project" , {params: { cus_id: customer.id  }});

        if(intakeProject.data.length === 0){
            intakeProject = await createProject();
            
        } else {            
            intakeProject = intakeProject.data[0].pro_id;
        }


            var data = {
                empId : props.user.emp_id,
                empName: props.user.emp_name,
                company_id: props.comp_id,
                tTitle: title,
                // tAssignedTo: state.basicDetails.tAssignedTo,
                tDesc: 'Please fill and submit your details',
                tProject: intakeProject,
                tPreReq: null,
                tType: 'Intake',
                tStrtDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                tEndDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
                tStartTime: moment().format('YYYY-MM-DD') + "T" + moment().format('HH:mm:ss'),
                tEndTime: moment(endDate).format('YYYY-MM-DD') + "T" + moment(endDate).format('HH:mm:ss'),
                tLoc: null,
                tLoc1: null,
                tLoc2: null,
                tAssTo: props.user.emp_id,
                tPriority: 'Medium',
                tIsBillable: 0,
                tas_required_sig: signature ? 1 : 0,
                tNotes: notes,
                tContacts: null,
                user_id: props.user.emp_id,
                cus_id: customer.id
            };

            let msg = await axios.post(APIURL+"/task", data);
            console.log('Task:', msg);
            

            if(msg.data.code === 200){
                console.log('Task Created');  
                let taskTypes = await axios.get(`${APIURL}/dropdowns/dropdownType/fullTaskType`, { params: { comp_id: props.comp_id } });

                let taskTypeId = taskTypes.data.filter((type) => type.EntityValue === 'Intake')[0].ddID          
                
                let forms = await axios.get(APIURL+"/task_form", {params: { taskType_id: taskTypeId, company_id: props.comp_id }});

                console.log('Forms:', forms);
                console.log('Customer:', customer);
                
                

                let formIds = forms.data.map((form) => form.form_id);

                
                let response = await axios.post(APIURL+"/tokens", {
                    expires_at: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
                    entity_data: JSON.stringify({ form_ids: 
                        formIds, 
                        task_id: msg.data.id, 
                        customer_id: customer.id, 
                        signature_required: signature, 
                        customer_name: customer.customer_name,
                        company_id: props.comp_id,
                        company_name: props.user.company_name
                    }),
                    entity_type: 'form',
                    created_by: props.user.emp_id
                });

                let token = response.data[0];
                console.log('Token:', token);

                response = await axios.post(APIURL+"/tokens/email/intake_form", {
                    data: {
                        email: customer.cus_email,
                        token: token.token,
                        task_id: msg.data.id,
                        form_ids: formIds,
                        customer_id: customer.cus_id,
                        created_by: props.user.emp_id,
                        employee_name: props.user.emp_name,
                        company_name: props.user.company_name,
                        company_logo: props.user.company_logo,
                    },
                    employee_id: props.user.emp_id,
                    email: customer.cus_email,
                    token: token.token
                });
                
                setSuccessAlert('Intake Form Created Successfully');
                setTitle('Intake Form');
                setCustomer(null);
                setEndDate(null);
                setSignature(false);
                setNotes('');
            } else {
                console.log('Task Creation Failed');
                setErrorAlert('Intake Form Creation Failed');
                return null;
            }
        } catch (error) {
            console.log('Error:', error);
            setErrorAlert('Intake Form Creation Failed');
        }
    }

    const createProject = async () => {
        var data = {
            company_id: props.comp_id,
            user_id: null,
            ddd_id:  '',
            active_plan_version: '',
            projId: null,
            pTitle: 'Intake Form Project - ' + customer.customer_name,
            pDesc: 'Default Project for Prospects',
            pCustomer: customer.id,
            pSponsor: 'NULL',
            // pContact: parseInt(state.basicDetails.pContact),
            pManager: null,
            pstrtDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            pEndDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            pPrice: null,
            pCurrency: null,
            pLoc: [],
            selectedEmp: []
        }; 

        let msg = await axios.post(APIURL+"/project", data);
        console.log('Project:', msg);
        

        if(msg.data.code === 200){
            console.log('Project Created');
            return msg.data.id;
        } else {
            console.log('Project Creation Failed');
            return null;
        }
    }

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        
        setSuccessAlert('');
    }

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorAlert('');
    }


    return (
        <div className='mx-3'>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 style={{ margin: '5px', marginBottom: '20px', width: '100%' }}>Intake Form</h4>
            </div>
            <CssTextField
                className={`my-3 w-100`}
                label="TASK TITLE"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                inputProps={{ maxLength: 50 }}
                variant="outlined"
                id="tTitle"
                required
            />
            <Autocomplete
                noOptionsText='No Customer Found'
                className={`mb-3 w-100`}
                value={customer}
                onChange={(event, newValue) => {
                    setCustomer(newValue);
                }}
                options={customerList}
                getOptionLabel={(option) => option.customer_name}
                renderInput={(params) => (
                    <CssTextField {...params} label="CUSTOMER *" variant="outlined" />
                )}
                renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        <ListItemText primary={option.customer_name} />
                      </li>
                    )
                }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment} locale="en">
                <DatePicker
                    label="BY WHEN THE RESPONSE IS EXPECTED"
                    value={endDate}
                    onChange={ (newValue) => setEndDate(newValue) }
                    sx={{ width: '100%' }}
                    required
                />
            </LocalizationProvider>
            <Form.Group as={Col}>
                <Form.Label>SIGNATURE REQUIRED</Form.Label><span><i style={{fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Row className="mx-1" style={{display: 'flex', gap: '10px', textAlign: 'center' }}>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        name="tSigReq"
                        id="radioSigYes"
                        required
                        onChange={(e) => setSignature(true)}
                        checked={signature === true}
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        name="tSigReq"
                        id="radioSigNo"
                        required
                        onChange={(e) => setSignature(false)}
                        checked={signature === false}
                    />
                </Row>
            </Form.Group>
            <CssTextField
                className={`my-3 w-100`}
                label="NOTES"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                inputProps={{ maxLength: 1000 }}
                variant="outlined"
                id="tNotes"
            />
            <Button
                variant="primary"
                onClick={createTask}
                style={{ width: '100%' }}
                disabled={customer === null || endDate === null || signature === null}
            >
                Create Intake Form
            </Button>
            <Snackbar open={successAlert != ''} autoHideDuration={5000} onClose={handleSuccessAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"success"} elevation={6} variant="filled" {...props}>
                    {successAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleSuccessAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
            <Snackbar open={errorAlert != ''} autoHideDuration={5000} onClose={handleErrorAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"error"} elevation={6} variant="filled" {...props}>
                    {errorAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleErrorAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default IntakeForm;
