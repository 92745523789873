import React, { Component } from 'react';
import {
    Alert,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Card,
    CardContent,
  } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Import Grid2
import { Emoji } from 'emoji-mart';
import axios from 'axios';
import moment from "moment";
import { APIURL } from '../../../config'
import dd from '../../../manifestdd.json'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HandyCard from '../../HandyCard/HandyCard';
import { Col, Form } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';

class Employees extends Component {
    state = {
        employees: this.props.employees.filter((emp) => (emp.pea_is_active !== false)),
        allEmployees: [],
        empStatus: [],
        deletedEmp: null,
        timer: null,
        value: null,
        empAsgnFormVals: {
            empName: '',
            pEmpStatus: '',
            pEmpBillRate: null,
            pEmpCurrency: 'USD',
            pEmpFrequency: '',
            pEmpAssFromDate: moment().format('YYYY-MM-DD'),
            pEmpAssToDate: moment().format('YYYY-MM-DD'),
            pea_id: ''
        },
        formError: false,
        errorMsg: '',
        isEdit: false,
        isLoading: false,
        success: 'In Progress',
        trackEmployeeCost: this.props.user.comp_settings.find(setting => setting.feature_id === 52 && setting.feature_value === 'true')?true:false
    }

    async componentDidMount() {
        const allEmployees = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: this.props.comp_id } });
        const empStatus = await axios.get(APIURL + '/dropdowns/dropdownType/empStatus', { params: { comp_id: this.props.comp_id } });
        this.setState({ allEmployees: allEmployees.data, empStatus: empStatus.data })
    }

    async empAssignmentReq(e) {
        if(!this.state.value)
            return
        e.preventDefault()
        this.setState({isLoading:true})
        var data = {
            id: this.state.empAsgnFormVals.pea_id,
            projId: this.props.projId,
            empId: this.state.value,
            status: this.state.empAsgnFormVals.pEmpStatus,
            billRate: this.state.empAsgnFormVals.pEmpBillRate ? this.state.empAsgnFormVals.pEmpBillRate : null,
            currency: this.state.empAsgnFormVals.pEmpCurrency,
            freq: this.state.empAsgnFormVals.pEmpFrequency,
            assFrom: this.state.empAsgnFormVals.pEmpAssFromDate,
            assTo: this.state.empAsgnFormVals.pEmpAssToDate,
            comp_id: this.props.comp_id
        }
        let msg;
        if (!this.state.isEdit) {
            msg = await axios.put(APIURL+'/project/UpdateProjEmp', data)
        } else {
            msg = await axios.put(APIURL+'/update/projEmp', data)
        }
        console.log(msg.data)
        // check msg and change modal contents
        if (msg.data.code === 200) {
            if (!this.state.isEdit) {
                //find employee from all employee if add
                let selEmp = this.state.allEmployees.find((emp) => (emp.id === +this.state.value))
                let addedEmp = { emp_id: selEmp.id, emp_first_name: selEmp.employee_name.split(' ')[0], emp_last_name: selEmp.employee_name.split(' ')[1], emp_type: selEmp.emp_type, pea_id: msg.data.pea_id, pea_status: this.state.empAsgnFormVals.pEmpStatus, pea_bill_rate: this.state.empAsgnFormVals.pEmpBillRate, pea_currency: this.state.empAsgnFormVals.pEmpCurrency, pea_frequency: this.state.empAsgnFormVals.pEmpFrequency, pea_associate_from: this.state.empAsgnFormVals.pEmpAssFromDate, pea_associate_to: this.state.empAsgnFormVals.pEmpAssToDate, pea_is_active: true }
                //add to employees state if add
                this.setState({ employees: [...this.state.employees, addedEmp] })
            } else {
                //find employee from employee state if edit
                let selEmp = this.state.employees.find((emp) => (emp.emp_id === this.state.value))
                //edit in employees state if edit 
                let editEmp = { ...selEmp, pea_status: this.state.empAsgnFormVals.pEmpStatus, pea_bill_rate: this.state.empAsgnFormVals.pEmpBillRate, pea_currency: this.state.empAsgnFormVals.pEmpCurrency, pea_frequency: this.state.empAsgnFormVals.pEmpFrequency, pea_associate_from: this.state.empAsgnFormVals.pEmpAssFromDate, pea_associate_to: this.state.empAsgnFormVals.pEmpAssToDate }
                let newEmpList = this.state.employees.map((emp) => {
                    if (emp.emp_id === this.state.value) {
                        emp = editEmp
                    }
                    return emp
                })
                this.setState({ employees: newEmpList })
            }
            this.setState({ success: 'Pass', isLoading: false })
        } else {
            this.setState({ success: 'Fail' , isLoading: false})
        }
    }

    onChange = (e) => {
        console.log(e.target.value)
        if(e.target.value === "Select Employee"){
            this.setState({value: null})
            return
        }
        this.setState({ 
            value: e.target.value, 
            empAsgnFormVals: { ...this.state.empAsgnFormVals, empName: this.state.allEmployees.find((emp) => (emp.id === +e.target.value)).employee_name }, 
            isEdit: false })
    }

    clearStates = () => {
        this.setState({ value: null, empAsgnFormVals: { ...this.state.empAsgnFormVals, empName: '', pEmpStatus: '', pEmpBillRate: '', pEmpFrequency: '', pEmpAssFromDate: moment().format('YYYY-MM-DD'), pEmpAssToDate: moment().format('YYYY-MM-DD') }, isEdit: false, success: 'In Progress' })
    }

    editForm = (empId) => {
        let selEmp = this.state.employees.find((emp) => (emp.emp_id === empId));
        this.setState(
            { 
                value: empId, 
                empAsgnFormVals: { 
                    ...this.state.empAsgnFormVals, 
                    empName: `${selEmp.emp_first_name} ${selEmp.emp_last_name}`, 
                    pEmpStatus: selEmp.pea_status, 
                    pEmpBillRate: selEmp.pea_bill_rate, 
                    pEmpFrequency: selEmp.pea_frequency, 
                    pEmpAssFromDate: selEmp.pea_associate_from, 
                    pEmpAssToDate: selEmp.pea_associate_to, 
                    pea_id: selEmp.pea_id 
                }, 
                isEdit: true 
            },
            () => {
                if (window.$) {
                    window.$('#empEdit').modal('show');
                }
            }
        );
    }

    onEmpChange = (evt) => {
        let empAssnFormVals = this.state.empAsgnFormVals;
        if (evt.target.id === 'pEmpAssFromDate') {
            if (moment(evt.target.value).isBetween(moment(this.props.project_start_date).subtract(1, 'd'), moment(this.props.project_end_date).add(1, 'd'))) {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: '', formError: false })
            } else {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: `Please Enter Valid Date Between ${moment(this.props.project_start_date).format('MMM DD YYYY')} - ${moment(this.props.project_end_date).format('MMM DD YYYY')}`, formError: true })
            }
        } else if (evt.target.id === 'pEmpAssToDate') {
            if (!empAssnFormVals.pEmpAssFromDate) {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: `Please Enter Associate From Field First`, formError: true })
            } else if (empAssnFormVals.pEmpAssFromDate && !moment(evt.target.value).isBetween(moment(empAssnFormVals.pEmpAssFromDate).subtract(1, 'd'), moment(this.props.project_end_date).add(1, 'd'))) {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: `Please Enter Associate To Field in range ${moment(empAssnFormVals.pEmpAssFromDate).format('MMM DD YYYY')} - ${moment(this.props.project_end_date).format('MMM DD YYYY')}`, formError: true })
            } else {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: '', formError: false })
            }
        }
        // else if(evt.target.value === ''){
        //     this.setState({ errorMsg: `Please Enter Missing Fields First`, formError: true })
        // }
        else {
            empAssnFormVals[evt.target.id] = evt.target.value
            this.setState({ errorMsg: '', formError: false })
        }
        // console.log(empAssnFormVals)
        this.setState({ empAsgnFormVals: empAssnFormVals })
        // this.setState({empAsgnFormVals: {...this.state.empAsgnFormVals, [evt.target.id]: evt.target.value}})
    }

    onEmpDel = async (id) => {
        if (this.state.timer) {
            alert("Another delete is in process, wait for the undo dailogue to disappear.")
        } else {
            let oldEmpList = [...this.state.employees]
            oldEmpList = oldEmpList.filter(emp => emp.emp_id !== id)
            this.setState({
                deletedEmp: this.state.employees.find(emp => emp.emp_id === id),
                timer: setTimeout(() => {
                    this.deleteRequest(this.state.deletedEmp.pea_id)
                    clearTimeout(this.state.timer)
                    this.setState({
                        timer: null,
                        deletedEmp: null
                    })
                }, 5000),
                employees: oldEmpList
            })
        }
        //find in employee state
        // console.log(this.state.employees)
        // let delEmp = this.state.employees.find((emp)=>(emp.emp_id === id))
        // console.log(delEmp)
        // // send Req to Backend if exist

    }

    deleteRequest = (id) => {
        axios.delete(APIURL+'/project/UpdateProjEmp', { data: { id: id } })
            .then(res => {
                if (res.status === 200) {
                    alert("Emp Deleted")
                } else {
                    alert("Something went wrong.")
                }
            })
            .catch(error => console.log(error))
    }

    undoDelete = () => {
        let oldEmpList = [...this.state.employees]
        oldEmpList.push(this.state.deletedEmp)
        clearTimeout(this.state.timer)
        this.setState({
            employees: oldEmpList,
            deletedEmp: null,
            timer: null
        })
    }

    showAlert = () => {
        if (this.state.timer) {
            return (
                <Alert variant="info" onClose={() => this.onDismissAlert()} style={{ marginTop: "10px" }} dismissible>
                    Employee Deleted. <span className="undo" onClick={() => this.undoDelete()}>Undo?</span>
                </Alert>
            )
        }
    }

    onDismissAlert = () => {
        this.deleteRequest(this.state.deletedEmp.pea_id)
        clearTimeout(this.state.timer)
        this.setState({
            timer: null,
            deletedEmp: null
        })
    }

    render() {
        const employeeOptions = this.state.allEmployees.filter(emp => !this.state.employees.some(item => item.emp_id === emp.id))
          .sort((a, b) => a.employee_name.localeCompare(b.employee_name));
        let empStatus = this.state.empStatus.map((empSt, indx) => <option key={indx} value={empSt}>{empSt}</option>);
        let frequency = dd.generic.frequency.map((freq, indx) => <option key={indx} value={freq.value}>{freq.value}</option>);
      
        return (
            <div>
                <div className="TopPanel" style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '10px' }}>
                        <Autocomplete
                            sx={{ width: 250 }}
                            options={employeeOptions}
                            getOptionLabel={(option) => option.employee_name}
                            onChange={(e, value) => {
                                if(value) {
                                    this.setState({
                                        value: value.id,
                                        empAsgnFormVals: { ...this.state.empAsgnFormVals, empName: value.employee_name },
                                        isEdit: false
                                    });
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Employee" variant="outlined" size="small" />}                        
                        />
                    </div>
                    <div>
                        { this.state.trackEmployeeCost ?
                            <Button data-toggle="modal" data-target="#empEdit" variant="contained" sx={{ height: "40px" }}>
                                Add Employee
                            </Button> :
                            <Button onClick={(e) => this.empAssignmentReq(e)} variant="contained" sx={{ height: "40px" }}>
                                Add Employee
                            </Button>
                        }
                    </div>
                </div>
                <div className="people_team_info">
                    <div style={{ marginBottom: '10px' }}>
                        <h5>Employees</h5>
                        {this.showAlert()}
                    </div>
                    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px 0' }}>
                        {this.state.employees.length !== 0 ? this.state.employees.map(employee =>
                            <Card key={employee.emp_id} sx={{ display: 'inline-block', marginRight: '15px', width: 175, height: 175 }}>
                                <CardContent>
                                    <div style={{ textAlign: 'center' }}>
                                        <div>
                                            <i className="fas fa-user-circle fa-3x text-muted"></i>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>
                                            <h6 style={{ marginBottom: 0 }}>{employee.emp_first_name} {employee.emp_last_name}</h6>
                                            <p style={{ color: 'gray', fontSize: 13 }}>{employee.emp_type || <br />}</p>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                        <i onClick={() => this.editForm(employee.emp_id)} className="fas fa-edit" style={{ margin: "10px", cursor: "pointer" }}></i>
                                        <i onClick={() => this.onEmpDel(employee.emp_id)} className="fas fa-trash" style={{ cursor: "pointer" }}></i>
                                    </div>
                                </CardContent>
                            </Card>
                        )
                        : (
                            <div className='d-flex flex-column justify-content-center align-items-center mt-4' style={{ height: '300px', margin: 5 }}>
                                <img src='./pie.png' alt='empTab' style={{ width: '10rem', height: '10rem' }}/>
                                <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 28, marginBottom: 28 }}>No Employees assigned</h5>
                                <p style={{ fontFamily: 'Lato', fontSize: 14 }} className='text-muted text-center w-50'>
                                    We could not find any employees assigned to this project. Please add an employee to assign them tasks.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="modal fade" id="empEdit" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Project - Employee Assignment {!this.state.isEdit ? "" : "Edit "}Form</h5>
                                    <button type="button" onClick={() => this.clearStates()} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                <form onSubmit={(e) => this.empAssignmentReq(e)}>
                                    {/* Error Alerts */}
                                    {this.state.formError && (
                                    <Alert severity="info">
                                        {!this.state.errorMsg
                                        ? 'Please Fill out missing form fields'
                                        : this.state.errorMsg}
                                    </Alert>
                                    )}

                                    {!this.state.value && (
                                    <Alert severity="warning" className="text-center">
                                        Please Select Employee to be Assigned
                                    </Alert>
                                    )}

                                    {this.state.value && this.state.success === 'Fail' && (
                                    <Alert
                                        severity="error"
                                        style={{ fontSize: '0.8rem' }}
                                        className="text-center"
                                    >
                                        Employee {this.state.empAsgnFormVals.empName} was not Assigned to
                                        the Project Successfully
                                    </Alert>
                                    )}

                                    {this.state.value && this.state.success === 'Pass' && (
                                    <Alert
                                        severity="success"
                                        style={{ fontSize: '0.8rem' }}
                                        className="text-center"
                                    >
                                        Employee {this.state.empAsgnFormVals.empName} Assigned to the
                                        Project Successfully
                                    </Alert>
                                    )}

                                    {/* Main Form */}
                                    {this.state.value && this.state.success === 'In Progress' && (
                                    <div>
                                        <Typography variant="h5" gutterBottom sx={{ marginBottom: '1rem' }}>
                                        {this.state.empAsgnFormVals.empName}
                                        </Typography>

                                        <Grid2 container spacing={2}>
                                        {/* EMPLOYEE STATUS */}
                                        <Grid2 xs={12}>
                                            <FormControl fullWidth required>
                                            <InputLabel id="pEmpStatus-label">EMPLOYEE STATUS</InputLabel>
                                            <Select
                                                labelId="pEmpStatus-label"
                                                id="pEmpStatus"
                                                value={this.state.empAsgnFormVals.pEmpStatus}
                                                onChange={(e) => this.onEmpChange({ target: { id: 'pEmpStatus', value: e.target.value } })}
                                            >
                                                {empStatus?.map((status, indx) => (
                                                    <MenuItem key={indx} value={status.props.value}>
                                                    {status.props.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid2>

                                        {/* BILL RATE */}
                                        <Grid2 xs={12} sm={6}>
                                            <TextField
                                            id="pEmpBillRate"
                                            label="BILL RATE"
                                            value={this.state.empAsgnFormVals.pEmpBillRate}
                                            placeholder="Amount"
                                            onChange={(e) => this.onEmpChange({ target: { id: 'pEmpBillRate', value: e.target.value } })}
                                            required={this.state.trackEmployeeCost}
                                            fullWidth
                                            />
                                        </Grid2>

                                        {/* CURRENCY */}
                                        <Grid2 xs={12} sm={6}>
                                            <FormControl fullWidth disabled>
                                            <InputLabel id="pEmpCurrency-label">CURRENCY</InputLabel>
                                            <Select
                                                labelId="pEmpCurrency-label"
                                                id="pEmpCurrency"
                                                value="USD"
                                                label="CURRENCY"
                                            >
                                                <MenuItem value="USD">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Emoji emoji=":flag-us:" size={26} />
                                                    <div style={{ marginLeft: '0.5rem' }}>USD</div>
                                                </div>
                                                </MenuItem>
                                            </Select>
                                            </FormControl>
                                        </Grid2>

                                        {/* FREQUENCY */}
                                        <Grid2 xs={12}>
                                            <FormControl
                                            fullWidth
                                            required={this.state.trackEmployeeCost}
                                            >
                                            <InputLabel id="pEmpFrequency-label">FREQUENCY</InputLabel>
                                            <Select
                                                labelId="pEmpFrequency-label"
                                                id="pEmpFrequency"
                                                value={this.state.empAsgnFormVals.pEmpFrequency}
                                                onChange={(e) => this.onEmpChange({ target: { id: 'pEmpFrequency', value: e.target.value } })}
                                                label="FREQUENCY"
                                            >
                                                {frequency?.map((freq, indx) => (
                                                    <MenuItem key={indx} value={freq.props.value}>
                                                    {freq.props.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid2>

                                        {/* ASSOCIATE FROM DATE */}
                                        <Grid2 xs={12} sm={6}>
                                            <FormControl fullWidth required>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        label="ASSOCIATE FROM"
                                                        value={
                                                        moment(this.state.empAsgnFormVals.pEmpAssFromDate) || moment()
                                                        }
                                                        onChange={(date) =>
                                                        this.onEmpChange({ target: { id: 'pEmpAssFromDate', value: date } })
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid2>

                                        {/* ASSOCIATE TO DATE */}
                                        <Grid2 xs={12} sm={6}>
                                            <FormControl fullWidth required>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        label="ASSOCIATE TO"
                                                        value={
                                                        moment(this.state.empAsgnFormVals.pEmpAssToDate) || moment()
                                                        }
                                                        onChange={(date) =>
                                                        this.onEmpChange({ target: { id: 'pEmpAssToDate', value: date } })
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid2>

                                        {/* SUBMIT BUTTON */}
                                        <Grid2 xs={12}>
                                            <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.isLoading}
                                            type="submit"
                                            fullWidth
                                            style={{ marginTop: '1rem' }}
                                            >
                                            {this.state.isLoading ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Save changes'
                                            )}
                                            </Button>
                                        </Grid2>
                                        </Grid2>
                                    </div>
                                    )}
                                </form>
                                </div>
                                {(!this.state.value || this.state.success === 'Fail' || this.state.success === 'Pass') &&
                                    <div style={{ justifyContent: 'center' }} className="modal-footer">
                                        <button type="button" onClick={() => { this.clearStates() }} className="btn btn-primary" data-dismiss="modal">Close</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default Employees;