import React, { useState, useEffect } from "react"
import axios from 'axios'
import moment from 'moment'
import { APIURL } from '../../../config'
import dd from '../../../manifestdd.json'
import { Card, CardActions, CardContent, Icon, IconButton, Dialog, DialogActions, DialogContent, TextField, Button, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import NoteFormv2 from '../../../components/forms/NoteForm/NoteFormv2'
import MessagePage from '../../../components/MessagePages/MessagePage'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIRichTextEditor from 'mui-rte';
import './Notes.css';

const myTheme = createTheme({
    overrides: {
        MUIRichTextEditor: {
            anchorLink: {
                color: "lightblue",
                textDecoration: "underline",
            },
        },
    },
});

export default function Notes(props) {
    var Buffer = require('buffer/').Buffer;

    const [noteList, setNoteList] = useState([])
    const [displayNotes, setDisplayList] = useState([])
    const [filter, setFilter] = useState([])
    const [editNote, setEditNote] = useState(null)
    const [showAddNote, setShowAddNote] = useState(false)
    const [showAddVoiceNote, setShowAddVoiceNote] = useState(false)
    const [empList, setEmpList] = useState([])
    const [typeList, setTypeList] = useState([])
    const [content, setContent] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')

    async function fetchData() {
        const response = await axios.get(APIURL + `/comments?customer_id=${props.cus_id}`)
        let notes = response.data

        notes = await Promise.all(notes.map(async (note) => {
            const noteDataResponse = await axios.get(APIURL + `/comments/details?comment_id=${note.comment_id}`)
            const noteData = noteDataResponse.data[0]
            return noteData
        }))

        notes = notes.filter((note) => note !== undefined);

        const audioResp = await axios.get(APIURL + `/audio_note`, {
            params: { customer_id: props.cus_id },
        });

        let audioNotes = audioResp.data.map((audioNote) => ({
            ...audioNote,
            created_at: audioNote.submitted,
        }));

        notes.push(...audioNotes)

        notes.sort((a, b) => {
            return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
        })
        setFilter({ class: null, specifier: null })
        setNoteList(notes)
        setDisplayList(notes)
        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [showAddNote, editNote]);

    useEffect(() => {
        //let empLs = props.allEmployees.map((emp)=> <option key={emp.id} value={emp.id}>{emp.employee_name}</option>)
        let typeLs = dd.generic.employeeType.map((type) => <option key={type.value} value={type.value}>{type.value}</option>)
        setEmpList([])
        setTypeList(typeLs)
        generateNoteCards(displayNotes)
    }, [displayNotes]);

    function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    const generateNoteCard = (note) => {
        let audioSrc;

        if (note.audio_note) {
            const decodedData = Buffer.from(note.audioNote[0].audio_note, 'base64');

            const blob = new Blob([decodedData], { type: 'audio/wav' });

            audioSrc = URL.createObjectURL(blob);
        }

        return (
            (<Card sx={{ marginBottom: '20px' }}>
                <CardActions sx={{ paddingLeft: '16px' }}>
                    <div style={{ width: '80%' }}>
                        {note.note_title && <h6 style={{ marginBottom: '0px' }}>{note.note_title}</h6>}
                        {!note.note_title ?
                            <h6 style={{ marginBottom: '0px' }}>{note.tas_title ? note.tas_title : note.pro_title ? note.pro_title : note.cus_name}</h6> :
                            <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{note.tas_title ? note.tas_title : note.pro_title ? note.pro_title : note.cus_name}</p>
                        }
                        <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{note.tas_type}</p>
                        <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{moment(note.created_at).format('MM/DD/YYYY hh:mm A')} created by {note.emp_first_name + ' ' + note.emp_last_name} ({note.emp_type})</p>
                        {!!note.updated_by && !!note.updated_at &&
                            <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{moment(note.updated_at).format('MM/DD/YYYY hh:mm A')} updated by {note.updated_emp_first_name + ' ' + note.updated_emp_last_name}</p>
                        }
                    </div>
                    {!note.audioNote &&
                        <div style={note.tas_id != null ? { width: '20%', paddingBottom: '40px' } : { width: '20%', paddingBottom: '0px' }}>
                            <IconButton
                                style={{ float: 'right' }}
                                onClick={() => {
                                    setEditNote(note)
                                }}
                            >
                                <Icon style={{ fontSize: '16px' }} baseClassName="fas" className="fa-edit" />
                            </IconButton>
                        </div>
                    }
                </CardActions>
                <CardContent sx={{ paddingBottom: '0px', paddingTop: '0px' }}>
                    <hr style={{ marginBottom: '10px', marginTop: '0px', backgroundColor: '#e2ecfa' }}></hr>
                    {!note.audioNote &&
                        <ThemeProvider theme={myTheme}>
                            <MUIRichTextEditor
                                defaultValue={isJSON(note.comment_text) ? note.comment_text : `{"blocks":[{"key":"block1","text":"${note.comment_text.replace(/\n/g, ' ').replace(/\t/g, ' ').replace(/"/g, '\\"')}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`}
                                readOnly={true}
                                toolbar={false}
                            />
                        </ThemeProvider>}
                    {/* <p style={{ marginBottom: '0px', fontSize: '1rem' }}>{note.comment_text}</p> */}
                    {note.audioNote && <audio controls src={audioSrc} />}
                </CardContent>
            </Card>)
        );
    }

    const generateNoteCards = (noteList) => {
        let cards = []
        cards = noteList.map((note) => {
            return generateNoteCard(note)
        })
        setContent(cards)
    }

    // sort function
    function sortBy(param) {
        let noteArr = displayNotes
        if (param === 'dateAsce') {
            noteArr = noteArr.sort((noteA, noteB) => moment(noteA.created_at) - moment(noteB.created_at))
        } else {
            noteArr = noteArr.sort((noteA, noteB) => moment(noteB.created_at) - moment(noteA.created_at))
        }
        setDisplayList(noteArr)
        generateNoteCards(noteArr)
    }

    const searchNoteHandler = (e) => {
        var searchQuery = e.target.value
        console.log(searchQuery)
        setSearchTerm(searchQuery)
    }

    const searchNoteResults = (e) => {
        e.preventDefault()
        var searchTerms = new RegExp(searchTerm, 'i')
        console.log(searchTerms)
        let noteSearchResults = noteList.map((note) => {
            if (searchTerms.exec(note.tas_title) || searchTerms.exec(note.tas_type) || searchTerms.exec(note.emp_first_name) || searchTerms.exec(note.emp_last_name)) {
                return note
            }
            else {
                return null
            }
        }).filter((note) => !!note)

        setDisplayList(noteSearchResults)
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <form style={{ width: '135%' }} onSubmit={(e) => searchNoteResults(e)}>
                        <TextField
                            onChange={(e) => searchNoteHandler(e)}
                            value={searchTerm}
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton type="submit">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ width: '50%' }}
                        />
                        <Button variant="contained" color="primary" style={{ marginLeft: '10px', height: '40px' }} onClick={() => setShowAddNote(true)}>
                            Add Note
                        </Button>
                        <Button variant="contained" color="primary" style={{ marginLeft: '10px', height: '40px' }} onClick={() => { setShowAddNote(true); setShowAddVoiceNote(true); }}>
                            Add Voice Note
                        </Button>
                    </form>
                </div>

                <div style={{ display: 'flex', justifyContent: 'start' }}>
                    <Button variant="outlined" color="primary" style={{ height: '40px' }} type="button" className="btn btn-sm bg-white mr-1" data-toggle="modal" data-target="#staticBackdropA">
                        <i className="fas fa-filter"></i> FILTER
                    </Button>
                    <div className="dropdown">
                        <Button variant="outlined" color="primary" style={{ height: '40px' }} className="btn btn-sm dropdown-toggle bg-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span><i className="fas fa-sort-amount-up"></i></span> SORT BY
                        </Button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <Button onClick={() => sortBy('dateDsce')} className="dropdown-item" type="button">Latest</Button>
                            <Button onClick={() => sortBy('dateAsce')} className="dropdown-item" type="button">Oldest</Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <p>{displayNotes.length < 1 ? 'No Notes were found' : ''}</p> */}
            <div className='notes_info' style={{ marginTop: '10px' }}>
                <div>
                    <h5>Notes</h5>
                </div>
                <div style={{ marginTop: '10px', overflowY: 'auto' }}>
                    {!!isLoading &&
                        <div className='d-flex flex-row w-100 justify-content-center mt-5 ignore_notes_info'>
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!isLoading && noteList.length === 0 &&
                        <div style={{ margin: 30, height: '100%' }} className='d-flex flex-column justify-content-center align-items-center'>
                            {<MessagePage title="No Recent Notes Found" icon="far fa-sticky-note"></MessagePage>}
                        </div>
                    }
                    <div className="taskCardList">
                        <div>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="staticBackdropA" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Filter By</h5>
                            <Button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        </div>
                        <div className="modal-body">
                            <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: '16px' }}>
                                <InputLabel id="filter1-label">Class</InputLabel>
                                <Select
                                    labelId="filter1-label"
                                    label="Class"
                                    value={!filter.class ? '' : filter.class}
                                    onChange={(e) => setFilter({ ...filter, class: e.target.value })}
                                >
                                    <MenuItem value="">Select Class</MenuItem>
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Employee Type">Employee Type</MenuItem>
                                    <MenuItem value="Employee">Employee</MenuItem>
                                </Select>
                            </FormControl>

                            {!!filter.class && filter.class !== 'All' && (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel id="filter2-label">Specify</InputLabel>
                                    <Select
                                        labelId="filter2-label"
                                        label="Specify"
                                        value={!filter.specifier ? '' : filter.specifier}
                                        onChange={(e) => setFilter({ ...filter, specifier: e.target.value })}
                                    >
                                        <MenuItem value="">Select Specifier</MenuItem>
                                        {filter.class === 'Employee Type' && typeList}
                                        {filter.class === 'Employee' && empList}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                        <div className="modal-footer">
                            <Button type="button" className="btn btn-info" data-dismiss="modal">Save</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog fullWidth={true} maxWidth={'sm'} open={editNote != null} >
                <DialogActions>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setEditNote(null)}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ paddingTop: '0px' }}>
                    <NoteFormv2 checkTokenExpiry={props.checkTokenExpiry} mode='edit' project_id={editNote?.proj_id} task_id={editNote?.tas_id} note_id={editNote?.comment_id} user={props.user} customer_id={props.cus_id} company_id={props.comp_id} onSubmit={() => setEditNote(null)}></NoteFormv2>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth={true} maxWidth={'sm'} open={showAddNote} >
                <DialogActions>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => { setShowAddNote(false); setShowAddVoiceNote(false); }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ paddingTop: '0px' }}>
                    <NoteFormv2 checkTokenExpiry={props.checkTokenExpiry} company_id={props.comp_id} mode='add' isVoiceNote={showAddVoiceNote ? true : false} user={props.user} customer_id={props.cus_id} onSubmit={() => { setShowAddNote(false); setShowAddVoiceNote(false); }}></NoteFormv2>
                </DialogContent>
            </Dialog>
        </div>
    );
}
