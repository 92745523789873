import React, { useState, useEffect } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import MTSummaryReport from "./MTSummaryReport";
import SCVisitReport from "./SCVisitReport";
import MTCompletionDetailReport from "./MTCompletionDetailReport";
import ManagementReviewReport from "./ManagementReviewReport";
import MTRejectionReport from "./MTRejectionReport";
import LateMTReport from "./LateMTReport";

export default function SupportCoordination_Reports(props) {
    const [activeKey, setActiveKey] = useState("scVisit");

    const isAdmin = props.user.type === 'Admin';

    const handleKeyChange = (event, activeKey) => {
        setActiveKey(activeKey);
    };

    useEffect(() => {
        setActiveKey(props.activeSubKey)
    }, [props.activeSubKey]);

    return (
        <div>
            <div>
                <Typography
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        fontFamily: "Lato",
                        marginBottom: 10,
                        marginLeft: 10
                    }}
                >
                    Support Coordination Reports
                </Typography>
            </div>
            <div>
                <Tabs
                    value={activeKey}
                    onChange={handleKeyChange}
                    variant="scrollable"
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .Mui-selected': {
                            backgroundColor: '#f0f0f0',
                            fontWeight: 'bold',
                        }
                    }}
                >
                    <Tab key={"mtSummary"} value={"mtSummary"} label="MT Summary Report" />
                    <Tab key={"scVisit"} value={"scVisit"} label="Visit Report" />
                    <Tab key={"mtCompletionDetail"} value={"mtCompletionDetail"} label="MT Completion Detail Report" />
                    <Tab key={"managementReview"} value={"managementReview"} label="Management Review Report" />
                    <Tab key={"mtRejection"} value={"mtRejection"} label="MT Rejection Report" />
                    <Tab key={"lateMT"} value={"lateMT"} label="Late MT Report" />
                </Tabs>

                {activeKey === "mtSummary" && <MTSummaryReport {...props} />}
                {activeKey === "scVisit" && <SCVisitReport {...props} />}
                {activeKey === "mtCompletionDetail" && <MTCompletionDetailReport {...props} />}
                {activeKey === "managementReview" && <ManagementReviewReport {...props} />}
                {activeKey === "mtRejection" && <MTRejectionReport {...props} />}
                {activeKey === "lateMT" && <LateMTReport {...props} />}
            </div>
        </div>
    )
};