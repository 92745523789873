import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormRenderer from "../forms/JsonForm/FormRenderer/FormRenderer";
import { Dialog, DialogActions, DialogTitle, DialogContent, Typography, Button } from "@mui/material"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import moment from "moment";
import axios from 'axios';
import { APIURL } from '../../config';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        // color: '#ccc',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: 'black',
    },
}));

const CheckoutForms = (props) => {
    const [open, setOpen] = useState(false);
    const [customerGoals, setCustomerGoals] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const response = (await axios.get(APIURL + `/customer_goal/getCustomerGoalsByTaskType`, {
                params: { customer_id: props.customer_id, task_id: props.task_id }
            })).data;
            setCustomerGoals(response);
        }
        fetchData();
    }, []);

    return (
        <Box>
            {props.forms.map(form =>
                <Box
                    key={form.form_id}
                    onClick={() => {
                        props.setSelectedForm(form);
                        setOpen(true);
                    }}
                    sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        margin: "5px",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>{form.form_name}</Box>
                    {props?.completedForms?.includes(form.form_id) ?
                        <CheckCircleOutlinedIcon /> :
                        null
                    }
                </Box>
            )}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth='md'
                fullWidth
                scroll='body'
                disableRestoreFocus
            >
                <DialogTitle sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px 24px',
                    borderBottom: '1px solid #e0e0e0'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {props.selectedForm?.form_name} <span style={{ fontWeight: 'normal' }}>for</span> {props.customer_name}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{
                            textAlign: 'right',
                            marginRight: '16px',
                            paddingRight: '16px',
                            borderRight: '1px solid #e0e0e0'
                        }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                {props?.taskDetails?.tas_title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {moment(props?.taskDetails?.tas_end_time).format('MM/DD/YYYY hh:mm A')}
                            </Typography>
                        </Box>

                        <BootstrapTooltip
                            title={
                                customerGoals?.length > 0 ? (
                                    <Box sx={{ maxWidth: 300 }}>
                                        {customerGoals.map((cg, index) => (
                                            <Box key={cg.customer_goal_id} sx={{ mb: index < customerGoals.length - 1 ? 2 : 0 }}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                    {index + 1}. {cg.goal_title}:
                                                </Typography>
                                                <Typography variant="body2">{cg.goal_detail}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                ) : (
                                    <Typography variant="subtitle2">No goals for this customer yet.</Typography>
                                )
                            }
                            placement="right-start"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    minWidth: 'unset',
                                    width: '42px',
                                    height: '42px',
                                    borderRadius: '21px',
                                    padding: 0,
                                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                    '&:hover': {
                                        backgroundColor: '#0069d9',
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    fontWeight: 'medium',
                                    fontSize: '14px'
                                }}>
                                    CG
                                </Box>
                            </Button>
                        </BootstrapTooltip>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FormRenderer
                        form_id={props.selectedForm?.form_type ? props.selectedForm?.form_type : props.selectedForm?.form_id}
                        json_schema={props.selectedForm?.json_schema}
                        ui_schema={props.selectedForm?.ui_schema}
                        styles={{ width: "100%", minWidth: "600px" }}
                        onSubmit={(form) => { setOpen(false); if (props.onSubmit) props.onSubmit(form); }}
                        onEdit={props.onEdit}
                        customer_id={props.customer_id}
                        task_id={props.task_id}
                        user_id={props.user_id}
                        readOnly={props.readOnly}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default CheckoutForms;