import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Checkbox, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { APIURL } from '../../../config';
import { FormControlLabel } from '@mui/material';

const IndividualSettings = (props) => {
    const [calendarNotifications, setCalendarNotifications] = useState(props.calendarNotifications);
    const [mtNA, setMtNA] = useState(props.mtNA);
    const [mtNamingConvention, setMtNamingConvention] = useState(props.mtNamingConvention);
    const [trackEmployeeCost, setTrackEmployeeCost] = useState(props.trackEmployeeCost);
    const [identifyAnnualVisit, setIdentifyAnnualVisit] = useState(props.identifyAnnualVisit);
    const [mtNotification, setMtNotification] = useState(props.mtNotification);
    const [taskNotification, setTaskNotification] = useState(props.taskNotification);

    useEffect(() => {
        setCalendarNotifications(props.calendarNotifications);
        setMtNA(props.mtNA);
        setMtNamingConvention(props.mtNamingConvention || '{customer_name}_{month}_{year}_{type}_Form');
        setTrackEmployeeCost(props.trackEmployeeCost);
        setIdentifyAnnualVisit(props.identifyAnnualVisit);
        setMtNotification(props.mtNotification);
        setTaskNotification(props.taskNotification);
    }, [props]);

    const handleMTNamingConvention = async (e) => {
        if(e.target.value.trim().length > 0)
            setMtNamingConvention(e.target.value);
        else
            setMtNamingConvention('{customer_name}_{month}_{year}_{type}_Form');

        try {
            let settingsData = (await axios.get(APIURL+"/config/settings", {params: {comp_id: props.comp_id, feature_id: 53 }})).data;
            if(settingsData.length > 0){
                let settingsPayload = {
                    settings_id: settingsData[0].settings_id, feature_value: e.target.value, feature_id: 53,
                }
                let result = await axios.put(APIURL+`/config/settings`, {...settingsPayload});
            } else {
                let settingsPayload = {
                    feature_value: e.target.value,
                    feature_id: 53,
                    company_id: props.comp_id,
                    added_by: props.user_id
                }
                let result = await axios.post(APIURL+`/config/settings`, {...settingsPayload});
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleCheckBoxChange = async (field, feature_id) => {
        const newValue = !eval(field);
        eval(`set${field.charAt(0).toUpperCase() + field.slice(1)}(newValue)`);

        try {
            let settingsData = (await axios.get(APIURL+"/config/settings", {params: {comp_id: props.comp_id, feature_id: feature_id}})).data;
            if(settingsData.length > 0){
                let settingsPayload = {
                    settings_id: settingsData[0].settings_id, feature_value: newValue ? 'true' : 'false', feature_id: feature_id,
                }
                let result = await axios.put(APIURL+`/config/settings`, {...settingsPayload});
            } else {
                let settingsPayload = {
                    feature_value: newValue ? 'true' : 'false', 
                    feature_id: feature_id, 
                    company_id: props.comp_id,
                    added_by: props.user_id
                }
                let result = await axios.post(APIURL+`/config/settings`, {...settingsPayload});
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            MT Settings
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Customize MT experience for your employees
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={mtNA}
                                    onChange={() => handleCheckBoxChange('mtNA', 52)}
                                />
                            }
                            label="Does MT Require N/A"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={calendarNotifications}
                                    onChange={() => handleCheckBoxChange('calendarNotifications', 50)}
                                />
                            }
                            label="Enable Calendar Notifications"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={trackEmployeeCost}
                                    onChange={() => handleCheckBoxChange('trackEmployeeCost', 54)}
                                />
                            }
                            label="Track Employee Cost"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={identifyAnnualVisit}
                                    onChange={() => handleCheckBoxChange('identifyAnnualVisit', 55)}
                                />
                            }
                            label="Identify Annual Visit"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={mtNotification}
                                    onChange={() => handleCheckBoxChange('mtNotification', 59)}
                                />
                            }
                            label="MT Notification"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={taskNotification}
                                    onChange={() => handleCheckBoxChange('taskNotification', 58)}
                                />
                            }
                            label="Task Notification"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                            <Typography sx={{ whiteSpace: 'nowrap' }}>
                                MT Naming Convention
                            </Typography>
                            <Tooltip title="Enter the naming convention for MTs. Use the following variables to create the naming convention: {customer_name}, {month}, {year}, {type}.">
                                <InfoIcon style={{ color: '#808080', marginRight: '5px', }} />
                            </Tooltip>
                        </Box>
                        <TextField
                            id="mtNamingConvention"
                            fullWidth
                            value={mtNamingConvention}
                            variant='standard'
                            onChange={(e) => setMtNamingConvention(e.target.value)}
                            inputProps={{ 
                                maxLength: 70,
                            }}
                            onBlur={(e) => {
                                handleMTNamingConvention(e);
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default IndividualSettings;
