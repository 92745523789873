import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { APIURL } from '../../../config';
import axios from 'axios';
import moment from 'moment';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


const EVV_NJ_RESEND = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [customerData, setCustomerData] = useState([]);
    const [taskData, setTaskData] = useState([]);

    const [selectedCustomer, setSelectedCutomer] = useState(null);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [loggedToSegment, setLoggedToSegment] = useState(false);

    const logToSegment = () => {
        if(loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page,window.location.href)
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page.toUpperCase() + " : Resend",{
            title:page.toUpperCase() + " : Resend",
            path:page.toUpperCase() + " : Resend",
        }, {
          userId:String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }

    const getCustomerData = async () => {
        let companyCustomerData = (await axios.get(APIURL + '/customers/v2', { params: { company_id: props.user.comp_id } })).data
        setCustomerData(companyCustomerData)
    }

    const getTaskData = async (customer_id) => {
        let companyTaskData = (await axios.get(APIURL + '/task/v2', { params: { company_id: props.user.comp_id, customer_id: customer_id } })).data
        companyTaskData.sort((a, b) => new Date(b.tas_start_time) - new Date(a.tas_start_time));
        companyTaskData = companyTaskData.filter((task) => task.tas_is_active !== 2)
        setTaskData(companyTaskData)
    }

    const resendTasks = async(tasks) => {
        let taskIDs = tasks.map((task) => task.tas_id)
        let payload={
            evvResendData: JSON.stringify([{com_id:props.user.comp_id, tasks:taskIDs}])
        }
        const config = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }
        let response = await axios.post(APIURL+"/evv/resend",payload,config);
        
    }

    useEffect(() => {
        getCustomerData().then(() => {setIsLoading(false)});
        logToSegment();
    }, [])

    useEffect(() => {
        getTaskData(selectedCustomer);
    }, [selectedCustomer])

    return (
        <div style={{maxWidth: '90vw', padding: '10px'}}>
            <h4>EVV Resend</h4>
            <hr></hr>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerData}
                getOptionLabel={(option) => `${option.cus_name} (${option.cus_id})`}
                sx={{ maxWidth: '500px', width: '50vw', }}
                renderInput={(params) => <TextField {...params} label="Select Customer" />}
                onChange={(event, value, reason) => setSelectedCutomer(value.cus_id)}
            />
            <Autocomplete
                disabled={taskData.length < 1}
                disablePortal
                id="combo-box-demo"
                options={taskData}
                getOptionLabel={(option) => `${('[' + moment(option.tas_start_time).format('MM/DD/YYYY') + '] ').replace('[Invalid date] ', '')}${option.tas_id} - ${option.tas_title}`}
                sx={{ maxWidth: '500px', width: '50vw', marginTop: '20px'}}
                renderInput={(params) => <TextField {...params} label="Select Task" />}
                onChange={(event, value, reason) => setSelectedTasks([value])}
            />
            <button onClick={() => resendTasks(selectedTasks)} className='FW-Button' style={{ marginTop: '20px', width: '150px', height: '40px', fontSize: '16px' }}>
                Resend Task
            </button>
        </div>
    )
}

export default EVV_NJ_RESEND;