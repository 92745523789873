import React, { useState, useEffect } from "react";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Form } from '@formio/react';
import { Box, Link, Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment";
import './Forms.css'

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        // color: '#ccc',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: 'black',
    },
}));

export default function Forms(props) {
    const [formsData, setFormsData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [selectedForm, setSelectedForm] = useState(null);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(APIURL + '/customer_form/customer/' + props.cus_id);
                if (response.data.length > 0) {
                    setFormsData(response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            setLoading(false);
        }
        fetchData();
    }, []);

    const handleSubmit = async (submission) => {
        try {
            const response = await axios.put(APIURL + '/customer_form/' + selectedForm.form_id, {
                data: JSON.stringify(submission),
                emp_id: props.user.emp_id
            });

            setOpen(false);
            setSelectedForm(null);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }

    const columns = [
        { field: "id", headerName: "ID", width: 100, hide: true },
        { field: "form_id", headerName: "Form ID", width: 100, hide: true },
        { field: "form_name", headerName: "Form Name", width: 150 },
        {
            field: "view_edit_form",
            headerName: "View/Edit",
            width: 90,
            hide: false,
            renderCell: (params) => {
                const onClickView = async () => {
                    setSelectedForm(params.value);
                    setOpen(true);
                    setIsEdit(false);
                };

                const onClickEdit = async () => {
                    setSelectedForm(params.value);
                    setOpen(true);
                    setIsEdit(true);
                };
                return (
                    <div style={{ display: 'flex' }}>
                        <div style={{ color: '#3FA7D6', marginRight: '10px' }}>
                            <Link href="#" underline="hover" onClick={onClickView}>
                                <i class="fas fa-list-ul" style={{ marginRight: '5px' }}></i>
                            </Link>
                        </div>
                        <div> | </div>
                        <div style={{ color: '#3FA7D6', marginLeft: '10px' }}>
                            <Link href="#" underline="hover" onClick={onClickEdit}>
                                <i class="far fa-edit" style={{ marginRight: '5px' }}></i>
                            </Link>
                        </div>
                    </div>
                )
            }
        },
        { field: "task_title", headerName: "Task Title", width: 150 },
        { field: "task_date", headerName: "Task Date", width: 100 },
        { field: "last_updated", headerName: "Form Last Updated", width: 175 },
        { field: "completed_by", headerName: "Completed By", width: 200 },
    ]

    let rows = formsData.map((form, idx) => {
        let formRow = {
            id: idx,
            form_id: form.form_id,
            form_name: form.form_name,
            task_title: form.tas_title,
            task_date: moment(form.checkoutEmpDate).format('MM/DD/YYYY'),
            last_updated: moment(form.last_updated).format('MM/DD/YYYY hh:mm A'),
            completed_by: form.emp_first_name ? form.emp_first_name + ' ' + form.emp_last_name : '-',
            view_edit_form: form
        };
        return formRow;
    });

    const generatePDF = async (downloadPdf = true) => {
        try {
            setIsGenerating(true);

            // Get the form element
            const formElement = document.querySelector('.formio-form');
            if (!formElement) {
                throw new Error('Form element not found');
            }

            // Create canvas from form
            const canvas = await html2canvas(formElement, {
                scale: 2, // Higher scale for better quality
                logging: false,
                useCORS: true,
                windowWidth: formElement.scrollWidth,
                windowHeight: formElement.scrollHeight
            });

            // Calculate dimensions
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            const pdf = new jsPDF('p', 'mm', 'a4');
            let page = 1;

            // Add header with form title
            if (selectedForm?.form_name) {
                pdf.setFontSize(16);
                pdf.text(selectedForm.form_name, 15, 15);
                position = 25;
            }

            while (heightLeft >= 0) {
                pdf.addImage(
                    canvas.toDataURL('image/png'),
                    'PNG',
                    0,
                    position,
                    imgWidth,
                    imgHeight,
                    '',
                    'FAST'
                );
                heightLeft -= pageHeight;

                if (heightLeft > 0) {
                    pdf.addPage();
                    page++;
                }
            }

            if (downloadPdf) {
                // Download PDF
                pdf.save(`${selectedForm?.form_name || 'form'}.pdf`);
            } else {
                // Open in new window for printing
                window.open(pdf.output('bloburl'), '_blank');
            }
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        (<div id="forms-main">
            <div className='forms_info'>
                <div style={{ marginBottom: '10px' }}>
                    <h5>Forms</h5>
                </div>
                {isLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div> :
                    <Box style={{ margin: "auto", height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '100%', height: "95%", background: 'white' }}>
                            <DataGridPro
                                rows={rows}
                                columns={columns}
                                initialState={{ sorting: { sortModel: [{ field: "last_updated", sort: "desc" }] } }}
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                                width={"100%"}
                                justifyContent="center"
                                slots={{
                                    toolbar: GridToolbar,
                                }}
                            />
                        </Box>
                        <Box>
                            <Dialog
                                open={open}
                                onClose={() => { setOpen(false); setSelectedForm(null); }}
                                maxWidth='md'
                                fullWidth
                                scroll='body'
                                disableRestoreFocus
                            >
                                <DialogTitle sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '16px 24px',
                                    borderBottom: '1px solid #e0e0e0'
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        {selectedForm?.form_name} <span style={{ fontWeight: 'normal' }}>for</span> {props.cust_details.cus_name}
                                    </Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{
                                            textAlign: 'right',
                                            marginRight: '16px',
                                            paddingRight: '16px',
                                            borderRight: '1px solid #e0e0e0'
                                        }}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                                {selectedForm?.tas_title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {moment(selectedForm?.checkoutEmpDate).format('MM/DD/YYYY hh:mm A')}
                                            </Typography>
                                        </Box>

                                        <BootstrapTooltip
                                            title={
                                                selectedForm?.cusGoals?.length > 0 ? (
                                                    <Box sx={{ maxWidth: 300 }}>
                                                        {selectedForm.cusGoals.map((cg, index) => (
                                                            <Box key={cg.customer_goal_id} sx={{ mb: index < selectedForm.cusGoals.length - 1 ? 2 : 0 }}>
                                                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                    {index + 1}. {cg.goal_title}:
                                                                </Typography>
                                                                <Typography variant="body2">{cg.goal_detail}</Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                ) : (
                                                    <Typography variant="subtitle2">No goals for this customer yet.</Typography>
                                                )
                                            }
                                            placement="right-start"
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    minWidth: 'unset',
                                                    width: '42px',
                                                    height: '42px',
                                                    borderRadius: '21px',
                                                    padding: 0,
                                                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                                    '&:hover': {
                                                        backgroundColor: '#0069d9',
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                                                    },
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Box sx={{
                                                    display: 'flex',
                                                    fontWeight: 'medium',
                                                    fontSize: '14px'
                                                }}>
                                                    CG
                                                </Box>
                                            </Button>
                                        </BootstrapTooltip>
                                    </Box>
                                </DialogTitle>
                                <DialogContent>
                                    <Form
                                        form={selectedForm?.json_schema}
                                        submission={selectedForm?.formData}
                                        onSubmit={handleSubmit}
                                        options={{
                                            readOnly: isEdit ? false : true,
                                            hide: {
                                                submit: isEdit ? false : true,
                                            }
                                        }}
                                    />
                                </DialogContent>
                                {!isEdit &&
                                    <DialogActions sx={{ border: '1px solid #ccc' }}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                onClick={() => generatePDF(false)}
                                                disabled={isGenerating}
                                            >
                                                Print
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={() => generatePDF(true)}
                                                disabled={isGenerating}
                                                sx={{ marginLeft: 1 }}
                                            >
                                                Download
                                            </Button>
                                        </div>
                                    </DialogActions>
                                }
                            </Dialog>
                        </Box>
                    </Box>
                }
            </div>
        </div>)
    );
}