import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function FinancialDashboard(props) {
    const [iframeUrl, setiframeUrl] = useState('');
    const [isIframeLoading, setIsIframeLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/metabase/financialDashboard", {
                params: { companyID: props.user.comp_id },
            })
            .then((response) => {
                setiframeUrl(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    };

    return (
        <div>
            <div style={{ marginLeft: 10, marginRight: 10, width: '75vw', marginTop: '-30px' }}>
                <iframe
                    src={iframeUrl}
                    style={{ width: '100%', height: '82vh', border: 'none' }}
                    allowTransparency
                    onLoad={handleIframeLoad}
                />
            </div>
        </div>
    );
};