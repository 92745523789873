import React, { useState, useEffect } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import DemographicsDashboard from "./DemographicsDashboard";
import OperationsDashboard from "./OperationsDashboard";
import HRDashboard from "./HRDashboard";
import FinancialDashboard from "./FinancialDashboard";
import CustomerDashboard from "./CustomerDashboard";
import EmployeeDashboard from "./EmployeeDashboard";

export default function BI_Dashboards(props) {
    const [activeKey, setActiveKey] = useState("demographicsDashboard");

    const pBusinessType = props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].feature_id : -1;
    const stateID = props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].state_id : '';
    const isAdmin = props.user.type === 'Admin';

    const handleKeyChange = (event, activeKey) => {
        setActiveKey(activeKey);
    };

    useEffect(() => {
        setActiveKey(props.activeSubKey)
    }, [props.activeSubKey]);

    return (
        <div>
            <div>
                <Typography
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        fontFamily: "Lato",
                        marginBottom: 10,
                        marginLeft: 10
                    }}
                >
                    BI Dashboards
                </Typography>
            </div>
            <div>
                <Tabs
                    value={activeKey}
                    onChange={handleKeyChange}
                    variant="scrollable"
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .Mui-selected': {
                            backgroundColor: '#f0f0f0',
                            fontWeight: 'bold',
                        },
                        position: 'relative',
                        zIndex: 1,
                        backgroundColor: '#fafafa',
                    }}
                >
                    {isAdmin && <Tab key={"demographicsDashboard"} value={"demographicsDashboard"} label="Demographics" />}
                    {isAdmin && stateID === 'NJ' && pBusinessType === 101 && <Tab key={"operationsDashboard"} value={"operationsDashboard"} label="Operations" />}
                    {isAdmin && <Tab key={"hrDashboard"} value={"hrDashboard"} label="HR" />}
                    {isAdmin && <Tab key={"financialDashboard"} value={"financialDashboard"} label="Financial" />}
                    <Tab key={"customerDashboard"} value={"customerDashboard"} label="Customer" />
                    <Tab key={"employeeDashboard"} value={"employeeDashboard"} label="Employee" />
                </Tabs>

                {activeKey === "demographicsDashboard" && <DemographicsDashboard {...props} />}
                {activeKey === "operationsDashboard" && <OperationsDashboard {...props} />}
                {activeKey === "hrDashboard" && <HRDashboard {...props} />}
                {activeKey === "financialDashboard" && <FinancialDashboard {...props} />}
                {activeKey === "customerDashboard" && <CustomerDashboard {...props} />}
                {activeKey === "employeeDashboard" && <EmployeeDashboard {...props} />}
            </div>
        </div>
    )
};