import React, { useState, useEffect } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import PlanRenewalReport from "./PlanRenewalReport";
import ActiveCustomerReport from "./ActiveCustomerReport";
import TasksReport from "./TasksReport";
import ClientTerminationReport from "./ClientTerminationReport";
import ClientStatusReport from "./ClientStatusReport";

export default function SP_Reports(props) {
    const [activeKey, setActiveKey] = useState('planRenewal');

    const isAdmin = props.user.type === 'Admin';

    const handleKeyChange = (event, activeKey) => {
        setActiveKey(activeKey);
    };

    useEffect(() => {
        setActiveKey(props.activeSubKey)
    }, [props.activeSubKey]);

    return (
        <div>
            <div>
                <Typography
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        fontFamily: "Lato",
                        marginBottom: 10,
                        marginLeft: 10
                    }}
                >
                    Administration Reports
                </Typography>
            </div>
            <div>
                <Tabs
                    value={activeKey}
                    onChange={handleKeyChange}
                    variant="scrollable"
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .Mui-selected': {
                            backgroundColor: '#f0f0f0',
                            fontWeight: 'bold',
                        }
                    }}
                >
                    {isAdmin && <Tab key={"clientTermination"} value={"clientTermination"} label="Client Termination Report" />}
                    {isAdmin && <Tab key={"clientStatus"} value={"clientStatus"} label="Client Status Report" />}
                    <Tab key={"planRenewal"} value={"planRenewal"} label="Plan Renewal Report" />
                    <Tab key={"activeCustomer"} value={"activeCustomer"} label="Active Customer Report" />
                    <Tab key={"tasks"} value={"tasks"} label="Tasks Report" />
                </Tabs>

                {activeKey === "clientTermination" && <ClientTerminationReport {...props} />}
                {activeKey === "clientStatus" && <ClientStatusReport {...props} />}
                {activeKey === "planRenewal" && <PlanRenewalReport {...props} />}
                {activeKey === "activeCustomer" && <ActiveCustomerReport {...props} />}
                {activeKey === "tasks" && <TasksReport {...props} />}
            </div>
        </div>
    )
};