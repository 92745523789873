import React from 'react';
import { useState, useEffect } from "react";
import './employeeHome.css'
import UpcomingAppointments from '../../../components/TopAppointments/index'
// import Notifications from './EmployeeHomeComponents/NotificationAndRecentCalls/Notifications'
import RecentCalls from '../../../components/RecentCalls/index'
import RecentNotes from '../../../components/RecentNotes/index'
// import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import Home from '../../../components/Home/HomeBox'
// import Documents from './EmployeeHomeComponents/Documents/Documents'
import TimeSheetWrapper from './EmployeeHomeComponents/Timesheets/TimesheetWrapper'
import Reportsv1 from '../../../components/Reports/Reportsv1'
import TimesheetReport from '../../../components/Reports/TimesheetReport'
import MTSummaryReport from '../../../components/Reports/MTSummaryReport'
import CaseManagerCaseload from '../../../components/Reports/CaseManagerCaseload';
import CaseInfo from '../../../components/CaseInfo/CaseInfo';
import PreAuthCsv from '../../../components/PreAuthCSV/PreAuthCsv'
import axios from 'axios'
import { APIURL } from '../../../config'
import { CSVReader } from 'react-papaparse';
import IRecordSync from '../../../components/forms/BulkUploadForm/IRecordSync'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SupportCoordinatorManagement from '../../Manager/SupportCoordinatorManagement';
import SupportCoordinatorCustomers from '../../SupportCoordinator/SupportCoordinatorCustomers'
import NewNote from '../../SupportCoordinator/Modals/NewNote';
import NewTask from '../../SupportCoordinator/Modals/NewTask';
import NewTimesheet from '../../SupportCoordinator/Modals/NewTimesheet';
import Upload from '../../SupportCoordinator/Modals/Upload';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import moment from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25vw',
    bgcolor: 'background.paper',
    border: '2px solid #48abd3',
    boxShadow: 24,
    borderRadius: '4px',
    p: 4,
};

const EmployeeHome = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [sDataLoading, setSDataLoading] = useState(true);
    const [value, setValue] = useState('1');
    const [data, setData] = useState(null);
    const [sData, setSData] = useState(null);
    const [MTSummaryData, setMTSummaryData] = useState([]);
    const [reportLoading, setReportLoading] = useState(true);

    const [showAddNote, setShowAddNote] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [showTask, setShowTask] = useState(false);
    const [showTimesheet, setShowTimesheet] = useState(false);

    const [noteCustomerID, setNoteCustomerID] = useState();
    const [uploadCustomerID, setUploadCustomerID] = useState("");
    const [timesheetCustomerID, setTimesheetCustomerID] = useState("")

    const handleShowNote = (id) => { setNoteCustomerID(id); setShowAddNote(true) };
    const handleShowUpload = (id) => { setUploadCustomerID(id); setShowUpload(true) };
    const handleShowTask = () => setShowTask(true);
    const handleCloseTask = () => setShowTask(false);
    const handleShowTimesheet = (id) => { setTimesheetCustomerID(id); setShowTimesheet(true) };
    const handleCloseTimesheet = () => setShowTimesheet(false);

    const isSubscibedToTimeManagement = props.user.subs.find((setting) => setting.feature_id === 12) ? true : false;
    const isSupport = props.user.comp_settings.find(setting => setting.feature_id === 101 && setting.feature_value === 'primaryBusinessType' && setting.state_id === 'NJ');
    const isCaseManagement = props.user.comp_settings.find(setting => setting.feature_id === 104 && setting.feature_value === 'primaryBusinessType' && setting.state_id === 'NJ');

    useEffect(() => {
        props.checkTokenExpiry()
    }, [])

    const getEmployees = async () => {
        let data = await axios.get(APIURL + "/monthlymonitoring/manager/data", {
            params: {
                comp_id: props.comp_id,
                emp_id: props.user.emp_id,
                start : moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
                end: moment().endOf('month').format('YYYY-MM-DDT23:59:59')
            }
        });

        setData(data.data);
        console.log(data.data);
        setDataLoading(false);
    }

    const getSCH = async () => {
        let data = await axios.get(APIURL + "/customers/SCH", {
            params: {
                comp_id: props.comp_id,
                emp_id: props.user.emp_id,
                start : moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
                end: moment().endOf('month').format('YYYY-MM-DDT23:59:59'),
            }
        });
        setSData(data.data)
        setSDataLoading(false);
        console.log(data.data)
    }

    useEffect(async () => {
        props.checkTokenExpiry();
        await getEmployees();
        await getSCH();
    }, [props])


    const updateTaskStatus = (cusId, tasStatus, tasId, month) => {
        let newSData = [...sData];
        newSData.forEach((customer, i) => {
            if (customer.cus_id === cusId) {
                customer[month].forEach((task, j) => {
                    if (task.tas_id === tasId) {
                        newSData[i][month][j].tas_mm_status = tasStatus;
                    }
                })
            }
        })
        let newData = [...data];
        newData.forEach((employee, i) => {
            if (employee.customer.cus_id === cusId)
                newData[i].customer[month].forEach((task, j) => {
                    if (task.tas_id === tasId) {
                        newData[i].customer[month][j].tas_mm_status = tasStatus;
                    }
                })
        })
        setData(newData);
        setSData(newSData);
    }

    const getMTSummaryReport = async () => {
        axios
            .get(APIURL + "/reports/getMTSummary", {
                params: { com_id: props.user.comp_id, emp_id: props.user.emp_id },
            })
            .then((response) => {
                setMTSummaryData(response?.data.length !== 0 ? response?.data : []);
                setReportLoading(false);
            })
            .catch((err) => {
                setReportLoading(false);
                console.log(err);
            });
    }

    return (
        <div className="employee-home-main">
            {isCaseManagement ?
                <div className="employee-home-wrapper">
                    <div className="employee-home-wrapper-top-row">
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            value={value}>
                            <Tab onClick={() => setValue('1')} label="Upcoming Appointments" value="1" />
                            <Tab onClick={() => setValue('2')} label="Caseload Report" value="2" />
                            {isSubscibedToTimeManagement && <Tab onClick={() => setValue('3')} label="Timesheets Report" value="3" />}
                            {props.user.type === 'Admin' && !!props.user.subs.find((d) => d.feature_id === 16) && <Tab onClick={() => setValue('4')} label="DASHBOARD" value="4" />}
                        </Tabs>
                        {value === '1' &&
                            <div className="upcoming-appointments-container">
                                {props.user.type === 'Admin' && <CaseInfo user={props.user} checkTokenExpiry={props.checkTokenExpiry} className="upcoming-appointments-recentcalls" />}
                                <div className="upcoming-appointments-top" >
                                    <UpcomingAppointments user={props.user} isCaseManagement={isCaseManagement}/>
                                </div>
                                <div className="upcoming-appointments-bottom">
                                    {!!props.user.subs.find((d) => d.feature_id === 12) && <TimeSheetWrapper user={props.user} className="upcoming-appointments-timesheets" />}
                                    <RecentNotes user={props.user} checkTokenExpiry={props.checkTokenExpiry} className="upcoming-appointments-recentcalls" />
                                </div>
                            </div>
                        }
                        {value === '2' &&
                            <div style={{ width: '98%', marginLeft: '1%' }}>
                                <CaseManagerCaseload user={props.user} comp_id={props.comp_id} />
                                {/* <Reportsv1 user={props.user} pagesize={20} comp_id={props.comp_id} /> */}
                            </div>
                        }
                        {value === '3' &&
                            <div style={{ width: '98%', height: '100vh', marginLeft: '1%' }}>
                                <TimesheetReport user={props.user} pagesize={20} comp_id={props.comp_id} />
                            </div>
                        }
                        {value === '4' &&
                            <div style={{ width: 1300 }}>
                                <Home {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                            </div>
                        }
                    </div>
                </div> :
                <div className="employee-home-wrapper">
                    <div className="employee-home-wrapper-top-row">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                value={value}
                            >
                                <Tab onClick={() => setValue('1')} label="Upcoming Appointments" value="1" />
                                {isSupport && props.user.type === 'Admin' ?
                                    <Tab onClick={() => { getMTSummaryReport(); setValue('2') }} label="Caseload Report" value="2" />
                                    :
                                    <Tab onClick={() => setValue('2')} label="Caseload Report" value="2" />
                                }
                                {!!props.user.subs.find((d) => d.feature_id === 12) && <Tab onClick={() => setValue('3')} label="Timesheets Report" value="3" />}
                                {props.user.type === 'Admin' && !!props.user.subs.find((d) => d.feature_id === 16) && <Tab onClick={() => setValue('4')} label="DASHBOARD" value="4" />}
                                {props.user.type !== 'Fieldworker' && <Tab onClick={() => setValue('5')} label="DDD Sync" value="5" />}
                                {isSupport && <Tab onClick={() => setValue('6')} label="SC Management" value="6" />}
                                {isSupport && <Tab onClick={() => setValue('7')} label="Customers" value="7" />}
                            </Tabs>
                            {(isSupport || props.user.comp_settings.find(setting => setting.feature_id === 102 && setting.feature_value === 'primaryBusinessType' && setting.state_id === 'NJ')) &&
                                <ButtonGroup size="small" sx={{ height: "40px", whiteSpace: "nowrap" }}>
                                    <Button variant="contained" onClick={() => setShowAddNote(true)}>NEW NOTE</Button>
                                    <Button variant="contained" onClick={handleShowTask}>NEW TASK</Button>
                                    {isSubscibedToTimeManagement &&
                                        <Button variant="contained" onClick={handleShowTimesheet}>NEW TIMESHEET</Button>
                                    }
                                    <Button size="small" variant="contained" onClick={() => setShowUpload(true)}>UPLOAD</Button>
                                </ButtonGroup>
                            }
                        </div>
                        {value === '1' &&
                            <div className="upcoming-appointments-container">
                                <div className="upcoming-appointments-top">
                                    <UpcomingAppointments user={props.user} />
                                </div>
                                <div></div>
                                <div className="upcoming-appointments-bottom">

                                    {!!props.user.subs.find((d) => d.feature_id === 12) && <TimeSheetWrapper user={props.user} className="upcoming-appointments-timesheets" />
                                    }
                                    <RecentCalls user={props.user} className="upcoming-appointments-recentcalls" />
                                </div>
                            </div>
                        }
                        {value === '2' &&
                            (isSupport && props.user.type === 'Admin' ?
                                (reportLoading ?
                                    <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div> :
                                    <div style={{ width: '98%', marginLeft: '1%' }}>
                                        <MTSummaryReport user={props.user} data={MTSummaryData} />
                                    </div>
                                )
                                :
                                <div style={{ width: '98%', marginLeft: '1%' }}>
                                    <Reportsv1 user={props.user} pagesize={20} comp_id={props.comp_id} />
                                </div>
                            )
                        }
                        {value === '3' &&
                            <div style={{ width: '98%', height: '100vh', marginLeft: '1%' }}>
                                <TimesheetReport user={props.user} pagesize={20} comp_id={props.comp_id} />
                            </div>
                        }
                        {value === '4' &&
                            <div style={{ width: 1300 }}>
                                <Home {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                            </div>
                        }
                        {value === '5' &&
                            <div style={{ width: 1300, paddingLeft: '30px' }}>
                                <IRecordSync comp_id={props.comp_id} {...props}></IRecordSync>
                            </div>
                        }
                        {value === '6' && isSupport &&
                            <div className="customers-container">
                                <SupportCoordinatorManagement {...props} handleShowNote={handleShowNote} handleShowUpload={handleShowUpload} data={data ? data : []} comp_id={props.comp_id} emp_id={props.user.emp_id} getSCH={getSCH} getEmployees={getEmployees} updateTaskStatus={updateTaskStatus} loading={dataLoading} />
                            </div>
                        }
                        {value === '7' && isSupport &&
                            <div style={{ width: '98%', height: '100vh', marginLeft: '1%' }}>
                                <SupportCoordinatorCustomers
                                    {...props}
                                    handleShowNote={handleShowNote}
                                    handleShowUpload={handleShowUpload}
                                    handleShowTimesheet={handleShowTimesheet}
                                    data={sData ? sData : []}
                                    comp_id={props.comp_id}
                                    emp_id={props.user.emp_id}
                                    rerender={() => { getSCH(); getEmployees() }}
                                    loading={sDataLoading}
                                    isSubscibedToTimeManagement={isSubscibedToTimeManagement}
                                />
                            </div>
                        }
                        {(isSupport || props.user.comp_settings.find(setting => setting.feature_id === 102 && setting.feature_value === 'primaryBusinessType' && setting.state_id === 'NJ')) &&
                            <div>
                                <NewNote checkTokenExpiry={props.checkTokenExpiry} showAddNote={showAddNote} setShowAddNote={setShowAddNote} company_id={props.comp_id} mode='add' user={props.user} customer_id={noteCustomerID} setNoteCustomerID={setNoteCustomerID}></NewNote>
                                <NewTask show={showTask} handleClose={handleCloseTask} user={props.user} emp_id={props.user.emp_id} comp_id={props.comp_id} {...props} />
                                {isSubscibedToTimeManagement &&
                                    <NewTimesheet show={showTimesheet} handleClose={handleCloseTimesheet} comp_id={props.comp_id} user={props.user} emp_id={props.user.emp_id} customer_id={timesheetCustomerID} setTimesheetCustomerID={setTimesheetCustomerID} rerender={getSCH}></NewTimesheet>
                                }
                                <Upload showUpload={showUpload} setShowUpload={setShowUpload} company_id={props.comp_id} user={props.user} customer_id={uploadCustomerID} setUploadCustomerID={setUploadCustomerID} rerender={getSCH}></Upload>
                            </div>
                        }
                    </div>
                    {/*<Dialog
                        open={value == '5'}
                        onClose={() => setState({ value: '1' })}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <DialogTitle style={{ minHeight: '50px', width: '80vw' }}>
                            <IconButton
                            aria-label="close"
                            onClick={() => setState({ value: '1' })}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                            >
                            <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                        <IRecordSync comp_id={props.comp_id}></IRecordSync>
                        </DialogContent>
                    </Dialog>*/}
                </div>
            }
        </div>
    )
}

export default EmployeeHome;