import React, { Component } from 'react';
import { Button, Form, FormControl, Alert } from 'react-bootstrap';
import HandyCard from '../../HandyCard/HandyCard';
import TaskForm from '../../../components/forms/TaskForm/TaskForm';
import './TasksPage.css';
import axios from 'axios';
import moment from "moment";
import {APIURL} from '../../../config'
import AlertMUI from '@mui/lab/Alert'
import ViewMTModal from '../../SupportCoordinator/Modals/ViewMTModal';
import ViewFormModal from '../../../components/forms/JsonForm/ViewFormModal';
import TaskCalendar from '../../Customers_V2/Tasks/TaskCalendar';
import { Card, CardActions, CardContent, IconButton, Dialog, DialogActions, DialogContent, TextField, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GestureIcon from '@mui/icons-material/Gesture';
import ArticleIcon from '@mui/icons-material/Article';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';

class TaskPage extends Component {
    state = {
        task: null,
        project_tasks: [],
        taskType: [],
        mtTask: null,
        cusFormTask: null,
        deletedTask: null,
        taskList_display: null,
        searchTerm: '',
        timer: null,
        taskList: null,
        selectedTab: 0,
        tabs: ['Ongoing', 'Upcoming', 'Completed'],
        showForm: false,
        showCusForms: false,
        loading: true,
        view: 'month'
    }

    isSupport = this.props.user.comp_settings.find(setting => setting.feature_id === 101 && setting.feature_value === 'primaryBusinessType' && setting.state_id === 'NJ');
    handleFormClose = () => { this.setState({ showForm: false }) };
    handleFormShow = (task) => { console.log(task); this.setState({ mtTask: task, showForm: true }) };

    handleCusFormsClose = () => { this.setState({ showCusForms: false }) };
    handleCusFormsShow = (task) => { console.log(task); this.setState({ cusFormTask: task, showCusForms: true }) };

    deleteTask = (taskId, taskTitle) => {
        if (this.state.timer) {
            alert("Another delete is in process, wait for the undo dailogue to disappear.")
        } else {
            let oldTaskList = [...this.state.taskList]
            oldTaskList = oldTaskList.filter(task => task.tas_id !== taskId)
            console.log("After deletion", oldTaskList);
            this.setState({
                deletedTask: this.state.taskList.find(task => task.tas_id === taskId),
                timer: setTimeout(() => {
                    this.deleteRequest(this.state.deletedTask.tas_id,taskTitle)
                    clearTimeout(this.state.timer)
                    this.setState({
                        timer: null,
                        deletedTask: null
                    })
                }, 5000),
                taskList: oldTaskList
            })
        }
    }

    deleteRequest = (id,title) => {
        axios.delete(APIURL+"/task", { data: { id: id, empId: this.props.user.emp_id, taskName: title, empName: this.props.user.emp_name, projectId: this.props.project_details.pid, companyId: this.props.comp_id} })
        .then(res => {
            if(res.status === 200){
                alert("Task Deleted")
            }else{
                alert("Something went wrong.")
            }
        })
        .catch(error => console.log(error))
    }

    undoDelete = () => {
        let oldTaskList = [...this.state.taskList]
        oldTaskList.push(this.state.deletedTask)
        clearTimeout(this.state.timer)
        this.setState({
            taskList: oldTaskList,
            deletedTask: null,
            timer: null,
        })
    }

    handleViewChange = (view) => {
        this.setState({ view: view })
    }

    showTaskList = (taskList, employees) => {
        try{
            if (taskList.length !== 0) {
                return taskList.map(task => employees.find((emp) => (emp.emp_id === task.tas_emp_id)) && task.tas_loc_id !== null && <HandyCard
                    key={task.tas_id}
                    task_id={task.tas_id}
                    taskLoc={this.props.location_list.find(loc => loc.loc_id === task.tas_loc_id)}
                    editForm={this.viewEditForm}
                    is_active={task.tas_is_active}
                    title={task.tas_title}
                    start_date={task.tas_start_date}
                    end_date={task.tas_end_date}
                    start_time={task.tas_start_time}
                    end_time={task.tas_end_time}
                    task_type={task.tas_type}
                    isCheckedIn={!!task.checkin?true:false}
                    employeeDets={employees.find((emp) => (emp.emp_id === task.tas_emp_id))}
                    deleteTask={this.deleteTask}
                    isSupport={this.isSupport}
                    handleFormShow={this.handleFormShow}
                    handleCusFormsShow={this.handleCusFormsShow}
                    locList={this.props.location_list}
                    customerDets={this.props.customer_details[0]}
                    task={task} />).reverse()
            } else {
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center mt-4' style={{height: '300px',margin: 5}}>
                        <img src='./pie.png' alt='taskTab' style={{width: '10rem', height: '10rem'}}/>
                        <h5 style={{fontFamily: 'Lato',fontSize: 18,marginTop: 48,marginBottom: 28}}>No tasks found </h5>
                        <p style={{fontFamily: 'Lato',fontSize: 14}} className='text-muted text-center w-50'>We could not find any tasks in this projects. Please add a task and assign them to you employees.</p>
                    </div>
                )
            }
        } catch(err){
            console.log(err)
            return <AlertMUI severity="error">An unexpected error occured. Please contact support. ErrorCode: P0001</AlertMUI>
        }
        
    }

    viewEditForm = (id) => {
        const task = this.state.project_tasks.filter(task => id === task.tas_id)
        this.setState({
            task: task[0]
        })
    }

    searchTaskHandler = (e) => {
        var searchQuery = e.target.value
        console.log(searchQuery)
        this.setState({searchTerm: e.target.value})
    }

    searchTaskResults = (e) => {
        e.preventDefault()
        var searchTerm = new RegExp(this.state.searchTerm, 'i')
        console.log(searchTerm)
        let artSearchResults = this.state.taskList.map((art)=> {
            if(searchTerm.exec(art.tas_title) || searchTerm.exec(art.tas_type) || searchTerm.exec(art.tas_prequisites_name)){
                return art
            }
            else{
                return null   
            }
        }).filter((art)=> !!art)
        console.log(artSearchResults);

        this.setState({taskList_display: artSearchResults})
    }

    viewTasks = () => {
        this.setState({
            task: ""
        })
    }

    filterOutTask = () => {
        let task = { ...this.state.task }
        if (this.state.task.hasOwnProperty("tas_prequisites_task")) {
            const tas_name = this.state.project_tasks.filter(item => item.tas_id === this.state.task.tas_prequisites_task)[0]
            if (tas_name) {
                task["tas_prequisites_name"] = tas_name.tas_title

            }
        }
        return task
    }

    filterOutTaskLocation = () => {
         let locations = []

        if (this.state.task.tas_loc_id) {
            locations.push(this.props.location_list.filter(loc => loc.loc_id === this.state.task.tas_loc_id)[0])
        }
        if (this.state.task.tas_loc1_id) {
            locations.push(this.props.location_list.filter(loc => loc.loc_id === this.state.task.tas_loc1_id)[0])
        }
        if (this.state.task.tas_loc2_id) {
            locations.push(this.props.location_list.filter(loc => loc.loc_id === this.state.task.tas_loc2_id)[0])
        }

        return locations 

    }

    filterOutEmp = () => {
        console.log(this.state.task.tas_emp_id)
        return this.props.employees.find(emp => emp.emp_id === this.state.task.tas_emp_id).emp_id
    }

    showAlert = () => {
        if (this.state.timer) {
            return (
                <Alert variant="info" onClose={() => this.onDismissAlert()} style={{ marginTop: "10px" }} dismissible>
                    Task Deleted. <span className="undo" onClick={() => this.undoDelete()}>Undo?</span>
                </Alert>
            )
        }
    }

    onDismissAlert = () => {
        this.deleteRequest(this.state.deletedTask.tas_id,this.state.deletedTask.tas_title)
        clearTimeout(this.state.timer)
        this.setState({
            timer: null,
            deletedTask: null
        })
    }

    setTasks() {
        let tasks =  (this.props.user.type === 'Admin'|| this.props.user.type === 'Manager')?this.state.project_tasks:this.state.project_tasks.filter((tas)=> tas.tas_emp_id === this.props.user.emp_id)
        tasks = tasks.filter((task) => (task.tas_type !== 'Checklist' || task.tas_mm_id === null))
        if(this.state.selectedTab === 0) {
            tasks = tasks.filter((task) => (task.tas_is_active === 0 && moment(task.tas_start_date).isSameOrBefore(new Date()))) 
        } else if(this.state.selectedTab === 1) {
            tasks = tasks.filter((task) => (task.tas_is_active === 0 && moment(task.tas_start_date).isAfter(new Date())))
        } else if(this.state.selectedTab === 2) {
            tasks = tasks.filter((task) => (task.tas_is_active === 1))
        } else {
            tasks = []
        }
        this.setState({
            taskList: tasks,
            taskList_display: tasks,
            loading: false
        })
    }

    selectTaskTab(tabIndex) {
        this.setState({
            selectedTab: tabIndex
        })
    }

    async componentDidMount() {
        let project_tasks = await axios.get(APIURL + "/project/projTask", { params: { proj_id: this.props.project_details.pid } })
        let taskTypes = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.comp_id, employeeType: this.props.user.type } });
        this.setState({ project_tasks: project_tasks.data, taskType: taskTypes.data })
        this.setTasks()        
        console.log(this.props)
    }

    componentDidUpdate(_, prevState) {
        if(prevState.selectedTab !== this.state.selectedTab) {
            this.setTasks()
        }
    }

    render() {
        // console.log(this.props)
        // debugger;
        let content = null;
        if (this.state.task) {
            content = (
                <Dialog fullWidth={true} maxWidth={'sm'} open={!!this.state.task} onClose={() => this.setState({ task: null })}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>Edit Task</h4>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.setState({ task: null })}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        <TaskForm
                            edit
                            mapRender={"task"}
                            history={this.props.history}
                            formVals={this.filterOutTask()}
                            projList={[this.props.project_details]}
                            taskType={this.state.taskType}
                            taskList={this.state.project_tasks}
                            isFormValidated={this.props.isFormValidated}
                            locList={this.props.location_list}
                            empList={this.props.employees}
                            task_location={this.filterOutTaskLocation()}
                            task_emp={this.filterOutEmp()}
                            taskPriority={this.state.task.tas_priority}
                            taskIsBillable={this.state.task.tas_billable_by_default}
                            tSigReq={this.state.task.tas_required_sig}
                            taskComment={this.state.task.tas_notes}
                            taskContacts={this.state.task.taskContacts}
                            taskId={this.state.task.tas_id}
                            comp_id={this.props.comp_id}
                            user={this.props.user}
                        />
                    </DialogContent>
                </Dialog>
            )
        } else {
            content = (
                <div className="TasksPage">
                    <div className="TopPanel">
                        <TextField
                            onChange={this.searchTaskHandler}
                            value={this.state.searchTerm}
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton type="submit">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className='tasks_info'>
                        <div>
                            <h5>{this.state.tabs[this.state.selectedTab]} Tasks</h5>
                            {this.showAlert()}
                        </div>
                        <div className='task_button_group'>
                            <div style={{ opacity: '90%' }} className={`project_button ${this.state.tabs[this.state.selectedTab] === 'Ongoing' ? 'active' : 'inactive'}`} onClick={() => this.selectTaskTab(0)}>
                                <div>On Going</div>
                                {this.state.tabs[this.state.selectedTab] === 'Ongoing' && <div className='project_button_number'>{this.state.taskList && this.state.taskList?.filter((task) => this.props.employees.find((emp) => (emp.emp_id === task.tas_emp_id)) && this.props.location_list?.find(loc => loc.loc_id === task.tas_loc_id)).length}</div>}
                            </div>
                            <div style={{ opacity: '90%' }} className={`project_button ${this.state.tabs[this.state.selectedTab] === 'Upcoming' ? 'active' : 'inactive'}`} onClick={() => this.selectTaskTab(1)}>
                                <div>Upcoming</div>
                                {this.state.tabs[this.state.selectedTab] === 'Upcoming' && <div className='project_button_number'>{this.state.taskList && this.state.taskList.filter((task) => this.props.employees.find((emp) => (emp.emp_id === task.tas_emp_id)) && this.props.location_list?.find(loc => loc.loc_id === task.tas_loc_id)).length}</div>}
                            </div>
                            <div style={{ opacity: '90%' }} className={`project_button ${this.state.tabs[this.state.selectedTab] === 'Completed' ? 'active' : 'inactive'}`} onClick={() => this.selectTaskTab(2)}>
                                <div>Completed</div>
                                {this.state.tabs[this.state.selectedTab] === 'Completed' && <div className='project_button_number'>{this.state.taskList && this.state.taskList.filter((task) => this.props.employees.find((emp) => (emp.emp_id === task.tas_emp_id)) && this.props.location_list?.find(loc => loc.loc_id === task.tas_loc_id)).length}</div>}
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <TaskCalendar
                                tasks={this.state.taskList_display}
                                openingTime={new Date(1972, 0, 1, 0, 0, 0, 0)}
                                closingTime={new Date(1972, 0, 1, 23, 59, 59, 999)}
                                selectedDays={[]}
                                onTaskClick={(task) => this.viewEditForm(task.tas_id)}
                                onEditTask={this.viewEditForm}
                                view={this.state.view}
                                onViewChange={this.handleViewChange}
                                onShowSignPopover={() => { }}
                                onShowCusForms={this.handleCusFormsShow}
                                onShowForm={this.handleFormShow}
                                isSupport={this.isSupport}
                                onDeleteTask={this.deleteTask}
                                onUndoDelete={this.undoDelete}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                {this.state.loading &&
                    (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    )
                }
                {!this.state.loading && content}
                <ViewMTModal
                    user={this.props.user}
                    show={this.state.showForm}
                    handleClose={this.handleFormClose}
                    type={this.state.mtTask ? this.state.mtTask.mt_type : ''}
                    data={this.state.mtTask ? JSON.parse(this.state.mtTask.data) : {}}
                    mm_id={this.state.mtTask ? this.state.mtTask.monthly_monitoring_id : null}
                    cus_name={this.props.customer_details ? this.props.customer_details[0].cus_name : ''}
                    checkoutEmpDate={this.state.mtTask ? this.state.mtTask.checkoutEmpDate : ''}
                />
                <ViewFormModal 
                    forms={this.state.cusFormTask ? this.state.cusFormTask.customerForm : []}
                    customer_id={this.state.cusFormTask ? this.state.cusFormTask.customerForm[0]?.customer_id : ''}
                    customer_name={this.props.customer_details[0].cus_name}
                    task_id={this.state.cusFormTask ? this.state.cusFormTask.tas_id : ''}
                    taskDetails={this.state.cusFormTask ? this.state.cusFormTask : ''}
                    user_id={this.props.user.emp_id}
                    read={true}
                    show={this.state.showCusForms}
                    hide={this.handleCusFormsClose}
                />
            </div>
        )
    }
};

export default TaskPage;