import React, { useState, useEffect } from 'react';
import { Box, Typography, Radio, Stack, FormControlLabel, FormGroup, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const StartOfTheWeek = (props) => {
    const [selectedDay, setSelectedDay] = useState(null);
    const [originalDay, setOriginalDay] = useState(null);

    const handleRadioChange = (event) => {
        setSelectedDay(Number(event.target.value));
    }

    const handleSave = async () => {
        try {
            let data = { ...props.companyDetail, startoftheWeek: selectedDay, company_id: props.comp_id, emp_id: props.user_id };
            await axios.put(`${APIURL}/company`, { ...data });

            setOriginalDay(selectedDay);

            // activity log
            // const sha1 = require('sha1');
            // let sessionID = window.localStorage.getItem('Fw4_access_Token');
            // let hash = sha1(sessionID);
            // let log_data = {
            //     emp_id: props.user_id,
            //     actionType: 'Configuration Settings Changed',
            //     actionSubType: 'Start of the week changed to ' + selectedDay,
            //     sessionID: hash
            // }
            // await axios.post(`${APIURL}/activity_log`, log_data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        setSelectedDay(props.selectedDay);
        setOriginalDay(props.selectedDay);
    }, [props.selectedDay]);

    const daysList = [
        { id: 0, value: 'Sunday' },
        { id: 1, value: 'Monday' },
        { id: 2, value: 'Tuesday' },
        { id: 3, value: 'Wednesday' },
        { id: 4, value: 'Thursday' },
        { id: 5, value: 'Friday' },
        { id: 6, value: 'Saturday' }
    ];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Start of the Week
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            What is your payroll's start of the week?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Box sx={{ minHeight: 22 }}>
                        </Box>
                        <FormGroup>
                            <RadioGroup name="startOfWeek" value={selectedDay !== null ? selectedDay.toString() : ''} onChange={handleRadioChange}>
                                <Grid container spacing={0}>
                                    {daysList.map((day) => (
                                        <Grid item xs={3} key={day.id}>
                                            <FormControlLabel sx={{ height: 30 }} control={<Radio value={day.id.toString()} />} label={day.value} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </RadioGroup>
                        </FormGroup>
                        <SaveButton onClick={handleSave} disabled={selectedDay === originalDay} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default StartOfTheWeek;
