import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Alert, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef, gridClasses } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function ManagementReviewReport(props) {
  const [gridId, setGridId] = useState(40);
  const apiRef = useGridApiRef();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
  const [reportDetails, setReportDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    fetchData();
  }, [currentMonth]);

  const fetchData = async () => {
    axios
      .get(APIURL + "/jsreport/getAllReports", {
        params: { empID: props.user.emp_id },
      })
      .then((response) => {
        const report = response?.data?.reports.find((ele) => ele.report_name === "Management Review Report");
        setReportDetails(report);
        setIsFavorite(report ? (report.status === 1 ? true : false) : false);
      });

    axios
      .get(APIURL + "/reports/getManagementReview", {
        params: { company_id: props.user.comp_id, emp_id: props.user.emp_id, period: moment(currentMonth).format('MMM YYYY') },
      })
      .then((response) => {
        setData(response?.data.length !== 0 ? response?.data : []);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const columns = [
    { field: "cus_id", headerName: "Customer ID", hide: true },
    { field: "tas_id", headerName: "Task ID", hide: true },
    { field: "tas_mm_id", headerName: "MM ID", hide: true },
    { field: "cus_name", headerName: "Customer Name", width: 180 },
    { field: "support_coordinator", headerName: "Support Coordinator", width: 180 },
    { field: "DDDID", headerName: "DDD ID", width: 100 },
    { field: "visit_type", headerName: "Visit Type", width: 100 },
    {
      field: "follow_up_items",
      headerName: "Follow up items",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params?.value?.length > 0 ?
            params.value.map((item, index) => {
              return (
                <div>
                  <span style={{ marginRight: 10, padding: 5 }} className="badge bg-secondary text-white">{moment(item?.tas_created_at).format('MM/DD/YYYY')}</span>
                  {item?.tas_description}
                </div>
              )
            })
            :
            '-'
          }
        </div>
      )
    },
  ]

  let rows = data?.map((data, index) => {
    let taskData = {
      rowID: index,
      cus_id: data.cus_id,
      tas_id: data.tas_id,
      tas_mm_id: data.tas_mm_id,
      cus_name: data.cus_name,
      DDDID: data?.DDDID,
      support_coordinator: data.SCName ? data.SCName.map((emp) => emp.emp_first_name + ' ' + emp.emp_last_name).join(', ') : '',
      visit_type: data?.visit_type === 'home_visit' ? 'Home Visit'
        : data?.visit_type === 'face_to_face' ? 'Face-to-Face'
          : data?.visit_type === 'phone/video_contact' ? 'Phone/Video'
            : '-',
      follow_up_items: data?.follow_up_items
    };

    return taskData;
  });

  function onDateChange(newValue) {
    setCurrMonth(moment(newValue).startOf("month"));
  }

  const markFavourite = async (data, type) => {
    const payload = {
      report_id: data?.report_id,
      status: type,
      empID: props.user.emp_id
    };

    axios
      .put(APIURL + "/jsreport/markFavorite", payload)
      .then((response) => {
        if (response?.status === 200 && response?.data?.code === 200) {
          setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
        } else {
          setErrorText("Something went wrong");
        }

        fetchData();
      });
  }

  return (
    <div style={{ margin: 10, width: '75vw' }}>
      <div style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div id="report-main" style={{ marginBottom: 20 }}>
            <Button
              size="large"
              className="DayButton"
              onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
            >
              <i className="fas fa-arrow-left"></i>
            </Button>
            <LocalizationProvider
              class="DatePickerParent"
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Select Date"
                views={["year", "month"]}
                value={new Date(currentMonth)}
                onChange={(newValue) => {
                  onDateChange(newValue);
                }}
                slotProps={{ textField: { variant: 'standard' } }}
              />
            </LocalizationProvider>
            <Button
              size="large"
              className="DayButton"
              onClick={() => onDateChange(currentMonth.add(1, "month"))}
            >
              <i className="fas fa-arrow-right"></i>
            </Button>
          </div>
          <div>
            <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
              <img
                src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                alt="barchart"
                style={{
                  fontFamily: "Lato",
                  width: "1.5rem",
                  height: "1.5rem",
                  cursor: "pointer",
                  margin: 5
                }}
                onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
              />
            </Tip>
          </div>
        </div>
      </div>
      <div>
        <div style={{ height: '70vh' }}>
          <ControlledDataGrid
            gridId={gridId}
            user={props.user}
            initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            getRowId={(row) => row.rowID}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
                whiteSpace: "pre-line",
              },
            }}
            loading={isLoading}
          />
        </div>
      </div>
      <Snackbar
        open={successText !== ""}
        autoHideDuration={30000}
        onClose={() => setSuccessText("")}
      >
        <Alert
          sx={{ width: "100%" }}
          severity={"success"}
          elevation={6}
          variant="filled"
        >
          {successText}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={() => setSuccessText("")}
          >
            <CloseIcon />
          </IconButton>
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorText !== ""}
        autoHideDuration={30000}
        onClose={() => setErrorText("")}
      >
        <Alert
          sx={{ width: "100%" }}
          severity={"error"}
          elevation={6}
          variant="filled"
        >
          {errorText}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={() => setErrorText("")}
          >
            <CloseIcon />
          </IconButton>
        </Alert>
      </Snackbar>
    </div>
  );
}