import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { createWorkWeek } from './WorkWeek';
import { createWorkDay } from './WorkDay';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Link, FormControl, Select, MenuItem, Box, Button, ButtonGroup, Autocomplete, TextField, InputLabel, ListItemText, Snackbar, Typography, styled  } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ScheduleTaskSessionModal from './Modals/ScheduleTaskSessionModal';
import EditTaskSessionModal from './Modals/EditTaskSessionModal';
import TaskAttendanceModal from './Modals/TaskAttendanceModal';
import ReccurrenceModal from './Modals/RecurrenceModal';
import TaskNotesModal from './Modals/TaskNotesModal';
import axios from 'axios';
import { APIURL } from '../../config';
import CloseIcon from '@mui/icons-material/Close';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LoopIcon from '@mui/icons-material/Loop';
import IconButton from '@mui/material/IconButton';
import LoadingSkeleton from './LoadingSkeleton';
import './Scheduler.css';
import { useReactToPrint } from 'react-to-print';
import { Grid } from '@mui/material';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const ServiceProviderScheduler = (props) => {
  const [selectedView, setSelectedView] = useState('resource');
  const [isScheduleTaskSessionModalOpen, setIsScheduleSessionModalOpen] = useState(false); // State for Schedule Task Session modal
  const [isEditTaskSessionModalOpen, setIsEditTaskSessionModalOpen] = useState(false); // State for Assign Customer modal
  const [isTaskAttendanceModalOpen, setIsTaskAttendanceModalOpen] = useState(false); // State for TaskAttendance modal
  const [isRecurrenceModalOpen, setIsRecurrenceModalOpen] = useState(false); // State for Recurrence modal
  const [isTaskNotesModalOpen, setIsTaskNotesModalOpen] = useState(false); // State for Task Notes modal

  const [resources, setResources] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]); 
  const [schedules, setSchedules] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);

  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedTaskCustomer, setSelectedTaskCustomer] = useState(null);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  const [slotScheduleInfo, setSlotScheduleInfo] = useState({});

  const [dataset, setDataset] = useState([]);

  const [openingTime, setOpeningTime] = useState(new Date(1972, 0, 1, 0, 0, 0, 0));
  const [closingTime, setClosingTime] = useState(new Date(1972, 0, 1, 23, 59, 59, 999));
  const [selectedDays, setSelectedDays] = useState([]);

  const [draggedSchedule, setDraggedSchedule] = useState(null);
  const [view, setView] = useState('day');

  const [isLoading, setIsLoading] = useState(true);

  const isAdmin = props.user.type === 'Admin';
  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleOpenScheduleTaskSessionModal = () => {
    setIsScheduleSessionModalOpen(true);
  };

  const handleCloseScheduleTaskSessionModal = () => {
    setIsScheduleSessionModalOpen(false);
  };

  const handleOpenEditTaskSessionModal = () => {
    if(isAdmin)
      setIsEditTaskSessionModalOpen(true);
  };

  const handleCloseEditTaskSessionModal = () => {
    setIsEditTaskSessionModalOpen(false);
  };

  const handleOpenTaskAttendanceModal = () => {
    setIsTaskAttendanceModalOpen(true);
  };

  const handleCloseTaskAttendanceModal = () => {
    setIsTaskAttendanceModalOpen(false);
  };

  const handleOpenRecurrenceModal = () => {
    setIsRecurrenceModalOpen(true);
  };

  const handleCloseRecurrenceModal = () => {
    setIsRecurrenceModalOpen(false);
  };

  const handleOpenTaskNotesModal = () => {
    setIsTaskNotesModalOpen(true);
  };

  const handleCloseTaskNotesModal = () => {
    setIsTaskNotesModalOpen(false);
  };

  const getData = async () => {
    try {
      const resources = await axios.get(APIURL + '/resources/company/' + props.comp_id);
      const employees = await axios.get(APIURL + '/employees', { params: { comp_id: props.comp_id } });
      const customers = await axios.get(APIURL + '/customers/v2/associated', { params: { company_id: props.comp_id, employee_id: props.user.emp_id } });
      const schedules = await axios.get(APIURL + '/master_schedule/company/' + props.comp_id);
      const sessions = await axios.get(APIURL + '/sessions/company/' + props.comp_id);
      const taskTypes = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: props.comp_id, employeeType: props.user.type } })

      console.log(customers.data);
      
      setResources(resources.data);
      setEmployees(employees.data);
      setCustomers(customers.data?.filter(customer =>( customer.cus_status === 'Active' || customer.cus_status === 'Prospect' )));
      setSchedules(schedules.data.filter(schedule => schedule.resource_id === null));
      setSessions(sessions.data);
      setTaskTypes(taskTypes.data);
      setIsLoading(false);

      console.log(schedules.data);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const reloadScheduleData = async () => {
    try {
      const schedules = await axios.get(APIURL + '/master_schedule/company/' + props.comp_id);
      
      setSchedules(schedules.data.filter(schedule => schedule.resource_id === null));
      setSelectedSchedule(schedules.data.find(schedule => schedule.schedule_id === selectedSchedule.schedule_id));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const reloadSessionData = async () => {
    try {
      const sessions = await axios.get(APIURL + '/sessions/company/' + props.comp_id);
      
      setSessions(sessions.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  
  const setData = (filteredSchedules, resource = false) => {
    setDataset(filteredSchedules.map((schedule, index) => ({
      id: index,
      title: schedule.schedule_name,
      start: new Date(schedule.start_time),
      end: new Date(schedule.end_time),
      schedule: schedule,
      tooltip: '',
      resourceId: selectedView === 'employee' ? schedule.employee_ids[0] : undefined,
      isDraggable: schedule.employee_ids.includes(props.user.emp_id) || isAdmin
    })));
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!isLoading)
      setSelectedView('employee');
  }, [isLoading]);

  useEffect(() => {
    let filteredSchedules = schedules;
    
    switch (selectedView) {
      case 'all':        
        setData(filteredSchedules);
        break;
      case 'employee':
        let filteredEmployees = employees.slice(0, 10);

        filteredSchedules = filteredSchedules.filter(schedule => filteredEmployees.map(employee => employee.id).includes(schedule.employee_ids[0]));
        console.log(filteredSchedules);
        setData(filteredSchedules);
        setSelectedEmployees(filteredEmployees);        
        break;
      case 'customer':
        let customer = customers[0];
        if(customer) {
          console.log(filteredSchedules);
          
          let customerSessions = sessions.filter(session => session.customer_id === customer.cus_id);          
          filteredSchedules = filteredSchedules.filter(schedule => customerSessions.map(session => session.schedule_id).includes(schedule.schedule_id));
          console.log(filteredSchedules);
          setData(filteredSchedules);
          setSelectedCustomers([customer]);
        }
        break;
      default:
        break;
    }
  }, [selectedView]);

  useEffect(() => {
    let filteredSchedules = schedules;
    let filteredEmployees = employees.slice(0, 10);

    filteredSchedules = filteredSchedules.filter(schedule => filteredEmployees.map(employee => employee.id).includes(schedule.employee_ids[0]));
    setData(filteredSchedules);
    setSelectedEmployees(filteredEmployees);
    console.log(filteredSchedules);
  }, [employees]);

  useEffect(() => {
    let filteredSchedules = schedules;
    if (selectedResources.length > 0) {
      filteredSchedules = filteredSchedules.filter(schedule => selectedResources.some(resource => resource.resource_id === schedule.resource_id));
      setData(filteredSchedules);
    }
  }, [selectedResources]);

  useEffect(() => {
    let filteredSchedules = schedules;
    if (selectedEmployees.length > 0) {
      filteredSchedules = filteredSchedules.filter(schedule => selectedEmployees.map(employee => employee.id).includes(schedule.employee_ids[0]));
      setData(filteredSchedules);
    } else {
      setDataset([]);
    }
  }, [selectedEmployees]);

  useEffect(() => {
    let filteredSchedules = schedules;
    if (selectedCustomers.length > 0) {
      let customerSessions = sessions.filter(session => selectedCustomers.some(customer => customer.cus_id === session.customer_id));
      filteredSchedules = filteredSchedules.filter(schedule => customerSessions.map(session => session.schedule_id).includes(schedule.schedule_id));
      setData(filteredSchedules);
    } else {
      setDataset([]);
    }
  }, [selectedCustomers]);
        
  useEffect(() => {
    if(props.user.comp_settings.find(setting => setting.feature_id === 80)) {
      let schedulerHours = JSON.parse(props.user.comp_settings.find(setting => setting.feature_id === 80).feature_value);
      setOpeningTime(new Date(schedulerHours.openingTime || '1972-01-01T00:00:00.000Z'));
      setClosingTime(new Date(schedulerHours.closingTime || '1972-01-01T23:59:59.999Z'));
      if(schedulerHours.days?.length > 0) {
        setSelectedDays(schedulerHours.days);
      }

    }

  }, [props.user]);

  useEffect(() => {
    let filteredSchedules = schedules;
    if(selectedView === 'all') {
      setData(filteredSchedules);
    } else if(selectedView === 'employee') {
      if(selectedEmployees.length > 0) {
        filteredSchedules = filteredSchedules.filter(schedule => selectedEmployees.map(employee => employee.id).includes(schedule.employee_ids[0]));
        setData(filteredSchedules);        
      }
    } else if(selectedView === 'customer') {
      if(selectedCustomers.length > 0) {
        let customerSessions = sessions.filter(session => selectedCustomers.some(customer => customer.cus_id === session.customer_id));
        filteredSchedules = filteredSchedules.filter(schedule => customerSessions.map(session => session.schedule_id).includes(schedule.schedule_id));
        setData(filteredSchedules);
      }
    }
  }, [schedules, sessions]);

  useEffect(() => {
    if(props.selectedView){
      setSelectedView(props.selectedView);
    }
  }, [props.selectedView]);

  const displayViewSelect = () => {
    let options;
    let getOptionLabel;
    let label;
    let onChange;
    let value;

    const StyledAutocomplete = styled(Autocomplete)({
      '& .MuiAutocomplete-tag': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '& .MuiInputBase-root': {
        flexWrap: 'nowrap',
      },
    });
  
    switch (selectedView) {
      case 'employee':
        options = employees;
        getOptionLabel = (option) => option.employee_name;
        label = 'Employee';
        onChange = (event, newValue) => setSelectedEmployees(newValue);
        value = selectedEmployees;
        break;
      case 'customer':
        options = customers;
        getOptionLabel = (option) => option.cus_name;
        label = 'Customer';
        onChange = (event, newValue) => setSelectedCustomers(newValue);
        value = selectedCustomers;
        break;
      default:
        return null;
    }
  
    return (
      <StyledAutocomplete
        size='small'
        options={options}
        getOptionLabel={getOptionLabel}
        style={{ width: 'auto', maxWidth: '1000px' }}
        multiple
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={onChange}
        renderOption={(props, option) => {
          let key = null;
          if (option.resource_id) {
            key = option.resource_id;
          } else if (option.id) {
            key = option.id;
          } else if (option.cus_id) {
            key = option.cus_id;
          }
          return (
            <li {...props} key={key}>
              <ListItemText primary={getOptionLabel(option)} />
            </li>
          )
        }}
        value={value}
        sx={{ width: value && value.length > 0 ? 300 + value.length * 30 : 300, minWidth: 300, mr: 2 }}
      />
    );
  }
  
  const onSelectEvent = (event) => {
    setSelectedSchedule(event.schedule);
    handleOpenEditTaskSessionModal();
  }

  const onSelectSlot = (slotInfo) => {
    if(slotInfo.action === 'select' && moment(slotInfo.start).isSame(slotInfo.end, 'day')) {
      setSlotScheduleInfo({
        startTime: slotInfo.start,
        endTime: slotInfo.end,
        resource: selectedResources.length > 0 ? selectedResources[0] : undefined
      });
      handleOpenScheduleTaskSessionModal();
    }
    
  }

  const addLineBreaks = (text, maxLineLength) => {
    const words = text.split(' ');
    let result = '';
    let line = '';
  
    for (const word of words) {
      if ((line + word).length > maxLineLength) {
        result += line + '\n\t';
        line = word + ' ';
      } else {
        line += word + ' ';
      }
    }
  
    return result + line;
  }
  
  const assignedCustomers = (event) => {
    let scheduledSessions = sessions.filter(session => session.schedule_id === event.schedule.schedule_id);
    let scheduleCustomers = scheduledSessions.map(session => session.customer_id);
    let assignedCustomers = customers.filter(customer => scheduleCustomers.includes(customer.cus_id));
    let customerNames = assignedCustomers.map(customer => customer.cus_name).join('\n\t');
    
    let notes = event.schedule.notes ? addLineBreaks(event.schedule.notes, 40) : '';
  
    return event.title + '\n' + event.tooltip + '\n' + 'Employees: \n\t' + event.schedule.employee_ids.map(employee_id => employees.find(employee => employee.id === employee_id)?.employee_name).join('\n\t') + '\n' +
    'Assigned Customers: \n\t' + (assignedCustomers.length > 0 ? customerNames : 'None') + '\nNotes:' + (notes ? '\n\t' + notes : '');
  }

  const onEventDrop = useCallback(async ({ event, start, end, resourceId }) => {
    const originalEvent = { ...event };
    event.start = start;
    event.end = end;

    if(resourceId !== event.resourceId && resourceId) {
      return;
    }

    let updatedSchedule = {
      ...event.schedule,
      date: moment(start).format('YYYY-MM-DD'),
      start_time: start,
      end_time: end
    }

    let schedulesCopy = [...schedules];
    let scheduleIndex = schedulesCopy.findIndex(schedule => schedule.schedule_id === event.schedule.schedule_id);
    schedulesCopy[scheduleIndex] = updatedSchedule;
    setSchedules(schedulesCopy);

    await axios.put(APIURL + '/master_schedule/' + event.schedule.schedule_id, updatedSchedule)
      .catch(error => {
        console.error("Failed to update event", error);
        setDataset((currentEvents) => {
          return currentEvents.map(evt => {
            if (evt.id === event.id) {
              return { ...evt, start: originalEvent.start, end: originalEvent.end };
            }
            return evt;
          });
        });
        setSchedules((currentSchedules) => {
          return currentSchedules.map(schedule => {
            if (schedule.schedule_id === event.schedule.schedule_id) {
              return { ...schedule, date: moment(originalEvent.start).format('YYYY-MM-DD'), start_time: originalEvent.start, end_time: originalEvent.end };
            }
            return schedule;
          });
        });
      });
  }, [dataset, schedules]);

  const onEventResize = useCallback(({ event, start, end }) => {
    const originalStart = event.start;
    const originalEnd = event.end;
    event.start = start;
    event.end = end;

    let updatedSchedule = {
      ...event.schedule,
      date: moment(start).format('YYYY-MM-DD'),
      start_time: start,
      end_time: end
    }

    let schedulesCopy = [...schedules];
    let scheduleIndex = schedulesCopy.findIndex(schedule => schedule.schedule_id === event.schedule.schedule_id);
    schedulesCopy[scheduleIndex] = updatedSchedule;
    setSchedules(schedulesCopy);
    
    axios.put(APIURL + '/master_schedule/' + event.schedule.schedule_id, updatedSchedule)
      .catch(error => {
        console.error("Failed to update event duration", error);
        setDataset((currentEvents) => {
          return currentEvents.map(evt => {
            if (evt.id === event.id) {
              return { ...evt, start: originalStart, end: originalEnd };
            }
            return evt;
          });
        });
        setSchedules((currentSchedules) => {
          return currentSchedules.map(schedule => {
            if (schedule.schedule_id === event.schedule.schedule_id) {
              return { ...schedule, date: moment(originalStart).format('YYYY-MM-DD'), start_time: originalStart, end_time: originalEnd };
            }
            return schedule;
          });
        });
      });
  }, [dataset, schedules]);

  const dragFromOutsideItem = useCallback(() => {
    return draggedSchedule;
  }, [draggedSchedule]);

  const CustomEvent = ({ event, onDelete }) => {
    let scheduledSessions = sessions.filter(session => session.schedule_id === event.schedule.schedule_id);
    let scheduleCustomers = scheduledSessions.map(session => session.customer_id);
    let assignedCustomers = customers.filter(customer => scheduleCustomers.includes(customer.cus_id));

    let style = view !== 'agenda' ? {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#fff',
      padding: '2px 4px',
      borderRadius: '2px',
      fontSize: '1rem',
    } : {
      display: 'flex',
      flexDirection: 'row',
      lineHeight: '2',
    };
    
    if(schedules.filter(schedule => schedule.schedule_id === event.schedule.schedule_id)[0]?.employee_ids?.length <= 0) {
      style.color = '#ff9800';
    }

    return (
      <div style={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%', flexDirection: view === 'agenda' ? 'column' : 'row', flexWrap: view !== 'month' ? 'wrap' : 'nowrap', overflow: 'hidden' }}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{event.title}</span>
          {view === 'agenda' &&
            <>
              <span>{event.schedule.resource_id ? resources.find(resource => resource.resource_id === event.schedule.resource_id)?.resource_name : ''}</span>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>{event.schedule.notes}</Typography>
            </>
          }
          {event.isDraggable &&
            <Box>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedSchedule(event.schedule);
                  handleOpenRecurrenceModal();
                }}
                size="small"
                style={{
                  height: '24px',
                  width: '24px',
                  marginLeft: 'auto',
                  color: view !== 'agenda' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)', // Adjust for visibility
                }}
              >
                <LoopIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedSchedule(event.schedule);
                  handleOpenTaskAttendanceModal();
                }}
                size="small"
                style={{
                  height: '24px',
                  width: '24px',
                  marginLeft: 'auto',
                  color: view !== 'agenda' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)', // Adjust for visibility
                }}
              >
                <PendingActionsIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={(e) => onDelete(e, event)}
                size="small"
                style={{
                  height: '24px',
                  width: '24px',
                  marginLeft: 'auto',
                  color: view !== 'agenda' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)', // Adjust for visibility
                }}
              >        
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box> 
          } 
        </Box>
        
        {view === 'agenda' &&
          <>
            <Box sx={{ width: '30%', alignSelf: 'center', }}>
              {event.schedule.employee_ids.map(employee_id =>
                <Typography variant="body2" sx={{ marginLeft: '10px', alignSelf: 'center' }}>{employees.find(employee => employee.id === employee_id)?.employee_name}<br/></Typography>
              )}
            </Box>
            <Box sx={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', alignSelf: 'center'}}>{`Assigned Customers: `}</Typography>
              <Grid container spacing={1} sx={{ alignSelf: 'center', width: 'auto', minWidth: '300px' }}>
              {assignedCustomers.map(customer =>
                <Grid item xs={assignedCustomers.length > 1 ? 6 : 12}>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    {scheduledSessions.find(session => session.customer_id === customer.cus_id && session.schedule_id === event.schedule.schedule_id)?.check_in_time && customer.cus_status === 'Active' ?
                      <Link 
                        key={customer.cus_id} 
                        href='#'
                        style={{ width: '100%', textAlign: 'center' }}
                        underline='none'
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedSchedule(event.schedule);
                          setSelectedTaskCustomer(customer);
                          handleOpenTaskNotesModal();
                        }}
                      >
                        {customer.cus_name}
                      </Link>
                      :
                      <Link
                        key={customer.cus_id}
                        href='#'
                        style={{ width: '100%', textAlign: 'center', color: 'black', textDecoration: 'none', cursor: 'default' }}
                        underline='none'
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        >
                        {customer.cus_name}
                      </Link>
                    }
                  </Typography>
                </Grid>
              )}
              </Grid>
            </Box>
          </>
        }
      </div>
    )
  };

  const handleEventDelete = async (e, event) => {
    e.stopPropagation();

    const confirmDelete = window.confirm('Are you sure you want to delete this event?');
    if (confirmDelete) {
      let schedulesCopy = [...schedules];
      let scheduleIndex = schedulesCopy.findIndex(schedule => schedule.schedule_id === event.schedule.schedule_id);
      schedulesCopy.splice(scheduleIndex, 1);
      setSchedules(schedulesCopy);

      await axios.delete(APIURL + '/master_schedule/' + event.schedule.schedule_id)
        .catch(error => {
          console.error("Failed to delete event", error);
          setDataset((currentEvents) => {
            return [...currentEvents, event];
          });
          setSchedules((currentSchedules) => {
            return [...currentSchedules, event.schedule];
          });
        });
    }
  }

  return (
    <Box sx={{ height: 'calc(100vh - 170px)', width: '100%' }}>
      {isLoading ? <LoadingSkeleton /> :
        <Box sx={{ height: 'calc(100vh - 170px)', width: '100%', display: 'flex', flexDirection: 'column', m: "10px" }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: "10px",
            }}
          >
            {/* Left-aligned FormControl */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl fullWidth size="small" sx={{ width: '200px', mr: 2 }}>
                <InputLabel id="view-select-label">View</InputLabel>
                <Select
                  value={selectedView}
                  onChange={(e) => setSelectedView(e.target.value)}
                  displayEmpty
                >
                  { isAdmin &&
                    <MenuItem value="all">All</MenuItem>
                  }
                  <MenuItem value="employee">Employee</MenuItem>
                  <MenuItem value="customer">Customer</MenuItem>
                </Select>
              </FormControl>
              {(selectedView  === 'customer' || (selectedView === 'employee' && isAdmin)) && displayViewSelect()}
            </Box>

            {/* Right-aligned ButtonGroup */}
            <ButtonGroup variant="contained" sx={{ mr: "20px" }}>
              <Button onClick={handlePrint}>Print</Button>
              <Button onClick={handleOpenScheduleTaskSessionModal}>Schedule Session</Button>
            </ButtonGroup>
          </Box>
          <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', height: ref.current ? 'calc(100vh - 170px)' : '100%', maxWidth: '100%', mr: '20px' }}>
            <Box sx={{ height: '100% !important', width: '100% !important' }}>
              <DnDCalendar
                slots={{
                  event: (props) => <CustomEvent {...props} onDelete={handleEventDelete} />
                }}
                localizer={localizer}
                events={dataset}
                startAccessor="start"
                endAccessor="end"
                tooltipAccessor={(event) => assignedCustomers(event)}
                onSelectEvent={onSelectEvent}
                onSelectSlot={onSelectSlot}
                selectable="ignoreEvents"
                resources={selectedView === 'employee' && selectedEmployees.length > 0 ? selectedEmployees : undefined}
                resourceIdAccessor={(r) => r.id}
                resourceTitleAccessor={(r) => r.employee_name}
                min={openingTime}
                max={closingTime}
                views={{
                  month: true,
                  week:  selectedDays.length > 0 ? createWorkWeek(selectedDays) : true,
                  day:  selectedDays.length > 0 ? createWorkDay(selectedDays) : true,
                  agenda: true
                }}
                handleDragStart={(event) => setDraggedSchedule(event)}
                onDropFromOutside={({ start, end, allDay }) => onEventDrop({event: draggedSchedule, start: start, end: end, resourceId: null})}
                onEventDrop={onEventDrop}
                onEventResize={onEventResize}
                draggableAccessor="isDraggable"
                dragFromOutsideItem={dragFromOutsideItem}
                resizable
                popup
                view={view}
                onView={(view) => setView(view)}
              />
            </Box>
          </Box>
          <ReccurrenceModal 
            open={isRecurrenceModalOpen}
            onClose={handleCloseRecurrenceModal}
            schedule={selectedSchedule}
            sessions={sessions}
            onSuccess={(message) => {setSuccess(true); setMessage(message)}}
            onError={(message) => {setError(true); setMessage(message)}}
            reloadScheduleData={reloadScheduleData}
            reloadSessionData={reloadSessionData}
            comp_id={props.comp_id}
            user={props.user}
          />
          <ScheduleTaskSessionModal
            open={isScheduleTaskSessionModalOpen}
            onClose={handleCloseScheduleTaskSessionModal}
            employees={employees}
            customers={customers}
            taskTypes={taskTypes}
            user={props.user}
            comp_id={props.comp_id}
            reloadScheduleData={reloadScheduleData}
            reloadSessionData={reloadSessionData}
            slotScheduleInfo={slotScheduleInfo}
            onSuccess={(message) => {setSuccess(true); setMessage(message)}}
            onError={(message) => {setError(true); setMessage(message)}}
            isAdmin={isAdmin}
          />
          <EditTaskSessionModal 
            open={isEditTaskSessionModalOpen} 
            onClose={handleCloseEditTaskSessionModal}
            schedule={selectedSchedule}
            customers={customers}
            employees={employees}
            sessions={sessions}
            resources={resources}
            reloadSessionData={reloadSessionData}
            reloadScheduleData={reloadScheduleData}
            onSuccess={(message) => {setSuccess(true); setMessage(message)}}
            onError={(message) => {setError(true); setMessage(message)}}
            isAdmin={isAdmin}
            user={props.user}
            comp_id={props.comp_id}
          />
          <TaskAttendanceModal
            open={isTaskAttendanceModalOpen}
            onClose={handleCloseTaskAttendanceModal}
            schedule={selectedSchedule}
            sessions={sessions}
            customers={customers}
            taskTypes={taskTypes}
            resources={resources}
            reloadSessionData={reloadSessionData}
            onSuccess={(message) => {setSuccess(true); setMessage(message)}}
            onError={(message) => {setError(true); setMessage(message)}}
            user={props.user}
            comp_id={props.comp_id}
          />
          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={success} autoHideDuration={6000} onClose={()=>{setSuccess(false)}}>
            <MuiAlert onClose={()=>{setSuccess(false)}} severity="success">
                {message}
            </MuiAlert>
          </Snackbar>
          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={()=>{setError(false)}}>
              <MuiAlert onClose={()=>{setError(false)}} severity="error">
                  {message}
              </MuiAlert>
          </Snackbar>
        </Box>
      }
    </Box>
  );
};

export default ServiceProviderScheduler;
