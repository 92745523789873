import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { APIURL } from '../../../config'
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DurationPicker from '../../../components/TimeManagement/DurationPicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const NewTimesheet = (props) => {

    const [empList, setEmpList] = useState([])
    const [projList, setProjList] = useState([])
    const [typeList, setTypeList] = useState([])
    const [isSupport, setIsSupport] = useState(props.user?.comp_settings.find(setting => setting.feature_id === 101 && setting.feature_value === 'primaryBusinessType') ? true : false)
    const [custID, setCustID] = useState(null)
    const [empTasksAvailable, setEmpTasksAvailable] = useState(false)
    const [currentProject, setCurrentProject] = useState(null)
    const [currentTaskType, setCurrentTaskType] = useState(null)
    const [currentTaskTitle, setCurrentTaskTitle] = useState(null)

    const [state, setState] = useState({
        tsData: [],
        csvAdd: false,
        add: false,
        edit: false,
        editInProg: false,
        addInProg: false,
        isLoading: false,
        duration: 'Bi-Weekly',
        num_entries: 20,
        addFormError: false, editFormError: false, success: false,
        editDataForm: { projDets: {}, taskDets: {}, empDets: { emp_id: null },start_time: moment().format('YYYY-MM-DDThh:mm'), end_time: moment().format('YYYY-MM-DDTHH:mm'), duration: '', tNotes: '' },
        addDataForm: { tTitle: '', proj_id: null, emp_id: props.emp_id, sDate: moment().format('YYYY-MM-DDTHH:mm'), eDate: moment().format('YYYY-MM-DDTHH:mm'), duration: null, tType: null, tNotes: '' },
        projects: [], typeList: [], location: [], employees: [], managedEmployees: [],
        currentEmployeeName: "None Selected",
        currentEmployee: null,
        empData: null,
        company: null,
        empTimeData: null,
        deleteTaskSuccess: false,
        payStubsList: [],
    })


    const getData = async () => {
        const emp_list = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: props.comp_id } });
        let empList = emp_list.data.filter((empl) => empl.id === props.emp_id).map((emp) => {
            return { title: emp.employee_name, value: emp.id }
        })
        const proj_list = await axios.get(APIURL + '/dropdowns/dropdownType/projectslist', { params: { comp_id: props.comp_id } });
        
        let newProjList = proj_list.data.filter((proj) => !!proj.empAssn && proj.empAssn.find((emp) => emp.id === empList[0].value)).map((pro) => {
            return { title: pro.pname, value: pro.pid, cus_id: pro.cus_id}
        })
        setProjList(newProjList)
        // const type_list = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: props.comp_id, employeeType: props.user.type } });
        // let typeList = type_list.data.map((type, indx) => {
        //     return { title: type, value: indx }
        // })
        let empTasks = await axios.get(APIURL + "/employees/EmployeeTasks", { params: { comp_id: props.comp_id, employee_id: props.emp_id } });
        if (empTasks.data.length !== 0) {
            let typeList = empTasks.data.map((type, indx) => {
                return { title: type.EntityValue, value: indx }
            })
            setEmpTasksAvailable(true)
            setTypeList(typeList)
        }

        const location_list = await axios.get(APIURL + '/dropdowns/dropdownType/locationslist', { params: { comp_id: props.comp_id } })

        setEmpList(empList)
        // setTypeList(typeList)
        
        setState({
            ...state,
            projects: proj_list.data,
            employees: emp_list.data,
            // typeList: type_list.data,
            location: location_list.data,
            isLoading: false
        })
    }

    useEffect(() => {
        getData().then();
        console.log(state.addDataForm);

    }, []);

    useEffect(() => {
        if(props.customer_id) {
            let customerProjects = projList.filter((pro) => pro.cus_id === props.customer_id)
            if(customerProjects.length > 0) {
                setCurrentProject(projList.filter((pro) => pro.cus_id === props.customer_id)[0])
            }
            let initialProject = projList.filter((pro) => pro.cus_id === props.customer_id)
            setState({
                ...state,
                addDataForm: { ...state.addDataForm, proj_id: initialProject.length > 0 ? initialProject[0].value : null },
            })
            setCustID(initialProject.length > 0 ? initialProject[0].cus_id : null);
            console.log({
                ...state,
                addDataForm: { ...state.addDataForm, proj_id: initialProject.length > 0 ? initialProject[0].value : null },
            });
        }
    }, [props.customer_id])

    useEffect(() => {
        async function fetchCusTasks() {
            if (!empTasksAvailable) {
                if (custID) {
                    let custTasks = await axios.get(APIURL + "/customers/CustomerTasks", { params: { comp_id: props.comp_id, customer_id: custID } })

                    if (custTasks.data.length !== 0) {
                        let typeList = custTasks.data.map((type, indx) => {
                            return { title: type.EntityValue, value: indx }
                        })
                        setTypeList(typeList)
                        setState({ ...state, addDataForm: { ...state.addDataForm, tType: null} })
                    }
                    else {
                        let type_list = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: props.comp_id, employeeType: props.user.type } })
                        let typeList = type_list.data.map((type, indx) => {
                            return { title: type, value: indx }
                        })
                        setTypeList(typeList)
                        setState({ ...state, addDataForm: { ...state.addDataForm, tType: null} })
                    }
                }
            }
        }
        fetchCusTasks();
    }, [custID])

    const validateAddForm = async () => {
        console.log(state.addDataForm, props.user.emp_id);
        let isVal = false
                        
        if(isSupport) {
            if (/^[0-9]{2}:[0-9]{2}$/.test(state.addDataForm.duration) && state.addDataForm.tType !== null && state.addDataForm.tType !== '' && state.addDataForm.sDate) {
                isVal = !isVal
            }
        }else {
            if (state.addDataForm.tType !== null && state.addDataForm.tType !== '' && !!state.addDataForm.proj_id &&
            (!!moment(state.addDataForm.eDate).isAfter(moment(state.addDataForm.sDate)))) {
                isVal = !isVal
            }
        }
        if (!!isVal) {

            console.log('passed validation test')
            setState({ ...state, addInProg: true })
            await addEntry();
            setState({ ...state, addInProg: false })
            // setState({add: false})
        } else {
            // Alert User 
            setState({ ...state, addFormError: true, add: true, addInProg: false })
            console.log('Validation Failed')
        }

    }

    const addEntry = async () => {
        let locDets = state.location.find((loc) => loc.id === state.projects.find((pro) => pro.pid === state.addDataForm.proj_id).proLoc[0].id)
        let minutes = isSupport ? (state.addDataForm.duration.split(":").map(function(x) { return parseInt(x, 10); }).reduce(function(a, b) { return a * 60 + b; })) : 1
        let data = {
            company_id: props.comp_id,
            tTitle: state.addDataForm.tTitle !== '' ? state.addDataForm.tTitle : `${props.user.emp_name} - ${moment().format('YYYY-DD-MM, HH:mm')} `,
            tDesc: 'Time Sheet Entry',
            tProject: state.addDataForm.proj_id,
            tPreReq: 'NULL',
            tType: state.addDataForm.tType,
            tStrtDate: isSupport?moment(state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD HH:mm:ss'):moment(state.addDataForm.sDate).format('YYYY-MM-DD HH:mm:ss'),
            tEndDate: isSupport?moment(state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss'):moment(state.addDataForm.eDate).format('YYYY-MM-DD HH:mm:ss'),
            tStartTime: isSupport?moment(state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD HH:mm:ss'):moment(state.addDataForm.sDate).format('YYYY-MM-DD HH:mm:ss'),
            tEndTime: isSupport?moment(state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss'):moment(state.addDataForm.eDate).format('YYYY-MM-DD HH:mm:ss'),
            tLoc: locDets.id,
            tActive: 1,
            tAssTo: props.user.emp_id,
            tPriority: 'Low',
            tIsBillable: true,
            tNotes: state.addDataForm.tNotes || 'N/A',
            user_id: props.user.emp_id,
        };
        let tasRes = await axios.post(APIURL + '/task', data)
        // let tasRes = {data:{code: 200, message: 'Task Insert Failed'}}
        if (tasRes.data.code === 200) {
            let empLocData = {
                assignedById: props.user.emp_id,
                projId: data.tProject,
                empId: data.tAssTo,
                empName: props.user.emp_name,
                companyId: props.comp_id,
                taskName: state.addDataForm.tTitle !== '' ? state.addDataForm.tTitle : `${props.user.emp_name} - ${moment().format('YYYY-DD-MM, HH:mm')} `,
                locId: data.tLoc,
                taskId: tasRes.data.id,
                emploc_lat: parseFloat(locDets.location_lat),
                emploc_lon: parseFloat(locDets.location_lon),
                start_time: data.tStartTime,
                end_time: data.tEndTime,
                timesheet_status: 1
            }
            console.log(empLocData);
            let tsRes = await axios.post(APIURL + '/timesheets', { ...empLocData })
            setCurrentTaskTitle(null)
            setCurrentProject(null)
            setCurrentTaskType(null)
            // let tsRes = {data:{code: 200, message: 'Timesheet Insert Failed'}}
            if (tsRes.data.code === 200) {
                console.log('Successfull', data, empLocData)
                setState({ ...state, isLoading: true })
                setState({ ...state, addFormError: false, success: true, add: false})
                setState({ ...state, isLoading: false });
                props.handleClose();
                props.rerender();
                return
            }
            else {
                console.log('Not Successfull', data, empLocData)
                setState({ ...state, addFormError: true, add: true, isLoading: false })
                return
            }
        } else {
            console.log('alert user of error')
            setState({ ...state, addFormError: true, add: true, isLoading: false })
            return
        }
    }

    const handleClose = () => {
        setState({
            ...state, addInProg: false, add: !state.add, addDataForm: { ...state.addDataForm, tType: null}, addDataForm: {
                tTitle: '', proj_id: null, emp_id: null, sDate: moment().format('YYYY-MM-DDTHH:mm'), eDate: moment().format('YYYY-MM-DDTHH:mm'), tType: null
            }
        });
        props.handleClose();
    }

    return (
            <Dialog open={props.show} >
                <DialogActions>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => {handleClose(); props.setTimesheetCustomerID()}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ paddingTop: '0px', width: 400 }}>
                    <div>
                        <h4>New Timesheet</h4>
                        <br></br>
                        <div>
                        <Autocomplete
                            id="combo-box-proj"
                            value={currentProject}
                            options={projList}
                            onChange={(e, newValue) => {
                                console.log(newValue)
                                setCurrentProject(newValue)
                                let value = projList.find((pro) => pro.value === newValue.value);
                                
                                if (value) {
                                    setCustID(value.cus_id);
                                    setState({ ...state, addDataForm: { ...state.addDataForm, proj_id: newValue.value } });
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionLabel={(option) => option.title}
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="PROJECT" variant="outlined" />}
                            error={state.addFormError && !state.addDataForm.proj_id} 
                            label="PROJECT" variant="outlined" />
                        <TextField
                            id="outlined"
                            label="TASK TITLE"
                            onChange={(e) => { 
                                setState({ ...state, addDataForm: { ...state.addDataForm, tTitle: e.target.value } })
                                setCurrentTaskTitle(e.target.value)
                            }}
                            value={currentTaskTitle}
                            variant="outlined"
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            />
                        <Autocomplete
                            id="combo-box-type"
                            // value={!!state.editDataForm.projDets?projList.find((pro)=> pro.value === state.editDataForm.projDets.pro_id):null}
                            value={currentTaskType}
                            options={typeList}
                            onChange={(e, newValue) => {
                                console.log(newValue)
                                setCurrentTaskType(newValue)
                                setState({ ...state, addDataForm: { ...state.addDataForm, tType: newValue.title} })
                            }}
                            getOptionLabel={(option) => option?.title}
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="TASK TYPE" variant="outlined" />}
                            error={(state.addFormError && !state.addDataForm.tType) || (!!state.addDataForm.tType &&  !typeList.some(item => item.title === state.addDataForm.tType))}
                            variant="outlined" />
                        {
                            isSupport ?
                            <>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Date"
                                        value={moment(state.addDataForm.sDate).isValid() ? moment(state.addDataForm.sDate) : null}
                                            format="YYYY-MM-DD"
                                        onChange={(date) => {
                                            setState({ ...state, addDataForm: { ...state.addDataForm, sDate: moment(date).format('YYYY-MM-DD') } })
                                        }}
                                        slotProps={{textField: {variant: 'outlined'}}}
                                        sx={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                                    />
                                </LocalizationProvider>
                                <DurationPicker
                                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                                    value={state.addDataForm.duration||''}
                                    onChange={(validDuration) => {setState({ ...state, addDataForm: { ...state.addDataForm, duration: validDuration } }) }}
                                /> 
                            </> :
                            <>
                                <TextField
                                    // required
                                    id="outlined"
                                    label="COMMENTS"
                                    onChange={(e) => { setState({ ...state, addDataForm: { ...state.addDataForm, tNotes: e.target.value } }) }}
                                    value={state.addDataForm.tNotes || ''}
                                    variant="outlined"
                                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                                    />
                                <TextField
                                    id="datetime-localA"
                                    label="CHECK-IN TIME"
                                    error={state.addFormError &&  !(Number(moment(state.addDataForm.eDate).format('YYYYMMDDHHmm')) - Number(moment(state.addDataForm.sDate).format('YYYYMMDDHHmm')) > 0 )}
                                    type="datetime-local"
                                    value={moment(state.addDataForm.sDate).format('YYYY-MM-DDTHH:mm')}
                                    onChange={(d) => {
                                        console.log('just changed', d.target.value)
                                        setState({ ...state, addDataForm: { ...state.addDataForm, sDate: d.target.value } })
                                    }}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}

                                />
                                <TextField
                                    id="datetime-localB"
                                    label="CHECK-OUT TIME"
                                    error={state.addFormError && !(Number(moment(state.addDataForm.eDate).format('YYYYMMDDHHmm')) - Number(moment(state.addDataForm.sDate).format('YYYYMMDDHHmm')) > 0 )}
                                    type="datetime-local"
                                    value={moment(state.addDataForm.eDate).format('YYYY-MM-DDTHH:mm')}
                                    onChange={(d) => {
                                        console.log('just changed', d.target.value)
                                        setState({ ...state, addDataForm: { ...state.addDataForm, eDate: d.target.value } })
                                    }}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                                />
                            </>
                        }
                        </div>
                        <Button disabled={state.addInProg} onClick={() => validateAddForm()} variant="primary"                     style={{
                            width: '100%',
                            padding: '5px',
                            margin: '5px',
                        }}>{state.addInProg ?
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : 'Save'}
                        </Button>
                        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={state.deleteTaskSuccess} autoHideDuration={6000} onClose={() => setState({ ...state, deleteTaskSuccess: false })}>
                            <MuiAlert onClose={() => setState({ ...state, deleteTaskSuccess: false })} severity="success">
                                Task Deleted!
                            </MuiAlert>
                        </Snackbar>
                    </div>
                </DialogContent>
            </Dialog>
    )
}

export default NewTimesheet