import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Divider, TextField, Grid, Autocomplete } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import CancelButton from '../Components/CancelButton';
import SaveButton from '../Components/SaveButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { APIURL } from '../../../config';
import ccs from 'countrycitystatejson';
import normalize from 'us-states-normalize';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';

const CompanyInformation = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [base64Logo, setBase64Logo] = useState('');
    const [errors, setErrors] = useState({ taxId: false });
    const [lastSavedCompanyDets, setLastSavedCompanyDets] = useState({});
    const [dropError, setDropError] = useState(null);

    useEffect(() => {
        setFormValues({ ...props.companyDetail });
        setLastSavedCompanyDets({ ...props.companyDetail });
    }, [props.companyDetail]);

    useEffect(async () => {
        if (formValues.com_logo) {
            if(typeof formValues.com_logo === 'string') {
                setBase64Logo(formValues.com_logo);
            } else {
                const reader = new FileReader();
                reader.onload = () => {                    
                    setBase64Logo(reader.result);
                };
                reader.readAsDataURL(formValues.com_logo);
            }
        } else {
            setBase64Logo('');
        }
    }, [formValues]);


    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormValues({
            ...formValues,
            [id]: value,
        });
        errors[id] = false;
    };

    const handleAutocompleteChange = (id, value) => {
        setFormValues({
            ...formValues,
            [id]: value,
        });
    };

    const handleCancel = () => {
        setFormValues({ ...props.companyDetail });
        setEditMode(false);
        setDropError(null);
    };

    const handleSave = async () => {
        let errors = {
            com_taxid: formValues.com_taxid?.split('').filter(c => c !== ' ').join('').length !== 9,
        }
        setErrors(errors);

        if (errors.com_taxid) return;

        try {
            const changes = Object.keys(lastSavedCompanyDets).filter(key => lastSavedCompanyDets[key] !== formValues[key]);

            if (changes.length === 0) {
                setEditMode(false);
                return;
            }

            let data = { ...formValues, company_id: props.comp_id, emp_id: props.user.emp_id };
            let response = await axios.put(APIURL + '/company', data);
            


            if (!lastSavedCompanyDets.com_logo && formValues.com_logo) {
                let formData = new FormData();
                formData.append('logo', formValues.com_logo);
                formData.append('company_id', props.comp_id);
                console.log('formData', formData.get('com_logo'));
                await axios.post(APIURL + '/company/logo/' + props.comp_id, formData, { headers: { "content-type": "multipart/form-data" }});
            } else if (lastSavedCompanyDets.com_logo && formValues.com_logo) {
                let formData = new FormData();
                formData.append('logo', formValues.com_logo);
                formData.append('company_id', props.comp_id);
                await axios.put(APIURL + '/company/logo/' + props.comp_id, formData, { headers: { "content-type": "multipart/form-data" }});
            } else if (lastSavedCompanyDets.com_logo && !formValues.com_logo) {
                await axios.delete(APIURL + '/company/logo/' + props.comp_id);
            }

            if (response.data.code === 200) {
                setLastSavedCompanyDets({ ...formValues });

                // Log activity
                // const sha1 = require('sha1');
                // let sessionID = window.localStorage.getItem('Fw4_access_Token');
                // let hash = sha1(sessionID);
                // let log_data = {
                //     emp_id: props.user_id,
                //     actionType: 'Company Information Changed',
                //     actionSubType: `${changes.join(', ')} ${changes.length === 1 ? 'was' : 'were'} changed`,
                //     sessionID: hash
                // };
                // await axios.post(APIURL + "/activity_log", log_data);
                
                setEditMode(false);
                setDropError(null);
            } else {
                console.error("Error updating company information");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const payCycleOptions = props.payrollData.map(fre => ({ title: fre.EntityValue, value: fre.EntityValue }));
    const daysoftheweek = [
        { title: 'Sunday', value: '0' },
        { title: 'Monday', value: '1' },
        { title: 'Tuesday', value: '2' },
        { title: 'Wednesday', value: '3' },
        { title: 'Thursday', value: '4' },
        { title: 'Friday', value: '5' },
        { title: 'Saturday', value: '6' }
    ];
    const states = ccs.getStatesByShort('US').filter(state => state.length > 2).map(state => ({ title: normalize(state), value: state }));
    const cities = formValues.loc_state ? ccs.getCities("US", normalize(formValues.loc_state, { returnType: 'name' })) : [];

    const getSubscriptionPlanLabel = (plan) => {
        switch (plan) {
            case 0:
                return "Free Trail";
            case 1:
                return "Trial Extended";
            case 5:
                return "Paid";
            case 10:
                return "Premium";
            default:
                return "No Subscription Found";
        }
    };

    const onDrop = (acceptedFiles, fileRejections) => {
        setDropError(null);

        if (fileRejections && fileRejections.length > 0) {            
            // If file rejected due to size or type
            const rejection = fileRejections[0].errors[0];
            if (rejection.code === 'file-too-large') {
                setDropError('File is too large. Max 1MB allowed.');
            } else if (rejection.code === 'file-invalid-type') {
                setDropError('Invalid file type. Allowed types: JPEG, PNG, SVG.');
            }
            return;
        }

        if (acceptedFiles && acceptedFiles[0]) {
            const file = acceptedFiles[0];

            setFormValues(prev => ({ ...prev, com_logo: file }));
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/svg+xml': ['.svg']
        },
        maxSize: 1 * 1024 * 1024, // 1MB
        onDrop,
        multiple: false
    });

    const removeLogo = () => {
        setFormValues(prev => ({ ...prev, com_logo: '' }));
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5">Company Information</Typography>
                {editMode ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CancelButton onClick={handleCancel} />
                        <SaveButton onClick={handleSave} />
                    </Box>
                ) : (
                    <IconButton onClick={() => setEditMode(true)}>
                        <Edit />
                    </IconButton>
                )}
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Company Name
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body1" sx={{ my: 1 }}>{formValues.com_name || ''}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Company Location
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {editMode ? (
                            <Box sx={{ display: 'flex', gap: 2, mr: "14px", ml: "-14px" }}>
                                <Box sx={{ flex: "8 1 0" }}>
                                    <TextField
                                        id="loc_addr_line1"
                                        value={formValues.loc_addr_line1 || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        placeholder="Address Line 1"
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ flex: "8 1 0" }}>
                                    <TextField
                                        id="loc_addr_line2"
                                        value={formValues.loc_addr_line2 || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        placeholder="Address Line 2"
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ flex: "5 1 0" }}>
                                    <Autocomplete
                                        id="loc_city"
                                        value={formValues.loc_city || ''}
                                        onChange={(e, value) => handleAutocompleteChange("loc_city", value.title)}
                                        fullWidth
                                        size="small"
                                        options={cities.map(city => ({ title: city, value: city, label: city }))}
                                        renderInput={(params) => <TextField {...params} placeholder="City" />}
                                    />
                                </Box>
                                <Box sx={{ flex: "3 1 0" }}>
                                    <Autocomplete
                                        id="loc_state"
                                        value={formValues.loc_state || ''}
                                        onChange={(e, value) => handleAutocompleteChange("loc_state", value.title)}
                                        fullWidth
                                        size="small"
                                        options={states.map(obj => ({...obj, label: obj.title}))}
                                        renderInput={(params) => <TextField {...params} placeholder="State" />}
                                        disableClearable
                                    />
                                </Box>
                                <Box sx={{ flex: "4 1 0" }}>
                                    <TextField
                                        id="loc_zip"
                                        value={formValues.loc_zip || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 10 }}
                                        placeholder="ZIP Code"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>
                                {formValues.loc_addr_line1 || ''} {formValues.loc_addr_line2 || ''} {formValues.loc_city || ''},{' '}
                                {formValues.loc_state || ''} {formValues.loc_zip || ''}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>Payroll Details</Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Company Tax ID
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="com_taxid"
                                value={formValues.com_taxid || ''}
                                onChange={handleInputChange}
                                fullWidth
                                inputProps={{ maxLength: 9 }}
                                size="small"
                                error={errors.com_taxid}
                                helperText={errors.com_taxid ? 'Invalid Tax ID' : ''}
                                sx={{ left: -14 }}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.com_taxid || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Pay Cycle
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>{formValues.payCycle || ''}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Start of the Week
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>{daysoftheweek.find(day => parseInt(day.value) === formValues.startoftheWeek)?.title || ''}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>Subscription Details</Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Subscription Plan
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body1" sx={{ my: 1 }}>{getSubscriptionPlanLabel(formValues.subscriptionPlan)}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Plan Start Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>
                            {moment(formValues.subscriptionStartDate).isValid() ? moment(formValues.subscriptionStartDate).utc().format('YYYY-MM-DD') : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Plan End Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>
                            {moment(formValues.subscriptionEndDate).isValid() ? moment(formValues.subscriptionEndDate).utc().format('YYYY-MM-DD') : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Trial Start Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>
                            {moment(formValues.trialStartDate).isValid() ? moment(formValues.trialStartDate).utc().format('YYYY-MM-DD') : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Trial End Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>
                            {moment(formValues.trialEndDate).isValid() ? moment(formValues.trialEndDate).utc().format('YYYY-MM-DD') : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>Logo</Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Company Logo
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {/* Fixed-size container to maintain height */}
                        <Box sx={{
                            width: '200px',
                            height: '200px',
                            border: editMode ? '2px dashed #ccc' : 'none',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            overflow: 'hidden',
                            backgroundColor: '#f9f9f9'
                        }}>
                            {editMode ? (
                                formValues.com_logo ? (
                                    <Box sx={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                                        <IconButton
                                            onClick={removeLogo}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                background: 'rgba(255,255,255,0.7)',
                                                '&:hover': { background: 'rgba(255,255,255,0.9)' }
                                            }}
                                            size="small"
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                        <img 
                                            src={base64Logo} 
                                            alt="Company Logo" 
                                            style={{ maxWidth: '100%', maxHeight: '100%' }} 
                                        />
                                    </Box>
                                ) : (
                                    <Box {...getRootProps()} sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        cursor: 'pointer'
                                    }}>
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <Typography>Drop file here or click to upload</Typography>
                                        ) : (
                                            <Typography>Drop file here or click to upload</Typography>
                                        )}
                                    </Box>
                                )
                            ) : (
                                formValues.com_logo ? (
                                    <img 
                                        src={base64Logo}
                                        alt="Company Logo" 
                                        style={{ maxWidth: '100%', maxHeight: '100%' }} 
                                    />
                                ) : (
                                    <Typography variant="body1" sx={{ my: 1 }}>No logo uploaded</Typography>
                                )
                            )}
                        </Box>
                        {dropError && (
                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                {dropError}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CompanyInformation;
