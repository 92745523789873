import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment";
import { TextField, Button, Box, Link, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./ChangeCapture.css";
import { Modal } from "react-bootstrap";
import makeStyles from "@mui/styles/makeStyles";
import Upload from "./Upload";
import Typography from "@mui/material/Typography";
import MonthlyTool from "../../../container/SupportCoordinator/MonthlyMonitoring/MonthlyTool/MonthlyTool";
import QuarterlyAnnualTool from "../../../container/SupportCoordinator/MonthlyMonitoring/QuarterlyAnnualTool/QuarterlyAnnualTool";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from "../../DataGrid/ControlledDataGrid";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#fff",
    backgroundColor: "#3FA7D6",
    "& .MuiButton-label": {
      color: "#fff", // or black
    },
  },
}));

export default function ChangeCapture(props) {
  const [gridId, setGridId] = useState(62);
  const apiRef = useGridApiRef();
  const ref = useRef();

  const [primaryBusinessType, setPrimaryBusinessType] = useState(null)
  const [claimsList, setClaimsList] = useState([]);
  const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
  const [isLoading, setLoading] = useState(true);
  const [nextStepStatus, setNextStep] = useState(false);
  const [csvAdd, setCsvAdd] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectValidClaims, setSelectValidClaims] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mt, setMT] = useState({
    type: '',
    data: null,
  });
  const [showMT, setShowMT] = useState(false);
  const handleCloseMT = () => setShowMT(false);

  const classes = useStyles();
  let rows = []

  const logToSegment = () => {
    let page = String(window.location.href).split("/").pop()
    console.log(page, window.location.href)
    if (page.includes("fieldworker") || page === "") {
      page = "Home";
    } else {
      page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
    }
    console.log(page)
    analytics.page(page + " : Charge Capture : Changes Captured", {
      title: page + " : Charge Capture : Changes Captured",
      path: page + " : Charge Capture : Changes Captured",
    }, {
      userId: String(props.user.emp_id),
    })
    console.log("logged to segment successfully")
  }

  useEffect(() => {
    logToSegment()
  }, [])

  useEffect(() => {
    async function fetchData() {
      let pBusinessType = props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].feature_id : 101

      axios.defaults.headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
        "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
        Authorization:
          "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
      };
      let preAuth = await axios.get(APIURL + "/preauth", {
        params: { companyID: props.comp_id, primaryBusinessType: pBusinessType, fromDate: moment(currentMonth).subtract(2, 'months').format('MM/DD/YYYY'), toDate: moment().endOf("month").format('MM/DD/YYYY') },
      });
      let preAuthData = preAuth.data;
      let id_num = 0;
      for (var i = 0; i < preAuthData.length; i++) {
        preAuthData[i].ChargeIdentified = '-';
        preAuthData[i].id = id_num;
        id_num += 1;
      }

      let currMonthData = preAuthData.filter((obj) => moment(obj.startDate).utc().format('MM/YYYY') === moment(currentMonth).utc().format('MM/YYYY'))

      let lastMonthData = preAuthData.filter((obj) => moment(obj.startDate).utc().format('MM/YYYY') === moment(currentMonth).subtract(1, 'months').format('MM/YYYY'))

      let custDiff = currMonthData.filter(obj1 => !lastMonthData.some(obj2 => obj1.cus_id === obj2.cus_id))
        .concat(lastMonthData.filter(obj2 => !currMonthData.some(obj1 => obj2.cus_id === obj1.cus_id)));

      custDiff.forEach((obj) => {
        if (currMonthData.some(data => data.cus_id === obj.cus_id)) {
          preAuthData.map((entry) => {
            if (entry.primaryID === obj.primaryID) {
              entry.ChargeIdentified = 'Customer added';
            }
          })
        }
        if (lastMonthData.some(data => data.cus_id === obj.cus_id)) {
          preAuthData.map((entry) => {
            if (entry.primaryID === obj.primaryID) {
              entry.ChargeIdentified = 'Customer removed';
            }
          })
        }
      })

      preAuthData.forEach((obj) => {
        if (obj.startDate && obj.ChargeIdentified === '-') {
          const startDate = moment(obj.startDate).utc();
          const endDate = moment(obj.endDate).utc();
          const isFirstDateOfMonth = startDate.date() === 1;
          const isLastDateOfMonth = endDate.date() === endDate.daysInMonth();

          if (!(isFirstDateOfMonth && isLastDateOfMonth)) {
            obj.ChargeIdentified = 'Partial Auth'
          }
        }
      })

      setClaimsList(preAuthData);
      setPrimaryBusinessType(pBusinessType);
      setLoading(false);
    }
    fetchData();
  }, [updateData]);

  useEffect(() => {
    async function sendToClaims(data, newList) {
      let claimsStatus = await axios.post(APIURL + "/claims", {
        entries: data,
        comp_id: props.user.comp_id,
        emp_id: props.user.emp_id,
        emp_name: props.user.emp_name,
        primaryBusinessType: primaryBusinessType
      });
      console.log(claimsStatus)
      setClaimsList(newList);
      setUpdateData(!updateData);
      setSelectValidClaims(false);
      setNextStep(false);
      setSelectionModel([]);
    }

    if (nextStepStatus) {
      if (selectionModel.length !== 0) {
        let dataToBeSent = claimsList.filter((claim) =>
          selectionModel.includes(claim.id)
        );
        let currClaimsList = claimsList;
        sendToClaims(dataToBeSent, currClaimsList);
      }
    }
  }, [nextStepStatus]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      let preAuth = await axios.get(APIURL + "/preauth", {
        params: { companyID: props.comp_id, primaryBusinessType: primaryBusinessType, fromDate: moment(currentMonth).subtract(2, 'months').format('MM/DD/YYYY'), toDate: moment().endOf("month").format('MM/DD/YYYY') },
      });
      let preAuthData = preAuth.data;
      let id_num = 0;
      for (var i = 0; i < preAuthData.length; i++) {
        preAuthData[i].ChargeIdentified = '-';
        preAuthData[i].id = id_num;
        id_num += 1;
      }

      let currMonthData = preAuthData.filter((obj) => moment(obj.startDate).utc().format('MM/YYYY') === moment(currentMonth).utc().format('MM/YYYY'))

      let lastMonthData = preAuthData.filter((obj) => moment(obj.startDate).utc().format('MM/YYYY') === moment(currentMonth).subtract(1, 'months').format('MM/YYYY'))

      let custDiff = currMonthData.filter(obj1 => !lastMonthData.some(obj2 => obj1.cus_id === obj2.cus_id))
        .concat(lastMonthData.filter(obj2 => !currMonthData.some(obj1 => obj2.cus_id === obj1.cus_id)));

      custDiff.forEach((obj) => {
        if (currMonthData.some(data => data.cus_id === obj.cus_id)) {
          preAuthData.map((entry) => {
            if (entry.primaryID === obj.primaryID) {
              entry.ChargeIdentified = 'Customer added';
            }
          })
        }
        if (lastMonthData.some(data => data.cus_id === obj.cus_id)) {
          preAuthData.map((entry) => {
            if (entry.primaryID === obj.primaryID) {
              entry.ChargeIdentified = 'Customer removed';
            }
          })
        }
      })

      preAuthData.forEach((obj) => {
        if (obj.startDate && obj.ChargeIdentified === '-') {
          const startDate = moment(obj.startDate).utc();
          const endDate = moment(obj.endDate).utc();
          const isFirstDateOfMonth = startDate.date() === 1;
          const isLastDateOfMonth = endDate.date() === endDate.daysInMonth();

          if (!(isFirstDateOfMonth && isLastDateOfMonth)) {
            obj.ChargeIdentified = 'Partial Auth'
          }
        }
      })

      setClaimsList(preAuthData);
      setLoading(false);
    }
    if (primaryBusinessType !== null) {
      fetchData();
    }
  }, [csvAdd, currentMonth]);

  useEffect(() => {
    if (selectValidClaims) {
      let readyToSubmitRows = rows
        .filter((r) => primaryBusinessType === 101 ? ((r.mt_status === "Approved" || r.ChargeIdentified === 'Customer added') && r.priorAuth && r.Status !== 'Sent to Claims') : (r.tas_ids && r.priorAuth && (r.cumulativeUnits <= r.remainingUnits)))
        .map((r) => r.id);
      setSelectionModel(readyToSubmitRows);
    }
    else {
      setSelectionModel([]);
    }
  }, [selectValidClaims]);

  useEffect(() => {
    if (primaryBusinessType === 101) {
      getSC_ChangedList();
    }
    else {
      getSP_ChangedList();
    }
  }, [updateData]);

  useEffect(() => {
    if (mt.data)
      setShowMT(true);
  }, [mt]);

  function handleConfirmModal() {
    setShowModal(!showModal);
  }

  function toggleCSVAdd() {
    setCsvAdd(!csvAdd);
  }

  function renderCSVModal() {
    return (
      <Upload
        showUpload={csvAdd}
        setCsvAdd={setCsvAdd}
        toggleCSVAdd={toggleCSVAdd}
        user={props.user}
      ></Upload>
    );
  }

  function onDateChange(newValue) {
    setCurrMonth(moment(newValue).startOf("month"));
  }

  function getSelectButton() {
    return (
      <div className="flex-item-d">
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button
            variant="contained"
            className={classes.button}
            fullWidth={true}
            onClick={() => {
              setSelectValidClaims(true);
            }}
            disabled={isLoading || claimsList.filter(
              (claim) =>
                (primaryBusinessType === 101 ? ((claim.mt_status === "Approved" || claim.ChargeIdentified === 'Customer added') && claim.priorAuth && !claim.tas_preAuth_id) : (claim.taskIds && claim.priorAuth && (claim.cumulativeUnits <= claim.remainingUnits))) &&
                moment(claim.startDate).utc().month() === currentMonth.month() &&
                moment(claim.startDate).utc().year() === currentMonth.year()
            ).length === 0}
          >
            Select Fileable Claims
          </Button>
        </Box>
      </div>
    );
  }

  function getUnselectButton() {
    return (
      <div className="flex-item-d1">
        <Box display="flex">
          <Button
            variant="contained"
            className={classes.button}
            sx={{ width: "80%" }}
            onClick={() => {
              setSelectValidClaims(false);
              setSelectionModel([]);
            }}
            style={{
              backgroundColor: "white",
              color: "grey"
            }}
          >
            Unselect All
          </Button>
        </Box>
      </div>
    );
  }

  function getSendButton() {
    return (
      <div className="flex-item-d2">
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => {
              handleConfirmModal();
            }}
            style={{
              backgroundColor: "green",
            }}
          >
            Send
          </Button>
        </Box>
      </div>
    );
  }

  // Columns for Support Co-ordination
  const SC_columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      hide: true,
    },
    {
      field: 'ChargeIdentified',
      headerName: 'Charge Identified',
      width: 125,
    },
    {
      field: "DDDID",
      headerName: "DDDID",
      width: 80,
      hide: false,
      editable: false,
    },
    {
      field: "PatientName",
      headerName: "Patient Name",
      width: 150,
      hide: false,
      editable: false,
    },
    {
      field: "DateOfBirth",
      headerName: "DOB",
      width: 100,
      hide: false,
      editable: false,
    },
    {
      field: "Gender",
      headerName: "Gender",
      width: 65,
      hide: false,
      editable: false,
    },
    {
      field: "memberMedicaid",
      headerName: "Medicaid ID",
      width: 120,
      hide: false,
      editable: false,
    },
    {
      field: "priorAuth",
      headerName: "Prior Auth",
      width: 110,
      hide: false,
      editable: false,
    },
    {
      field: "procedureCode",
      headerName: "Proc Code",
      width: 100,
      hide: false,
    },
    {
      field: "DiagnosisCode",
      width: 90,
      hide: false,
      editable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Diagnosis Code
          </div>
        );
      },
    },
    {
      field: "startDate",
      width: 100,
      hide: false,
      editable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Task Start Date
          </div>
        );
      },
    },
    {
      field: "endDate",
      width: 100,
      hide: false,
      editable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Task End Date
          </div>
        );
      },
    },
    {
      field: "authUnits",
      width: 50,
      hide: false,
      editable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Auth Units
          </div>
        );
      },
    },
    {
      field: "authAmount",
      width: 70,
      hide: false,
      editable: false,
      align: "right",
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Auth Amount
          </div>
        );
      },
    },
    {
      field: "total_charges",
      width: 70,
      hide: false,
      align: "right",
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Total Charges
          </div>
        );
      },
    },
    {
      field: "task_id",
      headerName: "Task ID",
      width: 120,
      hide: true,
    },
    {
      field: "tas_title",
      headerName: "Task Title",
      width: 120,
      hide: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <div class="crop">{params.value}</div>
          </Tooltip>
        )
      },
    },
    {
      field: "tas_type",
      headerName: "Task Type",
      width: 120,
      hide: false,
    },
    {
      field: "tas_start_date",
      headerName: "Task Date",
      width: 120,
      hide: false,
    },
    {
      field: "taskStatus",
      headerName: "Status",
      width: 120,
      hide: false,
    },
    {
      field: "Status",
      headerName: "Claim Status",
      width: 120,
      hide: false,
    },
    {
      field: "tas_id",
      headerName: "MMT",
      width: 70,
      hide: false,
      renderCell: (params) => {
        const onClick = async () => {
          const mm = await axios.get(APIURL + "/monthlymonitoring/" + params.value);
          if (mm.data !== '') {
            const mmt = (mm.data[0]);
            setMT(mmt);
          }
        }
        return (
          <div>
            {!!params.value &&
              <div style={{ color: '#3FA7D6', padding: '10px' }}>
                <Link href="#" underline="hover" onClick={onClick}>
                  <i class="far fa-sticky-note" style={{ marginRight: '5px' }}></i>
                  View
                </Link>
              </div>
            }
            {Object.keys(params.row).length !== 0 && !params.value &&
              <div style={{ color: '#3FA7D6', padding: '10px' }}>
                <Link style={{ textDecoration: 'none', color: 'grey', cursor: 'auto' }} href="#">
                  <i class="far fa-sticky-note" style={{ marginRight: '5px' }}></i>
                  View
                </Link>
              </div>}
          </div>
        )
      }
    },
    {
      field: "mt_status",
      headerName: "MT Status",
      width: 120,
      hide: false,
    },
    {
      field: "errCode",
      headerName: "Error Code",
      width: 120,
      hide: false,
    },
    {
      field: "errDescription",
      headerName: "Error Description",
      width: 140,
      hide: false,
    },
  ]

  function getSC_ChangedList() {
    let displayList = claimsList;

    displayList = displayList.filter(
      (claim) =>
        moment(claim.startDate).utc().month() === currentMonth.month() &&
        moment(claim.startDate).utc().year() === currentMonth.year() &&
        claim.ChargeIdentified !== '-'
    );

    rows = displayList.map((claim) => {
      let claimDets = {
        hierarchy: [claim.ChargeIdentified, claim.cus_name + ': ' + moment(claim.startDate).utc().format('MM/DD') + ' - ' + moment(claim.endDate).utc().format('MM/DD') + ' (' + claim.id + ')'],
        id: claim.id,
        DDDID: Array.isArray(claim.DDDID) ? claim.DDDID[0] : claim.DDDID,
        PatientName: claim.cus_name,
        DateOfBirth: !!claim.dob ? moment(claim.dob).utc().format("L") : '',
        Gender: claim.gender ? claim.gender : '',
        memberMedicaid: claim.memberMedicaid,
        priorAuth: claim.priorAuth,
        procedureCode: claim.procedureCode,
        startDate: moment(claim.startDate).isValid() ? moment(claim.startDate).utc().format("L") : '',
        endDate: moment(claim.endDate).isValid() ? moment(claim.endDate).utc().format("L") : '',
        DiagnosisCode: claim.PrimaryDiagnosisCode,
        authUnits: claim.authUnits,
        authAmount: claim.authAmount ? claim.authAmount.toFixed(2) : '',
        total_charges: claim.authAmount ? claim.authAmount.toFixed(2) : '',
        Status: !!claim.tas_preAuth_id ? 'Sent to Claims' : '',
        tas_title: claim.tas_title,
        tas_type: claim.tas_type,
        tas_start_date: !!claim.tas_start_date ? moment(claim.tas_start_date).utc().format("L") : '',
        taskStatus: !!claim.tas_title ? 'Task Matched' : '',
        mmt: claim.tas_id,
        errCode: claim.errCode,
        errDescription: claim.errDescription === 'Null' ? '' : claim.errDescription,
        tas_id: claim.tas_id,
        task_id: claim.tas_id,
        mt_status: claim.tas_mm_status,
        ChargeIdentified: claim.ChargeIdentified ? claim.ChargeIdentified : 'Partial Auth'
      };
      return claimDets;
    });

    let claimsListFin = (
      <Box sx={{ height: '100vh - 500px', maxHeight: '100vh - 500px', width: 1, display: 'flex', flexDirection: 'column', }}>
        <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
          <DataGridPro
            // gridId={gridId}
            // user={props.user}
            // apiRef={apiRef}
            initialState={{ sorting: { sortModel: [{ field: "mt_status", sort: "desc" }] }, pagination: { paginationModel: { pageSize: 25 } } }}
            onRowSelected={(params) => console.log(params)}
            justifyContent="center"
            checkboxSelection={true}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(ids) => {
              console.log(ids);
              // setSelectionModel(ids);
            }}
            rows={rows}
            columns={SC_columns}
            // columnVisibilityModel={{
            //   id: false,
            //   task_id: false,
            // }}
            slots={{
              toolbar: GridToolbar,
            }}
            isRowSelectable={(params) => ((params.row.mt_status === "Approved" || params.row.ChargeIdentified === 'Customer added') && params.row.priorAuth && params.row.Status !== 'Sent to Claims')}
            pagination
            pageSizeOptions={[25, 50, 100]}
            treeData
            getTreeDataPath={(row) => row.hierarchy}
            disableRowSelectionOnClick
          />
        </div>
      </Box>
    );
    return claimsListFin;
  }

  // Columns for Service Providers
  const SP_columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      hide: true,
    },
    {
      field: 'ChargeIdentified',
      headerName: 'Charge Identified',
      width: 125,
    },
    {
      field: "DDDID",
      headerName: "DDDID",
      width: 80,
      hide: false,
      editable: false,
    },
    {
      field: "PatientName",
      headerName: "Patient Name",
      width: 150,
      hide: false,
      editable: false,
    },
    {
      field: "DateOfBirth",
      headerName: "DOB",
      width: 100,
      hide: false,
      editable: false,
    },
    {
      field: "Gender",
      headerName: "Gender",
      width: 65,
      hide: false,
      editable: false,
    },
    {
      field: "memberMedicaid",
      headerName: "Medicaid ID",
      width: 120,
      hide: false,
      editable: false,
    },
    {
      field: "priorAuth",
      headerName: "Prior Auth",
      width: 110,
      hide: false,
      editable: false,
    },
    {
      field: "procedureCode",
      headerName: "Proc Code",
      width: 100,
      hide: false,
    },
    {
      field: "DiagnosisCode",
      width: 90,
      hide: false,
      editable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Diagnosis Code
          </div>
        );
      },
    },
    {
      field: "startDate",
      width: 100,
      hide: false,
      editable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Task Start Date
          </div>
        );
      },
    },
    {
      field: "endDate",
      width: 100,
      hide: false,
      editable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Task End Date
          </div>
        );
      },
    },
    {
      field: "authUnits",
      width: 50,
      hide: props.user.type === 'Admin' ? false : true,
      editable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Auth Units
          </div>
        );
      },
    },
    {
      field: "authAmount",
      width: 70,
      hide: props.user.type === 'Admin' ? false : true,
      editable: false,
      align: "right",
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Auth Amount
          </div>
        );
      },
    },
    {
      field: "total_charges",
      width: 70,
      hide: props.user.type === 'Admin' ? false : true,
      align: "right",
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Total Charges
          </div>
        );
      },
    },
    {
      field: "taskStatus",
      headerName: "Status",
      width: 120,
      hide: false,
    },
    {
      field: "tas_ids",
      headerName: "Task IDs",
      width: 120,
      hide: false,
    },
    {
      field: "tas_type",
      headerName: "Task Type",
      width: 120,
      hide: false,
    },
    {
      field: "cumulativeUnits",
      width: 55,
      hide: props.user.type === 'Admin' ? false : true,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Units used
          </div>
        );
      },
    },
    {
      field: "cumulativeMinutes",
      width: 80,
      hide: true,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Minutes spent
          </div>
        );
      },
    },
    {
      field: "remainingUnits",
      width: 80,
      hide: props.user.type === 'Admin' ? false : true,
      renderHeader: (params) => {
        return (
          <div
            style={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
              verticalAlign: "bottom",
            }}
          >
            Units remaining
          </div>
        );
      },
    },
    {
      field: "Status",
      headerName: "Claim Status",
      width: 120,
      hide: false,
    },
    {
      field: "errCode",
      headerName: "Error Code",
      width: 120,
      hide: false,
    },
    {
      field: "errDescription",
      headerName: "Error Description",
      width: 140,
      hide: false,
    },
  ]

  function getSP_ChangedList() {
    let displayList = claimsList;

    displayList = displayList.filter(
      (claim) =>
        moment(claim.startDate).utc().month() === currentMonth.month() &&
        moment(claim.startDate).utc().year() === currentMonth.year() &&
        claim.ChargeIdentified !== '-'
    );

    rows = displayList.map((claim) => {
      let claimDets = {
        hierarchy: [claim.ChargeIdentified, claim.cus_name + ': ' + moment(claim.startDate).utc().format('MM/DD') + ' - ' + moment(claim.endDate).utc().format('MM/DD') + ' (' + claim.id + ')'],
        id: claim.id,
        DDDID: Array.isArray(claim.DDDID) ? claim.DDDID[0] : claim.DDDID,
        PatientName: claim.cus_name,
        DateOfBirth: !!claim.dob ? moment(claim.dob).utc().format("L") : '',
        Gender: claim.gender ? claim.gender : '',
        memberMedicaid: claim.memberMedicaid,
        priorAuth: claim.priorAuth,
        procedureCode: claim.procedureCode,
        startDate: !!claim.startDate ? moment(claim.startDate).utc().format("L") : '',
        endDate: !!claim.endDate ? moment(claim.endDate).utc().format("L") : '',
        DiagnosisCode: claim.PrimaryDiagnosisCode,
        authUnits: claim.authUnits,
        authAmount: claim.authAmount ? claim.authAmount.toFixed(2) : '',
        total_charges: claim.authAmount ? claim.authAmount.toFixed(2) : '',
        Status: !!claim.tasks ? (!!claim.tasks[0] ? (!!claim.tasks[0].tas_preAuth_id ? 'Sent to Claims' : '') : '') : !!claim.tas_preAuth_id ? 'Sent to Claims' : '',
        taskStatus: claim.taskIds ? 'Tasks Matched' : '',
        tas_ids: claim.taskIds,
        tas_type: !!claim.tasks ? (!!claim.tasks[0] ? claim.tasks[0].tas_type : '') : claim.tas_type,
        cumulativeUnits: Math.round(claim.cumulativeUnits),
        cumulativeMinutes: claim.cumulativeMinutes,
        remainingUnits: Math.round(claim.remainingUnits),
        errCode: claim.errCode === 'None' ? '' : claim.errCode,
        errDescription: claim.errDescription === 'Null' ? '' : claim.errDescription,
        ChargeIdentified: claim.ChargeIdentified ? claim.ChargeIdentified : 'Partial Auth'
      };
      return claimDets;
    });

    let claimsListFin = (
      <Box sx={{ height: '100vh - 500px', maxHeight: '100vh - 500px', width: 1, display: 'flex', flexDirection: 'column', }}>
        <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
          <DataGridPro
            // gridId={gridId}
            // user={props.user}
            // apiRef={apiRef}
            initialState={{ sorting: { sortModel: [{ field: "tas_ids", sort: "desc" }] }, pagination: { paginationModel: { pageSize: 25 } } }}
            onRowSelected={(params) => console.log(params)}
            width={"100%"}
            justifyContent="center"
            checkboxSelection={true}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(ids) => {
              setSelectionModel(ids);
            }}
            style={{
              color: "#00000",
              maxHeight: "84%",
              maxWidth: "100%",
              overflow: "auto",
              display: "flex",
            }}
            rows={rows}
            columns={SP_columns}
            // columnVisibilityModel={{
            //   id: false,
            //   cumulativeMinutes: false,
            // }}
            slots={{
              toolbar: GridToolbar,
            }}
            isRowSelectable={(params) => params.row.tas_ids && params.row.priorAuth && (params.row.cumulativeUnits <= params.row.remainingUnits) && params.row.Status !== 'Sent to Claims'}
            pagination
            pageSizeOptions={[25, 50, 100]}
            treeData
            getTreeDataPath={(row) => row.hierarchy}
            disableRowSelectionOnClick
          />
        </div>
      </Box>
    );
    return claimsListFin;
  }

  return (
    <div>
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: "1.5rem",
          fontFamily: "Lato",
          marginBottom: 10,
        }}
      >
        Change Capture
      </Typography>
      <div
        id="claims-main"
        style={{ height: "100%", width: "calc(100vw - 500px)", backgroundColor: "#fafafa" }}
        className="d-flex row justify-content-start"
      >
        <div
          style={{ height: "100%", marginTop: 30, width: "calc(100vw - 500px)" }}
          className="col-lg-12 col-sm-7 d-flex flex-column justify-content-start"
        >
          {!isLoading && (
            <div className="flex-container" style={{ display: "flex" }}>
              <div
                className="flex-item"
                style={{
                  flexDirection: "row-reverse",
                  height: "40px",
                  marginTop: "5px",
                  marginBottom: "20px",
                  marginLeft: "20px",
                }}
              >
                <Button
                  size="large"
                  className="DayButton"
                  onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                >
                  <i className="fas fa-arrow-left"></i>
                </Button>
                <LocalizationProvider
                  class="DatePickerParent"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Select Date"
                    views={["year", "month"]}
                    value={new Date(currentMonth)}
                    sx={{ marginTop: '10px' }}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(newValue) => {
                      onDateChange(newValue);
                    }}
                  />
                </LocalizationProvider>
                <Button
                  size="large"
                  className="DayButton"
                  onClick={() => onDateChange(currentMonth.add(1, "month"))}
                >
                  <i className="fas fa-arrow-right"></i>
                </Button>
              </div>
              <div className="right">
                <div className="flex-item-u">
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{ marginLeft: "-50px" }}
                      onClick={() => {
                        setCsvAdd(!csvAdd);
                      }}
                    >
                      UPLOAD PRE-AUTH CSV
                    </Button>
                  </Box>
                </div>
                {!selectValidClaims && selectionModel.length === 0 && getSelectButton()}
                {(selectValidClaims || selectionModel.length !== 0) && getUnselectButton()}
                {(selectValidClaims || selectionModel.length !== 0) && getSendButton()}
              </div>
            </div>
          )}

          {!isLoading && claimsList.length !== 0 && primaryBusinessType === 101 && getSC_ChangedList()}
          {!isLoading && claimsList.length !== 0 && (primaryBusinessType === 102 || primaryBusinessType === 105) && getSP_ChangedList()}
          {!isLoading && claimsList.length === 0 && (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: "500px", marginTop: -30 }}
            >
              <h5
                style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                className="text-center"
              >
                You haven’t added any claims yet{" "}
              </h5>
            </div>
          )}
          {!!isLoading && (
            <div className="d-flex flex-row w-100 justify-content-center mt-5">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {renderCSVModal()}
        </div>
        <Modal show={showModal} onHide={handleConfirmModal}>
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center">SUBMIT CLAIMS</Modal.Title>
          </Modal.Header>
          <center>
            <Modal.Body>
              You have selected {selectionModel.length} claims.
            </Modal.Body>
            <Modal.Body>
              Are you sure you want to send the selected claims?
            </Modal.Body>
          </center>
          {/* <Modal.Footer> */}
          <div className="d-flex flex-row justify-content-center m-3">
            <Button
              variant="contained"
              onClick={handleConfirmModal}
              className={classes.button}
              style={{
                backgroundColor: "white",
                color: "grey"
              }}
            >
              Back
            </Button>
            <Box
              style={{ width: "30px" }}
            >
            </Box>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                setNextStep(true);
                handleConfirmModal();
              }}
              style={{
                backgroundColor: "green",
              }}
            >
              Yes
            </Button>
          </div>
          {/* </Modal.Footer> */}
        </Modal>
        <Modal show={showMT} onHide={handleCloseMT} dialogClassName="mm-modal-width">
          <Modal.Body>
            <Modal.Header closeButton={false}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>{mt.mt_type === "Monthly" ? "Monthly" : "Quarterly/Annual"} Monitoring Tool</Typography>
            </Modal.Header>
            {mt?.mt_type === "Monthly" ?
              <div>
                <MonthlyTool // fully expanded for printing and downloading
                  data={mt?.data ? JSON.parse(mt.data) : {}}
                  expand={true}
                  read={true}
                  onChange={() => { return }}
                />
                {mt?.mt_type === "Monthly" &&
                  <Box sx={{ overflow: 'hidden', height: 0 }}>
                    <MonthlyTool // fully expanded for printing and downloading
                      data={mt?.data ? JSON.parse(mt.data) : {}}
                      onChange={() => { return }}
                      ref={ref}
                      expand={true}
                    />
                  </Box>
                }
              </div> :
              <div >
                <QuarterlyAnnualTool // fully expanded for printing and downloading
                  data={mt?.data ? JSON.parse(mt.data) : {}}
                  expand={true}
                  read={true}
                  onChange={() => { return }}
                />
                {mt?.mt_type === "Quarterly" &&
                  <Box sx={{ overflow: 'hidden', height: 0 }}>
                    <QuarterlyAnnualTool // fully expanded for printing and downloading
                      data={mt?.data ? JSON.parse(mt.data) : {}}
                      onChange={() => { return }}
                      ref={ref}
                      expand={true}
                    />
                  </Box>
                }
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseMT}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}