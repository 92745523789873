import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './NavBar.css';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Notifications from '../../components/Notifications/notifications';
import axios from 'axios';
import { APIURL, VERSION, VERSION_DATE } from '../../config';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Badge from '@mui/material/Badge';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import WhatsNew from '../WhatsNew/WhatsNew';
import CloseIcon from '@mui/icons-material/Close';


class NavBar extends Component {
    state = {
        anchorEl: null,
        anchorElAbout: null,
        newNotificationAlert: false,
        numberNotifRecorded: 0,
        whatsNewVersionsCount: 0,
        isDialogOpen: false,
        logoSrc: this.props.user.company_logo ? this.props.user.company_logo : './FieldworkerIcon.png'
    }

    // function handles the pop-up help window 
    PopUpHelp() {
        window.open('https://fieldworker.gitbook.io/fieldworker-docs/', 'Data', 'height=700,width=1000');
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleOpenDialog = () => {
        this.setState({ isDialogOpen: true });
    };

    handleCloseDialog = () => {
        this.setState({ isDialogOpen: false });
    };

    // alertNotification = () => {
    //     this.setState({newNotificationAlert: !this.state.newNotificationAlert})
    // }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClickAbout = (event) => {
        this.setState({ anchorElAbout: event.currentTarget });
    };

    handleCloseAbout = () => {
        this.setState({ anchorElAbout: null });
    };

    getNotifData = async () => {
        try{
        let notificationsData = await axios.get(APIURL + '/notifications', {params : {rec_id: this.props.user.emp_id, send_id: this.props.user.emp_id}})
        let numberNotifs = notificationsData.data.filter((notif)=> !notif.isRead).length
        return numberNotifs
        }catch(err){
            return 0
        }
    }

    componentDidMount = async () => {
        let numberNotifRecorded = await this.getNotifData()
        this.setState({ numberNotifRecorded })

        let whatsNewUpdatesCount = await axios.get(APIURL + '/system/whatsnewcount', {params : {}})
        console.log(whatsNewUpdatesCount)
        this.setState({ whatsNewVersionsCount: whatsNewUpdatesCount.data.NumberOfVersions })
    }

    onFocus = async() =>  {
        let numberNotifRecorded = await this.getNotifData()
        console.log(numberNotifRecorded,this.state.numberNotifRecorded)
        console.log('When i run i ask for notifications')
        if(numberNotifRecorded - this.state.numberNotifRecorded > 0){
            this.setState({numberNotifRecorded: numberNotifRecorded, newNotificationAlert: (numberNotifRecorded - this.state.numberNotifRecorded <= 0)?false:true})
        }else{
            this.setState({numberNotifRecorded: numberNotifRecorded, newNotificationAlert: false})
        }
        // else{
        //     this.setState({numberNotifRecorded: numberNotifRecorded, newNotificationAlert: false})
        // }
    }

    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <header id="login-navbar">
                <AppBar position='fixed' onFocus={this.onFocus} style={{ zIndex: 2 }}>
                    <Toolbar className="navbar navbar-expand-lg navbar-light">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Icon 
                                    sx={{ height: 60, width: 60, color: '#95D0EB', ml: "-16px",  mt: "-15px" }}>
                                    <NavLink to="/" className="navbar-brand">
                                        <img src={this.state.logoSrc} alt="logo" style={{ height: 60, width: 60 }} onError={(e) => this.setState({ logoSrc: './FieldworkerIcon.png' })} />
                                    </NavLink>
                                </Icon>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "5px" }}>
                                <ul style={{ padding: 0 }} className="navbar-brand navbar-nav mr-auto">
                                    <li style={{ padding: 0 }} className="nav-item dropdown">
                                        <Typography variant='h6' style={{ color: '#3FA7D6', alignSelf: "center" }} className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <a style={{  color: '#3FA7D6' }} >
                                                {this.props.user.company_name ? this.props.user.company_name : 'Analytiq Inc'}
                                            </a>
                                        </Typography>
                                        <div className="dropdown-menu" id="navbar-dropdown" aria-labelledby="navbarDropdown">
                                            {this.props.isAuth &&
                                                <NavLink to="/empDashboards" className="dropdown-item">Employee Analytics</NavLink>
                                            }
                                            {this.props.isAuth &&
                                                <NavLink to="/team" className="dropdown-item">Team</NavLink>
                                            }
                                            <div className="dropdown-divider"></div>
                                            {!this.props.isAuth &&
                                                <button className="dropdown-item login-button" onClick={this.props.login}>Login</button>
                                            }
                                            {this.props.isAuth &&
                                                <button className="dropdown-item" onClick={this.props.logout}>Logout</button>
                                            }
                                        </div>
                                    </li>
                                </ul>
                               <Typography variant="caption" sx={{ lineHeight: '0.5', fontSize: '12px', padding: '0', color: '#95D0EB', alignSelf: "center" }}>Powered by FieldWorker</Typography>
                            </div>
                            <div style={{ marginLeft: '10px' }}>   
                                <Button onClick={this.handleOpenDialog} style={{ margin: '0px', padding: '0px' }}>
                                    <Badge badgeContent={this.state.whatsNewVersionsCount} color="primary">
                                        <SystemUpdateAltIcon style={{ color: '#95D0EB' }}/>
                                    </Badge>
                                </Button>
                                <Dialog
                                    open={this.state.isDialogOpen}
                                    onClose={this.handleCloseDialog}
                                    PaperProps={{
                                        style: {
                                        width: '60%', // Adjust width as needed
                                        height: '60%', // Adjust height as needed
                                        maxWidth: 'none', // Optional: Removes the max-width restriction
                                        },
                                    }}
                                    >
                                    <DialogTitle>
                                        What's New in FieldWorker
                                        <IconButton
                                        aria-label="close"
                                        onClick={this.handleCloseDialog}
                                        style={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                        <hr />
                                    </DialogTitle>
                                    <DialogContent>
                                        <WhatsNew props={this.props} checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.user.comp_id} />
                                    </DialogContent>
                                    <DialogActions sx={{ borderTop: '1px solid #d3d3d3' }}>
                                        <Button onClick={this.handleCloseDialog} color="primary">
                                        Cancel
                                        </Button>
                                    </DialogActions>
                                    </Dialog>
                            </div>
                        </div>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                {this.props.isAuth &&
                                    <li style={{ padding: 1, paddingTop: 10, color: 'black' }} className="nav-item"><h6>Signed In using <span className="badge badge-info">{this.props.user.email}</span> as <span className="badge badge-info">{this.props.user.emp_name}</span> </h6></li>
                                }
                                {this.props.isAuth &&
                                    <div className="d-flex flex-md-row flex-sm-column">
                                        <li className="reposrt-tooltip-nav-item">
                                            <NavLink to="/reports" className="nav-link"><i className="fas fa-table"></i></NavLink>
                                        </li>

                                        {/* <li className="nav-item">
                                            take the svg code from the figma and then do a ternary
                                            <NavLink to="/notifications" className="nav-link"><i className="fas fa-bell"></i></NavLink>
                                        </li> */}
                                        <li className="nav-item">
                                            <NavLink to="#" className="nav-link"><i className="fas fa-list" style={{ color: 'grey' }}></i></NavLink>
                                        </li>
                                        <li className="nav-item d-none">
                                            <NavLink to="/onboarding" className="nav-link"><i className="fas fa-table"></i></NavLink>
                                        </li>
                                        <li className="settings-tooltip-nav-item">
                                            {/* {this.props.user.type === 'Admin' &&  */}
                                            <NavLink to="/Settings" className="nav-link">
                                                <i className="fas fa-cog m-0"></i>
                                            </NavLink>
                                        </li>
                                        <li className="feature-request-tooltip-nav-item">
                                            <a href="https://fieldworker.canny.io/feature-requests" className="nav-link">
                                                <i className="fas fa-clipboard m-0"></i>
                                            </a>
                                        </li>
                                        <li className="helpcenter-tooltip-nav-item ">
                                            {/* <a href="https://fieldworker.tawk.help/" className="nav-link"><i className="fas fa-question-circle"></i></a> */}
                                            <div className="nav-link"><i className="fas fa-question-circle" onClick={this.PopUpHelp} style={{ cursor:'pointer'}}></i></div>
                                        </li>
                                        <li className="about-tooltip-nav-item ">
                                            {/* <a href="https://fieldworker.tawk.help/" className="nav-link"><i className="fas fa-question-circle"></i></a> */}
                                            <div 
                                                className="nav-link" 
                                                onMouseEnter={this.handleClickAbout} 
                                                onMouseLeave={this.handleCloseAbout}>
                                                <i className="fas fa-info" style={{ cursor:'pointer'}}></i>
                                            </div>
                                        </li>
                                        <div className='notification-tooltip-nav-item'>
                                        {/* <i style={{margin: 12,color: !this.state.anchorEl?'rgba(130, 130, 130, 1)':'#000000'}} className="fas fa-bell" onClick={this.handleClick}><sup>{(!open && !!this.state.newNotificationAlert) && <i style={{fontSize:8, color: 'orange'}} className="fas fa-circle"></i>}</sup></i> */}
                                        {!open && !this.state.newNotificationAlert && <i style={{ cursor:'pointer',margin: 12, marginLeft:'5px',color: !this.state.anchorEl?'rgba(130, 130, 130, 1)':'#000000'}} className={"fas fa-bell"} onClick={this.handleClick}><sup className='position-absolute top-0 start-0 translate-middle badge rounded-pill' style={{color: 'white',margin : '1px', backgroundColor: '#FA7171', marginTop:'22px', fontSize: '10px'}}>{this.state.numberNotifRecorded === 0 ? '': this.state.numberNotifRecorded}</sup></i>}
                                        {!!open && (!this.state.newNotificationAlert || !!this.state.newNotificationAlert ) && <i style={{margin: 12,marginLeft:'5px',color: 'rgba(130, 130, 130, 1)'}} className={"fas fa-bell"} onClick={this.handleClick}><sup className='position-absolute top-0 start-0 translate-middle badge rounded-pill' style={{color: 'white',margin : '1px', backgroundColor: '#FA7171', marginTop:'22px', fontSize: '10px'}}>{this.state.numberNotifRecorded === 0 ? '': this.state.numberNotifRecorded}</sup></i>}
                                        {/* {!!open && !!this.state.newNotificationAlert && <i style={{margin: 12,color: 'red'}} className={"fas fa-bell"} onClick={this.handleClick}></i>} */}
                                        {!open && !!this.state.newNotificationAlert && <div className='d-flex flex-row'><img onClick={this.handleClick} style={{margin: 12,marginLeft:'5px',height: '1rem', width: '1rem'}} src='../alertNotif.png' alt='alertNotif'/><sup className='position-absolute top-0 start-0 translate-middle badge rounded-pill' style={{color: 'white',margin : '1px', backgroundColor: '#FA7171',marginTop:'22px',marginLeft:'20px', fontSize: '10px'}}>{this.state.numberNotifRecorded === 0 ? '': this.state.numberNotifRecorded}</sup></div>}
                                            <Popover
                                                id={id}
                                                open={open}
                                                style={{top: 30, left: window.innerWidth-500, right: 5} }
                                                anchorEl={this.state.anchorEl || null}
                                                onClose={this.handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Notifications user={this.props.user} />
                                            </Popover>
                                        </div>
                                        <Popover
                                            id="mouse-over-popover"
                                            sx={{
                                                pointerEvents: 'none',
                                                border: '2px solid gray', 
                                                borderRadius: "2px",
                                                minWidth: "400px"
                                            }}
                                            open={Boolean(this.state.anchorElAbout)}
                                            anchorEl={this.state.anchorElAbout || null}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}

                                            onClose={this.handleCloseAbout}
                                            disableRestoreFocus
                                        >
                                            <Typography sx={{ p: 1, pb: 0, fontSize: 14 }}><b>Version</b>  {VERSION}</Typography>
                                            <Typography sx={{ p: 1, fontSize: 14 }}><b>Release</b>  {VERSION_DATE}</Typography>
                                        </Popover>
                                    </div>
                                }
                            </ul>
                        </div>
                    </Toolbar>
                </AppBar>
            </header>
        );
    }

}

export default NavBar;