import React, { Component } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
// import { Emoji } from 'emoji-mart'
import ccs from 'countrycitystatejson';
import normalize  from 'us-states-normalize';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import './PersonalDetails.css'
import axios from 'axios';
import { APIURL } from '../../../../config'
import dayjs from 'dayjs';


const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
})(TextField);

class PersonalDetails extends Component {
    state = {
        radioIndividual: true,
        currentAddress: "",
        addressSuggestions: [],
    }

    radioChangeHander = (event) => {
        this.props.onRadioChangeHandler(event.target.value)
        console.log(event.target.value)
        if (event.target.value === "Individual") {
            this.setState({
                radioIndividual: true
            })
            this.props.formChangeHandler({target: {id: "cus_is_company", value: false}})
        } else {
            this.setState({
                radioIndividual: false
            })
            this.props.formChangeHandler({target: {id: "cus_is_company", value: true}})
        }
    }

    // state = {
    //     radioProject: true
    // }

    // radioProjectHander = (event) => {
    //     this.props.onRadioChangeHandler(event.target.value)
    //     if (event.target.value === "Yes") {
    //         this.setState({
    //             radioProject: true
    //         })
    //     } else {
    //         this.setState({
    //             radioProject: false
    //         })
    //     }
    // }

    createAddressFields = () => {
        // let states = this.props.states.map((elem, index) => {
        //     return (<option key={elem + index}>{elem}</option>)
        // }) 
        let states = ccs.getStatesByShort('US').filter( state => { return state.length > 2 });
        // states=states.map((state, indx) => <option key={state.isoCode} value={state.isoCode}>{state.name}</option>)
        states=states.map((state, indx) =>{return {title:normalize(state),value:state}})
        let addressList = this.props.addressList.map((add,indx)=> {
            // console.log(add);
            let cities = add.state ? ccs.getCities('US', normalize(add.state, { returnType: 'name' })) : [];
            // cities=cities.map((city, indx) => <option key={indx} value={city.name}>{city.name}</option>)
            cities=cities.map((city, indx) => {return {value:city, title: city}});

            let currAddress = add.address_line_1 + ', ' + (!!add.address_line_2.trim() ? add.address_line_2 + ', ' : '') + add.city + ', ' + add.state + ', ' + add.zip

            return (
                (<div key={add.addId}>
                    <div className={`d-flex flex-row justify-content-between ${this.props.edit?'w-100':'w-75'}`}>
                        <h5>Address {++indx}</h5>{(!this.props.edit?true:!add.disableFields) && <i className="fas fa-trash" id={"del"+add.addId} onClick={(event) => this.props.onDeleteAddress(event)} style={{ fontSize: "1.5rem", cursor: "pointer" }}></i>}
                    </div>
                    <Form.Row>
                        <Autocomplete
                            defaultValue={{description: /^[, ]+$/.test(currAddress) ? '' : currAddress}}
                            freeSolo
                            autoSelect
                            selectOnFocus
                            disablePortal
                            id="address_maps"
                            sx={{ width: 436 }}
                            popupIcon={null}
                            options={this.state.addressSuggestions}
                            getOptionLabel={(option) => option.description !== undefined ? option.description : option}
                            onChange={(event, value, reason) => {
                                if(reason === 'selectOption'){
                                    this.handleAddressSelect(indx, value)
                                }
                            }}
                            onInputChange={(event, value, reason) => {
                                if(reason === 'input'){
                                    this.handleAddressSuggestions(value)
                                }
                            }}
                            renderInput={(params) => <CssTextField {...params} className={`mx-2 my-3 w-100`} label="ADDRESS *" variant="outlined" id="address_maps_TextField"/> }
                        />
                    </Form.Row>
                    {/* <Form.Group controlId="address_line_1">
                        <Form.Label>ADDRESS LINE 1</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        <Form.Control value={add.address_line_1} onChange={(event) => this.props.handleAddressListChange(add.addId,event)} disabled={!this.props.edit?false:add.disableFields}/>
                    </Form.Group> */}
                    {/*<CssTextField
                    // style={{width:'35%'}}
                        className={this.props.edit?`mx-2 my-3 w-100`:`mx-2 my-3 w-75`}
                        label="ADDRESS LINE 1 *"
                        value={add.address_line_1}
                        onChange={(event) => this.props.handleAddressListChange(add.addId,event)} 
                        variant="outlined"
                        id="address_line_1"
                       // disabled={!this.props.edit?false:add.disableFields}
                    />*/}
                    {/* <Form.Group controlId="address_line_2">
                        <Form.Label>ADDRESS LINE 2</Form.Label>
                        <Form.Control value={add.address_line_2} onChange={(event) => this.props.handleAddressListChange(add.addId,event)} disabled={!this.props.edit?false:add.disableFields}/>
                    </Form.Group> */}
                    {/*<CssTextField
                    // style={{width:'35%'}}
                        className={this.props.edit?`mx-2 my-3 w-100`:`mx-2 my-3 w-75`}
                        label="ADDRESS LINE 2"
                        value={add.address_line_2}
                        onChange={(event) => this.props.handleAddressListChange(add.addId,event)} 
                        variant="outlined"
                        id="address_line_2"
                        //disabled={!this.props.edit?false:add.disableFields}
                    />*/}
                    <Form.Row>
                        {/* <Form.Group as={Col} controlId="city">
                            <Form.Label>CITY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                            <Form.Control value={add.city} onChange={(event) => this.props.handleAddressListChange(add.addId,event)} disabled={!this.props.edit?false:add.disableFields}/>
                        </Form.Group> */}

                        {/* <Form.Group as={Col} controlId="state">
                            <Form.Label>STATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                            <Form.Control as="select" value={add.state} onChange={(event) => this.props.handleAddressListChange(add.addId,event)} disabled={!this.props.edit?false:add.disableFields}>
                                <option value="">Select a State</option>
                                {states}
                            </Form.Control>
                        </Form.Group> */}
                        {/*<Autocomplete
                            id="state"
                            value={states.find((type)=> type.title.toUpperCase() === add.state.toUpperCase()) || null}
                            className={this.props.edit?`mx-2 my-3`:`mx-2 my-3`}
                            onChange={(e)=> this.props.handleAddressListChange(add.addId, {target:{id:'state', value: e.target.innerHTML}})}
                            disableClearable={true}
                            options={states}
                            getOptionLabel={(option) => option.title}
                            style={{width:!!this.props.edit?'35%':'25%'}}
                            renderInput={(params) => <CssTextField {...params} label="STATE *" variant="outlined" />}
                          //  disabled={!this.props.edit?false:add.disableFields}
                        />*/}
                        {/* <Form.Group as={Col} controlId="city">
                            <Form.Label>CITY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                            <Form.Control as="select" value={add.city} onChange={(event) => this.props.handleAddressListChange(add.addId,event)} disabled={!this.props.edit?false:add.disableFields}>
                                <option value="">Select a City</option>
                                {cities}
                            </Form.Control>
                        </Form.Group> */}
                        {/*<Autocomplete
                            id="city"
                            value={cities.find((type)=> type.title.toLowerCase() === add.city.toLowerCase()) || null}
                            className={this.props.edit?`mx-2 my-3`:`mx-2 my-3`}
                            onSelect={(event) => this.props.handleAddressListChange(add.addId,event)}
                            options={cities}
                            getOptionLabel={(option) => option.title}
                            style={{width:!!this.props.edit?'55%':'46%'}}
                            renderInput={(params) => <CssTextField {...params} label="CITY *" variant="outlined" />}
                           // disabled={!this.props.edit?false:add.disableFields}
                        />*/}
                    </Form.Row>
                    {/* <Form.Group controlId="zip">
                        <Form.Label>ZIP</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        <Form.Control value={add.zip} onChange={(event) => this.props.handleAddressListChange(add.addId,event)} disabled={!this.props.edit?false:add.disableFields}/>
                    </Form.Group> */}
                    {/*<CssTextField
                    // style={{width:'35%'}}
                        className={this.props.edit?`mx-2 my-3 w-100`:`mx-2 my-3 w-75`}
                        label="ZIP *"
                        value={add.zip}
                        onChange={(event) => this.props.handleAddressListChange(add.addId,event)} 
                        variant="outlined"
                        id="zip"
                      //  disabled={!this.props.edit?false:add.disableFields}
                    />*/}
                    {(indx!==0) &&
                        <Form.Row>
                        {/* <Form.Group as={Col} controlId="latitude">
                            <Form.Label>LATITUDE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                            <Form.Control type="text" value={add.latitude}  
                            onChange={(event) => this.props.handleAddressListChange(add.addId,event)}
                            readOnly/>
                        </Form.Group> */}
                         {/*<CssTextField
                            style={{width:!!this.props.edit?'46%':'35%'}}
                            className={`mx-2 my-3`}
                            label="LATITUDE"
                            value={add.latitude}
                            onChange={(event) => this.props.handleAddressListChange(add.addId,event)} 
                            variant="outlined"
                            id="latitude"
                            disabled
                            // disabled={!this.props.edit?false:add.disableFields}
                        />*/}
                        {/* <Form.Group as={Col} controlId="longitude">
                            <Form.Label>LONGITUDE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                            <Form.Control type="text" value={add.longitude}
                            onChange={(event) => this.props.handleAddressListChange(add.addId,event)}
                            readOnly/>
                        </Form.Group> */}
                        {/*<CssTextField
                            style={{width:!!this.props.edit?'46%':'35%'}}
                            className={`mx-2 my-3`}
                            label="LONGITUDE"
                            value={add.longitude}
                            onChange={(event) => this.props.handleAddressListChange(add.addId,event)} 
                            variant="outlined"
                            id="longitude"
                            disabled
                            // disabled={!this.props.edit?false:add.disableFields}
                        />*/}
                    </Form.Row>
                    }
                </div>)
            );});
        return addressList
    }

    createPhoneNumberList = () => {
        let code = ccs.getCountryByShort('US');
        let phoneList = this.props.phnNumList.map((phn, indx)=>(
            <div key={phn.phnId}>
            <div className={`d-flex flex-row justify-content-between ${this.props.edit?'w-100':'w-75'}`}>
                <h5>Phone Line {++indx}</h5><i className="fas fa-trash" id={"del"+phn.phnId} onClick={(event) => this.props.deletePhoneNumber(event)} style={{ fontSize: "1.5rem", cursor: "pointer" }}></i>
            </div>
            <Form.Row>
                {/* <Form.Group as={Col} controlId={"country_code"}>
                    <Form.Label>COUNTRY CODE</Form.Label>
                    <Form.Control value={phn.country_code} onChange={(event) => this.props.handlePhoneListChange(phn.phnId,event)} />
                </Form.Group> */}
                {/* <Form.Group as={Col} controlId="country_code">
                    <Form.Label>COUNTRY CODE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <div className="d-flex flex-row justify-content-around" style={{width: '14.5rem',height:'2.4rem',border:'1px solid #D6D6D6',borderRadius:'5px', textAlign:'center'}}>
                        <div style={{marginLeft: 0, marginTop:5}}><Emoji emoji=':flag-us:' size={26}/></div> <div style={{marginRight: 5, marginTop:5}}>United States (+1)</div>
                    </div>
                </Form.Group> */}
                <CssTextField
                    style={{width: this.props.edit?'40%':'30%'}}
                    className={`mx-1 my-3`}
                    label="COUNTRY CODE"
                    value={`${code.emoji} ${code.name}(${code.phone})`}
                    // onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="country_code"
                />
                {/* <Form.Group as={Col} controlId={"phone"}>
                    <Form.Label>PHONE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={phn.phone} onChange={(event) => this.props.handlePhoneListChange(phn.phnId,event)} />
                </Form.Group> */}
                 <CssTextField
                    style={{width: this.props.edit?'55%':'45%'}}
                    className={`mx-1 my-3`}
                    label="PHONE *"
                    value={phn.phone}
                    onChange={(event)=>{this.props.handlePhoneListChange(phn.phnId,event)}} 
                    variant="outlined"
                    id="phone"
                    inputProps={{maxLength: 100}}
                />
            </Form.Row>
        </div>
        ));
        return phoneList
    }

    displayNameOnEdit(){
        if(!this.props.is_company){
            this.setState({
                radioIndividual: true
            })
        }else{
            this.setState({
                radioIndividual: false
            })
        }
    }

    onDOBChange(date) {
        console.log(date)
        let event = {
            target: {
                id: 'dob',
                value: dayjs(date).format('MM/DD/YYYY'),
            }
        }
        console.log(event)
        this.props.formChangeHandler(event);
    }

    componentDidMount(){
        this.displayNameOnEdit()
    }

    handleAddressSuggestions = async(value) => {
        let newAddressSuggestions = await axios.get(APIURL + '/maps', { params: { input: value } })
        this.setState({addressSuggestions: newAddressSuggestions.data.predictions, currentAddress: value === undefined  ? '' : value})
    }

    handleAddressSelect = async(indx, value) => {
        let newAddressDetails = await axios.get(APIURL + '/maps/details', { params: { place_id: value.place_id } })
        this.props.handleAddressListChange(indx, {
            target: {
                id: 'address_maps'
            },
            value: newAddressDetails.data
        })
    }

    render() {
        let typelist = this.props.customerType.map((data,i)=> {
            return (
                <Form.Check
                key={'radio'+i}
                            type="radio"
                            label={data}
                            name="radioIndividual"
                            id="radioIndividual"
                            value={data}
                            defaultChecked={data==='Individual'}
                            //checked={data === 'Individual' ? true : false}
                            // checked={this.state.radioIndividual}
                            onChange={(event) => this.radioChangeHander(event)}
                            
                            
                        />
                       
            )
        })
        let addressList = this.createAddressFields()
        let phoneList = this.createPhoneNumberList()
        let errorIndicatorDDD
        if(this.props.formVals.DDDID== ""){
            errorIndicatorDDD=undefined
        }
        else{
            errorIndicatorDDD = this.props.customerList && !!this.props.customerList.find((entry)=> entry.ddd_id === this.props.formVals.DDDID)
        }
        // console.log(errorIndicatorDDD,this.props.customerList)
        return (
            <div className='mt-3'>
                {/* {edit && } */}
                {!this.props.edit && <Form.Group>
                    <Col sm={10}>
                        {typelist}
                        {/* <Form.Check
                            type="radio"
                            label="Individual"
                            name="radioIndividual"
                            id="radioIndividual"
                            value="individual"
                            checked={this.state.radioIndividual}
                            onChange={(event) => this.radioChangeHander(event)}
                        />
                        <Form.Check
                            type="radio"
                            label="Company"
                            name="radioIndividual"
                            value="company"
                            id="radioCompany"
                            checked={!this.state.radioIndividual}
                            onChange={(event) => this.radioChangeHander(event)}
                        /> */}
                    </Col>
                </Form.Group>}
                {/* <Form.Group controlId="email">
                        <Form.Label>Email Address</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        <Form.Control value={this.props.formVals.email} onChange={(event) => this.props.formChangeHandler(event)} disabled={!!this.props.edit?true:false} />
                </Form.Group> */}
                {this.state.radioIndividual ? (
                    <Form.Row>
                        {/* <Form.Group as={Col} controlId="first_name">
                            <Form.Label>FIRST NAME</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                            <Form.Control type="text" value={this.props.first_name} onChange={(event) => this.props.formChangeHandler(event)} />
                        </Form.Group> */}
                        <CssTextField
                            style={{width:this.props.edit?'46%':'35%'}}
                            className={`mx-2 my-3`}
                            label="FIRST NAME *"
                            value={this.props.first_name}
                            onChange={this.props.formChangeHandler} 
                            variant="outlined"
                            id="first_name"
                            inputProps={{maxLength: 25}}
                            // disabled={!!this.props.edit?true:false}
                        />

                        {/* <Form.Group as={Col} controlId="last_name">
                            <Form.Label>LAST NAME</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                            <Form.Control type="text" value={this.props.last_name} onChange={(event) => this.props.formChangeHandler(event)} />
                        </Form.Group> */}
                        <CssTextField
                            style={{width:this.props.edit?'46%':'35%'}}
                            className={`mx-2 my-3`}
                            label="LAST NAME *"
                            value={this.props.last_name}
                            onChange={this.props.formChangeHandler} 
                            variant="outlined"
                            id="last_name"
                            inputProps={{maxLength: 25}}
                            // disabled={!!this.props.edit?true:false}
                        />
                    </Form.Row>
                ) : (
                        <Form.Row>
                            {/* <Form.Group as={Col} controlId="company_name">
                                <Form.Label>COMPANY NAME</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                                <Form.Control type="text" value={this.props.company_name} onChange={(event) => this.props.formChangeHandler(event)} />
                            </Form.Group> */}
                            <CssTextField
                                // style={{width:'35%'}}
                                className={this.props.edit?`mx-2 my-3 w-100`:`mx-2 my-3 w-75`}
                                label="COMPANY NAME *"
                                value={this.props.company_name}
                                onChange={this.props.formChangeHandler} 
                                variant="outlined"
                                id="company_name"
                                // disabled={!!this.props.edit?true:false}
                            />
                        </Form.Row>
                    )}

                {/* {!this.props.edit && this.state.radioProject ? (
                    <Form.Row>
                        <Form.Group as={Col} controlId="radioYes">
                            <Form.Label>Would you like to add a project?</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        </Form.Group>
                        </Form.Row>
                ):(
                        <Form.Row>
                            <Form.Group as={Col} controlId="radioYes">
                            <Form.Label>Would you like to add a project?</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                            </Form.Group>
                        </Form.Row>  
                )} */}

            {/* {!this.props.edit && <Form.Group>
                    <Col sm={10}>
                        <Form.Check
                            type="radio"
                            label="Yes"
                            name="radioProject"
                            value="yes"
                            id="radioYes"
                            checked={this.state.radioProject}
                            onChange={(event) => this.radioProjectHander(event)}
                        />
                        <Form.Check
                            type="radio"
                            label="No"
                            name="radioProject"
                            value="no"
                            id="radioNo"
                            checked={!this.state.radioProject}
                            onChange={(event) => this.radioProjectHander(event)}
                        />
                    </Col>
                </Form.Group>} */}

                <Form.Row>
                    {this.props.isStateNotNJ ? 
                    <CssTextField
                        style={{width:this.props.edit?'35%':'35%'}}
                        className={`mx-2 my-3`}
                        label="DDD ID *"
                        value={this.props.formVals.DDDID}
                        onChange={this.props.formChangeHandler} 
                        variant="outlined"
                        id="DDDID"
                        type="text"
                        inputProps={{maxLength: 10}}
                        // onInput = {(e) =>{
                        //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                        // }}
                        error={errorIndicatorDDD}
                        // error={((this.props.customeList && !!this.props.customeList.find((entry)=> entry.ddd_id === this.props.formVals.DDDID))) || false}
                        // error={this.props.customeList && !!this.props.customeList.find((entry)=> +entry.ddd_id === +this.props.formVals.DDDID)}
                        helperText={errorIndicatorDDD?'Already Exist.':''}
                        // disabled={!!this.props.edit?true:false}
                    /> :
                    <CssTextField
                        style={{width:this.props.edit?'35%':'35%'}}
                        className={`mx-2 my-3`}
                        label="DDD ID *"
                        value={isNaN(this.props.formVals.DDDID)?'':this.props.formVals.DDDID}
                        onChange={this.props.formChangeHandler} 
                        variant="outlined"
                        id="DDDID"
                        type="text"
                        inputProps={{maxLength: 10}}
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                        }}
                        error={errorIndicatorDDD}
                        // error={((this.props.customeList && !!this.props.customeList.find((entry)=> entry.ddd_id === this.props.formVals.DDDID))) || false}
                        // error={this.props.customeList && !!this.props.customeList.find((entry)=> +entry.ddd_id === +this.props.formVals.DDDID)}
                        helperText={errorIndicatorDDD?'Already Exist.':''}
                        // disabled={!!this.props.edit?true:false}
                    />}
                    <Autocomplete
                        id="gender"
                        value={this.props.gender !== undefined ? this.props.gender : 'M'}
                        className={this.props.edit?`mx-2 my-3`:`mx-2 my-3`}
                        disableClearable
                        onChange={(e, v) => this.props.formChangeHandler({target: {id: 'gender', value: v}})}
                        options={['M', 'F', 'Other']}
                        style={{width:!!this.props.edit?'18%':'18%'}}
                        renderInput={(params) => <CssTextField {...params} label="GENDER" variant="outlined" />}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            margin="normal"
                            label="DOB *"
                            format="MM/DD/YYYY"
                            value={this.props.dob instanceof String ? dayjs(this.props.dob.replace('Z', '')) : null}
                            slotProps={{textField: {variant: 'outlined'}}}
                            className={this.props.edit ? `mx-1 my-3` : `mx-1 my-3`}
                            onChange={(d) => {this.onDOBChange(d)}}
                        />
                    </LocalizationProvider>
                </Form.Row>
                <Form.Row>
                    <CssTextField
                        style={{width: '100%'}}
                        className="mx-2 my-3"
                        label="CUSTOMER LOCAL ID"
                        value={this.props.cus_local_id}
                        onChange={this.props.formChangeHandler} 
                        variant="outlined"
                        id="cus_local_id"
                        type="text"
                        inputProps={{maxLength: 255}}
                        // disabled={!!this.props.edit?true:false}
                    />
                </Form.Row>             
                <Form.Row>
                    <CssTextField
                        style={{width: '100%'}}
                        className="mx-2 my-3"
                        label="EMAIL *"
                        value={this.props.email !== undefined ? this.props.email : ""}
                        onChange={this.props.formChangeHandler} 
                        variant="outlined"
                        id="email"
                        type="email"
                        inputProps={{maxLength: 255}}
                        // disabled={!!this.props.edit?true:false}
                    />
                </Form.Row>

                {addressList}

                <p style={{ cursor: "pointer" }} onClick={() => this.props.onAddAddress()}><i className="fas fa-plus-circle"></i> Add Another Location</p>

                {phoneList}

                <p style={{ cursor: "pointer" }} onClick={() => this.props.addPhoneNumber()}><i className="fas fa-plus-circle"></i> Add Another Phone Number</p>

            <Button disabled={errorIndicatorDDD} className={!!this.props.edit?'w-100':'w-75 ml-3'} variant="primary" block type="button" onClick={() => this.props.onContinue("other_details")}>
                    Continue
            </Button>
            </div>
        )
    }
}

export default PersonalDetails;