import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import makeStyles from "@mui/styles/makeStyles";
import { Snackbar, IconButton, Alert, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../DataGrid/ControlledDataGrid';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

const useStyles = makeStyles((theme) => ({
    GridTableBox: {
        "& .MuiDataGrid-columnHeader:first-child": {
            width: "300px !important",
            maxWidth: "300px !important",
        },
        "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:first-child":
        {
            width: "300px !important",
            maxWidth: "300px !important",
        },
    },
}));

export default function DailyAttendanceSummary(props) {
    const classes = useStyles();
    const [gridId, setGridId] = useState(69);
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [sessionDate, setSessionDate] = useState(moment().format("YYYY-MM-DD"));
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports", {
                params: { empID: props.user.emp_id },
            })
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "Daily Attendance Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        axios
            .get(APIURL + "/reports/getSessionsByDate", {
                params: {
                    compID: props.user.comp_id,
                    sessionDate: sessionDate,
                },
            })
            .then((response) => {
                setData(response?.data.length !== 0 ? response?.data.filter((session) => session.tas_id) : []);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    let rows = data.map((data, idx) => {
        let taskDuration = moment(data.tas_end_time).isValid() && moment(data.tas_start_time).isValid()
            ? (
                moment(data.tas_end_time).diff(
                    moment(data.tas_start_time),
                    "m"
                ) / 60
            ).toFixed(2)
            : ''
        let taskData = {
            hierarchy: [data.cus_name, `${idx + 1}.`],
            id: idx,
            cus_id: data.cus_id,
            tas_id: data.tas_id,
            task_name: data.tas_title,
            task_type: data.tas_type,
            tas_time_spent: taskDuration,
            units_spent: data.unitsSpent,
            auth_units: data.procProcCode + (data.procModifierCode ? data.procModifierCode.replaceAll(":", '') : '') === data.procedureCode ? data.authUnits : '-'
        };
        return taskData;
    });

    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "cus_id", headerName: "Customer ID", hide: true },
        { field: "tas_id", headerName: "Task ID", hide: true },
        { field: "task_name", headerName: "Session Name", width: 150 },
        { field: "task_type", headerName: "Session Task Type", width: 150 },
        { field: "tas_time_spent", headerName: "Session Duration", width: 150 },
        { field: "units_spent", headerName: "Units Spent", width: 150 },
        { field: "auth_units", headerName: "Authorized Units", width: 150 },
    ]

    const groupingColDef = {
        headerName: 'Clients',
        valueFormatter: (value, row, column, apiRef) => value,
    };

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
            empID: props.user.emp_id
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    return (
        (<div style={{ margin: 10, width: '75vw' }}>
            <div style={{ display: 'flex', marginTop: 20, marginBottom: 20, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="date"
                            label="SESSION DATE"
                            value={new Date(moment(sessionDate).format('YYYY-MM-DDTHH:mm:ss'))}
                            onChange={(d) => {
                                setSessionDate(moment(d).format('YYYY-MM-DD'));
                            }}
                            slotProps={{ textField: { variant: 'outlined' } }}
                        />
                    </LocalizationProvider>

                    <Button
                        onClick={() => fetchData()}
                        variant="primary"
                        size="lg"
                        className="font-weight-bold"
                        style={{ marginLeft: 10, fontSize: 14, fontFamily: "Lato" }}
                    >
                        SEARCH
                    </Button>
                </div>
                <div>
                    <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                        <img
                            src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                            alt="barchart"
                            style={{
                                fontFamily: "Lato",
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                                margin: 5
                            }}
                            onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                        />
                    </Tip>
                </div>
            </div>
            <div style={{ height: '70vh' }}>
                <ControlledDataGrid
                    gridId={gridId}
                    user={props.user}
                    initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                    apiRef={apiRef}
                    getRowId={(row) => row.id}
                    rows={rows}
                    columns={columns}
                    treeData
                    getTreeDataPath={(row) => row.hierarchy}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    groupingColDef={groupingColDef}
                    className={classes.GridTableBox}
                    loading={isLoading}
                />
            </div>
            <Snackbar
                open={successText !== ""}
                autoHideDuration={30000}
                onClose={() => setSuccessText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"success"}
                    elevation={6}
                    variant="filled"
                >
                    {successText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setSuccessText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorText !== ""}
                autoHideDuration={30000}
                onClose={() => setErrorText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"error"}
                    elevation={6}
                    variant="filled"
                >
                    {errorText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setErrorText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
        </div>)
    );
}