import React, {Component} from 'react';
import { ReactBingmaps } from 'react-bingmaps';
import {BINGAPIKEY} from '../../config'

class MapsBar extends Component{
    constructor(props){
        super()
        this.state = {
            showMap: !props.hideMap
        }
         this.toggleMap = this.toggleMap.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.state.showMap !== nextState.showMap || 
            this.props.currentProjectLocation !== nextProps.currentProjectLocation || 
            this.props.mapsData !== nextProps.mapsData
        ) {
            return true;
        }
        return false;
    }

    toggleMap(){
        this.setState({showMap: !this.state.showMap});
        this.props.toggleMap(this.state.showMap)
    }

    render(){
        return(
            <div>
                <div className="d-flex justify-content-end" style={{backgroundColor: "#EBF8FF",height:"2rem", border: "1px solid #3FA7D6"}}><i style={{color:"#3FA7D6", padding: "1px 1px 3px 3px", margin: "5px"}} onClick={ this.toggleMap} className="fas fa-map"></i></div>
                {this.state.showMap && <div className="border border-info" style={{height: "17rem"}}>
                    <ReactBingmaps
                    id={"projHome"}
                    bingmapKey = {BINGAPIKEY}
                    center = {this.props.currentProjectLocation ? [parseFloat(this.props.currentProjectLocation?.loc_lat), parseFloat(this.props.currentProjectLocation?.loc_lon)] : [40.735657, -74.172363]}
                    infoboxesWithPushPins = {this.props.mapsData}
                    > 
                    </ReactBingmaps>
                </div>}
            </div>
        )
    }
}

export default MapsBar;