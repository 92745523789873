import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { APIURL } from '../../../config'
import TaskForm from '../../../components/forms/TaskForm/TaskForm'
import './NewTask.css'
const NewTask = (props) => {

    // const [tasks, setTasks] = useState([])
    const [taskTypes, setTaskTypes] = useState([])
    const [mapRender, setMapRender] = useState([])
    const [empList, setEmpList] = useState([])
    const [locList, setLocList] = useState([])
    const [projList, setProjList] = useState([])
    const [contactList, setContactList] = useState([])

    const getTaskData = async () => {
        await props.checkTokenExpiry();
        // console.log(window.sessionStorage)
        axios.defaults.headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
          "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
          Authorization:
            "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
        };
        // let tasks = await axios.get(APIURL + "/dropdowns/dropdownType/taskslist", {params: { comp_id: props.comp_id }});
        let taskType = await axios.get(APIURL + "/dropdowns/dropdownType/taskType", {params: { comp_id: props.comp_id, employeeType: props.user.type }});
        let locList = await axios.get(APIURL + "/dropdowns/dropdownType/locationslist", {params: { comp_id: props.comp_id }});
        console.log(props.user.emp_id);
        let projList = await axios.get(APIURL + "/project", {params: { comp_id: props.comp_id, emp_id: props.user.emp_id }});
        //let test = await axios.get(APIURL + "/dropdowns/dropdownType/projectslist", {params: { comp_id: props.comp_id }});
        let contactList = await axios.get(APIURL + "/contacts", {params: { com_id: props.comp_id }});
        // setTasks(tasks.data);
        setTaskTypes(taskType.data);
        setEmpList([{id:props.user.emp_id, employee_name: props.user.emp_name}]);
        setLocList(locList.data);
        setProjList(projList.data.map(proj => {
          return {
            pid: proj.pro_id,
            pname: proj.pro_title,
            end_date: proj.pro_ends_on,
            start_date: proj.pro_starts_on,
            empAssn: [{ id: props.user.emp_id }],
            proLoc: proj.location ? proj.locations.map(location => {return { id: location.loc_id }}) : null,
            cus_id: proj.pro_customer_id
          }
        }));
        setContactList(contactList.data);
        }

    const isFormValidated = (objForVal) => {
        var missingField= {
          isValidated:true
        }
        let temp=Object.keys(objForVal)
        if(temp[0]=='selectedEmp')
        {
          
          let selectedEm=objForVal.selectedEmp
          for(let i=0;i<selectedEm.length;i++)
          {
            for (let objKey in selectedEm[i]) {
              if (
                selectedEm[i][objKey] === "" ||
                selectedEm[i][objKey] === undefined ||
                selectedEm[i][objKey].length === 0
              ){
                missingField.isValidated=false;
                missingField.missingKey=objKey
                return missingField
              }
          }
        }
        }
      
        
    
    
        for (let objKey in objForVal) {
          if (
            objForVal[objKey] === "" ||
            objForVal[objKey] === undefined ||
            objForVal[objKey].length === 0
          ) {
            if (
              objKey === "address2" ||
              objKey === "location_addr2" ||
              objKey === "tComment" ||
              //objKey === "ddd_id" ||
              objKey === "address_line_2" ||
              objKey === "email" ||
              objKey === "medicaid_id" ||
              objKey === "medicaid_eligible" ||
              objKey === "sign_page_date" ||
              objKey === "last_plan_approval_date" ||
              objKey === "last_assess_date" ||
              objKey === "renewal_date" ||
              //objKey === "DDDID" ||
              objKey === "personal_days_off" ||
              objKey === "vacation_days"||
              objKey === "DDD_tier" ||
              objKey === "diagnose_code" ||
              objKey === "func_criteria_met" ||
              objKey === "waiver_program" ||
              objKey === "tContacts"
            ) {
              // return true
              continue;
            }else if(objForVal["cus_is_company"]==true && objForVal["company_name"]==""){
              missingField.isValidated=false;
              missingField.missingKey="company_name"
              return missingField;
            }
             else {
              console.log(objKey);
    
              if (
                (objKey === "company_name" &&
                  objForVal["first_name"] !== "" &&
                  objForVal["last_name"] !== "") ||
                ((objKey === "first_name" || objKey === "last_name") &&
                objForVal["company_name"] !== "" &&  objForVal["company_name"] !== undefined)
              ) {
                continue;
              } else {
                missingField.isValidated=false;
                missingField.missingKey=objKey
                return missingField;
              }
            }
          } else if (Array.isArray(objForVal[objKey])) {
            let arrValid = objForVal[objKey].every(function (objTesting) {
              for (let objTestKey in objTesting) {
                console.log(objTestKey);
                if (
                  objTesting[objTestKey] === "" ||
                  objTesting[objTestKey] === undefined
                ) {
                  if (
                    objTestKey === "address_line_2" ||
                    objTestKey === "location_addr2"
                  ) {
                    // return true
                    continue;
                  } else {
                    missingField.isValidated=false;
                    missingField.missingKey=objKey
                    return missingField;
                  }
                }
              }
              return true;
            });
            if (!arrValid) return false;
          } else if (objKey === "active_plan_version") {
            for (const digit of objForVal[objKey]) {
              if (Number.isNaN(parseInt(digit)) && digit !== ".") {
                alert("PROJECT ACTIVE PLAN VERSION has to be a number");
                objForVal[objKey] = "";
                return false;
              }
            }
          }
        }
        
        return missingField;
      };


    useEffect(() => {
        getTaskData();
    }, [])

    const getData = async () => {
        const cust_list = await axios.get(APIURL + '/customers', { params: { company_id: props.comp_id } });
        return cust_list.data;
    }

    return (
    <Dialog open={props.show}>
      <DialogActions>
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.25 }}
          onClick={props.handleClose}
          >
          <CloseIcon />
        </IconButton>
      </DialogActions>
        <DialogContent sx={{ paddingTop: '0px', width: 500 }}>
          <TaskForm  {...props}
            isFormValidated={isFormValidated}
            mapRender={"task"}
            // taskList={tasks}
            empList={empList}
            contactList={contactList}
            locList={locList}
            projList={projList}
            taskType={taskTypes}
            updateMapRender={mapRender}
            comp_id={props.comp_id}
            goHome={props.handleClose}/>            
        </DialogContent>
      </Dialog>
    )
}

export default NewTask