import React, { useState, useEffect } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import ClientAttendanceReport from "./ClientAttendanceReport";
import DailyAttendanceSummary from "./DailyAttendanceSummary";
import EmployeeSessionsReport from "./EmployeeSessionsReport";

export default function Scheduler_Reports(props) {
    const [activeKey, setActiveKey] = useState("clientAttendance");

    const handleKeyChange = (event, activeKey) => {
        setActiveKey(activeKey);
    };

    useEffect(() => {
        setActiveKey(props.activeSubKey)
    }, [props.activeSubKey]);

    return (
        <div>
            <div>
                <Typography
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        fontFamily: "Lato",
                        marginBottom: 10,
                        marginLeft: 10
                    }}
                >
                    Schedule Management Reports
                </Typography>
            </div>
            <div>
                <Tabs
                    value={activeKey}
                    onChange={handleKeyChange}
                    variant="scrollable"
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .Mui-selected': {
                            backgroundColor: '#f0f0f0',
                            fontWeight: 'bold',
                        }
                    }}
                >
                    <Tab key={"clientAttendance"} value={"clientAttendance"} label="Client Attendance Report" />
                    <Tab key={"dailyAttendance"} value={"dailyAttendance"} label="Daily Attendance Summary" />
                    <Tab key={"employeeSessions"} value={"employeeSessions"} label="Employee Sessions Report" />
                </Tabs>

                {activeKey === "clientAttendance" && <ClientAttendanceReport {...props} />}
                {activeKey === "dailyAttendance" && <DailyAttendanceSummary {...props} />}
                {activeKey === "employeeSessions" && <EmployeeSessionsReport {...props} />}
            </div>
        </div>
    )
};