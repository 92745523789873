import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function EmployeeDashboard(props) {
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [associatedCustomers, setAssociatedCustomers] = useState(null);
    const [iframeUrl, setiframeUrl] = useState('');
    const [isIframeLoading, setIsIframeLoading] = useState(false);

    useEffect(() => {
        fetchEmployeeData();
    }, []);

    useEffect(() => {
        if (selectedEmployee) {
            fetchMetabaseData();
        }
    }, [selectedEmployee]);

    const fetchEmployeeData = async () => {
        if (props.user.type === 'Admin') {
            axios
                .get(APIURL + '/dropdowns/dropdownType/employeeslist', {
                    params: {
                        comp_id: props.user.comp_id
                    }
                })
                .then((response) => {
                    const empList = response.data.map((emp) => { return { emp_id: emp.id, emp_name: emp.employee_name } });
                    setEmployeeList(empList);
                });
        } else if (props.user.type.includes('Manager')) {
            axios
                .get(APIURL + "/employees/v2/associated", {
                    params: {
                        company_id: props.user.comp_id,
                        employee_id: props.user.emp_id
                    },
                })
                .then((response) => {
                    let associatedEmployees = response.data.directlyManaged.concat(response.data.hr_managed).concat({ emp_id: props.user.emp_id, emp_name: props.user.emp_name });

                    associatedEmployees = associatedEmployees.filter((obj, index, self) => {
                        return index === self.findIndex((t) => (
                            t.emp_id === obj.emp_id
                        ));
                    }).map((emp) => { return { emp_id: emp.emp_id, emp_name: emp.emp_name ? emp.emp_name : emp.emp_first_name + ' ' + emp.emp_last_name } });

                    setEmployeeList(associatedEmployees);
                });
        } else {
            setEmployeeList([{ emp_id: props.user.emp_id, emp_name: props.user.emp_name }]);
        }
    };

    const fetchMetabaseData = async () => {
        setIsIframeLoading(true);

        const associatedCustomers = await axios
            .get(APIURL + "/customers/v2/associated", {
                params: {
                    company_id: props.user.comp_id,
                    employee_id: selectedEmployee
                },
            })
            .then((response) => {
                return response?.data.filter((cus) => cus.cus_status === 'Active').map((cus) => cus.cus_id);
            });

        axios
            .get(APIURL + "/metabase/employeeDashboard", {
                params: { employeeID: selectedEmployee, associatedCustomers: associatedCustomers },
            })
            .then((response) => {
                setiframeUrl(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    };

    return (
        <div>
            <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10, width: '75vw' }}>
                <Autocomplete
                    id="employee-select"
                    options={employeeList}
                    getOptionLabel={(option) => option.emp_name}
                    onChange={(event, value) => setSelectedEmployee(value?.emp_id ? value.emp_id : null)}
                    renderOption={(props, option) => (
                        <li {...props} key={option.emp_id}>
                            {option.emp_name}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Select Employee" />}
                    sx={{ width: 250 }}
                    size="small"
                />
            </div>
            {selectedEmployee &&
                <div style={{ marginLeft: 10, marginRight: 10, width: '75vw', marginTop: '-60px' }}>
                    <iframe
                        src={iframeUrl}
                        style={{ width: '100%', height: '80vh', border: 'none' }}
                        allowTransparency
                        onLoad={handleIframeLoad}
                    />
                </div>
            }
        </div>
    );
};