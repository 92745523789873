import React, { Component } from 'react';
// import HandyCard from '../../container/HandyCard/HandyCard';
import ContactForm from '../forms/ContactForm/ContactForm';
// import ContactDet from '../../container/Contacts/ContactDet'
import ConDetails from '../../container/Contacts/ConDetails'
// import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
// import { Emoji } from 'emoji-mart';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../config'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Redirect } from 'react-router-dom';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


class Contacts extends Component {
  state = {
    contacts: [],
    cust_list: [],
    designation: [],
    contactType: [],
    contactStatus: [],
    states: [],
    selectedContact: null,
    isEdit: false,
    isLoading: true,
    redirectToNew: false,
    loggedToSegment: false,
  }

  // isFormValidated = (objForVal) => {
  //   for (let objKey in objForVal) {
  //     console.log(objKey);
  //     if ((objForVal[objKey] === "" || objForVal[objKey] === undefined)) {
  //       if ((objKey === 'address2' || objKey === 'address_line_2' || objKey === 'Cemail' || objKey === 'customer')) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     } else if (Array.isArray(objForVal[objKey])) {
  //       let arrValid = objForVal[objKey].every(function (objTesting) {
  //         for (let objTestKey in objTesting) {
  //           console.log(objTestKey)
  //           if (objTesting[objTestKey] === "" || objTesting[objTestKey] === undefined) {
  //             if ((objTestKey === 'address_line_2')) {
  //               return true
  //             } else {
  //               return false
  //             }
  //           }
  //         }
  //         return true
  //       })
  //       if (!arrValid) return false
  //     }
  //   }
  //   return true
  // }

  dateComparator = (v1, v2) => moment(v1).diff(moment(v2));
  timeComparator = (v1, v2) => moment(v1, ["h:mm A"]).diff(moment(v2, ["h:mm A"]));

  logToSegment = async () => {
    if(this.state.loggedToSegment) {
      return;
    }
    let page = String(window.location.href).split("/").pop()
    if(page.includes("fieldworker") || page === "" || page === "#") {
        page = "Home";
    } else {
        page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
    }
    console.log(page,this.props.user.emp_id)
    analytics.page(page, {
        title:page,
        path:page,
    }, {
      userId:String(this.props.user.emp_id),
    })
    console.log("logged to segment successfully")
    this.setState({ loggedToSegment : true });
  }

  
  isFormValidated = (objForVal) => {
    var missingField= {
      isValidated:true
    }
    let temp=Object.keys(objForVal)
    if(temp[0]==='selectedEmp')
    {
      
      let selectedEm=objForVal.selectedEmp
      for(let i=0;i<selectedEm.length;i++)
      {
        for (let objKey in selectedEm[i]) {
          if (
            selectedEm[i][objKey] === "" ||
            selectedEm[i][objKey] === undefined ||
            selectedEm[i][objKey].length === 0
          ){
            missingField.isValidated=false;
            missingField.missingKey=objKey
            return missingField
          }
      }
    }
    }
  
    


    for (let objKey in objForVal) {
      if (
        objForVal[objKey] === "" ||
        objForVal[objKey] === undefined ||
        objForVal[objKey] === null ||
        objForVal[objKey].length === 0
      ) {
        if (
          objKey === "Cemail" ||
          objKey === "address2" ||
          objKey === "location_addr2" ||
          objKey === "tComment" ||
          //objKey === "ddd_id" ||
          objKey === "address_line_2" ||
          objKey === "email" ||
          objKey === "medicaid_id" ||
          objKey === "medicaid_eligible" ||
          objKey === "sign_page_date" ||
          objKey === "last_plan_approval_date" ||
          objKey === "last_assess_date" ||
          objKey === "renewal_date" ||
          //objKey === "DDDID" ||
          objKey === "personal_days_off" ||
          objKey === "vacation_days"||
          objKey === "DDD_tier" ||
          objKey === "diagnose_code" ||
          objKey === "func_criteria_met" ||
          objKey === "waiver_program"
        ) {
          // return true
          continue;
        }else if(objForVal["cus_is_company"]===true && objForVal["company_name"]===""){
          missingField.isValidated=false;
          missingField.missingKey="company_name"
          return missingField;
        }
         else {
          console.log(objKey);

          if (
            (objKey === "company_name" &&
              objForVal["first_name"] !== "" &&
              objForVal["last_name"] !== "") ||
            ((objKey === "first_name" || objKey === "last_name") &&
            objForVal["company_name"] !== "" &&  objForVal["company_name"] !== undefined)
          ) {
            continue;
          } else {
            missingField.isValidated=false;
            missingField.missingKey=objKey
            return missingField;
          }
        }
      } else if (Array.isArray(objForVal[objKey])) {
        let arrValid = objForVal[objKey].every(function (objTesting) {
          for (let objTestKey in objTesting) {
            console.log(objTestKey);
            if (
              objTesting[objTestKey] === "" ||
              objTesting[objTestKey] === undefined
            ) {
              if (
                objTestKey === "address_line_2" ||
                objTestKey === "location_addr2"
              ) {
                // return true
                continue;
              } else {
                missingField.isValidated=false;
                missingField.missingKey=objKey
                return missingField;
              }
            }
          }
          return true;
        });
        if (!arrValid) return false;
      } else if (objKey === "active_plan_version") {
        for (const digit of objForVal[objKey]) {
          if (Number.isNaN(parseInt(digit)) && digit !== ".") {
            alert("PROJECT ACTIVE PLAN VERSION has to be a number");
            objForVal[objKey] = "";
            return false;
          }
        }
      }
    }
    
    return missingField;
  };

  onSelect = (id) => {
    let selectedContact = this.state.contacts.find((con) => (con.con_id === id))
    this.setState({ selectedContact: selectedContact, isEdit: false })
  }

  onDel = async (id) => {
    console.log(id)
    let msg = await axios.delete(APIURL + '/contacts', { data: { id: id, bval: false } })
    console.log(msg)
    if (msg.data.code === 200) {
      let contacts = this.state.contacts.filter((con) => (con.con_id !== id))
      this.setState({ contacts })
      if (!!this.state.selectedContact && id === this.state.selectedContact.con_id) {
        this.setState({ selectedContact: null })
      }
    } else {
      return
    }
  }

  onEdit = () => {
    this.setState({ isEdit: true })
  }

  updateContact = (basic, other) => {
    // update the fields using map 
    let contacts = this.state.contacts.map((con) => {
      if (con.con_id === this.state.selectedContact.con_id) {
        con = { ...con, con_first_name: basic.first_name, con_last_name: basic.last_name, con_Addr_line1: basic.address_line_1, con_Addr_line2: basic.address_line_2, con_city: basic.city, con_state: basic.state, con_zip: basic.zip, con_phone: basic.phnNumList.map((number) => { return number.country_code + number.phone }).join(), con_designation: other.designation, con_status: other.status, con_contact_type: other.contact_type, con_first_contact_date: other.first_contact_date, con_last_contact_date: other.last_contact_date }
      }
      return con
    })
    // setstate 
    this.setState({ contacts })
  }

  async componentDidMount() {
    this.props.checkTokenExpiry()
    this.logToSegment();
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
    }
    let contacts = await axios.get(APIURL + '/contacts', { params: { com_id: this.props.comp_id } })
    let dropdowns = {
      cust_list: (await axios.get(APIURL + '/customers', { params: { company_id: this.props.comp_id } })).data,
      designation: (await axios.get(APIURL + '/dropdowns/dropdownType/designation', { params: { comp_id: this.props.comp_id } })).data,
      contactType: (await axios.get(APIURL + '/dropdowns/dropdownType/contactType', { params: { comp_id: this.props.comp_id } })).data,
      contactStatus: (await axios.get(APIURL + '/dropdowns/dropdownType/contactStatus', { params: { comp_id: this.props.comp_id } })).data,

    }
    this.setState({
      contacts: contacts.data, cust_list: dropdowns.cust_list, selectedContact: contacts.data[0], designation: dropdowns.designation, contactType: dropdowns.contactType, contactStatus: dropdowns.contactStatus,
      // states: contacts.data.states, 
      isLoading: false
    })
  }

  render() {
    let cols = [
      {
        field: 'id',
        headerName: ' ',
        width: 50,
        hide: false,
        renderCell: (params) => {
          // console.log('from render cell ', params.row.id)
          return (
            <div>
              <i style={{ color: '#3FA7D6', margin: 10 }} onClick={() => this.onSelect(params.value)} className={!!this.state.selectedContact && this.state.selectedContact.con_id === params.value ? "fas fa-check-square" : "far fa-square"}></i>
            </div>
          )
        }
      },
      {
        field: 'con_name',
        headerName: 'NAME',
        width: 200,
        hide: false,
        // renderCell : (params) =>{
        //   // console.log('from render cell ', params.value)
        //   return (
        //       params
        //   )}
      },
      {
        field: 'con_phone',
        headerName: 'PHONE',
        width: 150,
        hide: false
      },
      {
        field: 'con_created_at',
        headerName: 'CREATED DATE',
        width: 200,
        hide: false,
        sortComparator: this.dateComparator
      },
      {
        field: 'con_updated_at',
        headerName: 'LAST UPDATE DATE',
        width: 200,
        hide: false,
        sortComparator: this.dateComparator
      },

    ]

    let rows = this.state.contacts.map((con) => {

      let conDets = {
        id: con.con_id,
        con_name: `${con.con_first_name} ${con.con_last_name}`,
        con_phone: con.con_phone.split(',')[0],
        // con_phone: phnRec.length === 10?`${phnRec.split(',')[0].slice(0,3)}-${phnRec.split(',')[0].slice(3,6)}-${phnRec.split(',')[0].slice(6,10)}` :phnRec.length === 12?`${con.con_phone_number.split(',')[0].slice(2,5)}-${con.con_phone_number.split(',')[0].slice(5,8)}-${con.con_phone_number.split(',')[0].slice(8,12)}`:'-',  
        // details: {
        //     onView : this.onView,
        //     onEdit : this.onEdit
        //  },
        con_status: con.con_is_active,
        con_updated_at: !con.con_updated_at ? '-' : moment(con.con_updated_at).format('MM/DD/YYYY'),
        con_created_at: !con.con_created_at ? '-' : moment(con.con_created_at).format('MM/DD/YYYY')
      }
      return conDets
    })

    let contList = (
      <DataGrid
        initialState={{ density: 'compact' }}
        onRowSelected={(params) => this.onSelect(params.data.id)}
        width={'90%'}
        style={{ color: '#00000', maxHeight: '900px' }}
        rows={rows}
        columns={cols}
        showToolbar slots={{ toolbar: GridToolbar, color: '#3fadf6' }}
        autoHeight={false}
        pageSize={!this.props.pagesize ? 20 : this.props.pagesize}
        onRowClick={(params) => this.onSelect(params.id)}
      />
    )

    // this.state.contacts.map((con)=> (<HandyCard selected={!!this.state.selectedContact?this.state.selectedContact.con_id === con.con_id:false} contactUpgrade contactTab key={con.con_id} onDel={this.onDel} onSelect={this.onSelect} data={con}/>))
    return (
      <div style={{ height: '100vh', width: '100%', backgroundColor: '#fafafa' }} className="d-flex row justify-content-start">
        <div className="col-lg-6 col-sm-12" style={{ borderRight: '1px solid #3FA7D6', margin: '1rem', maxWidth: '33%', }}>
          <div className="d-flex flex-row justify-content-between">
            <h4>Contacts</h4>
            {!!this.state.selectedContact && <button className='btn btn-sm btn-outline-danger' onClick={() => { this.setState({ selectedContact: null }) }}>Cancel</button>}
          </div>
          {!this.state.selectedContact &&
            <div style={{ marginBottom: '1rem' }} className="jumbotron jumbotron-fluid bg-white">
              <div className="container">
                <h3 style={{ fontFamily: 'Lato' }} className="text-center mb-5">No Contact Selected</h3>
                <hr style={{ background: "#3FA7D6" }} />
                <p style={{ fontFamily: 'Lato', fontSize: 12 }} className="text-muted text-center mt-5">Once you add a Contact, its basic information<br /> includes phone number, address,email and<br />  more details, will show here.</p>
              </div>
            </div>
          }
          {this.state.selectedContact && !this.state.isEdit &&
            <ConDetails data={this.state.selectedContact} onDel={this.onDel} onEdit={this.onEdit} />
          }
          {this.state.isEdit && this.state.selectedContact &&
            <ContactForm
              edit
              comp_id={this.props.comp_id}
              history={this.props.history}
              onUpdate={this.updateContact}
              isFormValidated={this.isFormValidated}
              data={this.state.selectedContact}
              states={this.state.states}
              contactType={this.state.contactType}
              contactStatus={this.state.contactStatus}
              designation={this.state.designation}
              customerList={this.state.cust_list}
            />
          }
        </div>
        <div style={{ maxWidth: '75%', marginTop: 30 }} className='col-lg-7 col-sm-12 d-flex flex-column justify-content-start'>
        <button disabled={this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator'} style={{ height: 30, margin:5}} className={`btn btn-sm btn-primary w-25 ${this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator' ? 'd-none' : ''}`} onClick={(e) => { this.setState({ redirectToNew: true }); this.props.changeScreen('new', 'con'); }}><i style={{margin:5}} className='fas fa-plus'></i>Add New Contact</button>
          {!this.state.isLoading && this.state.contacts.length !== 0 && contList}
          {!this.state.isLoading && this.state.contacts.length === 0 &&
            <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '500px' }}>
              <img src='./Contacts.png' alt='Contacts' style={{ width: '10rem', height: '10rem', margin: 10 }} />
              <h5 style={{ fontFamily: 'Lato', marginTop: 48, marginBottom: 30 }} className='text-center'>You haven’t added any contacts yet </h5>
              <p style={{ fontSize: 14, width: '70%', fontFamily: 'Lato' }} className='text-muted text-center text-wrap'>Add your clients’ contact info to keep all essential details in one place.</p>
              <button disabled={this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator'} style={{ height: 30 }} className={`btn btn-sm btn-primary w-25 ${this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator' ? 'd-none' : ''}`} onClick={(e) => { this.setState({ redirectToNew: true }); this.props.changeScreen('new', 'con')  }}>Create a Contact</button>
            </div>}
          {!!this.state.isLoading && <div className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div></div>}

          {/* {contList} */}
        </div>
        {this.state.redirectToNew &&
            <Redirect to="/new" />
        }
      </div >
    )
  }
}

export default Contacts;