import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function ActiveCustomerReport(props) {
    const [gridId, setGridId] = useState(41);
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports", {
                params: { empID: props.user.emp_id },
            })
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "Active Customer Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        axios
            .get(APIURL + "/reports/getActiveCustomers", {
                params: { com_id: props.user.comp_id, emp_id: props.user.emp_id },
            })
            .then((response) => {
                setData(response?.data.length !== 0 ? response?.data : []);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const columns = [
        { field: "cus_id", headerName: "Customer ID", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        { field: "gender", headerName: "Gender", width: 60 },
        {
            field: "cus_address",
            headerName: "Customer Address",
            width: 400,
        },
        { field: "cus_phone", headerName: "Customer Phone", width: 120 },
        { field: "cus_email", headerName: "Customer Email", width: 200 },
        { field: "DDDID", headerName: "DDD ID", width: 70 },
        { field: "cus_local_id", headerName: "Customer Local ID", width: 130 },
        {
            field: "cus_acquired_date",
            headerName: "Customer Acquired Date",
            width: 170,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "last_plan_approval_date",
            headerName: "Last Plan approval Date",
            width: 165,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "renewal_date",
            headerName: "Renewal Date",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "medicaid_eligible", headerName: "Medicaid eligible", width: 120 },
        { field: "medicaid_id", headerName: "Medicaid ID", width: 90 },
        { field: "waiver_program", headerName: "Waiver Program", width: 120 },
        { field: "PrimaryDiagnosisCode", headerName: "Primary Diagnostic Code", width: 170 },
        {
            field: "MedicaidEndDate",
            headerName: "Medicaid End Date",
            width: 135,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "AgencyAssignDate",
            headerName: "Agency Assign Date",
            width: 140,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "County", headerName: "County", width: 60 },
        {
            field: "LastAnnualVisitDate",
            headerName: "Last Annual Visit",
            width: 130,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
    ]

    let rows = data

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
            empID: props.user.emp_id
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    return (
        <div style={{ margin: 10, width: '75vw' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div />
                    <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                        <img
                            src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                            alt="barchart"
                            style={{
                                fontFamily: "Lato",
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                                margin: 5
                            }}
                            onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                        />
                    </Tip>
                </div>
                <div style={{ height: '75vh' }}>
                    <ControlledDataGrid
                        gridId={gridId}
                        user={props.user}
                        initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                        apiRef={apiRef}
                        getRowId={(row) => row.cus_id}
                        rows={rows}
                        columns={columns}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        loading={isLoading}
                    />
                </div>
            </div>
            <Snackbar
                open={successText !== ""}
                autoHideDuration={30000}
                onClose={() => setSuccessText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"success"}
                    elevation={6}
                    variant="filled"
                >
                    {successText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setSuccessText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorText !== ""}
                autoHideDuration={30000}
                onClose={() => setErrorText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"error"}
                    elevation={6}
                    variant="filled"
                >
                    {errorText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setErrorText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
        </div>
    );
}