import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Alert, Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function MTRejectionReport(props) {
    const [gridId, setGridId] = useState(67);
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, [currentMonth]);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports", {
                params: { empID: props.user.emp_id },
            })
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "MT Rejection Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        axios
            .get(APIURL + "/reports/getMTRejection", {
                params: { com_id: props.comp_id, emp_id: props.user.emp_id, period: moment(currentMonth).format('MMM YYYY') },
            })
            .then((response) => {
                setData(response?.data.length !== 0 ? response?.data : []);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const columns = [
        { field: "monthly_monitoring_id", headerName: "Monthly Monitoring ID", hide: true },
        { field: "emp_name", headerName: "Employee Name", width: 200 },
        { field: "submitted_on", headerName: "MT Submit Date", width: 200 },
        { field: "mt_type", headerName: "MT Type", width: 200 },
        { field: "mt_document_type", headerName: "MT Document Type", width: 200 },
        { field: "rejected_on", headerName: "MT Rejected Date", width: 200 },
        { field: "resubmission_on", headerName: "MT Resubmission Date", width: 200 },
        { field: "approved_on", headerName: "MT Approval Date", width: 200 },
        { field: "mt_comment", headerName: "Comment", width: 200 },
    ];

    let rows = data.map((data, idx) => {
        const MTSubmitDate = data.MTSubmitDate ? moment(data.MTSubmitDate).format('MM/DD/YYYY') : '-';
        const MTReSubmitDate = data.MTReSubmitDate ? data.MTReSubmitDate.length > 1 ? moment(data.MTReSubmitDate[0]).format('MM/DD/YYYY') : '-' : '-';
        const MTRejectedDate = data.MTRejectedDate ? moment(data.MTRejectedDate).format('MM/DD/YYYY') : '-';
        const MTApprovedDate = data.MTApprovedDate ? moment(data.MTApprovedDate).format('MM/DD/YYYY') : '-';

        let taskData = {
            monthly_monitoring_id: data.monthly_monitoring_id,
            emp_name: data.emp_first_name + ' ' + data.emp_last_name,
            submitted_on: MTSubmitDate,
            mt_type: data.is_annual ? 'Annual' : data.mt_type,
            mt_document_type: data.mt_type,
            rejected_on: MTRejectedDate,
            resubmission_on: MTReSubmitDate,
            approved_on: MTApprovedDate,
            mt_comment: data.comment
        };
        return taskData;
    });

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
            empID: props.user.emp_id
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    return (
        (<div style={{ margin: 10, width: '75vw' }}>
            <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div id="report-main" style={{ marginBottom: 20 }}>
                        <Button
                            size="large"
                            className="DayButton"
                            onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                        >
                            <i className="fas fa-arrow-left"></i>
                        </Button>
                        <LocalizationProvider
                            class="DatePickerParent"
                            dateAdapter={AdapterDateFns}
                        >
                            <DatePicker
                                label="Select Date"
                                views={["year", "month"]}
                                value={new Date(currentMonth)}
                                onChange={(newValue) => {
                                    onDateChange(newValue);
                                }}
                                slotProps={{ textField: { variant: 'standard' } }}
                            />
                        </LocalizationProvider>
                        <Button
                            size="large"
                            className="DayButton"
                            onClick={() => onDateChange(currentMonth.add(1, "month"))}
                        >
                            <i className="fas fa-arrow-right"></i>
                        </Button>
                    </div>
                    <div>
                        <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                            <img
                                src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                                alt="barchart"
                                style={{
                                    fontFamily: "Lato",
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    cursor: "pointer",
                                    margin: 5
                                }}
                                onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                            />
                        </Tip>
                    </div>
                </div>
            </div>
            <div>
                <div style={{ height: '70vh' }}>
                    <ControlledDataGrid
                        gridId={gridId}
                        user={props.user}
                        initialState={{ sorting: { sortModel: [{ field: "monthly_monitoring_id", sort: "desc" }] } }}
                        apiRef={apiRef}
                        getRowId={(row) => row.monthly_monitoring_id}
                        rows={rows}
                        columns={columns}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        loading={isLoading}
                    />
                </div>
            </div>
            <Snackbar
                open={successText !== ""}
                autoHideDuration={30000}
                onClose={() => setSuccessText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"success"}
                    elevation={6}
                    variant="filled"
                >
                    {successText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setSuccessText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorText !== ""}
                autoHideDuration={30000}
                onClose={() => setErrorText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"error"}
                    elevation={6}
                    variant="filled"
                >
                    {errorText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setErrorText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
        </div>)
    );
}