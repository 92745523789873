import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { TextField, Select, FormControl, MenuItem, InputLabel, Box } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GoalCard from './GoalCard';
import CircularProgress from '@mui/material/CircularProgress';
import CustomerGoalForm from "../../../components/forms/CustomerGoalForm/CustomerGoalForm";
import PermittedTaskForm from "../../../components/forms/PermittedTaskForm/PermittedTaskForm";
import { Dialog, DialogTitle, DialogContent, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function CustomerGoals(props) {
    const [isLoading, setLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const [customerGoals, setCustomerGoals] = useState([])
    const [openNewGoalDialog, setOpenNewGoalDialog] = useState(false)
    const [openNewPermittedTaskDialog, setOpenNewPermittedTaskDialog] = useState(false)

    useEffect(() => {
        async function fetchData() {
            const response = (await axios.get(APIURL + `/customer_goal?customer_id=${props.cus_id}`)).data
            setCustomerGoals(response)
            setLoading(false)
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const response = (await axios.get(APIURL + `/customer_goal?customer_id=${props.cus_id}`)).data
            setCustomerGoals(response)
            setLoading(false)
        }
        if(refreshData){
            console.log("Refresh Goals Page")
            fetchData();
            setRefreshData(false);
        }
    }, [refreshData]);

    const getGoalCards = () => {
        let goalCards = customerGoals.map(goal => {
            if(goal.goal_status == 3){
                return null;
            }
            return (
                <div key={goal.customer_goal_id} style={{ marginBottom: '10px' }}> {/* Add margin to each card container */}
                    <GoalCard user={props.user} refreshData={refreshData} setRefreshData={setRefreshData} goal={{ 
                        goalId: goal.customer_goal_id, 
                        customerId: goal.customer_id, 
                        projectId: goal.project_id, 
                        goalTitle: goal.goal_title, 
                        goalDetails: goal.goal_detail, 
                        status: goal.goal_status, 
                        progress: goal.goal_progress
                    }} />
                </div>
            );
        });
        return goalCards;
    }

    return(
        <div id="customer-goals-main">
            <Button inline style={{ height: '40px' }} onClick={() => setOpenNewGoalDialog(true)} variant="contained">New Goal</Button>
            <Button inline style={{ height: '40px', marginLeft: '10px' }} onClick={() => setOpenNewPermittedTaskDialog(true)} variant="contained">New Permitted Task Type</Button>
            <div style={{ 'border': '1px solid #3fa7d6', backgroundColor: '#f9f9f9', borderRadius: '10px', minHeight: '100px', padding: '15px 20px', marginTop: '10px', height: 'auto' }}>
                <h5 style={{ marginBottom: '15px' }}>Goals</h5>
                {isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div> : getGoalCards()}
            </div>
            <Dialog open={openNewGoalDialog} onClose={() => setOpenNewGoalDialog(false)} maxWidth="md" fullWidth>
                <DialogTitle>
                    Add New Goal
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenNewGoalDialog(false)}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <CustomerGoalForm user={props.user} customer_id={props.cust_details.cus_id} onSubmit={() => { setOpenNewGoalDialog(false); setRefreshData(true);}}/>
                </DialogContent>
            </Dialog>
            <Dialog open={openNewPermittedTaskDialog} onClose={() => setOpenNewPermittedTaskDialog(false)} maxWidth="md" fullWidth>
                <DialogTitle>
                    Add New Permitted Task
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenNewPermittedTaskDialog(false)}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <PermittedTaskForm user={props.user} customer_id={props.cust_details.cus_id} onSubmit={() => { setOpenNewPermittedTaskDialog(false); setRefreshData(true); console.log('12') }}/>
                </DialogContent>
            </Dialog>
        </div>
    )
}