import React, { Component } from 'react';
import { Form, Col, Alert } from 'react-bootstrap';
import { Button, Autocomplete, TextField } from '@mui/material';
import HandyCard from '../../HandyCard/HandyCard';
import axios from 'axios';
import { APIURL } from '../../../config'
import "./Contacts.css"


class ContactsPage extends Component {
    state = {
        contacts: [],
        contactsList: [],
        deletedContact: null,
        timer: null,
        conid: null
    }

    async componentDidMount() {
        let projCont = await axios.get(APIURL + "/project/projCont", { params: { proj_id: this.props.projId } })
        let contacts = await axios.get(APIURL + "/contacts", { params: { comp_id: this.props.comp_id } })
        this.setState({ contacts: projCont.data, contactsList: contacts.data.filter((con) => (!!con.con_is_active)) })
    }

    onConSelect = (e) => {
        this.setState({ conid: e.target.value })
    }

    onAddContact = async () => {
        //Check if exist in States
        if (!this.state.conid) {
            return
        }
        let conExist = this.state.contacts.find((con) => con.con_id === +this.state.conid)
        if (!conExist) {
            let msg = await axios.put(APIURL + '/project/updateProjContact', { projId: this.props.projId, contId: this.state.conid })
            console.log(msg.data)
            if (msg.data.code === 200) {
                //find contact in list 
                let addContact = this.state.contactsList.find((con) => ((con.con_id === +this.state.conid) || (con.id === +this.state.conid)))
                console.log(addContact)
                if (!!addContact.id) {
                    addContact = { ...addContact, con_id: addContact.id, con_first_name: addContact.contact_name.split(' ')[0], con_last_name: addContact.contact_name.split(' ')[1] }
                    // addContact = {con_phone: addContact.con_phone,con_first_name: 'Reload',con_last_name: 'Reload',con_id: addContact.id, con_designation: 'Reload'}
                }
                // push in state
                this.setState({ conid: null, contacts: [...this.state.contacts, addContact] })
            }
        } else {
            //Alert User
            this.setState({ conid: null })
            return
        }
    }

    onDelContact = async (id) => {
        // console.log(id)
        if (this.state.timer) {
            alert("Another delete is in process, wait for the undo dailogue to disappear")
        } else {
            let oldContactList = [...this.state.contacts]
            oldContactList = oldContactList.filter(con => con.con_id !== id)
            this.setState({
                deletedContact: this.state.contacts.find(con => con.con_id === id),
                timer: setTimeout(() => {
                    this.deleteRequest(this.state.deletedContact.con_id)
                    clearTimeout(this.state.timer)
                    this.setState({
                        timer: null,
                        deletedContact: null
                    })
                }, 5000),
                contacts: oldContactList
            })
        }


    }

    deleteRequest = (id) => {
        axios.delete(APIURL + '/project/updateProjContact', { data: { projId: this.props.projId, contId: id } })
            .then(res => {
                if (res.status === 200) {
                    alert("Contact Deleted")
                } else {
                    alert("Something went wrong.")
                }
            })
            .catch(error => console.log(error))
    }

    showAlert = () => {
        if (this.state.timer) {
            return (
                <Alert variant="info" onClose={() => this.onDismissAlert()} style={{ marginTop: "10px" }} dismissible>
                    Contact Deleted. <span className="undo" onClick={() => this.undoDelete()}>Undo?</span>
                </Alert>
            )
        }
    }

    onDismissAlert = () => {
        this.deleteRequest(this.state.deletedContact.con_id)
        clearTimeout(this.state.timer)
        this.setState({
            timer: null,
            deletedContact: null
        })
    }

    undoDelete = () => {
        let oldContactList = [...this.state.contacts]
        oldContactList.push(this.state.deletedContact)
        clearTimeout(this.state.timer)
        this.setState({
            contacts: oldContactList,
            deletedContact: null,
            timer: null
        })
    }

    render() {
        // Convert contactsList into options for Autocomplete:
        const contactOptions = this.state.contactsList.map(con => ({
            id: con.id || con.con_id,
            label: con.contact_name || (con.con_first_name + ' ' + con.con_last_name)
        }));
        return (
            <div className="ConPage">
                {/* Updated header with left-aligned controls */}
                <div style={{ marginBottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                        <Autocomplete
                            options={contactOptions}
                            sx={{ width: 250 }}
                            renderInput={(params) => <TextField {...params} label="Select Contact" variant="outlined" size="small" />}
                            value={
                                contactOptions.find(option=> option.id === this.state.conid) || null
                            }
                            onChange={(event, newValue) => {
                                this.setState({ conid: newValue ? newValue.id : null });
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.onAddContact()}
                            style={{ height: '40px' }}
                        >
                            Add Contact
                        </Button>
                    </div>
                </div>
                {/* ...existing contacts list container... */}
                <div style={{ padding: '15px 20px', border: '1px solid #3fa7d6', borderRadius: '10px', background: '#F9F9F9', overflow: 'auto' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <h5 style={{ margin: 0 }}>Contacts</h5>
                    </div>
                    {this.state.contacts.length !== 0 ? this.state.contacts.map((conData) => (
                        <div key={conData.con_id}>
                            <HandyCard onDel={this.onDelContact} contact data={conData} />
                        </div>
                    )) : (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '300px', margin: 5 }}>
                            <img src='./line.png' alt='conttAB' style={{ width: '10rem', height: '10rem' }} />
                            <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 38, marginBottom: 28 }}>
                                No contacts found.
                            </h5>
                            <p style={{ fontFamily: 'Lato', fontSize: 14 }} className="text-muted text-center">
                                We could not find any contacts in this project. Please add contacts to view others involved.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
};

export default ContactsPage;