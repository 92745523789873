import React from 'react'
import './styles/timesheet.css'
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Icon from '@mui/material/Icon';
import Timesheet from './Timesheet'
import axios from 'axios'
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Modal } from 'react-bootstrap'
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { APIURL } from '../../../../../config';
import DurationPicker from '../../../../../components/TimeManagement/DurationPicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ViewFormModal from '../../../../../components/forms/JsonForm/ViewFormModal';
import dayjs from 'dayjs';

axios.defaults.headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Origin': '*'
    'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Headers": '*',
    "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
}

export default class TimesheetWrapper extends React.Component {

    state = {
        startDate: moment().format('YYYY-MM-DD'),
        timesheetData: null,
        edit: false, add: false, isloading: false,
        editDataForm: { projDets: null, start_time: moment().format('YYYY-MM-DDThh:mm'), end_time: moment().format('YYYY-MM-DDTHH:mm'), duration: null, tNotes: '', tType: null, },
        addDataForm: { tTitle: '', sDate: moment().format('YYYY-MM-DDTHH:mm'), eDate: moment().format('YYYY-MM-DDTHH:mm'), duration: null, tType: null, tNotes: '' },
        projects: [], typeList: [], location: [],
        isSupport: this.props.user?.comp_settings.find(setting => (setting.feature_id === 101 || setting.feature_id === 104) && setting.feature_value === 'primaryBusinessType') ? true : false,
        empTasksAvailable: false,
        currentTask: null,
        showTaskForms: false,
        selectedProject: null,
        currentDay: moment().format('YYYY-MM-DDTHH:mm'),
    }

    async componentDidMount() {
        await this.getData()
    }

    async componentDidUpdate(prevProps, prevState) {
        console.log('COMPONENT DID UPDATE')
        if (!this.state.empTasksAvailable) {
            if (prevState.addDataForm.proj_id !== this.state.addDataForm.proj_id) {
                console.log('UPDATING TASKS')
                let customerID = this.state.projects.find(pro => pro.pid === this.state.addDataForm.proj_id)?.cus_id;
                let custTasks = await axios.get(APIURL + "/customers/CustomerTasks", { params: { comp_id: this.props.user.comp_id, customer_id: customerID } })
                let cusTaskTypes = []
                if (custTasks.data.length !== 0) {
                    console.log('TASK TYPES FOR CUSTOMER')
                    custTasks.data.forEach((task) => {
                        cusTaskTypes.push(task.EntityValue)
                    })
                    this.setState({ typeList: cusTaskTypes, addDataForm: {...this.state.addDataForm, tType: null} })
                }
                else {
                    console.log('NO TASK TYPES FOR CUSTOMER')
                    let taskTypes = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.user.comp_id, employeeType: this.props.user.type } })
                    this.setState({ typeList: taskTypes.data, addDataForm: {...this.state.addDataForm, tType: null} })
                }
            }
        }
    }

    addEntry = async () => {
        let locDets = this.state.location.find((loc) => loc.id === this.state.projects.find((pro) => pro.pid === this.state.addDataForm.proj_id).proLoc[0].id)
        let minutes = this.state.isSupport?(this.state.addDataForm.duration.split(":").map(function(x) { return parseInt(x, 10); }).reduce(function(a, b) { return a * 60 + b; })):0
        
        let data = {
            empName: this.props.user.emp_name,
            company_id: this.props.user.comp_id,
            tTitle: this.state.addDataForm.tTitle !== '' ? this.state.addDataForm.tTitle : `${this.props.user.emp_name} - ${moment().format('YYYY-DD-MM,HH:mm')} `,
            tDesc: 'Time Sheet Entry',
            tProject: this.state.addDataForm.proj_id,
            tPreReq: 'NULL',
            tType: this.state.addDataForm.tType,
            tStrtDate: this.state.isSupport?moment(this.state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD HH:mm:ss'):moment(this.state.addDataForm.sDate).format('YYYY-MM-DD HH:mm:ss'),
            tEndDate: this.state.isSupport?moment(this.state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss'):moment(this.state.addDataForm.eDate).format('YYYY-MM-DD HH:mm:ss'),
            tStartTime: this.state.isSupport?moment(this.state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD HH:mm:ss'):moment(this.state.addDataForm.sDate).format('YYYY-MM-DD HH:mm:ss'),
            tEndTime: this.state.isSupport?moment(this.state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss'):moment(this.state.addDataForm.eDate).format('YYYY-MM-DD HH:mm:ss'),
            tLoc: locDets.id,
            tLoc: locDets.id,
            tActive: 1,
            tAssTo: this.props.user.emp_id,
            tPriority: 'Low',
            tIsBillable: true,
            tNotes: this.state.addDataForm.tNotes,
            user_id: this.props.user.emp_id
        };
        console.log(data)
        let tasRes = await axios.post(APIURL + '/task', data)
        
        var tasktype=this.state.addDataForm.tType
        console.log("tasktpye:"+tasktype)
        
        if (tasktype.includes('Phone'))
        {
            let calldata =
            {
                recp_id: this.state.addDataForm.cust_id,
                tas_id: tasRes.data.id,
                recp_type: 'customer',
                pro_id: this.state.addDataForm.proj_id,
                emp_id: this.props.user.emp_id,
                start_time: this.state.isSupport?moment(this.state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD HH:mm:ss'):moment(this.state.addDataForm.sDate).format('YYYY-MM-DD HH:mm:ss'),
                end_time: this.state.isSupport?moment(this.state.addDataForm.sDate).set({hour: 9, minute: 0, second: 0, millisecond: 0}).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss'):moment(this.state.addDataForm.eDate).format('YYYY-MM-DD HH:mm:ss'),
            }
            console.log(calldata)
            let tasResC = await axios.post(APIURL + '/calls', calldata)
            if(tasResC.data.code === 200){console.log('Successful')}
            else{console.log('Not Successful')}
        }
        
        // let tasRes = {data:{code: 200, message: 'Task Insert Failed'}}
        if (tasRes.data.code === 200) {
            let empLocData = {
                assignedById: this.props.user.emp_id,
                projId: data.tProject,
                empId: data.tAssTo,
                empName: this.props.user.emp_name,
                companyId: this.props.user.comp_id,
                taskName: this.state.addDataForm.tTitle !== '' ? this.state.addDataForm.tTitle : `${this.props.user.emp_name} - ${moment().format('YYYY-DD-MM,HH:mm')} `,
                locId: data.tLoc,
                taskId: tasRes.data.id,
                emploc_lat: parseFloat(locDets.location_lat),
                emploc_lon: parseFloat(locDets.location_lon),
                start_time: data.tStartTime,
                end_time: data.tEndTime,
                timesheet_status: 1
            }
            let tsRes = await axios.post(APIURL + '/timesheets', { ...empLocData })
            // let tsRes = {data:{code: 200, message: 'Timesheet Insert Failed'}}
            if (tsRes.data.code === 200) {
                console.log('Successful', data, empLocData)
                let tsEntry = { start_time: empLocData.start_time, end_time: empLocData.end_time, id: tsRes.data.id, projDets: { pro_id: empLocData.projId }, locDets: { loc_id: locDets }, taskDets: { tas_title: data.tTitle, tas_id: empLocData.taskId, tas_start_date: data.tStrtDate, tas_end_date: data.tEndDate, tas_start_time: data.tStartTime, tas_end_time: data.tEndTime } }
                this.setState({ addFormError: false, success: true, add: false, timesheetData: [...this.state.timesheetData, tsEntry] })
                this.getData()
            }
            else {
                console.log('Not Successful', data, empLocData)
                this.setState({ addFormError: true, add: true })
                return
            }
        } else {
            console.log('alert user of error')
            this.setState({ addFormError: true, add: true })
            return
        }

    }

    calCumulativeTimeSpent = (data, type) => {
        var TotalTime = moment()
        if (!!data) {
            data.forEach((taskData) => {
                if (type === 'billed') {
                    let CheckInTime;
                    let CheckoutTime;
                    let taskTime;
                    if (!taskData.locDets && !!taskData.taskDets && taskData.taskDets.tas_type === 'Phone Call') {
                        CheckInTime = moment(taskData.start_time)
                        CheckoutTime = moment(taskData.end_time)
                        taskTime = moment.duration(CheckoutTime.diff(CheckInTime))
                        TotalTime.add(taskTime)
                    } else if (!!taskData.locDets && taskData.taskDets.tas_type !== 'Phone Call') {
                        CheckInTime = moment(taskData.start_time)
                        CheckoutTime = moment(taskData.end_time)
                        taskTime = moment.duration(CheckoutTime.diff(CheckInTime))
                        TotalTime.add(taskTime)
                    }
                    else if (!taskData.locDets && !taskData.taskDets) {
                        CheckInTime = moment(taskData.start_time)
                        CheckoutTime = moment(taskData.end_time)
                        taskTime = moment.duration(CheckoutTime.diff(CheckInTime))
                        TotalTime.add(taskTime)
                    }
                }

                else {
                    if (!!taskData.locDets) {
                        TotalTime.add(this.checktaskBillableHours(taskData.taskDets.tas_start_date, taskData.taskDets.tas_end_date, taskData.taskDets.tas_start_time, taskData.taskDets.tas_end_time))
                    }
                    // console.log(this.checktaskBillableHours(taskData.taskDets.tas_start_date,taskData.taskDets.tas_end_date,taskData.taskDets.tas_start_time,taskData.taskDets.tas_end_time))
                }
            })
            let time = moment.duration(TotalTime.diff(moment())).asHours()
            return time.toString().substring(0, time.toString().lastIndexOf('.') + 3)
        } else {
            return 0
        }

    }

    checktaskBillableHours = (start, end, stime, etime) => {
        let bh
        // console.log('bh',start,end,stime,etime)
        if (moment(stime).isValid()) {
            bh = moment.duration(moment(etime).diff(moment(stime)))
        } else {
            let actualStime = parseInt(stime.slice(0, 2));
            let actualEtime = parseInt(etime.slice(0, 2));

            if (stime.slice(-2) === 'PM' && actualStime !== 12) {
                actualStime += 12
            } else if (stime.slice(-2) === 'AM' && actualStime === 12) {
                actualStime = 0
            }
            if (etime.slice(-2) === 'PM' && actualEtime !== 12) {
                actualEtime += 12
            } else if (etime.slice(-2) === 'AM' && actualEtime === 12) {
                actualEtime = 0
            }
            bh = moment.duration(moment(moment(end).add(actualEtime, 'hours')).diff(moment(start).add(actualStime, 'hours')))
        }
        // console.log(bh.asHours(),stime,etime)
        return bh
    }

    updateEntry = async (id) => {
        let minutes = this.state.isSupport?(this.state.editDataForm.duration.split(":").map(function(x) { return parseInt(x, 10); }).reduce(function(a, b) { return a * 60 + b; })):0

        let empLocData = {
            empId: this.props.user.emp_id,
            empName: this.props.user.emp_name,
            companyId: this.props.user.comp_id,
            taskName: this.state.editDataForm.tTitle !== '' ? this.state.editDataForm.tTitle : `${this.props.user.emp_name} - ${moment().format('YYYY-DD-MM,HH:mm')} `,
            proj_id: this.state.editDataForm.projDets.pro_id,
            start: this.state.isSupport?moment(this.state.editDataForm.start_time).set({hour: 9, minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD HH:mm:ss'):moment(this.state.editDataForm.start_time.replace(/Z/g, "")).format('YYYY-MM-DD HH:mm:ss'),
            end: this.state.isSupport?moment(this.state.editDataForm.start_time).set({hour: 9, minute: 0, second: 0, millisecond: 0}).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss'):moment(this.state.editDataForm.end_time.replace(/Z/g, "")).format('YYYY-MM-DD HH:mm:ss'),
            id: id,
            tNotes: this.state.editDataForm.tNotes || 'N/A',
        }     
        console.log(empLocData.taskName)
        let response = await axios.put(APIURL + '/timesheets', { ...empLocData })
        
        try{
            let response2 = await axios.put(APIURL + '/timesheets/v2', { 
                task_id: this.state.editDataForm.taskDets.tas_id,
                title: empLocData.taskName,
                tas_type: this.state.editDataForm.tType,
             })
        } catch(err){

        }
        
        // let response = {data: {code: 200}}
        if (response.data.code === 200) {
            console.log(empLocData, response.data)
            let updateTs = this.state.editDataForm
            let tsData = this.state.timesheetData.map((ts) => {
                if (ts.id === id) {
                    return { ...updateTs, start_time: moment(updateTs.start_time), end_time: moment(updateTs.end_time), projDets: { ...updateTs.projDets, pro_title: this.state.projects.find((pro) => pro.pid === updateTs.projDets.pro_id).pname } }
                } else {
                    return ts
                }
            })
            this.setState({ timesheetData: tsData })
            this.setState({ editFormError: false, editDataForm: {}, edit: false, success: true })
            // this.props.navigation.navigate('CompletedTimesheet')
        } else {
            console.log('alert user of error', response.data.code)
            this.setState({ editFormError: true })
        }
    }

    getData = async () => {
        this.setState({ isloading: true })
        // let dropdowns = await axios.get(APIURL+'/dropdowns',{params:{comp_id: this.props.user.comp_id}})
        let dropdowns = {
            projects: (await axios.get(APIURL + '/dropdowns/dropdownType/projectslist', { params: { comp_id: this.props.user.comp_id } })).data,
            locations: (await axios.get(APIURL + '/dropdowns/dropdownType/locationslist', { params: { comp_id: this.props.user.comp_id } })).data,
            // taskType: (await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.user.comp_id, employeeType: this.props.user.type } })).data
        }
        let timesheetData = await axios.get(APIURL + '/timesheets', { params: { emp_id: this.props.user.emp_id, comp_id: this.props.user.comp_id, start: moment(this.state.startDate).startOf('week').format('YYYY-MM-DD'), end: moment(this.state.startDate).endOf('week').format('YYYY-MM-DD') } })
         console.log(timesheetData.data)

        let empTasks = await axios.get(APIURL + "/employees/EmployeeTasks", { params: { comp_id: this.props.user.comp_id, employee_id: this.props.user.emp_id } });
        if (empTasks.data.length !== 0) {
            let empTaskTypes = []

            empTasks.data.forEach((task) => {
                empTaskTypes.push(task.EntityValue)
            })

            this.setState({ empTasksAvailable: true })
            this.setState({ typeList: empTaskTypes })
        }
        // this.setState({timesheetData: timesheetData.data, projects: dropdowns.data.projects.list, typeList: dropdowns.data.tasks.taskType, location: dropdowns.data.locations.list,isloading: false})
        this.setState({ timesheetData: timesheetData.data, projects: dropdowns.projects, location: dropdowns.locations, isloading: false })
    }

    goBackWard = async () => {
        await this.setState({ startDate: moment(this.state.startDate).subtract(7, 'days').format('YYYY-MM-DD') })
        await this.getData()
    }

    goForward = async () => {
        await this.setState({ startDate: moment(this.state.startDate).add(7, 'days').format('YYYY-MM-DD') })
        await this.getData()
    }

    openaddForm = async () => {
        await this.setState({ add: !this.state.add, edit: false, editDataForm: { projDets: null, start_time: moment(), end_time: moment(), id: null } })
    }

    getEditData = (id) => {
        let data = this.state.timesheetData.find((ts) => ts.id === +id)
        let totalMinutes = moment(data.end_time).diff(moment(data.start_time), 'minutes')
        let minutes = (totalMinutes % 60).toString().padStart(2, '0');
        let hours = ((totalMinutes-minutes)/60).toString().padStart(2, '0');

        console.log(data.taskDets.tas_type)

        if (data.id === this.state.editDataForm.id) {
            const editDataForm = {
                ...data,
                duration: hours + ':' + minutes,
                tType: data.taskDets.tas_type
            };

            const addDataForm = {
                tTitle: '',
                proj_id: null,
                sDate: moment().format('YYYY-MM-DDThh:mm'),
                eDate: moment().format('YYYY-MM-DDThh:mm'),
                tType: data.taskDets.tas_type
            };

            this.setState({
                edit: !this.state.edit,
                editDataForm: editDataForm,
                add: false,
                addDataForm: addDataForm
            });

        } else {
            this.setState({ 
                edit: !this.state.edit,
                editDataForm: {...data, tTitle: data.taskDets.tas_title, duration: hours + ':' + minutes, tType: data.taskDets.tas_type},
                add: false,
                addDataForm: { tTitle: '', proj_id: null, sDate: moment().format('YYYY-MM-DDThh:mm'), eDate: moment().format('YYYY-MM-DDThh:mm'), tType: data.taskDets.tas_type }
            })
        }

    }

    // Converts the editDataForm.taskDets into an object and gets the tas_notes (index 10).
    getNoteData = () => {
        //console.log((this.state.editDataForm.taskDets));
        let noteObject = new Object(this.state.editDataForm.taskDets);
        //console.log({noteObject});
        return Object.values(noteObject)[10];
    }

    validateEditForm = async () => {
        let isVal = false
        // let missing = ''
        if(this.state.isSupport) {
            if (/^[0-9]{2}:[0-9]{2}$/.test(this.state.editDataForm.duration) && !!this.state.editDataForm.projDets){
                isVal = !isVal
            }
        } else {
            if ((!moment(this.state.editDataForm.start_time.substring(0, 16)).isSameOrAfter(moment(this.state.editDataForm.end_time.substring(0, 16))) && !!this.state.editDataForm.projDets)
            ) {
                isVal = !isVal
            }
         }
        if (!!isVal) {
            // Toggle isloading
            // Add task 
            // Add Timesheet entry on retrieval of task_id
            // Toggle isloading
            // Alert on Error 
            // Navigate to sucess screen  
            console.log('passed validation test')
            // this.setState({isLoading: true})
            let updateId = this.state.editDataForm.id
            await this.updateEntry(updateId)
            await this.getData()
            // this.setState({isLoading: false})
        } else {
            // Alert User 
            this.setState({ editFormError: true })
            console.log('Validation Failed')
        }
    }
    validateAddForm = async () => {
        let isVal = false
        // console.log( (moment(this.state.addDataForm.eDate).isAfter(moment(this.state.addDataForm.sDate))))
        if(this.state.isSupport){
            if (/^[0-9]{2}:[0-9]{2}$/.test(this.state.addDataForm.duration) && this.state.addDataForm.tType !== null && this.state.addDataForm.tType !== '' && !!this.state.addDataForm.proj_id){
                isVal = !isVal
            }
        } else {
            if (this.state.addDataForm.tType !== null && this.state.addDataForm.tType !== '' && !!this.state.addDataForm.proj_id && 
            (!!moment(this.state.addDataForm.eDate).isAfter(moment(this.state.addDataForm.sDate)))
            ) {
    
                isVal = !isVal
            }
        }

        if(!this.state.typeList.some(item => item === this.state.addDataForm.tType)){
            isVal=false;
        }

        console.log(this.state.addDataForm);
        if (!!isVal) {

            console.log('passed validation test')
            await this.addEntry()
            // this.setState({add: false})
        } else {
            // Alert User 
            this.setState({ addFormError: true, add: true })
            console.log('Validation Failed')
        }

    }
    render() {
        // console.log('this.state: ', this.state)
        let timeLine = Array.from(Array(7).keys())
        timeLine = timeLine.map((d, i) => {
            // console.log(i)
            if (i === 0) {
                return { day: moment(this.state.startDate).startOf('week').format('ddd'), date: moment(this.state.startDate).startOf('week') }
            } else if (i === 7) {
                return { day: moment(this.state.startDate).endOf('week').format('ddd'), date: moment(this.state.startDate).endOf('week') }
            } else {
                return { day: moment(this.state.startDate).startOf('week').add(i, 'days').format('ddd'), date: moment(this.state.startDate).startOf('week').add(i, 'days') }
            }
        })

        let DaysTabContentList = timeLine.map((tabs) => {
            return (
                <button className='btn btn-sm btn-light mx-1' onClick={() => this.setState({ startDate: moment(tabs.date).format('YYYY-MM-DD'), currentDay: moment(tabs.date).format('YYYY-MM-DDThh:mm') })} style={{ borderBottomWidth: moment(this.state.startDate).format('ddd') === tabs.day ? 4 : 1, backgroundColor: moment(this.state.startDate).isSame(moment(tabs.date)) ? '#3FA7D6' : 'white', borderBottomColor: moment(this.state.startDate).isSame(moment(tabs.date)) ? '#3FA7D6' : '#3FA7D6' }} key={tabs.day} >
                    {/* <div> */}
                    <p style={{ alignSelf: 'center', fontSize: 10, margin: 5, color: moment(this.state.startDate).isSame(moment(tabs.date)) ? 'white' : 'black' }}>{tabs.day.toUpperCase()}</p>
                    {/* </div> */}
                </button>
            )
        })

        let timesheetData = !this.state.timesheetData ? [] : this.state.timesheetData.filter((ts) => parseInt(moment(ts.start_time).format('YYYYMMDD')) - parseInt(moment(this.state.startDate).format('YYYYMMDD')) === 0)

        // console.log('Billable Hours ', this.calCumulativeTimeSpent(timesheetData,'billable'))
        // console.log('Billed Hours ', this.calCumulativeTimeSpent(timesheetData,'billed'))
        // console.log('Total Billable Hours ', this.calCumulativeTimeSpent(this.state.timesheetData,'billable'))
        // console.log('Total Billed Hours ', this.calCumulativeTimeSpent(this.state.timesheetData,'billed'))
        // let billable = 
        // let billed = 
        let tsData = timesheetData.map((ts) => {
            return (
                <Timesheet
                    data={ts}
                    key={ts.id}
                    onEdit={this.getEditData}
                    setCurrentTask={(task) => this.setState({ currentTask: task, showTaskForms: true })}
                    user={this.props.user}
                // billedHrs={this.calCumulativeTimeSpent(timesheetData,'billed')}
                // billableHrs={this.calCumulativeTimeSpent(timesheetData,'billable')}
                />)
        })

        let unexpiredProjList = this.state.projects.filter(proj => {
            return (Date.parse(proj.end_date) > Date.now()) || (this.state.isSupport && (!proj.start_date || !proj.end_date || moment(proj.pro_starts_on).isSameOrBefore("1900-01-01") || moment(proj.pro_ends_on).isSameOrBefore("1900-01-01"))) 
        })
        let projList = unexpiredProjList.filter(proj => proj.empAssn != null).filter(proj => proj.empAssn.find(employee => employee.id==this.props.user.emp_id) != undefined).map((proj, indx) => ({value:proj.pid ,title: proj.pname}))

        console.log(this.state.typeList)

        let typeList = this.state.typeList.map((type, indx) => {
            return { title: type, value: indx }
        })
        return (
            (<div className={this.props.user.type === 'Case Manager' ? 'timesheet-wrapper-cm' : 'timesheet-wrapper'} style={{ width: '100%', overflow: 'hidden', border: '3px solid #3fa7d6', borderRadius: '10px' }}>
                <div style={{ }}>
                    <div className="timesheet-wrapper-top-row">
                        <Icon onClick={() => this.openaddForm()} className="fa fa-plus-circle timesheet-icon" style={{ fontSize: 30, color: '#3FA7D6', cursor: 'pointer' }} />
                        <h3 style={{ fontSize: 20, marginTop: 15 }} className="timesheet-wrapper-header">TIMESHEETS</h3>
                        <div className="date-and-icon-wrapper">
                            <p className='mt-2 p-1'>Viewing For:  {moment(this.state.startDate).format('MMM DD')} </p>
                        </div>
                    </div>
                    <Snackbar open={this.state.success} autoHideDuration={6000} onClose={() => { this.setState({ success: false }) }}>
                        <MuiAlert onClose={() => { this.setState({ success: false }) }} severity="success">
                            Successfully inserted Timesheet record!
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={this.state.addFormError} autoHideDuration={6000} onClose={() => { this.setState({ addFormError: false }) }}>
                        <MuiAlert onClose={() => { this.setState({ addFormError: false }) }} severity="error">
                            ERROR OCCURRED WHILE ADDING TIMESHEET ENTRY!!!
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={this.state.editFormError} autoHideDuration={6000} onClose={() => { this.setState({ editFormError: false }) }}>
                        <MuiAlert onClose={() => { this.setState({ editFormError: false }) }} severity="error">
                            ERROR OCCURRED WHILE UPDATING TIMESHEET ENTRY!!!
                        </MuiAlert>
                    </Snackbar>
                    <div className='timesheet-cycle'>
                        <ChevronLeftIcon className="left-icon" onClick={() => this.goBackWard()} />
                        <p>{moment(this.state.startDate).startOf('week').format('DD MMM, YYYY')} - {moment(this.state.startDate).endOf('week').format('DD MMM, YYYY')}</p>
                        <ChevronRightIcon className="right-icon" onClick={() => this.goForward()} />
                    </div>
                    <div className='d-flex flex-row justify-content-around mx-3'>
                        <div>
                            <span style={{ backgroundColor: '#3FA7D6' }} className='badge rounded-pill text-white'>Weekly Scheduled Hours: {this.calCumulativeTimeSpent(this.state.timesheetData, 'billed')}</span>
                        </div>
                        <div>
                            <span style={{ backgroundColor: '#3FA7D6' }} className='badge rounded-pill text-white'>Weekly Worked Hours : {this.calCumulativeTimeSpent(this.state.timesheetData, 'billable')}</span></div>
                    </div>

                    <div className='d-flex flex-row  mx-1 my-1 justify-content-center'>
                        {DaysTabContentList}
                    </div>
                </div>
                <div className='overflow-auto' style={{ height: '100%' }}>

                    {!this.state.isloading && timesheetData.length !== 0 && tsData}
                    {!!this.state.isloading &&
                        <div className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>
                    }
                    {!this.state.isloading && timesheetData.length === 0 &&
                        <div className='d-flex flex-column justify-content-start align-items-center' style={{ marginTop: 30 }}>
                            {/* <img src='./Timesheetwhite.png' alt='Timesheetwhite2' style={{width: '10rem', height: '10rem', margin: 10}}/> */}
                            <h5 style={{ fontFamily: 'Lato' }} className='text-center'>No timesheets entry to show for {moment(this.state.startDate).format('MMMM DD')} </h5>
                            <div className='d-flex flex-row justify-content-between'><div className='m-2 mr-2' style={{ paddingBottom: '26.5px' }}>
                                <span style={{ fontFamily: 'Lato', fontSize: 12, width: '70%' }} className='text-muted text-center text-wrap'>Tap the </span>
                                <button style={{ display: 'inline-block' }} className='btn btn-primary mx-2' onClick={() => this.openaddForm()} >  <i className='fas fa-plus'></i> </button>
                                <span style={{ fontFamily: 'Lato', fontSize: 12, width: '70%' }} className='text-muted text-center text-wrap'>button to add your Timesheet</span>
                            </div>
                            </div>
                        </div>
                    }
                </div>
                {/*EDIT START*/}
                <Modal
                    show={this.state.edit}
                    onHide={() => { this.setState({ edit: !this.state.edit }) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Existing Timesheet Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {((!this.state.editDataForm.locDets && !this.state.editDataForm.projDets) || (!this.state.editDataForm.locDets && !!this.state.editDataForm.projDets && !this.state.editDataForm.projDets.pro_title)) &&
                            <Autocomplete
                                //   disabled = {}
                                id="combo-box-demo"
                                value={!!this.state.editDataForm.projDets ? projList.find((pro) => pro.value === this.state.editDataForm.projDets.pro_id) : null}
                                className='my-3'
                                onChange={(e,newValue) => {
                                    console.log(newValue)

                                    let value = this.state.projects.find((pro) => pro.pname === newValue.title)
                                    if (!!value) {
                                        this.setState({ editDataForm: { ...this.state.editDataForm, projDets: { ...this.state.editDataForm.projDets, pro_id: value.pid } } })
                                    }

                                }}
                                options={projList}
                                getOptionLabel={(option) => option.title}
                                style={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label="PROJECT" variant="outlined" />}
                            />}
                        <TextField
                            id="outlined"
                            className='my-1'
                            onChange={(e) => {
                                this.setState({ editDataForm: { ...this.state.editDataForm, tTitle: e.target.value } });
                            }}
                            value={this.state.editDataForm.tTitle}
                            variant="outlined"
                            label="TASK TITLE"
                            style={{ width: '100%', fontSize: 20 }}
                        />
                        <Autocomplete
                            id="combo-box-demoA"
                            className='my-1'
                            value={this.state.editDataForm.tType}
                            clearOnEscape
                            options={typeList}
                            onChange={(e, newValue) => {
                                this.setState({ editDataForm: { ...this.state.editDataForm, tType: newValue.title } });
                            }}
                            getOptionLabel={(option) => option.title !== undefined ? option.title : option}
                            style={{ width: '100%', fontSize: 20 }}
                            renderInput={(params) => <TextField {...params} label="TASK TYPE" 
                            error={(this.state.editFormError && !this.state.editDataForm.tType) || (!!this.state.editDataForm.tType && !typeList.some(item => item.title === this.state.editDataForm.tType))}
                            variant="outlined" />}
                        />
                        {
                            !this.state.isSupport ?
                            <>
                                <TextField
                                    id="datetime-localA"
                                    label="CHECK-IN TIME"
                                    type="datetime-local"
                                    error={this.state.editDataForm &&  !(Number(moment(this.state.editDataForm.end_time).format('YYYYMMDDHHmm')) - Number(moment(this.state.editDataForm.start_time).format('YYYYMMDDHHmm')) > 0 )}
                                    value={typeof this.state.editDataForm.start_time === "string" ? moment(this.state.editDataForm.start_time.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm')}
                                    onChange={(d) => {
                                        console.log('just changed', d.target.value)
                                        this.setState({ editDataForm: { ...this.state.editDataForm, start_time: moment(d.target.value.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm') } })
                                    }}
                                    // className={classes.textField}
                                    className='my-3'
                                    style={{ width: '100%' }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="datetime-localB"
                                    label="CHECK-OUT TIME"
                                    type="datetime-local"
                                    error={this.state.editDataForm &&  !(Number(moment(this.state.editDataForm.end_time).format('YYYYMMDDHHmm')) - Number(moment(this.state.editDataForm.start_time).format('YYYYMMDDHHmm')) > 0 )}
                                    value={typeof this.state.editDataForm.end_time === "string" ? moment(this.state.editDataForm.end_time.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm'): moment().format('YYYY-MM-DDTHH:mm')}
                                    onChange={(d) => {
                                        console.log('just changed', d.target.value)
                                        this.setState({ editDataForm: { ...this.state.editDataForm, end_time: moment(d.target.value.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm') } })
                                    }}
                                    className='my-3'
                                    style={{ width: '100%' }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </> :
                            <>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date"
                                        value={moment(this.state.editDataForm.start_time).isValid() ? dayjs(this.state.editDataForm.start_time) : null}
                                        className='my-2 mx-1'
                                        onChange={(date) => {
                                            this.setState({ ...this.state, editDataForm: { ...this.state.editDataForm, start_time: moment(date.$d).format('YYYY-MM-DD') } })
                                        }}
                                        slotProps={{textField: {variant: 'outlined'}}}
                                        sx={{ width: '100%' }}
                                    />
                                </LocalizationProvider>
                                <DurationPicker
                                    style={{ width: "100%", fontSize: 20 }}
                                    className='my-2 mx-1'
                                    value={this.state.editDataForm.duration||''}
                                    onChange={(validDuration) => {this.setState({ ...this.state, editDataForm: { ...this.state.editDataForm, duration: validDuration } }) }}
                                /> 
                            </> 
                        }
                        <TextField
                            id="outlined"
                            className='my-1'
                            label="NOTES"
                            onChange={(e) => {
                                this.setState({ editDataForm: { ...this.state.editDataForm, tNotes: e.target.value } });
                            }}

                            value={(this.state.editDataForm.tNotes || this.getNoteData()) || ''}
                            variant="outlined"
                            style={{ width: '100%', fontSize: 20 }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" 
                        onClick={() => { this.setState({ edit: !this.state.edit }) }}
                        >
                            <p style={{ color: '#3fa7d6', margin: 10 }}>
                            Cancel
                            </p>
                        </Button>
                        <Button onClick={() => this.validateEditForm()} style={{ backgroundColor: '#3FA7D6' }} className='my-3 text-white' variant="primary">
                            <p style={{ color: 'white', margin: 10 }}>SUBMIT</p>
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/*EDIT END*/}
                {/* ADD START*/}
                <Modal
                    show={this.state.add}
                    onShow={() => { this.setState({ addDataForm: {...this.state.addDataForm, sDate: this.state.currentDay } }) }}
                    onHide={() => { this.setState({ add: !this.state.add, addDataForm: {...this.state.addDataForm, tType: null} }) }}
                    backdrop="static"
                    keyboard={false}
                    style={{ marginTop: '5%' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Add New Timesheet Entry
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Autocomplete
                            id="combo-box-demoB"
                            className='my-1'
                            options={projList}
                            value={this.state.selectedProject}
                            onChange={(e, newValue) => {
                                this.setState({ empTasksAvailable: false })
                                this.setState({ selectedProject: newValue });
                                this.setState({ addDataForm: { ...this.state.addDataForm, proj_id: newValue.value } });
                            }}
                            getOptionLabel={(option) => option.title}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            style={{ width: '100%', fontSize: 20 }}
                            renderInput={(params) => <TextField {...params}
                            error={this.state.addFormError && !this.state.addDataForm.proj_id}
                            label="PROJECT" variant="outlined" />}
                        />
                        <TextField
                            // required
                            id="outlined"
                            label="TASK TITLE"
                            onChange={(e) => { this.setState({ addDataForm: { ...this.state.addDataForm, tTitle: e.target.value } }) }}
                            // defaultValue="Hello World"
                            variant="outlined"
                            className='my-1'
                            style={{ width: '100%', fontSize: 20 }}
                        />
                        <Autocomplete
                            id="combo-box-demoA"
                            className='my-1'
                            value={this.state.addDataForm.tType}
                            clearOnEscape
                            options={typeList}
                            onChange={(e, newValue) => {
                                this.setState({ addDataForm: { ...this.state.addDataForm, tType: newValue.title } });
                            }}
                            getOptionLabel={(option) => option.title !== undefined ? option.title : option}
                            style={{ width: '100%', fontSize: 20 }}
                            renderInput={(params) => <TextField {...params} label="TASK TYPE" 
                            error={(this.state.addFormError && !this.state.addDataForm.tType) || (!!this.state.addDataForm.tType && !typeList.some(item => item.title === this.state.addDataForm.tType))}
                            variant="outlined" />}
                        />
                        <TextField
                            id="outlined"
                            className='my-1'
                            label="COMMENTS"
                            onChange={(e) => { //console.log(e.target.value); 
                                this.setState({ addDataForm: { ...this.state.addDataForm, tNotes: e.target.value } })
                            }}

                            value={this.state.addDataForm.tNotes || ''}
                            variant="outlined"
                            style={{ width: '100%', fontSize: 20 }}
                        />
                        {
                            !this.state.isSupport ?
                            <>
                                <TextField
                                    id="datetime-localA"
                                    label="CHECK-IN TIME"
                                    error={this.state.addFormError && !(Number(moment(this.state.addDataForm.eDate).format('YYYYMMDDHHmm')) - Number(moment(this.state.addDataForm.sDate).format('YYYYMMDDHHmm')) > 0)}
                                    type="datetime-local"
                                    value={moment(this.state.addDataForm.sDate).format('YYYY-MM-DDTHH:mm')}
                                    onChange={(d) => {
                                        console.log('just changed', d.target.value)
                                        this.setState({ addDataForm: { ...this.state.addDataForm, sDate: d.target.value } })
                                    }}
                                    // className={classes.textField}
                                    className='my-2'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="datetime-localB"
                                    label="CHECK-OUT TIME"
                                    error={this.state.addFormError && !(Number(moment(this.state.addDataForm.eDate).format('YYYYMMDDHHmm')) - Number(moment(this.state.addDataForm.sDate).format('YYYYMMDDHHmm')) > 0)}
                                    type="datetime-local"
                                    value={moment(this.state.addDataForm.eDate).format('YYYY-MM-DDTHH:mm')}
                                    onChange={(d) => {
                                        console.log('just changed', d.target.value)
                                        this.setState({ addDataForm: { ...this.state.addDataForm, eDate: d.target.value } })
                                    }}
                                    // className={classes.textField}
                                    className='my-2'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </> :
                            <>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date"
                                        value={moment(this.state.addDataForm.sDate).isValid() ? dayjs(this.state.addDataForm.sDate) : null}
                                        className='my-1'
                                        onChange={(date) => {
                                            this.setState({ ...this.state, addDataForm: { ...this.state.addDataForm, sDate: moment(date.$d).format('YYYY-MM-DD') } })
                                        }}
                                        slotProps={{textField: {variant: 'outlined'}}}
                                        sx={{ width: '100%' }}
                                    />
                                </LocalizationProvider>
                                <DurationPicker
                                    style={{ width: "100%", fontSize: 20 }}
                                    className='my-1'
                                    value={this.state.addDataForm.duration||''}
                                    onChange={(validDuration) => {this.setState({ ...this.state, addDataForm: { ...this.state.addDataForm, duration: validDuration } }) }}
                                /> 
                            </> 
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{ this.setState({ add: !this.state.add, addDataForm: {...this.state.addDataForm, tType: null} }) }}>
                        <p style={{ color: '#3fa7d6', margin: 10 }}>Cancel</p>
                        </Button>
                        <Button onClick={() => { this.validateAddForm() }} style={{ backgroundColor: '#3FA7D6', fontColor: 'white' }} className='my-2' variant="contained" color="primary">
                            <p style={{ color: 'white', margin: 10 }}>SUBMIT</p>
                        </Button>

                    </Modal.Footer>
                </Modal>
                <ViewFormModal
                    forms={this.state.currentTask?.task_form ? this.state.currentTask.task_form : []}
                    cus_id={this.state.currentTask? this.state.currentTask.cus_id : ''} 
                    customer_name={this.state.currentTask ? this.state.currentTask.cus_name : ''} 
                    task_id={this.state.currentTask ? this.state.currentTask.taskDets.tas_id : ''}
                    taskDetails={this.state.currentTask ? this.state.currentTask.taskDets : ''}
                    user_id={this.props.user.emp_id}
                    read={false}
                    show={this.state.showTaskForms}
                    hide={() => this.setState({ showTaskForms: false })}
                    isTimeSheetControl={true}
                />
            </div>)
        );
    }
}