import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { ONBOARDAPIURL } from '../../../config'
import { APIURL } from '../../../config'
// import {render} from 'react-dom';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Box } from "@mui/material";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import moment from "moment";

class onboarding extends React.Component {

    state = {
        loading: false,
        rows: [],
        success: false,
        error: false,
        warning: false,
        message: '',
        onboarding_form: {
            first_name: '',
            last_name: '',
            employee_email: '',
        }
    }

    async componentDidMount() {
        let empInvites = await axios.get(APIURL + '/activity_log', {
            params: { empID: this.props.user.emp_id },
        });

        let rows = empInvites.data.map((data) => {
            let inviteData = {
                fw_log_id: data.fw_log_id,
                action_subtype: data.action_subtype,
                systime: data.systime,
            };
            return inviteData;
        });

        this.setState({ rows: rows });
    }



    clearForm = () => {
        this.setState({
            loading: false, onboarding_form: {
                ...this.state.onboarding_form,
                first_name: '',
                last_name: '',
                employee_email: '',
            }
        })
    }

    handleOnboardingChange = (e) => {
        // if(e.target.value !== ''){
        let newState = { ...this.state.onboarding_form, [e.target.id]: e.target.value }
        this.setState({
            onboarding_form: newState
        })
        // }else{return}

    }
    handlevalidationonClick = async (e) => {
        e.preventDefault()
        console.log(this.state.onboarding_form)
        if (this.state.onboarding_form.first_name === '' || this.state.onboarding_form.last_name === ''
            || this.state.onboarding_form.employee_email === '') {
            this.setState({ warning: true, message: 'Missing Fields' })
            return
        }
        else {
            await this.addonboardingformtoDB()
        }
    }


    addonboardingformtoDB = async () => {
        // make call to onboard API

        // { "primaryBusinessType": "Support Coordination", "featureValue": 101 },
        // { "primaryBusinessType": "Case Management", "featureValue": 104 },
        let pBusinessType = this.props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? this.props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].feature_id : 100

        console.log(pBusinessType)
        this.setState({ loading: !this.state.loading })
        let data = {
            comp_id: this.props.user.comp_id,
            company_name: this.props.user.company_name,
            user_name: this.props.user.emp_name,
            first_name: this.state.onboarding_form.first_name,
            last_name: this.state.onboarding_form.last_name,
            employee_email: this.state.onboarding_form.employee_email,
            employee_type: pBusinessType === 101 ? 'Support Coordinator' : pBusinessType === 104 ? 'Case Manager' : 'Fieldworker',
            emp_id: this.props.user.emp_id,
        }
        console.log(axios)
        console.log(data)
        let empConfirm = await axios.post(ONBOARDAPIURL + '/onboard/initaddEmployee', { ...data })
        console.log(empConfirm)
        // let empConfirm = await axios.post('http://localhost:4000/api/fieldworker/onboard/initaddEmployee', {...data})
        if (empConfirm.data.code === 200) {
            // success snackbar
            this.clearForm()
            this.setState({ success: true, message: empConfirm.data.message })

            //activty log
            // var sha1 = require('sha1');
            // let sessionID = window.localStorage.getItem('Fw4_access_Token')
            // var hash = sha1(sessionID);
            // var log_data = {
            //     email: this.props.user.email,
            //     actionType: 'Invite Employee',
            //     actionSubType: data.employee_email,
            //     sessionID: hash
            // }
            // let msg = await axios.post(APIURL + "/activity_log", log_data);


        } else {
            // error snackbar
            this.setState({ loading: false, error: true, message: empConfirm.data.message })
        }
        console.log('Invite User Logic', data)
    }


    render() {
        const columns = [
            { field: "fw_log_id", headerName: "FW LOG ID", hide: true },
            { field: "action_subtype", headerName: "Invite Email", width: 300 },
            {
                field: "systime",
                headerName: "Invite Date",
                width: 200,
                renderCell: (params) => {
                    return (
                        <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY, hh:mm A') : params.value}</div>
                    )
                }
            },
        ]

        return (
            (<div>
                <div style={{ borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.1)' }} className='d-flex flex-row justify-content-center w-75 p-1'>
                    <Form
                    // onSubmit={this.handlevalidationonClick}
                    >
                        <h2 className='text-center'>Employee Onboarding</h2>
                        <hr />
                        <Form.Row>
                            <Form.Group as={Col} controlId="first_name">
                                <Form.Label>FIRST NAME</Form.Label><span><i style={{ margin: 5, fontSize: '0.7rem' }} className="fas fa-asterisk text-danger"></i></span>
                                <Form.Control value={this.state.onboarding_form.first_name} type="text" onChange={this.handleOnboardingChange} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="last_name">
                                <Form.Label>LAST NAME</Form.Label><span><i style={{ margin: 5, fontSize: '0.7rem' }} className="fas fa-asterisk text-danger"></i></span>
                                <Form.Control value={this.state.onboarding_form.last_name} type="text" onChange={this.handleOnboardingChange} />
                            </Form.Group>
                        </Form.Row>


                        <Form.Group controlId="employee_email">
                            <Form.Label>EMAIL ADDRESS</Form.Label><span><i style={{ margin: 5, fontSize: '0.7rem' }} className="fas fa-asterisk text-danger"></i></span>
                            <Form.Control value={this.state.onboarding_form.employee_email} onChange={this.handleOnboardingChange} />
                        </Form.Group>

                        <Button disabled={!!this.state.loading ? true : false} className='w-75 mx-5' variant="primary" style={{ margin: 5, height: '3rem' }} type="button"
                            onClick={(e) => this.handlevalidationonClick(e)}
                        >{!this.state.loading ? 'Invite User' : <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>}</Button>
                    </Form>
                    <Snackbar open={this.state.success} autoHideDuration={6000} onClose={() => this.setState({ success: false })}>
                        <MuiAlert onClose={() => this.setState({ success: false })} severity="success">
                            {this.state.message}
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={this.state.error} autoHideDuration={6000} onClose={() => this.setState({ error: false })}>
                        <MuiAlert onClose={() => this.setState({ error: false })} severity="error">
                            {this.state.message}
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={this.state.warning} autoHideDuration={6000} onClose={() => this.setState({ warning: false })}>
                        <MuiAlert onClose={() => this.setState({ warning: false })} severity="warning">
                            {this.state.message}
                        </MuiAlert>
                    </Snackbar>
                </div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <h5>Invites Sent</h5>
                </div>
                <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                    <Box sx={{ width: '75%', height: "calc(100vh - 470px)" }}>
                        <DataGridPro
                            getRowId={(row) => row.fw_log_id}
                            rows={this.state.rows}
                            columns={columns}
                            initialState={{ sorting: { sortModel: [{ field: "systime", sort: "desc" }] } }}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </div>
            </div >)
        );
    }
}
export default onboarding