import React, { useState, useEffect, useCallback } from 'react';
import { 
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
 } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { Call, Home, Clear, AddCircleOutlineOutlined, CheckCircleOutline, Edit, Note, FileUpload, AccessTimeFilled, Timelapse, Person, DownloadDone } from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as FaceToFace } from './facetoface.svg';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button, Divider } from "@mui/material";
import MonthlyMonitoring from "./MonthlyMonitoring/MonthlyMonitoring";
import { APIURL } from '../../config';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import MMTRecord from './Modals/MMTRecord';
import './../Manager/SupportCoordinatorManagement.css';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import UnlinkConfirmation from './Modals/UnlinkConfirmation';
import BulkDownloadButton from './BulkDownloadButton';
import SaveViewButton from '../../components/DataGrid/SaveViewButton';
import JSZip from 'jszip';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';
import { debounce } from 'lodash';
import BulkDownload from './Modals/BulkDownload';

const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

function SupportCoordinatorCustomers(props) {
    const [showRecord, setShowRecord] = useState(false);

    const handleShowRecord = () => setShowRecord(true);
    const handleCloseRecord = () => setShowRecord(false);

    const [showUnlink, setShowUnlink] = useState(false);
    const [onUnlink, setOnUnLink] = useState(null);
    
    const handleShowUnlink = () => setShowUnlink(true);
    const handleCloseUnlink = () => setShowUnlink(false);

    const [showBulkDownload, setShowBulkDownload] = useState(false);

    const handleShowBulkDownload = () => setShowBulkDownload(true);
    const handleCloseBulkDownload = () => setShowBulkDownload(false);

    const [showMM, setShowMM] = useState(false);
    const [mmData, setMMData] = useState(null);
    const [recordData, setRecordData] = useState(null);
    const [cusName, setCusName] = useState('')

    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');

    const [gridId, setGridId] = useState(51);
    const apiRef = useGridApiRef();

    const [rowHeight, setRowHeight] = useState(60);

    const [rows, setRows] = useState([]);

    const [monthlyTemplate, setMonthlyTemplate] = useState(null);
    const [quarterlyTemplate, setQuarterlyTemplate] = useState(null);
    const [isBulkDownloadLoading, setIsBulkDownloadLoading] = useState(false);
    const [loggedToSegment, setLoggedToSegment] = useState(false);
    const [isChromiumBased, setIsChromiumBased] = useState(false);

    let clicked = false;
    const templateString = props.user.comp_settings.find(setting => setting.feature_id === 53) ? props.user.comp_settings.find(setting => setting.feature_id === 53).feature_value : null;
    const billableByDefault = props.user.comp_settings.find(setting => setting.feature_id === 56) ? props.user.comp_settings.find(setting => setting.feature_id === 56).feature_value === 'true' : false;

    const logToSegment = () => {
        if(loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        analytics.page(page + " : Customers",{
            title:page + " : Customers",
            path:page + " : Customers",
        }, {
          userId:String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }

    useEffect(() => {
        setRows(props.data.map((customer, index) => {
            return {
                id: index,
                cus_id: customer.cus_id,
                ddd_id: customer.DDDID,
                local_id: customer.cus_local_id,
                customer_name: customer.cus_name,
                month1: customer.month1,
                month2: customer.month2,
                month3: customer.month3,
                month4: customer.month4,
                month5: customer.month5,
                month6: customer.month6,
                mmt: customer.month3,
                service_plan: customer.active_plan_version,
                program: customer.plan_program_name,
                pre_authorization: customer.priorAuth,
                plan_approval_date: customer.plan_approval_date ? moment(customer.plan_approval_date).utc().format('MM/DD/YYYY') : null,
                plan_start_date: customer.pro_starts_on ? moment(customer.pro_starts_on).utc().format('MM/DD/YYYY') : null,
                plan_end_date: customer.pro_ends_on ? moment(customer.pro_ends_on).utc().format('MM/DD/YYYY') : null,
                last_annual_visit: customer.LastAnnualVisitDate ? moment(customer.LastAnnualVisitDate).utc().format('MM/DD/YYYY') : null,
                last_mt_upload: customer.LastMMTUploadDate ? moment(customer.LastMMTUploadDate).utc().format('MM/DD/YYYY') : null,
                last_download_date: customer.month3.find(tas => tas.tas_mm_id !== null)?.last_download_date ? moment(customer.month3.find(tas => tas.tas_mm_id !== null).last_download_date).utc().format('MM/DD/YYYY') : null
            }
        }))
        clicked = false;
    }, [props.data]);

    useEffect(async () => {
        let monthlyTemplate = await axios.get(APIURL+"/monthlymonitoring/template/" + "monthly", { responseType: 'arraybuffer' });
        let quarterlyTemplate = await axios.get(APIURL+"/monthlymonitoring/template/" + "quarterly", { responseType: 'arraybuffer' });
        logToSegment();
        monthlyTemplate = monthlyTemplate.data;
        quarterlyTemplate = quarterlyTemplate.data;
        setMonthlyTemplate(monthlyTemplate);
        setQuarterlyTemplate(quarterlyTemplate);
    }, []);

    useEffect(() => {
        const detectChromium = async () => {
            const userAgent = navigator.userAgent;
            const vendorName = navigator.vendor;
            const isOpera = typeof window.opr !== 'undefined';
            const isFirefox = typeof InstallTrigger !== 'undefined';
            const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
            const isEdgeChromium = userAgent.indexOf('Edg') > -1; // Edge Chromium
            let isBrave = false;
      
            if (navigator.brave && navigator.brave.isBrave) {
              isBrave = await navigator.brave.isBrave();
            }
      
            if (
              (window.chrome && vendorName === 'Google Inc.' && !isOpera && !isEdgeChromium && !isBrave) ||
              isEdgeChromium ||
              isBrave ||
              isOpera
            ) {
              setIsChromiumBased(true);
            }
        };
      
        detectChromium();
      }, []);

    const handleMMShow = async () => {
        setShowMM(true);
    }
    const handleMMClose = () => {
        setShowMM(false)
    };

    const setAlert = (type, message) => {
        if(type === 'success') {
            setUpdated(true);
        } else {
            setError(true);
        }
        setMessage(message);
    }

    function FaceToFaceIcon(props) {
        return (
          <SvgIcon {...props} viewBox="0 0 256 256" component={FaceToFace}/>
        );
    }

    const reviewComparater = (v1, v2) => {
        let v1Status = moment().add(3, 'years');
        let v2Status = moment().add(3, 'years');

        if(!v1 || !v2) 
            return 0;
            
        v1.map(tas => {
            if(tas.tas_mm_id) {
                if(tas.tas_mm_status === 'Waiting For Approval') {
                    v1Status = moment(tas.submitted_on);
                } else if (tas.tas_mm_status === 'Approved') {
                    v1Status = moment(tas.submitted_on).add(2, 'years');
                } else if (tas.tas_mm_status === 'Rejected') {
                    v1Status = moment(tas.submitted_on).add(1, 'years');
                }
                return;
            }
        })
        v2.map(tas => {
            if(tas.tas_mm_id) {
                if(tas.tas_mm_status === 'Waiting For Approval') {
                    v2Status = moment(tas.submitted_on);
                } else if (tas.tas_mm_status === 'Approved') {
                    v2Status = moment(tas.submitted_on).add(2, 'years');
                } else if (tas.tas_mm_status === 'Rejected') {
                    v2Status = moment(tas.submitted_on).add(1, 'years');
                }
                return;
            }
        })

        return v2Status - v1Status;
    }

    const CustomToolbar = (params) => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <BulkDownloadButton onClick={handleShowBulkDownload} />
                <SaveViewButton {...params} />
            </GridToolbarContainer>
        );
    }

    const lastBulkDownloadDate = async () => {
        try {
            let res = await axios.get(APIURL+"/activity_log/action", { params: {
                actionType: 'MT Form Action',
                actionSubType: 'MT bulk download',
                employee_id: props.emp_id
            }});

            console.log(res);

            let lastDownloadDate = moment(res.data.data[0].systime);

            return lastDownloadDate;
        } catch (error) {
            console.log(error);
        }

        return null;
    }

    const saveFiles = async (isLastBulkDownload = false) => {
        
        try {
            let fileName = props.user.emp_name.replace(' ', '_') + '_' + moment().format('MMM_YYYY') + '_MT_Forms.zip';
            let fileHandle
            if(isChromiumBased) {
                fileHandle = await window.showSaveFilePicker({
                    id: "monthly-monitoring",
                    suggestedName: fileName,
                    types: [{
                        description: 'Zip Files',
                        accept: {
                            'application/zip': ['.zip']
                        }
                    }]
                });
            }
            const createReport = window.createReport;

            let data = await axios.get(APIURL+"/monthlymonitoring/employee/all/"+props.emp_id, { params: { 
                comp_id: props.comp_id,
                created_on: moment().startOf('month').format('YYYY-MM-DD') 
            }});

            if(isLastBulkDownload) {
                let lastDownloadDate = await lastBulkDownloadDate();
                if(lastDownloadDate.isAfter(moment().startOf('month')) && lastDownloadDate.isBefore(moment().endOf('month'))) {
                    data.data = data.data?.filter(mt => moment(mt.last_updated).isAfter(lastDownloadDate));
                }
            }
            console.log(data);

            let zip = new JSZip();

            let mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

            let createFiles = data.data?.map(async mt => {
                const report = await createReport({
                    template: mt.mt_type === "Monthly" ? monthlyTemplate : quarterlyTemplate,
                    data: JSON.parse(mt.data),
                    cmdDelimiter: ['{', '}'],
                    fixSmartQuotes: true,
                    noSandbox: true
                });
                const blob = new Blob([report], { type: mimeType });
                if(templateString) {
                    const values = {
                        customer_name: mt.cus_name.replace(' ', '_'),
                        month: moment(mt.mt_date).utc().format('MMM'),
                        year: moment(mt.mt_date).utc().format('YYYY'),
                        type: mt.mt_type
                    };
                    const replacedString = templateString.replace(/{(.*?)}/g, (match, key) => {
                        return values[key] || match; // Use the value from the 'values' object or keep the placeholder if not found
                    });
                    zip.file(replacedString + '.docx', blob);
                } else {
                    zip.file(mt.cus_name.replace(' ', '_') + '_' + moment(mt.mt_date).format('MMM_YYYY') + `_${mt.mt_type}_Form.docx`, blob);
                }
                await axios.put(APIURL+"/monthlymonitoring/downloaded/" + mt.monthly_monitoring_id);
            })

            await Promise.all(createFiles)
            let blob = await zip.generateAsync({ type: 'blob' })
            
            if(isChromiumBased) {
                const writable = await fileHandle.createWritable();
                await writable.write(blob);
                await writable.close();
            } else {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(url);
            }

            var sha1 = require('sha1');
            let sessionID=window.localStorage.getItem('Fw4_access_Token')
            var hash = sha1(sessionID);
            var log_data={
                emp_id: props.emp_id,
                actionType:'MT Form Action',
                actionSubType:'MT bulk download',
                sessionID:hash
            }                                                                                                                                                                                                                                                               
            let msg = await axios.post(APIURL+"/activity_log", log_data );
        } catch (error) {
            console.log(error);
        }
    }

    const getRowHeight = useCallback(({ model, densityFactor  }) => {
        let height = 0;

        if(model.month3.length === 0 || !props.isSubscibedToTimeManagement) {
            height = rowHeight * densityFactor;
        } else {
            model.month3.forEach(task => {
                height += ((rowHeight * 0.5) + (model.month3[0]?.locInfo?.length * rowHeight * 0.5)) * densityFactor;
            })
        }
        return height;
    }, [rowHeight])

    let mmt = (params, mtDate) => {
        let disable = true;
        let data;
        let status;
        let className='completed';

        let onClick = () => {
            handleMMShow();
            setMMData(data);
        }

        params.value.map(tas => {
            if(tas.tas_mm_id !== null) {
                disable = false;
                data = {
                    task: tas,
                    cus_id: params.row.cus_id,
                    cus_name: params.row.customer_name,
                    mt_date: mtDate
                };
                status = tas.tas_mm_status === null ? "Complete The Form" : tas.tas_mm_status;
                if(status === "Waiting For Approval") {
                    disable = true;
                } else if (status === 'Approved') {
                    disable = false;
                    className = "approved";
                    onClick = () => { setCusName(params.row.customer_name);handleShowRecord();setRecordData(data); };
                } else if (status === 'Rejected') {
                    disable = false;
                    className = "rejected";
                    onClick = () => { setCusName(params.row.customer_name);handleShowRecord();setRecordData(data); };
                }
                return;
            }
        })

        if(!data) {
            return (
                <Clear  style = {{color : "red", margin : 10, width: '100%'}} />
            )
        }

        return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: '100%', flexDirection: 'column', flexDirection: 'column' }}>
                <Button sx={{ whiteSpace: "normal", lineHeight: "1rem", py: ".4rem" }} disabled={disable} className={className} onClick={onClick} size="small" variant={className==="completed"?"outlined":"text"}>{status}</Button>
                {status === "Rejected" &&
                    <Button sx={{ whiteSpace: "normal", lineHeight: "1rem", py: ".4rem" }} className="completed" onClick={() => {handleMMShow();setMMData(data)}} size="small" variant="outlined">Resubmit</Button>
                }
            </Box>
        )
    }

    const currentMonthTaskList = (params) => {      
        if(params.value.length === 0) {
            return (
                <Clear  style = {{color : "red", margin : 10, width: '100%'}} />
            )
        }
        let submitted = false;
         params.value.forEach( task => {
             if(task.tas_mm_status === "Waiting For Approval" || task.tas_mm_status === "Approved" || task.tas_mm_status === "Rejected") {
                submitted = true;
             }
         })
        return (
            (<Box sx={{ display: 'flex', flexDirection: 'column', width: "100%"}}>
                {
                    params.value.map(task => {
                        let tooltip;

                        const linkMMT = async () => {
                            let res = await axios.put(APIURL + "/task/link", { task: task, cus_name:params.row.customer_name, tasks: params.value, empId: props.user.emp_id, empName: props.user.emp_name, companyId: props.user.comp_id });
                            if(res.data.code === 200) {
                                let newRows = [...rows];
                                for(let i = 0; i < rows.length; i++) {
                                    if(newRows[i].cus_id === params.row.cus_id) {
                                        newRows[i].month3.forEach(t => {
                                            if(t.tas_id === task.tas_id) {
                                                t.tas_mm_id = 0;
                                            } else {
                                                t.tas_mm_id = null;
                                            }
                                        })
                                        newRows[i].mmt.forEach(t => {
                                            if(t.tas_id === task.tas_id) {
                                                t.tas_mm_id = 0;
                                            } else {
                                                t.tas_mm_id = null;
                                            }
                                        })
                                    }
                                }
                                setRows(newRows);
                            }
                        }

                        const unlinkMMT = async () => {
                            let res = await axios.put(APIURL + "/task/link", { task: task });
                            if(res.data.code === 200) {
                                let newRows = [...rows];
                                for(let i = 0; i < newRows.length; i++) {
                                    if(newRows[i].cus_id === params.row.cus_id) {
                                        newRows[i].month3.forEach(t => {
                                            if(t.tas_id === task.tas_id) {
                                                t.tas_mm_id = null;
                                            }
                                        })
                                        newRows[i].mmt.forEach(t => {
                                            if(t.tas_id === task.tas_id) {
                                                t.tas_mm_id = null;
                                            }
                                        })
                                    }
                                }
                                setRows(newRows);
                            } 
                        }
                        
                        const unLink = () => {
                            handleShowUnlink();
                            setOnUnLink(() => unlinkMMT);
                        }
                            
                        if(task.tas_mm_id !== null) {
                            tooltip = <Tooltip title="Linked to MT" placement="right">
                                          <IconButton size="small" onClick={unLink} disabled={submitted}>
                                                <CheckCircleOutline sx={{ fontSize: "20px", color: '#3fa7d6' }}/>
                                          </IconButton>
                                      </Tooltip>
                        } else {
                            tooltip = <Tooltip title="Link to MT" placement="right">
                                          <IconButton size="small" onClick={linkMMT} disabled={submitted} sx={{ padding: 0 }}>
                                                <AddCircleOutlineOutlined sx={{ fontSize: "20px" }}/>
                                          </IconButton>
                                      </Tooltip>
                        }

                        let phone, addresses;

                        const getIcon = (task) => {
                            let taskType = task.tas_type.toLowerCase();
                            taskType = taskType.includes('visit') && taskType !== 'home visit' ? 'visit' : taskType;
                            switch(taskType) {
                                case 'phone call':
                                    phone = task.con_phone?.split(",")[0];
                                    return <Call style={{color : "#3FA7D6", marginRight : 10}} />
                                case 'home visit':
                                    addresses = task.locInfo;
                                    return <Home style={{color : "#3FA7D6", marginRight : 10}} />
                                case 'visit':
                                    addresses = task.locInfo;
                                    return <FaceToFaceIcon style={{ height: "22px", color : "#3FA7D6", marginRight: 10 }} />
                                default:
                                    return <Clear style={{color : "red", marginRight : 10, visibility: "hidden"}} />
                            }
                        }

                        const getDuration = (start, end) => {
                            let duration = moment.duration(moment(end).diff(moment(start)));
                            let days = duration.days();
                            let hours = duration.hours();
                            let minutes = duration.minutes();

                            let durationStr = "";

                            days > 0 && (durationStr += days + "d ");
                            hours > 0 && (durationStr += hours + "h ");
                            minutes > 0 && (durationStr += minutes + "m");

                            return durationStr;
                        }
                        
                        return (<>
                            <Box sx={{ width: "100%", height: 'auto' }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    {getIcon(task)}
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        height: "100%",
                                    }}>
                                        <Box sx={{ display: "flex", height: rowHeight * 0.5 }}>
                                            <Box sx={{ display: "flex", alignItems: "center", minWidth: "100px" }}>
                                                <span>{moment(task.checkoutEmpDate.replace(/Z/g,"")).format('MM/DD/YYYY')}</span>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", width: "100px" }}>
                                                <span style={{ marginRight: 10 }}>{task.tas_title}</span>
                                                {tooltip}
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", height: rowHeight * 0.5 }}>
                                            <Box sx={{ display: "flex", alignItems: "center", minWidth: "100px" }}>
                                                <span><Timelapse style={{ color : "#3FA7D6", marginRight : 5 }} fontSize='small' />{getDuration(task.empLocdate, task.checkoutEmpDate)}</span>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                {addresses ? 
                                                        <span style={{ marginRight: 10 }}>{addresses[0].loc_addr_line1 + " " + addresses[0].loc_addr_line2 + ", " + addresses[0].loc_city + ", " + addresses[0].loc_state + ", " + addresses[0].loc_zip}</span> :
                                                    <>
                                                        {task.con_name ?
                                                            <>
                                                                <span style={{ lineHeight: "50%", marginRight: 10 }}><Person style={{ color : "#3FA7D6", marginRight : 2 }} fontSize='small' />
                                                                    {task.con_name}
                                                                </span>
                                                                <span style={{ lineHeight: "50%", marginRight: 10 }}><Timelapse style={{ color : "#3FA7D6", marginRight : 2 }} fontSize='small' />
                                                                    {getDuration(task.startDateofCall, task.endDateofCall)}
                                                                </span>
                                                                <span style={{ marginRight: 10 }}><Call style={{ color : "#3FA7D6", marginRight : 2 }} fontSize='small' />{phone}</span>
                                                            </> :
                                                            <span>No call information found</span>
                                                        }
                                                    </>
                                                }
                                            </Box>
                                        </Box>
                                        { addresses && addresses.length > 1 &&
                                            addresses.slice(1).map((address, index) => {
                                                return (
                                                    <Box sx={{ display: "flex", height: rowHeight * 0.5 }}>
                                                        <Box sx={{ display: "flex", alignItems: "center", minWidth: "100px" }}>
                                                            <span></span>
                                                        </Box>
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <span style={{ marginRight: 10 }}>{address.loc_addr_line1 + " " + address.loc_addr_line2 + ", " + address.loc_city + ", " + address.loc_state + ", " + address.loc_zip}</span>
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                </Box>
                                <Divider/>
                            </Box>
                        </>);
                })}
            </Box>)
        );
    }

    let futureMonths = (params) => {
        let styles = { display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" };
        switch (params.value.toLowerCase()) {
            case 'home visit':
                return  (<div style={styles}><Home  style = {{color : "gray" }} /><div style={{ lineHeight: "25px" }}>Home Visit</div></div>)
            case 'visit':
                return  (<div style={styles}><FaceToFaceIcon  style = {{ height: "22px", color : "gray" }} /><div style={{ lineHeight: "25px" }}>Face-to-Face</div></div>)
            default:
                return  (<div style={styles}><Call  style = {{color : "gray" }} /><div style={{ lineHeight: "25px" }}>Phone/Video</div></div>)
        }
    }

    let timeManagedMonth = (params, mtDate, month) => {
        let disable = false;
        let status;
        let className='completed';
        let mtTask = params.value.length > 0 ? params.value.reduce((max, tas) => tas.tas_mm_id > max.tas_mm_id ? tas : max, params.value[0]) : undefined;
        let data = mtTask ? {
            task: mtTask,
            cus_id: params.row.cus_id,
            mt_date: mtDate
        } : undefined;

        let onClick = mtTask === undefined && !clicked ? debounce(async () => {
            clicked = true;
            let initiated = await initiateMT(params, mtDate, month);

            if(!initiated) {
                clicked = false;
            }
        }, 1000, { leading: true, trailing: false }) : () => {
            handleMMShow();
            setMMData(data);
        }
        
        if(mtTask === undefined) {
            if(month === 'month3') {
                return (
                    <Clear style = {{color : "red", margin : 10, width: '100%'}} />
                )
            }
            status = "Initiate The Form";
        } else {
            mtTask.tas_mm_status === null ? status = "Complete The Form" : status = mtTask.tas_mm_status;
            if(status === "Waiting For Approval") {
                disable = true;
            } else if (status === 'Approved') {
                disable = false;
                className = "approved";
                onClick = () => { setCusName(params.row.customer_name);handleShowRecord();setRecordData(data); };
            } else if (status === 'Rejected') {
                disable = false;
                className = "rejected";
                onClick = () => { setCusName(params.row.customer_name);handleShowRecord();setRecordData(data); };
            }
        }

        return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
                <Button sx={{ whiteSpace: "normal", lineHeight: "1rem", py: ".4rem" }} disabled={disable || clicked} className={className} onClick={onClick} size="small" variant={className==="completed"?"outlined":"text"}>{status}</Button>
                {status === "Rejected" &&
                    <Button sx={{ whiteSpace: "normal", lineHeight: "1rem", py: ".4rem" }} className="completed" onClick={() => {handleMMShow();setMMData(data)}} size="small" variant="outlined">Resubmit</Button>
                }
            </Box>
        )
    }

    const timeManagedInitiateMT = (params, mtDate, month) => {
        let mtTask = params.value.length > 0 ? params.value.reduce((max, tas) => tas.tas_mm_id > max.tas_mm_id ? tas : max, params.value[0]) : undefined;
        let status = "Initiate Form For This Month";
        let disable = mtTask ? true : false;

        let initiate = async () => {
            disable = true;
            if( mtTask === undefined && !clicked) {
                clicked = true;
                let initiated = await initiateMT(params, mtDate, month);

                if(!initiated) {
                    disable = false;
                    clicked = false;
                }
            }
        }

        let onClick = debounce(async () => await initiate(), 1000, { leading: true, trailing: false });

        return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
                <Button sx={{ whiteSpace: "normal", lineHeight: "1rem", py: ".4rem" }} disabled={disable || clicked} className="completed" onClick={onClick} size="small" variant="outlined">{status}</Button>
            </Box>
        )

    }

    const initiateMT = async (params, mtDate, month) => {
        let row = props.data.find(row => row.cus_id === params.row.cus_id);

        let response = await axios.post(APIURL + '/monthlymonitoring/initiate/mt', {
            cus_id: params.row.cus_id,
            emp_id: props.user.emp_id,
            tas_title: `${props.user.emp_name} - ${moment().format('YYYY-DD-MM, HH:mm')} `,
            tas_start_date: moment(mtDate).format('YYYY-MM-DD HH:mm:ss'),
            tas_end_date: moment(mtDate).format('YYYY-MM-DD HH:mm:ss'),
            tas_start_time: moment(mtDate).format('YYYY-MM-DD HH:mm:ss'),
            tas_end_time: moment(mtDate).format('YYYY-MM-DD HH:mm:ss'),
            tas_pro_id: row.pro_id,
            tas_billable_by_default: billableByDefault ? 1 : 0,
            comp_id: props.comp_id,
            loc_id: row.loc_id,
            emploc_lat: parseFloat(row.loc_lat),
            emploc_lon: parseFloat(row.loc_lon),
            data: JSON.stringify({}),
            mt_period: moment(mtDate).format('MMM YYYY'),
            created_on: moment(mtDate).format('YYYY-MM-DD'),
        });

        if(response.data.code === 200) {
            let taskData = await axios.get(APIURL + '/task', { params: { taskId: response.data.id } });
                if(taskData?.data?.length > 0) {
                    let task = taskData.data[0];
                    let newRows = [...rows];
                    for(let i = 0; i < newRows.length; i++) {
                        if(newRows[i].cus_id === params.row.cus_id) {
                            newRows[i][month].push(task);
                            break;
                        }
                    }
                    setRows(newRows);
                }
            return true
        } else {
            return false;
        }
    }

    const columns = [
        {
            field: 'customer_name',
            headerName: 'Customer Name',
            headerAlign: 'center',
            width: 200,
            hide: false,
            type: "string",
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            height: "100%",
                            justifyContent: 'center',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: "25px",
                            }}
                            >
                            <div style={{ flex: 1 }}></div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    flex: 6,
                                }}
                            >
                                <NavLink
                                    to={{
                                    pathname: '/customers',
                                    customersProps: {
                                        cus_id: params.row.cus_id,
                                        edit: false,
                                    },
                                    }}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        height: 'auto',
                                    }}
                                >
                                    {params.value}
                                </NavLink>
                            </Box>
                            <div style={{ flex: 1 }}>
                                {params.row.last_download_date &&
                                    <Tooltip title={<Box><div>Downloaded</div><div>{params.row.last_download_date}</div></Box>} placement="bottom">
                                        <DownloadDone size="small" style={{ color: 'gray' }} />
                                    </Tooltip>
                                }
                            </div>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: "25px",
                            }}>
                            <Tooltip title="Edit" placement="bottom">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <NavLink
                                        to={{
                                            pathname:'/customers',
                                            customersProps:{
                                                cus_id: params.row.cus_id,
                                                edit: true
                                            }
                                        }}
                                    >
                                        <IconButton size="small">
                                            <Edit fontSize="inherit" style={{ color: 'gray' }}/>
                                        </IconButton>
                                    </NavLink>
                                </Box>
                            </Tooltip>
                            <Tooltip title="Note" placement="bottom">
                                <IconButton size="small" color="primary" onClick={() => props.handleShowNote(params.row.cus_id)}>
                                    <Note fontSize="inherit" style={{ color: 'gray' }}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Timesheet" placement="bottom">
                                <IconButton size="small" color="primary" onClick={() => props.handleShowTimesheet(params.row.cus_id)}>
                                    <AccessTimeFilled fontSize="inherit" style={{ color: 'gray' }}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Upload" placement="bottom">
                                <IconButton size="small" color="primary" onClick={() => props.handleShowUpload(params.row.cus_id)}>
                                    <FileUpload fontSize="inherit" style={{ color: 'gray' }}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                )
            },
            valueGetter: (value, row, column, apiRef) => {return value}
        },
        {
            field: "ddd_id",
            headerName: 'DDD ID',
            headerAlign: 'center',
            width: 133
        },
        {
            field: "local_id",
            headerName: 'Local ID',
            headerAlign: 'center',
            width: 133
        },
        {
            field: "month1",
            headerName: moment().subtract(2, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                const mtDate = moment().subtract(2, "month").startOf("month").add(9, 'hours');
                return props.isSubscibedToTimeManagement ? mmt(params, mtDate) : timeManagedMonth(params, mtDate, "month1")
            }
        },
        {
            field: "month2",
            headerName: moment().subtract(1, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                const mtDate = moment().subtract(1, "month").startOf("month").add(9, 'hours');
                return props.isSubscibedToTimeManagement ? mmt(params, mtDate) : timeManagedMonth(params, mtDate, "month2")
            }
        },
        {
            field: "month3",
            headerName: moment().startOf("month").format('MMMM') + " (Current Month)",
            headerAlign: 'center',
            width: props.isSubscibedToTimeManagement ? 450 : 250,
            hide: false,
            renderCell: (params) => {
                const mtDate = moment().subtract(0, "month").startOf("month").add(9, 'hours');
                return props.isSubscibedToTimeManagement ? currentMonthTaskList(params) : timeManagedInitiateMT(params, mtDate, "month3")
            }
        },
        {
            field: 'mmt',
            headerName: 'MMT',
            headerAlign: 'center',
            width: 133,
            hide: false,
            sortComparator: reviewComparater,
            renderHeader: (params) => (
                <Tooltip title="Monthly Monitoring Tool" placement="bottom">
                    <span>MT</span>
                </Tooltip>

            ),
            renderCell: (params) => {
                const mtDate = moment().subtract(0, "month").startOf("month").add(9, 'hours');
                return props.isSubscibedToTimeManagement ? mmt(params, mtDate) : timeManagedMonth(params, mtDate, "month3")
            }
        },
        {
            field: "month4",
            headerName: moment().subtract(-1, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
        }
        },
        {
            field: "month5",
            headerName: moment().subtract(-2, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month6",
            headerName: moment().subtract(-3, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: 'service_plan',
            headerName: 'Service Plan',
            width: 140,
            hide: false
        },
        {
            field: 'program',
            headerName: 'Program',
            width: 140,
            hide: false
        },
        {
            field: 'mt_submission_date',
            headerName: 'MT Submit Date',
            width: 140,
            hide: false,
            type: "date",
            renderCell: (params) => {
                return (
                    <div>{params.value.toDateString() === new Date(0).toDateString() ? '' : moment(params.value).format("MM/DD/YYYY")}</div>
                )
            },
            valueGetter: (value, row, column, apiRef) => {
                let date = new Date(0);

                if(!row.month3) {
                    return date;
                }
                row.month3.map(tas => {
                    if(tas.tas_mm_id) {
                        if(tas.submitted_on)
                            date = new Date(tas.submitted_on);
                    }
                })
                return date;
            },
            valueFormatter: (value, row, column, apiRef) => value !== new Date(0) ? moment(value).format('MM/DD/YYYY') : null
        },
        {
            field: 'plan_approval_date',
            headerName: 'Plan Approval Date',
            type: "date",
            width: 140,
            hide: false,
            renderCell: (params) => {
                return (
                    <div>{params.value.toDateString() === new Date(0).toDateString() ? '' : moment(params.value).format("MM/DD/YYYY")}</div>
                )
            },
            valueGetter: (value, row, column, apiRef) => {
                let date = new Date(0);
                if (value) {
                    date = new Date(value);
                }
                return date;
            },
            valueFormatter: (value, row, column, apiRef) => value !== new Date(0) ? moment(value).format('MM/DD/YYYY') : null
        },
        {
            field: 'plan_start_date',
            headerName: 'Plan Start Date',
            type: "date",
            width: 140,
            hide: false,
            renderCell: (params) => {
                return (
                    <div>{params.value.toDateString() === new Date(0).toDateString() ? '' : moment(params.value).format("MM/DD/YYYY")}</div>
                )
            },
            valueGetter: (value, row, column, apiRef) => {
                let date = new Date(0);
                if (value) {
                    date = new Date(value);
                }
                return date;
            },
            valueFormatter: (value, row, column, apiRef) => value !== new Date(0) ? moment(value).format('MM/DD/YYYY') : null
        },
        {
            field: 'plan_end_date',
            headerName: 'Plan End Date',
            type: "date",
            width: 140,
            hide: false,
            renderCell: (params) => {
                return (
                    <div>{params.value.toDateString() === new Date(0).toDateString() ? '' : moment(params.value).format("MM/DD/YYYY")}</div>
                )
            },
            valueGetter: (value, row, column, apiRef) => {
                let date = new Date(0);
                if (value !== null) {
                    date = new Date(value);
                }
                return date;
            },
            valueFormatter: (value, row, column, apiRef) => value !== new Date(0) ? moment(value).format('MM/DD/YYYY') : null
        },
        {
            field: 'last_annual_visit',
            headerName: 'Last Annual Visit',
            type: "date",
            width: 140,
            hide: false,
            renderCell: (params) => {
                return (
                    <div>{params.value.toDateString() === new Date(0).toDateString() ? '' : moment(params.value).format("MM/DD/YYYY")}</div>
                )
            },
            valueGetter: (value, row, column, apiRef) => {
                let date = new Date(0);
                if (value !== null) {
                    date = new Date(value);
                }
                return date;
            },
            valueFormatter: (value, row, column, apiRef) => value !== new Date(0) ? moment(value).format('MM/DD/YYYY') : null
        },
        {
            field: 'last_mt_upload',
            headerName: 'Last MT Upload',
            type: "date",
            width: 140,
            hide: false,
            renderCell: (params) => {
                return (
                    <div>{params.value.toDateString() === new Date(0).toDateString() ? '' : moment(params.value).format("MM/DD/YYYY")}</div>
                )
            },
            valueGetter: (value, row, column, apiRef) => {
                let date = new Date(0);
                if (value !== null) {
                    date = new Date(value);
                }
                return date;
            },
            valueFormatter: (value, row, column, apiRef) => value !== new Date(0) ? moment(value).format('MM/DD/YYYY') : null
        }
    ];
            
    return (
        <Box sx={{ width: 'calc(100vw - 250px)', height: "calc(100vh - 150px)", display: 'flex', flexDirection: 'column' }}>
            <ControlledDataGrid
                gridId={gridId}
                user={props.user}
                getRowHeight={getRowHeight}
                initialState={{ 
                    pinnedColumns: { 
                        left: ['cus_id', 'customer_name']
                    }
                }}
                columnVisibilityModel={{
                    ddd_id: false,
                    local_id: false,
                }}
                rows={rows}
                columns={columns}
                pagination
                hideFooterSelectedRowCount={true}
                //checkboxSelection
                disableRowSelectionOnClick 
                loading={props.loading}
                apiRef={apiRef}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
            <MonthlyMonitoring rerender={props.rerender} user={props.user} data={mmData} emp_id={props.emp_id} show={showMM} handleClose={handleMMClose} setAlert={setAlert} isSubscibedToTimeManagement={props.isSubscibedToTimeManagement} checkTokenExpiry={props.checkTokenExpiry} />
            <MMTRecord  show={showRecord} handleClose={handleCloseRecord} cus_name={cusName} data={recordData} company_id={props.comp_id} user={props.user} reload={props.getEmployees}/>
            <UnlinkConfirmation show={showUnlink} onClose={handleCloseUnlink} onUnlink={onUnlink} />
            <BulkDownload show={showBulkDownload} onClose={handleCloseBulkDownload} saveFiles={saveFiles} />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={updated} autoHideDuration={6000} onClose={()=>{setUpdated(false)}}>
                <MuiAlert onClose={()=>{setUpdated(false)}} severity="success">
                   {message}
                </MuiAlert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={()=>{setError(false)}}>
                <MuiAlert onClose={()=>{setError(false)}} severity="error">
                    {message}
                </MuiAlert>
            </Snackbar>
      </Box>
    )
}

export default SupportCoordinatorCustomers;