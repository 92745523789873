import React, { Component } from 'react';
import { Tabs, Tab, Form, Alert } from 'react-bootstrap';
import BasicDets from './BasicDets/BasicDets';
import DateLocation from './Date&Loc/DateLocation';
import FinalDets from './FinalDets/FinalDets';
import MsgModalBox from '../../../container/MessageModalBox/MsgModalBox';
import axios from 'axios';
import moment from 'moment';
import {APIURL} from '../../../config'
import { Redirect } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from '@mui/material/Tooltip';

class TaskForm extends Component {
    constructor(props) {
        super(props);
        let companyAddress = this.props.locList.filter((loc) => loc.location_type == "Company")
        let com_lat = companyAddress.length == 0 ? 40.730610 : Number(companyAddress[0].location_lat)
        let com_lon = companyAddress.length == 0 ? -73.935242 : Number(companyAddress[0].location_lon)
        let loc = this.props.task_location
        this.state = {
            goHome: false,
            basicDetails: {
                tTitle: (this.props.edit) ? (this.props.formVals.tas_title) : "",
                tDesc: (this.props.edit) ? (this.props.formVals.tas_description) : "",
                tProject: (this.props.edit) ? (this.props.projList[0].pid) : "",
                tPreReq: (this.props.edit) ? ((!!this.props.formVals.tas_prequisites_task) ? this.props.formVals.tas_prequisites_task : "No Pre Req") : "No Pre Req",
                tType: (this.props.edit) ? (this.props.formVals.tas_type) : "",
                // tAssignedTo: (this.props.edit) ? (this.props.formVals.tas_emp_id) : ""
            },
            dateLocation: {
                tStrtDate: (this.props.edit) ? moment(this.props.formVals.tas_start_time).format('YYYY-MM-DDTHH:mm:ss') :  moment().format('YYYY-MM-DDTHH:mm:ss'),
                tEndDate: (this.props.edit) ? moment(this.props.formVals.tas_end_time).format('YYYY-MM-DDTHH:mm:ss') : moment().add(1, 'm').format('YYYY-MM-DDTHH:mm:ss'),
                tStartTime: (this.props.edit) ? moment(this.props.formVals.tas_start_time).format('YYYY-MM-DDTHH:mm:ss') :  moment().format('YYYY-MM-DDTHH:mm:ss'),
                // (this.props.edit) ? !!moment(this.props.formVals.tas_start_time).isValid()?moment(this.props.formVals.tas_start_time).format('h'):(this.props.formVals.tas_start_time.split(" ")[0]) : 1,
                // timeStrtIndicator: (this.props.edit) ? !!moment(this.props.formVals.tas_start_time).isValid()?moment(this.props.formVals.tas_start_time).format('A'):(this.props.formVals.tas_start_time.split(" ")[1]) : "AM",
                tEndTime: (this.props.edit) ? moment(this.props.formVals.tas_end_time).format('YYYY-MM-DDTHH:mm:ss') : moment().add(1, 'm').format('YYYY-MM-DDTHH:mm:ss'),
                // !!moment(this.props.formVals.tas_end_time).isValid()?moment(this.props.formVals.tas_end_time).format('h'):(this.props.formVals.tas_end_time.split(" ")[0]) : 1,
                // timeEndIndicator: (this.props.edit) ? !!moment(this.props.formVals.tas_end_time).isValid()?moment(this.props.formVals.tas_end_time).format('A'):(this.props.formVals.tas_end_time.split(" ")[1]) : "AM",
                tLoc: (this.props.edit) ? this.props.task_location?.map((loc, indx) => {if (loc) {return {value:loc?.loc_id,title: loc?.loc_addr_line1 + " " + loc?.loc_addr_line2 + ", " + loc?.loc_city + ", " + loc?.loc_state + ", " + loc?.loc_zip}}}) : [],
            },
            recurrenceDetails: null,
            finalDetails: {
                tAssTo: (this.props.edit) ? (this.props.task_emp) : "",
                tPriority: (this.props.edit) ? (this.props.taskPriority) : "Med",
                tIsBillable: (this.props.edit) ? (this.props.taskIsBillable) : true,
                tSigReq: (this.props.edit) ? (this.props.tSigReq === undefined ? 0 : this.props.tSigReq) : 0,
                tComment: (this.props.edit) ? (this.props.taskComment) : "",
                tContacts: (this.props.edit) ? (this.props.taskContacts?.map((contact) => {return {id: contact.con_id, label: contact.con_first_name + " " + contact.con_last_name}})) : [],
            },
            mapPushPins: [],
            taskDates: [],
            key: "basicDetails",
            showModal: false,
            addError: false,
            basicDetails_val: false,
            dateLocation_val: false,
            finalDetails_val: false,
            showAlert: false,
            showNoEmployeeAlert: false,
            errorMsg: '',
            loc_comp_pos: [com_lat,com_lon],
            showSelectWorkerField: false,
            locList: [],
            preAuthList: [],
            taskAuthorizationStatus: null,
            taskAuthorizationText: '',
            isServiceProvider: this.props.user.comp_settings.find(setting => setting.feature_id === 102 && setting.feature_value === 'primaryBusinessType') ? true : false,
            isSupport: this.props.user?.comp_settings.find(setting => setting.feature_id === 101 && setting.feature_value === 'primaryBusinessType') ? true : false,
            empTasksAvailable: false,
            typeList: [],
            cust_id: null,
            contactList: [],
            taskList:[],
        }
        this.handleFinalDetsChange = this.handleFinalDetsChange.bind(this)
        this.handleBasicDetsChange = this.handleBasicDetsChange.bind(this)
        this.handleDateLocDetsChange = this.handleDateLocDetsChange.bind(this)
        // this.handleTimer = this.handleTimer.bind(this)
        // this.handleTimerIdicator = this.handleTimerIdicator.bind(this)
        this.addTaskToDB = this.addTaskToDB.bind(this)
        this.handleMapsPin = this.handleMapsPin.bind(this)
    }
    

    async addTaskToDB(e) {
        e.preventDefault();
        const newState = {
            basicDetails: {
                tTitle: "",
                tDesc: "",
                tProject: "",
                tPreReq: "",
                tType: "",
                // tAssignedTo: ''
            },
            dateLocation: {
                tStrtDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                tEndDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                tLoc: [],
            },
            recurrenceDetails: null,
            contactList: [],
            finalDetails: {
                tAssTo: "",
                tPriority: "",
                tIsBillable: true,
                tSigReq: 0,
                tComment: "",
                tContacts: [],
            }
        }
        e.persist()
        var data = {
            empId : this.props.user.emp_id,
            empName: this.props.user.emp_name,
            company_id: this.props.comp_id,
            tTitle: this.state.basicDetails.tTitle,
            // tAssignedTo: this.state.basicDetails.tAssignedTo,
            tDesc: this.state.basicDetails.tDesc,
            tProject: parseInt(this.state.basicDetails.tProject),
            tPreReq: parseInt(this.state.basicDetails.tPreReq),
            tType: this.state.basicDetails.tType,
            tStrtDate: moment(this.state.dateLocation.tStrtDate).format('YYYY-MM-DD') + "T" + moment(this.state.dateLocation.tStartTime).format('HH:mm:ss'),
            tEndDate:  moment(this.state.dateLocation.tEndDate).format('YYYY-MM-DD') + "T" + moment(this.state.dateLocation.tEndTime).format('HH:mm:ss'),
            tStartTime: moment(this.state.dateLocation.tStrtDate).format('YYYY-MM-DD') + "T" + moment(this.state.dateLocation.tStartTime).format('HH:mm:ss'),
            tEndTime: moment(this.state.dateLocation.tEndDate).format('YYYY-MM-DD') + "T" + moment(this.state.dateLocation.tEndTime).format('HH:mm:ss'),
            tLoc: this.state.dateLocation.tLoc[0]?.value || null,
            tLoc1: this.state.dateLocation.tLoc[1]?.value || null,
            tLoc2: this.state.dateLocation.tLoc[2]?.value || null,
            tAssTo: parseInt(this.state.finalDetails.tAssTo),
            tPriority: this.state.finalDetails.tPriority,
            tIsBillable: this.state.finalDetails.tIsBillable,
            tas_required_sig: this.state.finalDetails.tSigReq,
            tNotes: this.state.finalDetails.tComment,
            tContacts: this.state.finalDetails.tContacts,
            taskId: this.props.taskId,
            user_id: this.props.user.emp_id
        };
        let msg = null;

        
        if(this.props.edit){
            msg = await axios.put(APIURL+"/task", data);
        }else{
            if(this.state.recurrenceDetails !== null) {
                msg = await axios.post(APIURL+"/task/recurringTask", {...data, ...this.state.recurrenceDetails});
            } else {
                msg = await axios.post(APIURL+"/task", data);
            }
        }
        if (msg.status === 200) {

            // Create Note For Task
            let project_customer_id = (await axios.get(APIURL + `/project?proj_id=${this.state.basicDetails.tProject}&comp_id=${this.props.comp_id}`)).data.pro_customer_id
            
            try{
                if(this.state.finalDetails.tComment) {
                    let noteResponse = await axios.post(APIURL + `/comments`, {
                        cus_id: project_customer_id,
                        pro_id: parseInt(this.state.basicDetails.tProject),
                        tas_id: msg.data.id,
                        emp_id: this.props.user.emp_id,
                        comment_text: this.state.finalDetails.tComment,
                    })
                }
            } catch (err) {
                console.log(err)
            }
            

            // debugger;
            e.target.reset();
            this.setState({ basicDetails: newState.basicDetails })
            this.setState({ dateLocation: newState.dateLocation })
            this.setState({ finalDetails: newState.finalDetails })
            this.setState({ recurrenceDetails: newState.recurrenceDetails})
            this.setState({ showModal: true })
            this.setState({ addError: false })
            if(msg.data.tasks)
                this.setState({ taskDates: msg.data.tasks})
        } else {
            // debugger
            this.setState({ basicDetails: this.state.basicDetails })
            this.setState({ dateLocation: this.state.dateLocation })
            this.setState({ finalDetails: this.state.finalDetails })
            this.setState({ recurrenceDetails: this.state.recurrenceDetails})
            this.setState({ addError: true })
            this.setState({ showModal: true })
            this.setState({ taskDates: []})
            console.log("Error");
        }
    }

    goHome = (e) => {
        this.setState({ addError: false })
        this.setState({ showModal: false })
        this.setState({ showAlert: false })
        this.setState({ showNoEmployeeAlert: false })
        this.setState({ basicDetails_val: false })
        this.setState({ dateLocation_val: false })
        this.setState({ finalDetails_val: false })
        
        if(this.props.goHome !== undefined) {
            this.props.goHome()
        } else {
            this.setState({goHome: true})
        }
    }

    addNew = (e) => {
        if (this.state.addError) {
            this.setState({ showModal: false })
            this.setState({ showAlert: false })
            this.setState({ showNoEmployeeAlert: false })
            if(this.props.edit){this.props.history.push("/projects")}
        } else {
            this.setState({ showModal: false })
            this.setState({ showAlert: false })
            this.setState({ showNoEmployeeAlert: false })
            this.setState({ addError: false })
            this.setState({ basicDetails_val: false })
            this.setState({ dateLocation_val: false })
            this.setState({ finalDetails_val: false })
            this.setState({ key: "basicDetails" })
            if(this.props.edit){this.props.history.push("/projects")}
        }
    }

    onChange = (key) => {

        const conversion = {
            tTitle: 'Task title',
            tDesc: 'Task Description',
            tProject: 'Project',
            tType: 'Task type',
            tLoc: 'Location',
            tAssTo: 'tAssTo',
            tStrtDate: 'Start date',
            tStartTime: 'Start time',
            tEndDate: 'End date',
            tEndTime: 'End time',
            tAssTo: 'Assigned to',
            tContacts: 'Contacts',
        }


        const preKey = this.state.key;
        var validation=this.props.isFormValidated(this.state[preKey])
   
        if (!(validation.isValidated)) {
            if (preKey === "basicDetails") {
                this.setState({ basicDetails_val: false })
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            } else if (preKey === "dateLocation") {
                this.setState({ dateLocation_val: false })
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }else if (preKey === "finalDetails") {
                this.setState({ finalDetails_val: false })
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }
             else {
                this.setState({ finalDetails_val: false })
            }
            this.setState({ showAlert: true })
            this.setState({ key: preKey })
            return false
        } else {
            if (preKey === "basicDetails") {
                this.setState({ basicDetails_val: true })
            } else if (preKey === "dateLocation") {
                if (moment(this.state.dateLocation.tEndDate).isAfter(this.state.dateLocation.tStrtDate) && moment(this.state.dateLocation.tEndTime).isAfter(this.state.dateLocation.tStartTime)) {
                    this.setState({ dateLocation_val: true })
                } else {
                    // moment().format()
                    //this.setState({ dateLocation })
                    this.setState({ errorMsg: `End Date/Time must be after Start Date/Time.`, showAlert: true })
                    return
                }
            } else {
                this.setState({ finalDetails_val: true })
            }
            this.setState({ showAlert: false })
            this.setState({ showNoEmployeeAlert: false })
            this.setState({ key })
            return true
        }
    }


    // handleTimerIdicator(e) {
    //     let dateLocation = { ...this.state.dateLocation }
    //     dateLocation[e.target.name] = e.target.value;
    //     this.setState({ dateLocation })
    // }

    // handleTimer(e) {
    //     let timeID = e.target.id
    //     let oldState = { ...this.state.dateLocation }
    //     if (timeID === "timeStrtAdd") {
    //         oldState.tStartTime += 1;
    //         if (oldState.tStartTime > 12) {
    //             oldState.tStartTime = 12;
    //             // this.setState({errorMsg:'Time between 1 & 12',showAlert: true})
    //             // alert("Time between 1 & 12")
    //         }
    //         // this.setState({dateLocation: oldState})
    //     } else if (timeID === "timeEndAdd") {
    //         oldState.tEndTime += 1;
    //         if (oldState.tEndTime > 12) {
    //             oldState.tEndTime = 12;
    //             // this.setState({errorMsg:'Time between 1 & 12',showAlert: true})
    //             // alert("Time between 1 & 12")
    //         }
    //         // this.setState({dateLocation: oldState})
    //     }
    //     else if (timeID === "timeStrtMinus") {
    //         oldState.tStartTime -= 1;
    //         if (oldState.tStartTime < 1) {
    //             oldState.tStartTime = 1;
    //             // this.setState({errorMsg:'Time between 1 & 12',showAlert: true})
    //             // alert("Time between 1 & 12")
    //         }
    //         // this.setState({dateLocation: oldState})
    //     } else if (timeID === "timeEndMinus") {
    //         oldState.tEndTime -= 1;
    //         if (oldState.tEndTime < 1) {
    //             oldState.tEndTime = 1;
    //             // this.setState({errorMsg:'Time between 1 & 12',showAlert: true})
    //             // alert("Time between 1 & 12")
    //         }
    //         // this.setState({dateLocation: oldState})
    //     } else {
    //         oldState[e.target.name] = e.target.value

    //     }
    //     //check indicators and store in a variable
    //     let timeStart = oldState.tStartTime
    //     let timeEnd = oldState.tEndTime
    //     if (oldState.timeStrtIndicator === 'PM' && timeStart !== 12) {
    //         timeStart += 12
    //     } else if (oldState.timeStrtIndicator === 'AM' && timeStart === 12) {
    //         timeStart = 0
    //     }
    //     if (oldState.timeEndIndicator === 'PM' && timeEnd !== 12) {
    //         timeEnd += 12
    //     } else if (oldState.timeEndIndicator === 'AM' && timeEnd === 12) {
    //         timeEnd += 12
    //     }

    //     if (!oldState.tStrtDate && !oldState.tEndDate) {
    //         this.setState({ errorMsg: 'Please Enter Start and End Dates First', showAlert: true })
    //     } else {
    //         if (!(moment(oldState.tStrtDate).add(timeStart, 'h').isBefore(moment(oldState.tEndDate).add(timeEnd, 'h')))) {
    //             this.setState({ errorMsg: 'Please Enter Valid Time Slot', showAlert: true })
    //         } else {
    //             this.setState({ errorMsg: '', showAlert: false })
    //         }
    //         this.setState({ dateLocation: oldState })
    //     }

    // }

    handleProjChange = async (cus_id, pro_id) => {
        try {
            let contacts = await axios.get(APIURL + '/project/projCont', { params: { proj_id: pro_id } })
            contacts = contacts.data.map((contact) => ({
                id: contact.con_id,
                label: contact.con_first_name + " " + contact.con_last_name
            }))
            this.setState({ cust_id: cus_id, contactList: contacts })
        } catch (err) {
            console.log(err)
        }
    }

    handleProjLoc = (id, field) => {
        
        if(field === 'tProject'){
            axios.get(APIURL+'/location/v2', { params: { company_id: this.props.comp_id, project_id: id } }).then((res) => {
                let locations = res.data.map((loc) => ({
                    id: loc.loc_id,
                    location_addr1: loc.loc_addr_line1,
                    location_addr2: loc.loc_addr_line2,
                    location_city: loc.loc_city,
                    location_state: loc.loc_state,
                    location_zip: loc.loc_zip,
                }))
                this.setState({
                    basicDetails: {...this.state.basicDetails, [field]: id},
                    dateLocation: { ...this.state.dateLocation},
                    finalDetails: !this.props.edit ? { ...this.state.finalDetails, tAssTo: '' } : { ...this.state.finalDetails },
                    mapPushPins: [],
                    locList: locations||[],
                })
            }).catch((err) => {
                console.log(err)
            })
        }else if(field === 'tPreReq'){
            this.setState({ basicDetails: {...this.state.basicDetails, [field]: id} }) 
        }else if(field === 'taskStartTime' || field === 'taskEndTime' || field === 'tStrtDate' ||  field === 'tEndDate' || field === 'tStartTime' || field === 'tEndTime'){
        
            let selectedProj = this.props.projList.find((proj) => (proj.pid === +this.state.basicDetails.tProject))
            let dateLocation = { ...this.state.dateLocation }
            if (field === 'tStrtDate') {
                dateLocation.tStrtDate = moment(id).format('YYYY-MM-DDTHH:mm:ss')
                dateLocation.tEndDate = moment(id).format('YYYY-MM-DDTHH:mm:ss')
                this.setState({ dateLocation: dateLocation })
                return
            } else if (field === 'tEndDate') {
                if (moment(id).isBetween(selectedProj.start_date, selectedProj.end_date) && !!dateLocation.tStrtDate && moment(id).isSameOrAfter(moment(dateLocation.tStrtDate))) {
                    dateLocation.tEndDate = moment(id).format('YYYY-MM-DDTHH:mm:ss')
                } else {
                    dateLocation.tEndDate = ''
                    // moment().format()
                    this.setState({ dateLocation })
                    this.setState({ errorMsg: `End Date must be between range ${moment(selectedProj.start_date).format('DD MMM YYYY')} - ${moment(selectedProj.end_date).format('DD MMM YYYY')} and be Same or After ${moment().format('DD MMM YYYY')}`, showAlert: true })
                    return
                }
            }else if (field === 'tStartTime') {
                let newTime = moment(id).format('YYYY-MM-DDTHH:mm:ss')
                if(moment(dateLocation.tStrtDate.substring(0,10) + 'T' + newTime.substring(11)).isBefore(dateLocation.tEndDate.substring(0,10) + 'T' + dateLocation.tEndTime.substring(11))){
                    dateLocation.tStartTime = newTime;
                }else{
                    dateLocation.tStartTime = ''
                    this.setState({ errorMsg: `Start Time must be before ${moment(dateLocation.tEndTime).format('hh:mm')} `, showAlert: true })
                    return;
                }
               
            }else if (field === 'tEndTime') {
                let newTime = moment(id).format('YYYY-MM-DDTHH:mm:ss');
                if((moment(dateLocation.tStrtDate.substring(0,10) + 'T' + dateLocation.tStartTime.substring(11)).isBefore(dateLocation.tEndDate.substring(0,10) + 'T' + newTime.substring(11)))){
                    dateLocation.tEndTime = newTime;
                }else{
                    this.setState({ errorMsg: `End Time must be after range ${moment(dateLocation.tStartTime).format('HH:mm')} `, showAlert: true })
                    return
                }
            }else if (field === 'taskStartTime') {
                dateLocation.tStrtDate = moment(id).format('YYYY-MM-DDTHH:mm:ss')
                dateLocation.tStartTime = moment(id).format('YYYY-MM-DDTHH:mm:ss')
            }else if (field === 'taskEndTime') {
                dateLocation.tEndDate = moment(id).format('YYYY-MM-DDTHH:mm:ss')
                dateLocation.tEndTime = moment(id).format('YYYY-MM-DDTHH:mm:ss')
            }
             else {
                dateLocation = { ...this.state.dateLocation, [field]: moment(id).format('YYYY-MM-DDTHH:mm:ss') }
            }
            this.setState({ errorMsg: '', showAlert: false, showNoEmployeeAlert: false })
            this.setState({ dateLocation })
            // this.setState({ dateLocation: {...this.state.dateLocation, [field]: moment(id).format('YYYY-MM-DDTHH:mm:ssZ')} }) 
        }else if(field === 'tAssTo'){
            this.setState({ finalDetails: {...this.state.finalDetails, [field]: id}}) 
        }
        else{
            this.setState({ dateLocation: { ...this.state.dateLocation, [field]: id } })
            this.setState({ mapPushPins: [] })
        }
    }

    handleBasicDetsChange(e) {
        let newState = { ...this.state.basicDetails, [e.target.id]: e.target.value }
        // if (e.target.id === 'tProject') {
        //     this.setState({ dateLocation: { ...this.state.dateLocation, tStrtDate: '', tEndDate: '' } })
        //     this.setState({ mapPushPins: [] })
        // }
        this.setState({
            basicDetails: newState
        })
    }

    handleDateLocDetsChange(e) {
        // let newState = { ...this.state.dateLocation, [e.target.id]: e.target.value }
        let selectedProj = this.props.projList.find((proj) => (proj.pid === +this.state.basicDetails.tProject))
        let dateLocation = { ...this.state.dateLocation }
        if (e.target.id === 'tStrtDate') {
            if (moment(e.target.value).isBetween(selectedProj.start_date, selectedProj.end_date) && moment(e.target.value).isSameOrAfter(moment().subtract(1, 'd'))) {
                dateLocation.tStrtDate = e.target.value
            } else {
                dateLocation.tStrtDate = ''
                this.setState({ dateLocation })
                this.setState({ errorMsg: `Start Date must be between range ${moment(selectedProj.start_date).format('DD MMM YYYY')} - ${moment(selectedProj.end_date).format('DD MMM YYYY')} and be same or after ${moment().format('DD MMM YYYY')}`, showAlert: true })
                return
            }
        } else if (e.target.id === 'tEndDate') {
            if (moment(e.target.value).isBetween(selectedProj.start_date, selectedProj.end_date) && !!dateLocation.tStrtDate && moment(e.target.value).isSameOrAfter(moment(dateLocation.tStrtDate))) {
                dateLocation.tEndDate = e.target.value
            } else {
                dateLocation.tEndDate = ''
                this.setState({ dateLocation })
                this.setState({ errorMsg: `End Date must be between range ${moment(selectedProj.start_date).format('DD MMM YYYY')} - ${moment(selectedProj.end_date).format('DD MMM YYYY')} and be Same or After ${moment().format('DD MMM YYYY')}`, showAlert: true })
                return
            }
        } else {
            dateLocation = { ...this.state.dateLocation, [e.target.id]: e.target.value }
        }
        this.setState({ errorMsg: '', showAlert: false, showNoEmployeeAlert: false })
        this.setState({ dateLocation })
    }

    handleMapsPin(e) {
        let newState = { ...this.state.dateLocation, [e.id]: e.value }
        let chosenLocId = e.value;
        // console.log(chosenLocId)
        let chosenLoc = null
        if (!!this.props.edit) {
            // console.log(this.props.locList)
            chosenLoc = this.state.locList.find(({ loc_id }) => loc_id === chosenLocId)
        } else {
            // console.log(this.props.locList)
            chosenLoc = this.state.locList.find(({ loc_id }) => loc_id === chosenLocId)
        }
        // console.log(chosenLoc)
        this.setState({ dateLocation: newState })
        let newMapPin;
        if (e.value === "" || !chosenLoc) {
            newMapPin = []
        } else {
            if (this.props.edit) {
                newMapPin = [
                    {
                        "location": [+chosenLoc.loc_lat, +chosenLoc.loc_lon],
                        "addHandler": "click",
                        "infoboxOption": { title: chosenLoc.loc_city, visible: true, description: chosenLoc.loc_addr_line1 + ',' + chosenLoc.loc_addr_line2 },
                        "pushPinOption": { title: 'Task Location' }
                    }
                ]
            } else {
                newMapPin = [
                    {
                        "location": [+chosenLoc.location_lat, +chosenLoc.location_lon],
                        "addHandler": "click",
                        "infoboxOption": { title: chosenLoc.location_city, visible: true, description: chosenLoc.location_addr1 + ',' + chosenLoc.location_addr2 },
                        "pushPinOption": { title: 'Task Location' }
                    }
                ]
            }

        }
        this.setState({ mapPushPins: newMapPin })
    }

    handleFinalDetsChange(e) {
        let finalDetails = { ...this.state.finalDetails };
        // let newState = { ...this.state.dateLocation, [e.target.id]: e.target.value }
        if (e.target.id === "radioLow") {
            finalDetails.tPriority = "Low"
        } else if (e.target.id === "tNotes"){
            finalDetails.tComment = e.target.value
        } else if (e.target.id === "radioMed") {
            finalDetails.tPriority = "Med"
        } else if (e.target.id === "radioHigh") {
            finalDetails.tPriority = "High"
        } else if (e.target.id === "radioBillYes") {
            finalDetails.tIsBillable = true
        } else if (e.target.id === "radioBillNo") {
            finalDetails.tIsBillable = false
        } else if (e.target.id === "radioSigYes") {
            finalDetails.tSigReq = 1
        } else if (e.target.id === "radioSigNo") {
            finalDetails.tSigReq = 0
        } else {
            finalDetails = { ...this.state.finalDetails, [e.target.id]: e.target.value }
        }
        this.setState({ finalDetails })
    }

    handleRadioButtonToggle = (e) => {
        // console.log('e.target.value: ', e.target.value)
        const value = e.target.value
        if (value === 'select worker') {
            this.setState({
                showSelectWorkerField: true
            })
        } else {
            this.setState({
                showSelectWorkerField: false
            }) 
        }
    }

    handleRecurrenceSubmit = (recDets) => {
        let selectedProj = this.props.projList.find((proj) => (proj.pid === +this.state.basicDetails.tProject));

        recDets.projectEndDate = selectedProj.end_date;
        this.setState({ recurrenceDetails: recDets });
        console.log(recDets);
    }

    handleRecurrenceCancel = () => {
        this.setState({recurrenceDetails: null});
    }

    getEmpList(){
        try{
            return (this.props.edit) ? this.props.empList : (!this.state.basicDetails.tProject ? [] : this.props.projList.find((proj) => (proj.pid === +this.state.basicDetails.tProject)).empAssn.map((relEmp) => {
                if (this.props.empList.some((emp) => { return emp.id === relEmp.id })) {
                    let relEmployee = this.props.empList.find((rEmp) => rEmp.id === relEmp.id)
                    return relEmployee
                }
                return null
            }))
        } catch(err){
            this.setState({ errorMsg: 'Please ensure that the project being selected has an employee assigned to it!', showNoEmployeeAlert: true, basicDetails: {...this.state.basicDetails, tProject: ''} })
            return []
        }
    }

    calculateStatus = (tasks, preAuth) => {
        let allowedUnits = preAuth.authUnits;
        let completedUnits = 0, totalUnits = 0;
        let unit = preAuth.procUnitOfService;
        let duration = moment(this.state.dateLocation.tEndTime).isValid() && moment(this.state.dateLocation.tStartTime).isValid() ?
        moment.duration(moment(this.state.dateLocation.tEndTime).diff(moment(this.state.dateLocation.tStartTime))) : moment.duration(0)

        switch(unit) {
            case '15 Minutes':
                tasks.forEach((task) => {
                    let taskDuration = moment.duration(moment(task.checkoutEmpDate).diff(moment(task.empLocdate))).asMinutes();
                    let roundedUnits = taskDuration < 15 ? 1 : Math.round(taskDuration / 15);
                    if(moment(task.checkoutEmpDate).isBefore(moment()))
                        completedUnits += roundedUnits;
                    totalUnits += roundedUnits;
                })
                duration  = duration.asMinutes() < 0 ? 0 : duration.asMinutes();
                duration = duration < 15 ? 1 : Math.round(duration / 15);
                break;
            case '1 Hour':
                tasks.forEach((task) => {
                    let taskDuration = moment.duration(moment(task.checkoutEmpDate).diff(moment(task.empLocdate))).asHours();
                    let roundedUnits = taskDuration < 1 ? 1 : Math.round(taskDuration);
                    if(moment(task.checkoutEmpDate).isBefore(moment()))
                        completedUnits += roundedUnits;
                    totalUnits += roundedUnits;
                })
                duration = duration.asHours() < 0 ? 0 : duration.asHours();
                duration = duration < 1 ? 1 : Math.round(duration);
                break;
            case 'Per Diem':
                tasks.forEach((task) => {
                    if(moment(task.checkoutEmpDate).isBefore(moment()))
                        completedUnits += 1;
                    totalUnits += 1;
                })
                duration = 1;
                break;
            case 'Per Visit':
                tasks.forEach((task) => {
                    if(moment(task.checkoutEmpDate).isBefore(moment()))
                        completedUnits += 1;
                    totalUnits += 1;
                });
                duration = 1;
                break;
            default:
                break;
        }

        if(completedUnits > allowedUnits){
            return -1;
        } else if(totalUnits > allowedUnits){
            return 0;
        } else if (totalUnits + duration > allowedUnits){
            return 0;
        } else {
            return 1;
        }
    }

    setTaskAuthorizationStatus = () => {
        if(this.state.basicDetails.tProject === ''){
            return false
        }

        let preAuth;
        let tasks = []

        preAuth = this.state.preAuthList.find((preAuth) => {
            return preAuth.pro_id === this.state.basicDetails.tProject 
        })

        if(!preAuth){
            return null;
        }

        tasks = this.state.taskList.filter((task) => {
            return task.tas_type === preAuth.procTaskType && task.tas_pro_id === preAuth.pro_id;
        })

        if(preAuth.procTaskType !== this.state.basicDetails.tType){
            return null;
        }

        return this.calculateStatus(tasks, preAuth);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.basicDetails.tProject !== this.state.basicDetails.tProject) {
            const taskList = await axios.get(APIURL + "/dropdowns/dropdownType/taskslist", {
                params: { comp_id: this.props.comp_id, projID: this.state.basicDetails.tProject },
            });

            this.setState({ taskList: taskList.data });
        }

        if((prevState.basicDetails.tProject !== this.state.basicDetails.tProject || 
           prevState.basicDetails.tType !== this.state.basicDetails.tType ||
           prevState.dateLocation.tStartTime !== this.state.dateLocation.tStartTime ||
           prevState.dateLocation.tEndTime !== this.state.dateLocation.tEndTime) && 
           this.state.isServiceProvider){
            let taskAuthorizationStatus = this.setTaskAuthorizationStatus();
            this.setState({ 
                taskAuthorizationStatus: taskAuthorizationStatus, 
                taskAuthorizationText: taskAuthorizationStatus > 0 ?
                "Under prior authorization limit" : "Exceeds prior authorization limit"
            });
        }
        if (!this.state.empTasksAvailable) {
            if (prevState.cust_id !== this.state.cust_id) {
                let custTasks = await axios.get(APIURL + "/customers/CustomerTasks", { params: { comp_id: this.props.comp_id, customer_id: this.state.cust_id } })
                let cusTaskTypes = []
                if (custTasks.data.length !== 0) {
                    custTasks.data.forEach((task) => {
                        cusTaskTypes.push(task.EntityValue)
                    })
                    this.setState({ typeList: cusTaskTypes, basicDetails: {...this.state.basicDetails, tType: null} })
                }
                else {
                    let taskTypes = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.comp_id, employeeType: this.props.user.type } })
                    this.setState({ typeList: taskTypes.data, basicDetails: {...this.state.basicDetails, tType: null} })
                }
            }
        }
    }

    async componentDidMount() {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": '*',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
             Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
          }
        // simulated an event to push pin if props.edit is set

        let empTasks = await axios.get(APIURL + "/employees/EmployeeTasks", { params: { comp_id: this.props.user.comp_id, employee_id: this.props.user.emp_id } });
        if (empTasks.data.length !== 0) {
            let empTaskTypes = []

            empTasks.data.forEach((task) => {
                empTaskTypes.push(task.EntityValue)
            })

            this.setState({ empTasksAvailable: true })
            this.setState({ typeList: empTaskTypes })

            if (this.props.edit) {
                this.setState({ basicDetails: {...this.state.basicDetails, tType: this.props.formVals.tas_type}})
            }
        }

        if(this.props.edit) {
            if (!this.state.empTasksAvailable) {
                let custTasks = await axios.get(APIURL + "/customers/CustomerTasks", { params: { comp_id: this.props.comp_id, customer_id: this.props.projList[0].cus_id } })
                let cusTaskTypes = []
                if (custTasks.data.length !== 0) {
                    custTasks.data.forEach((task) => {
                        cusTaskTypes.push(task.EntityValue)
                    })
                    this.setState({ typeList: cusTaskTypes, basicDetails: {...this.state.basicDetails, tType: this.props.formVals.tas_type} })
                }
                else {
                    let taskTypes = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.comp_id, employeeType: this.props.user.type } })
                    this.setState({ typeList: taskTypes.data, basicDetails: {...this.state.basicDetails, tType: this.props.formVals.tas_type} })
                }
            }
            this.handleMapsPin( {id: 'tLoc', value: this.state.dateLocation.tLoc })
            console.log(this.state.basicDetails.tProject);
            this.handleProjLoc(this.state.basicDetails.tProject, 'tProject')
            this.handleProjChange(this.props.projList[0].cus_id, this.state.basicDetails.tProject)
        }

        if (this.state.isServiceProvider) {
            let preAuthList = await axios.get(APIURL + "/preauth", {
                params: { companyID: this.props.comp_id },
            });
    
            this.setState({ preAuthList: preAuthList.data });
        }
    }

    render() {
        // console.log('this.props.formVals.tas_type: ', this.props.formVals.tas_type)
        // console.log('this.props.formVals: ', this.props.formVals)
        // console.log('this.state.dateLocation: ', this.state.basicDetails)

        return (
            (<div style={{ textAlign: "left"}}>
                {(this.state.showModal) ? (<MsgModalBox edit={this.props.edit} taskDates={this.state.taskDates} addNew={this.addNew} goHome={this.goHome} text="Task" showError={this.state.addError} style={{ position: "absolute" }} />) : (
                   // <Form onSubmit={(e) => this.addTaskToDB(e)}>
                   (<Form onSubmit={(e) => {if (this.onChange('finalDetails')){ this.addTaskToDB(e)}
                   else { e.preventDefault()} 
                }} >
                       <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <h4 style={{ margin: '5px', marginBottom: '20px', width: '100%' }}>{this.props.edit ? "" : this.props.customerPage ? "" : "New Task"} </h4>
                           {this.state.taskAuthorizationStatus !== null &&
                               <Tooltip title={this.state.taskAuthorizationText} placement="right">
                                   <CircleIcon sx={{ color: this.state.taskAuthorizationStatus > 0 ? "green" : this.state.taskAuthorizationStatus < 0 ? "red" : "yellow", alignSelf: "center", size: "large"  }}/>
                               </Tooltip>
                           }
                       </div>
                       {this.state.showAlert &&
                           <Alert variant="info">
                               {!this.state.errorMsg ? 'Please Fill out missing form fields' : this.state.errorMsg}
                           </Alert>}
                       {this.state.showNoEmployeeAlert &&
                           <Alert variant="info">
                               {!this.state.errorMsg ? 'Please ensure that the project being selected has an employee assigned to it!' : this.state.errorMsg}
                           </Alert>}
                       <Tabs activeKey={this.state.key} id="task-tabs" onSelect={key => this.onChange(key)}>
                           <Tab eventKey="basicDetails" title={(this.state.basicDetails_val) ?
                               (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Basic Details</div></div>) :
                               (<div style={{ margin: "-1px" }}>Basic Details</div>)}>
                               <BasicDets
                                   edit={this.props.edit}
                                   taskList={this.state.taskList}
                                   projList={this.props.projList}
                                   taskType={this.state.typeList}
                                   empList={this.props.empList}
                                   formVals={this.state.basicDetails}
                                   project_name={(this.props.edit) ? this.props.projList[0].pname : ""}
                                   pre_task_name={(this.props.edit) ? (this.props.formVals.tas_prequisites_name ? this.props.formVals.tas_prequisites_name : "NO TASK PREREQUISITE") : "NO TASK PREREQUISITE"}
                                   onContinue={this.onChange}
                                   handleProjLoc={this.handleProjLoc}
                                   handleProjChange={this.handleProjChange}
                                   handleBasicDetsChange={this.handleBasicDetsChange}
                                   handleRadioButtonToggle={this.handleRadioButtonToggle}
                                   showSelectWorkerField={this.state.showSelectWorkerField}
                                   isSupport={this.state.isSupport}
                               />
                           </Tab>
                           <Tab eventKey="dateLocation" title={(this.state.dateLocation_val) ?
                               (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Date & Location</div></div>) :
                               (<div style={{ margin: "-1px" }}>Date & Location</div>)}>
                               <DateLocation
                                   edit={this.props.edit}
                                   token={this.props.token}
                                   mapRender={this.props.mapRender}
                                   locList={this.state.locList}
                                   currentPin={this.state.loc_comp_pos}
                                   task_location={this.props.edit ? this.props.task_location[0] : ""}
                                   timeAlloc={[this.state.dateLocation.tStartTime, this.state.dateLocation.tEndTime]}
                                   timeIndicator={[this.state.dateLocation.timeStrtIndicator, this.state.dateLocation.timeEndIndicator]}
                                   project={this.props.projList.find((proj) => (proj.pid === +this.state.basicDetails.tProject))}
                                   handleDateLocDetsChange={this.handleDateLocDetsChange}
                                   formVals={this.state.dateLocation}
                                   handleMapsPin={this.handleMapsPin}
                                   handleTimer={this.handleTimer}
                                   mapsPushPin={this.state.mapPushPins}
                                   onContinue={this.onChange}
                                   error={this.state.errorMsg}
                                   handleProjLoc={this.handleProjLoc}
                                   handleTimerIdicator={this.handleTimerIdicator}
                                   updateMapRender={this.props.updateMapRender}
                                   handleRecurrenceSubmit={this.handleRecurrenceSubmit}
                                   handleRecurrenceCancel={this.handleRecurrenceCancel}
                               />
                           </Tab>
                           <Tab eventKey="finalDetails" title={(this.state.finalDetails_val) ?
                               (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Final Details</div></div>) :
                               (<div style={{ margin: "-1px" }}>Final Details</div>)}>
                               <FinalDets
                                   edit={this.props.edit}
                                   handleProjLoc={this.handleProjLoc}
                                   handleFinalDetsChange={this.handleFinalDetsChange}
                                   formVals={this.state.finalDetails}
                                   empList={this.getEmpList()}
                                   contactList={this.state.contactList}
                                   user={this.props.user}
                               />
                           </Tab>
                       </Tabs>
                   </Form>)
                )}
                {this.state.goHome &&
                    <Redirect to="/" />
                }
            </div>)
        );
    };
}

export default TaskForm;