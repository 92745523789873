import React, { Component } from 'react';
import HandyCard from '../../HandyCard/HandyCard';
import { Form, FormControl } from 'react-bootstrap';
import { TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import {APIURL} from '../../../config'
import moment from 'moment'
import FilterSortBy from './FilterSortBy/FilterSortBy';
import UplaodForm from '../../../components/forms/UploadForm/UploadForm';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import DocumentViewerModal from '../../Documents/Modals/DocumentViewerModal';

class Artifacts extends Component {
    state = {
        grid: false,
        artifacts: null,
        artifacts_display: null,
        searchTerm: '',
        deleteArt: null,
        show_upload: false,
        taskList: [],
        taskType: [],
        showDocViewer: false,
        fileUrl: '',
        fileExt: ''
    }

    async fetchArtifacts() {
        let artifactsData = await axios.get(APIURL + "/artifacts?id=" + this.props.projectId);
        let employeeList = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: this.props.comp_id } });
        let taskList = await axios.get(APIURL + "/project/projTask", { params: { proj_id: this.props.projectId } });
        let taskTypes = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.comp_id, employeeType: this.props.user.type } });
        let artifacts = artifactsData.data.map((art) => {
            const task = taskList.data.find(task => art.task_id === task.tas_id);
            const employee = employeeList.data.find(emp => art.employee_id === emp.id);
            if (!employee) {
                return { ...art, taskName: '', taskType: '', empName: " " };
            }
            return !!task
                ? { ...art, taskName: task.tas_title, taskType: task.tas_type, empName: employee.employee_name }
                : { ...art, taskName: '', taskType: '', empName: employee.employee_name };
        });
        this.setState({
            artifacts: artifacts,
            artifacts_display: artifacts.filter((art) => !!art.show),
            taskList: taskList.data,
            taskType: taskTypes.data
        });
    }

    async componentDidMount(){
        await this.fetchArtifacts();
    }

    setArtifacts = (artifacts) => {
        this.setState({...this.state, artifacts_display: artifacts});
    }

    changeToGrid = () => {
        this.setState({ grid: true })
    }

    changeToRows = () => {
        this.setState({ grid: false })
    }

    renderGrid = () => {
        let content = []
        content = this.state.artifacts_display.map((item)=>{
            const file = item.url.split('/')[4]
            // const fileName = file.split('.')[0]
            const fileType = file.split('.')[1]
            return <HandyCard
                key={item.id}
                id={item.id}
                fileName={item.file_name}
                fileType={fileType}
                taskName={item.taskName}
                empName={item.empName}
                url={item.url}
                artifact={true}
                date={item.submitted}
                pname={this.props.project_title}
                del={this.delArt}
                grid
                openDocViewer={(url, ext) => this.setState({ showDocViewer: true, fileUrl: url, fileExt: ext })}
            />
        })
        return content
    }

    renderList = () => {
        if (this.state.artifacts_display) {
            return this.state.artifacts_display.map((item,indx) => {
                const file = item.url.split('/')[4]
                const fileType = file.split('.')[1]
                return <HandyCard
                    key={item.id}
                    id={item.id}
                    artifact={true}
                    fileName={item.file_name}
                    fileType={fileType}
                    taskName={''}
                    empName={item.empName}
                    url={item.url}
                    date={item.submitted}
                    pname={this.props.project_title}
                    del={this.delArt}
                    openDocViewer={(url, ext) => this.setState({ showDocViewer: true, fileUrl: url, fileExt: ext })}
                />
            })
        } else {
            return <div>Loading...</div>
        }

    }

    delArtifact = async () => {
        // console.log("Gonna Delete", this.state.deleteArt)
        let delArt = await axios.delete(APIURL+'/artifacts', { data: { id: this.state.deleteArt.id } })
        // console.log(delArt.data)
        if(delArt.data.code === 200){
            let artifactsArr1 = this.state.artifacts.filter((art)=> art.id !== this.state.deleteArt.id )
            let artifactsArr2 = this.state.artifacts_display.filter((art)=> art.id !== this.state.deleteArt.id )

            this.setState({artifacts_display: artifactsArr1, artifacts: artifactsArr2, deleteArt: null})
        }else{
            console.log('Artifact not deleted', delArt.data)
        }
    }

    delArt = (id) => {
        let art = this.state.artifacts_display.find(((art)=> art.id === id))
        // console.log(art)
        if(!!art){
            this.setState({deleteArt: art})
        }
    }

    searchArtHandler = (e) => {
        var searchQuery = e.target.value
        console.log(searchQuery)
        this.setState({searchTerm: e.target.value})
    }

    searchArtResults = (e) => {
        e.preventDefault()
        var searchTerm = new RegExp(this.state.searchTerm, 'i')
        console.log(searchTerm)
        let artSearchResults = this.state.artifacts.filter((art)=> !!art.show).map((art)=> {
            if(searchTerm.exec(art.file_name) || searchTerm.exec(art.taskName) || searchTerm.exec(art.empName)){
                return art
            }
            else{
                return null   
            }
        }).filter((art)=> !!art)

        this.setState({artifacts_display: artSearchResults})
    }

    render() {
        const { artifacts_display, grid, searchTerm } = this.state;
        let empList = !!this.props.empList ? this.props.empList.map((emp)=> <option key={emp.emp_id} value={emp.emp_id}>{emp.emp_first_name} {emp.emp_last_name}</option>) : <></>
        let taskList = !this.state.taskList?[]:this.state.taskList.map((task)=> <option key={task.tas_id} value={task.tas_id}>{task.tas_title}</option>)
        let taskTypeList = this.state.taskType.map((type,index)=> <option key={index} value={type}>{type}</option>)
        let content = artifacts_display && artifacts_display.length > 0 
            ? (grid ? this.renderGrid() : this.renderList())
            : (<div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '300px', margin: 5 }}>
                    <img src='./projectgraphic.png' alt='ArtTab' style={{ width: '10rem', height: '10rem' }}/>
                    <h5 style={{ fontFamily: 'Lato', fontSize: 18 }}>No Artifacts found </h5>
                    <p style={{ fontFamily: 'Lato', fontSize: 12 }} className='text-muted'>
                        We could not find any artifacts. Please add files via the Fieldworker mobile app.
                    </p>
               </div>);
         
        return (
            <div>
                {/* Moved controls outside the styled container */}
                <div className="TopPanel" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            onChange={(e) => this.setState({ searchTerm: e.target.value })}
                            value={searchTerm}
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton type="submit" onClick={(e)=> this.searchArtResults(e)}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => this.setState({ show_upload: true })}>
                            Upload
                        </Button>
                    </div>
                    <FilterSortBy 
                        artifacts={this.state.artifacts} 
                        artifactsDisplay={artifacts_display} 
                        setArtifacts={this.setArtifacts} 
                        taskList={taskList} 
                        taskTypeList={taskTypeList} 
                        empList={empList}
                    />
                </div>
                {/* Styled container for artifacts and remaining content */}
                <div style={{ padding: '15px 20px', border: '1px solid #3fa7d6', borderRadius: '10px', background: '#F9F9F9', overflow: 'hidden' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <h5 style={{ margin: 0 }}>FIles</h5>
                    </div>
                    <div className="Artifacts">
                        <ul>
                            <li onClick={() => this.changeToGrid()}><i className="fas fa-th"></i></li>
                            <li onClick={() => this.changeToRows()}><i className="fas fa-list"></i></li>
                        </ul>
                    </div>
                    {content}
                    <Dialog open={this.state.show_upload} onClose={() => {this.setState({...this.state, show_upload: false})}}>
                        <DialogActions>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.25 }}
                                onClick={() => {this.setState({...this.state, show_upload: false})}}
                                >
                                    <CloseIcon />
                            </IconButton>
                        </DialogActions>
                        <DialogContent sx={{ paddingTop: '0px', width: 370 }}>
                            <UplaodForm company_id={this.props.comp_id} mode='add' user={ this.props.user } project_id={this.props.projectId} onSubmit={() => {
                                this.fetchArtifacts();
                                this.setState({...this.state, show_upload: false});
                            }}></UplaodForm>
                        </DialogContent>
                    </Dialog>
                    <div className="modal fade" id="staticBackdropDel" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                            <h5 className="modal-title" id="staticBackdropLabel">Delete Artifact {!this.state.deleteArt?'':this.state.deleteArt.file_name}</h5>
                            <hr className="HorizontalLine"></hr>
                            <div className="d-flex flex-row justify-content-center">
                            <button onClick={()=> {}} type="button" className="btn btn-outline-info m-1" data-dismiss="modal">Cancel</button>
                            <button onClick={()=> this.delArtifact()} type="button" className="btn btn-info m-1" data-dismiss="modal">Delete</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <DocumentViewerModal
                        open={this.state.showDocViewer}
                        handleClose={() => this.setState({ showDocViewer: false })}
                        fileUrl={this.state.fileUrl}
                        fileExt={this.state.fileExt}
                    />
                </div>
            </div>
        )
    }
};

export default Artifacts;