import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Box, Autocomplete, TextField, Snackbar, IconButton, Alert } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../DataGrid/ControlledDataGrid';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";
import dayjs from "dayjs";

export default function EmployeeSessionsReport(props) {
    const [gridId, setGridId] = useState(73);
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [companyFilter, setCompanyFilter] = useState({
        dateRange: [
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
        ],
    });
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports", {
                params: { empID: props.user.emp_id },
            })
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "Employee Sessions Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        axios
            .get(APIURL + "/reports/getEmployeeSessions", {
                params: {
                    compID: props.user.comp_id,
                    start: moment(companyFilter?.dateRange[0]).startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
                    end: moment(companyFilter?.dateRange[1]).endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
                },
            })
            .then((response) => {
                setData(response?.data.length !== 0 ? response?.data : []);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    let rows = data.map((data, idx) => {
        let taskData = {
            id: idx,
            schedule_id: data.schedule_id,
            emp_name: data.emp_first_name + ' ' + data.emp_last_name,
            schedule_name: data.schedule_name,
            task_type: data.task_type,
            start_time: moment(data.start_time).format('MM/DD/YYYY hh:mm A'),
            end_time: moment(data.end_time).format('MM/DD/YYYY hh:mm A'),
        };
        return taskData;
    });

    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "schedule_id", headerName: "Schedule ID", hide: true },
        { field: "emp_name", headerName: "Employee Name", width: 200 },
        { field: "schedule_name", headerName: "Session Name", width: 200 },
        { field: "task_type", headerName: "Session Task Type", width: 150 },
        { field: "start_time", headerName: "Session Start Time", width: 200 },
        { field: "end_time", headerName: "Session End Time", width: 200 },
    ]

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
            empID: props.user.emp_id
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    return (
        (<div style={{ margin: 10, width: '75vw' }}>
            <div style={{ display: 'flex', marginTop: 20, marginBottom: 20, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        localeText={{ start: "From Date", end: "To Date" }}
                    >
                        <DateRangePicker
                            value={dayjs(companyFilter?.dateRange)}
                            onChange={(newValue) => {
                                setCompanyFilter({
                                    ...companyFilter,
                                    dateRange: [new Date(newValue[0]), new Date(newValue[1])],
                                });
                            }}
                            slotProps={{ fieldSeparator: { children: 'to' } }}
                        />
                    </LocalizationProvider>

                    <Button
                        onClick={() => fetchData()}
                        variant="primary"
                        size="lg"
                        className="font-weight-bold"
                        style={{ marginLeft: 10, fontSize: 14, fontFamily: "Lato" }}
                    >
                        SEARCH
                    </Button>
                </div>

                <div>
                    <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                        <img
                            src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                            alt="barchart"
                            style={{
                                fontFamily: "Lato",
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                                margin: 5
                            }}
                            onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                        />
                    </Tip>
                </div>
            </div>
            <div style={{ height: '70vh' }}>
                <DataGridPro
                    // gridId={gridId}
                    // user={props.user}
                    initialState={{ sorting: { sortModel: [{ field: "schedule_id", sort: "desc" }] } }}
                    // apiRef={apiRef}
                    getRowId={(row) => row.id}
                    rows={rows}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                />
            </div>
            <Snackbar
                open={successText !== ""}
                autoHideDuration={30000}
                onClose={() => setSuccessText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"success"}
                    elevation={6}
                    variant="filled"
                >
                    {successText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setSuccessText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorText !== ""}
                autoHideDuration={30000}
                onClose={() => setErrorText("")}
            >
                <Alert
                    sx={{ width: "100%" }}
                    severity={"error"}
                    elevation={6}
                    variant="filled"
                >
                    {errorText}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setErrorText("")}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
        </div>)
    );
}