import React from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#3FA7D6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3FA7D6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightblue",
      },
      "&:hover fieldset": {
        borderColor: "#3FA7D6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3FA7D6",
      },
    },
  },
})(TextField);

const BasicDets = (props) => {
  const { customerList, empList, payerList } = props;

  let selCust = [];
  let pSponsor = [];
  let pManager = [];
  let pCustomer = customerList.map((cust, indx) => {
    return {
      value: !props.edit ? cust.id : cust.cus_id,
      title: !props.edit ? cust.customer_name : cust.cus_name,
    };
  });
  let pPayers = payerList.map((p) => { return { value: p?.payerDets?.payer_id, title: p?.payerDets?.payer_name }; })

  if (props.edit) {
    selCust.push(customerList[0].cus_is_company);
    pSponsor.push({
      value: customerList[0].cus_id,
      title: customerList[0].cus_name,
    });
    pManager = !props.proj_employees
      ? []
      : props.proj_employees
        .filter(
          (emp) =>
            emp.emp_is_active === true
        )
        .map((emp, indx) => {
          return {
            value: emp.emp_id,
            title: emp.emp_first_name + " " + emp.emp_last_name,
          };
        });
  } else {
    selCust = customerList
      .filter(({ id }) => id === +props.formVals.pCustomer)
      .map((cus) => {
        return cus.cus_is_company;
      });
    pSponsor = customerList
      .filter(({ id }) => id === +props.formVals.pCustomer)
      .map((cust, indx) => {
        return { value: cust.id, title: cust.customer_name };
      });
    pManager = empList
      .filter((emp) => emp.emp_type === "Manager" || emp.emp_type === "Admin")
      .map((emp, indx) => {
        return { value: emp.id, title: emp.employee_name };
      });
  }

  pSponsor = [{ value: "No Sponsor", title: "No Sponsor" }, ...pSponsor];

  return (
    <div>
      <CssTextField
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        label="PROJECT TITLE"
        value={props.formVals.pTitle}
        onChange={props.handlebasicDetailsChange}
        variant="outlined"
        id="pTitle"
        required
        inputProps={{ maxLength: 100 }}
      />

      <CssTextField
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        label="PROJECT DESCRIPTION"
        value={props.formVals.pDesc}
        onChange={props.handlebasicDetailsChange}
        variant="outlined"
        id="pDesc"
        required
        inputProps={{ maxLength: 250 }}
      />

      <CssTextField
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        label="PROJECT ACTIVE PLAN VERSION"
        value={props.formVals.active_plan_version || ""}
        onChange={props.handlebasicDetailsChange}
        variant="outlined"
        id="active_plan_version"
        required
        inputProps={{ maxLength: 20 }}
      />

      <Autocomplete
        id="pCustomer"
        value={pCustomer.find((type) => type.value === props.formVals.pCustomer)}
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        onChange={(event, newValue) => {
          console.log(newValue)
          let val = pCustomer.find((man) => man.title === newValue.title);
          props.handlebasicDetailsChange({
            target: { id: "pCustomer", value: !!val ? val.value : "" },
          });
        }}
        options={pCustomer}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <CssTextField {...params} label="CUSTOMER *" variant="outlined" />
        )}
        disabled={props.edit}
      />

      <Autocomplete
        id="pSponsor"
        value={
          pSponsor.find((type) => type.value === props.formVals.pSponsor) ||
          null
        }
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        onChange={(event, newValue) => {
          let val = pSponsor.find((man) => man.title === newValue.title);
          props.handlebasicDetailsChange({
            target: { id: "pSponsor", value: !!val ? val.value : "No Sponsor" },
          });
        }}
        options={pSponsor}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <CssTextField
            {...params}
            label="PROJECT SPONSOR"
            variant="outlined"
          />
        )}
        disabled={props.edit}
      />

      <Autocomplete
        id="pManager"
        required
        value={
          pManager.find((type) => type.value === props.formVals.pManager) ||
          null
        }
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        onChange={(event, newValue) => {
          let val = pManager.find((man) => man.title === newValue.title);
          props.handlebasicDetailsChange({
            target: { id: "pManager", value: !!val ? val.value : "" },
          });
        }}
        options={pManager}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <CssTextField
            {...params}
            label="PROJECT MANAGER *"
            variant="outlined"
          />
        )}
      />

      <Form.Group as={Col}>
        <Form.Label>SELF PAY?</Form.Label>
        <Row className="mx-1" style={{ display: 'flex', gap: '10px', textAlign: 'center' }}>
          <Form.Check
            type="radio"
            label="Yes"
            name="pSelfPay"
            id="radioSelfPayYes"
            required
            onChange={(e) => {
              props.handlebasicDetailsChange({
                target: { id: "pSelfPay", value: true },
              });
            }}
            checked={props.formVals.pSelfPay === true}
          />
          <Form.Check
            type="radio"
            label="No"
            name="pSelfPay"
            id="radioSelfPayNo"
            required
            onChange={(e) => {
              props.handlebasicDetailsChange({
                target: { id: "pSelfPay", value: false },
              });
            }}
            checked={props.formVals.pSelfPay === false}
          />
        </Row>
      </Form.Group>

      <div className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`} style={{ display: 'flex' }}>
        <Autocomplete
          id="pPrimaryPayer"
          value={
            pPayers.find((payer) => payer.value === props.formVals.pPrimaryPayer) ||
            null
          }
          className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
          onChange={(event, newValue) => {
            props.handlebasicDetailsChange({
              target: { id: "pPrimaryPayer", value: newValue.value },
            });
          }}
          options={pPayers}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <CssTextField
              {...params}
              label="PRIMARY PAYER"
              variant="outlined"
            />
          )}
        />

        <Autocomplete
          id="pSecondaryPayer"
          value={
            pPayers.find((payer) => payer.value === props.formVals.pSecondaryPayer) ||
            null
          }
          className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
          onChange={(event, newValue) => {
            props.handlebasicDetailsChange({
              target: { id: "pSecondaryPayer", value: newValue.value },
            });
          }}
          options={pPayers}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <CssTextField
              {...params}
              label="SECONDARY PAYER"
              variant="outlined"
            />
          )}
        />
      </div>

      <Button
        className={!!props.edit ? "w-100 ml-2" : "w-75 ml-2"}
        variant="primary"
        block
        type="button"
        onClick={() => props.onContinue("dateLocation")}
      >
        Continue
      </Button>
    </div>
  );
};

export default BasicDets;
