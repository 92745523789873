import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import {
    CircularProgress,
    Box,
    Snackbar,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from "@mui/styles/withStyles";
import { Button } from "react-bootstrap";
import axios from 'axios';
import { APIURL } from '../../config';
import SaveViewButton from './SaveViewButton';
//import LoadingSkeleton from './LoadingSkeleton';

// This is a custom DataGrid component that allows for the user to save their grid settings
// and have them persist between sessions. It also allows for the user to save their own
// custom views of the grid
// mandatory props:
//      formID: the form id || reportID: the report id
//      user: the user object
//      columns: the columns of the grid
//      rows: the rows of the grid
//      apiRef: the apiRef of the grid

// Besides the mandatory props, you can use this component just like you would use the DataGridPro component

// If you want to use a Custom Toolbar you can pass it as a function that returns the toolbar along
// with the SaveViewButton component. The function should take in params and spread them into the 
// SaveViewButton component like in the DefaultToolbar function below

// optional props:
//      onLoad: a function or array of functions that will be called when the grid loads. The function(s) will be passed the settings object
//      onSave: a function or array of functions that will be called when the user saves the grid settings. The function(s) will be passed the settings object

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#3FA7D6",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#3FA7D6",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "lightblue",
            },
            "&:hover fieldset": {
                borderColor: "#3FA7D6",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3FA7D6",
            },
        },
    },
})(TextField);

const ControlledFormDataGrid = (props) => {
    const [isLoadingSettings, setIsLoadingSettings] = useState(true);
    const [initialSettings, setInitialSettings] = useState(null);
    const [orderedColumns, setOrderedColumns] = useState(props.columns ? props.columns.reduce((acc, col) => ([...acc, col.field]), []) : []);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);
    const [density, setDensity] = useState("standard");
    const [rows, setRows] = useState(props.rows ? props.rows : []);
    const [openModal, setOpenModal] = useState(false);
    const [reportName, setReportName] = useState('');
    const [reportHeader, setReportHeader] = useState('');
    const [reportSubheader1, setReportSubheader1] = useState('');
    const [reportSubheader2, setReportSubheader2] = useState('');
    const [snackbar, setSnackbar] = useState(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    const apiRef = props.apiRef;

    useEffect(async () => {
        if (props.reportID) {
            let getGridSettings = await axios.get(`${APIURL}/forms/form_report`, { params: { report_id: props.reportID, report_company_id: props.user.comp_id } });
            if (getGridSettings.data.length > 0) {
                let settings = JSON.parse(getGridSettings.data[0].report_definition)
                setInitialSettings(settings);
                setColumnVisibilityModel({ ...props.columnVisibilityModel, ...settings.columnVisibilityModel });
                setSelectionModel(settings.selectionModel ? settings.selectionModel : []);
                setDensity(settings.density ? settings.density : "standard");
                if (props.onLoad) {
                    Array.isArray(props.onLoad) ? props.onLoad.forEach((func) => func(settings)) : props.onLoad(settings);
                }
            }
        }
        setIsLoadingSettings(false);
    }, []);

    useEffect(() => {
        if (props.rows) {
            setRows(props.rows);
        }
    }, [props.rows]);

    const handleColumnOrderChange = useCallback((params) => {
        setOrderedColumns((prevOrderedColumns) => {
            const newOrderedColumns = [...prevOrderedColumns];
            const oldIndex = params.oldIndex - props.checkboxSelection ? 1 : 0;
            const targetIndex = params.targetIndex - props.checkboxSelection ? 1 : 0;
            const oldColumn = prevOrderedColumns[oldIndex];
            newOrderedColumns.splice(oldIndex, 1);
            newOrderedColumns.splice(targetIndex, 0, oldColumn);
            return newOrderedColumns;
        });
    }, []);

    const handleColumnVisibilityModelChange = (newModel) => {
        setColumnVisibilityModel(newModel);
    }

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    }

    const columns = useMemo(() => {

        let allColumns = props.columns ? props.columns.reduce((acc, col) => ({ ...acc, [col.field]: col }), {}) : {};

        if (initialSettings?.columns?.dimensions) {
            Object.keys(initialSettings.columns.dimensions).forEach((field) => {
                try {
                    if (initialSettings.columns.dimensions.hasOwnProperty(field) && allColumns.hasOwnProperty(field))
                        allColumns[field].width = initialSettings.columns.dimensions[field].width;
                }
                catch (e) {
                    console.log('ERROR', e)
                }
            });
        }

        return orderedColumns.reduce((acc, field) => {
            return [...acc, allColumns[field]];
        }, []);
    }, [rows]);

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };

    const handleSaveSettings = async () => {
        let settings = props.apiRef.current.exportState();
        settings.columnVisibilityModel = columnVisibilityModel;
        settings.selectionModel = selectionModel;
        settings.density = density;
        if (props.onSave) {
            Array.isArray(props.onSave) ? props.onSave.forEach((func) => func(settings)) : props.onSave(settings);
        }
        settings = JSON.stringify(settings);

        let saveGridSettings = props.formID
            ? await axios.post(`${APIURL}/forms/form_report`, { report_name: reportName, report_header: reportHeader, report_subheader1: reportSubheader1, report_subheader2: reportSubheader2, report_owner: props.user.emp_id, report_form_id: props.formID, report_definition: settings, report_company_id: props.user.comp_id })
                .then(async (response) => {
                    setSnackbar({
                        children: "New report added.",
                        severity: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setSnackbar({
                        children: err.message,
                        severity: "error"
                    });
                })
            : await axios.put(`${APIURL}/forms/form_report`, { report_id: props.reportID, report_definition: settings, report_company_id: props.user.comp_id })
                .then(async (response) => {
                    setSnackbar({
                        children: "Report saved.",
                        severity: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setSnackbar({
                        children: err.message,
                        severity: "error"
                    });
                });

        setOpenModal(false);
    }

    const handleOnClick = async () => {
        if (props.formID) {
            setOpenModal(true);
        } else {
            handleSaveSettings();
        }
    }

    const DefaultToolbar = (params) => {
        return (
            <GridToolbarContainer>
                <Box display="flex" justifyContent="space-between" width="100%" padding={1} borderBottom="1px solid">
                    {props?.reportHeader &&
                        <Box>
                            <h4>{props?.reportHeader}</h4>
                            <h5>{props?.reportSubheader1}</h5>
                            <h6>{props?.reportSubheader2}</h6>
                        </Box>
                    }
                    <Box>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                        {props.user.type === 'Admin' && props.isReportBuilder && <SaveViewButton {...params} buttonName={props.formID ? 'CREATE NEW REPORT' : 'SAVE REPORT'} />}
                    </Box>
                </Box>
            </GridToolbarContainer>
        );
    };

    return (<>
        {isLoadingSettings ?
            <Box sx={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                <CircularProgress />
            </Box> :
            <Box sx={{ width: 'calc(100vw - 570px)', height: "calc(100vh - 205px)" }}>
                <DataGridPro
                    {...props}
                    sx={{
                        backgroundColor: '#F9F9F9',
                        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                            backgroundColor: '#F9F9F9',
                        },
                        ...props.sx
                    }}
                    rows={rows}
                    columns={columns}
                    density={density}
                    onDensityChange={(newDensity) => setDensity(newDensity)}
                    initialState={initialSettings}
                    slots={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton'
                        },
                        color: '#3fadf6',
                        ...props.components,
                        ...props.slots,
                        toolbar: () => {
                            if (props.components?.toolbar) {
                                return props.components?.toolbar({ onClick: debounce(handleOnClick, 500) })
                            } else if (props.slots?.toolbar) {
                                return props.slots?.toolbar({ onClick: debounce(handleSaveSettings, 500) })
                            }
                            return DefaultToolbar({ onClick: debounce(handleOnClick, 500) });
                        }
                    }}
                    apiRef={apiRef}
                    onColumnOrderChange={handleColumnOrderChange}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={handleSelectionModelChange}
                />
                <Dialog fullWidth={true} maxWidth={'sm'} open={openModal} onClose={() => setOpenModal(false)}>
                    <DialogActions>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setOpenModal(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        <h4>Add Report</h4>
                        <CssTextField
                            value={reportName}
                            onChange={(event) => {
                                setReportName(event.target.value)
                            }}
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            inputProps={{ maxLength: 50 }}
                            label="Report Name *"
                            id="rName"
                            error={!reportName}
                        />
                        <CssTextField
                            value={reportHeader}
                            onChange={(event) => {
                                setReportHeader(event.target.value)
                            }}
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            inputProps={{ maxLength: 50 }}
                            label="Report Header *"
                            id="rHeader"
                            error={!reportHeader}
                        />
                        <CssTextField
                            value={reportSubheader1}
                            onChange={(event) => {
                                setReportSubheader1(event.target.value)
                            }}
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            inputProps={{ maxLength: 50 }}
                            label="Report Subheader 1"
                            id="rSubHeader1"
                        />
                        <CssTextField
                            value={reportSubheader2}
                            onChange={(event) => {
                                setReportSubheader2(event.target.value)
                            }}
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            inputProps={{ maxLength: 50 }}
                            label="Report Subheader 2"
                            id="rSubHeader2"
                        />
                        <Button
                            style={{
                                width: '100%',
                                padding: '5px',
                                margin: '5px',
                            }}
                            onClick={() => handleSaveSettings()}
                        >
                            Add Report
                        </Button>
                    </DialogContent>
                </Dialog>
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </Box>
        }
    </>);
}

export default ControlledFormDataGrid;