import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Chip, Checkbox, Stack, FormControlLabel, FormGroup, TextField, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Cancel, MoreVert, FormatListBulleted, Task } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import { isEmpty } from 'lodash';
import LinkCode from '../Modals/LinkCode';
import LinkForm from '../Modals/LinkForm';
import axios from 'axios';
import { APIURL } from '../../../config';
import { set } from 'date-fns';
import { or } from '@jsonforms/core';
import { forms } from '@formio/react';

const TaskType = (props) => {
    const [taskTypeList, setTaskTypeList] = useState([]);
    const [selectedTaskTypes, setSelectedTaskTypes] = useState([]);
    const [originalTaskTypes, setOriginalTaskTypes] = useState([]);
    const [newTaskType, setNewTaskType] = useState("");
    const [menuTaskType, setMenuTaskType] = useState({});
    const [tempTaskType, setTempTaskType] = useState({});
    const [taskTypeEditMode, setTaskTypeEditMode] = useState(false);
    const [openLinkCode, setOpenLinkCode] = useState(false);
    const [openLinkForm, setOpenLinkForm] = useState(false);
    const [selectedProcCodes, setSelectedProcCodes] = useState({});
    const [originalProcCodes, setOriginalProcCodes] = useState({});
    const [selectedForms, setSelectedForms] = useState({});
    const [originalForms, setOriginalForms] = useState({});
    const [newId, setNewId] = useState(-1);
    const [newPayerDets, setNewPayerDets] = useState(props.payerList);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, taskType) => {
        setAnchorEl(event.currentTarget);
        setMenuTaskType(taskType);
        setTempTaskType(taskType);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const textFieldRef = useRef(null);

    useEffect(() => {
        console.log(taskTypeEditMode)
        if (taskTypeEditMode) {
            textFieldRef.current?.focus();
        }
    }, [taskTypeEditMode]);

    useEffect(() => {
        async function fetchData() {
            setTaskTypeList(props.taskTypeList.map((type) => {
                return { title: type.EntityValue, id: type.ddID, deletable: type.is_Active === 3 || type.is_Active === 4, is_Active: type.is_Active }
            }));
            setSelectedTaskTypes(props.taskTypeList.filter((type) => type.is_Active === 0 || type.is_Active === 3).map((type) => {
                return { title: type.EntityValue, id: type.ddID, deletable: type.is_Active === 3 || type.is_Active === 4, is_Active: type.is_Active }
            }));
            setOriginalTaskTypes(props.taskTypeList.map((type) => {
                return { title: type.EntityValue, id: type.ddID, deletable: type.is_Active === 3 || type.is_Active === 4, is_Active: type.is_Active }
            }));

            let newPayerDetails = await axios.get(APIURL + `/associate_payer`, {
                params : {
                    compId: props.comp_id,
                }
            });

            setNewPayerDets(newPayerDets.filter((p) => newPayerDetails.data.map((p) => p.payerId).includes(p.payer_id)));

            let selectedProcCodes = {};

            for (const taskType of props.taskTypeList) {
                let Procdata = await axios.get(APIURL + '/procedureCodes', { params: { dropdown_id: taskType.ddID } });
                selectedProcCodes[taskType.ddID] = Procdata.data[0]?.procedure_code_id || null;
            }
            setSelectedProcCodes(selectedProcCodes);
            setOriginalProcCodes(selectedProcCodes);

            let selectedForms = {};

            for (const taskType of props.taskTypeList) {
                let taskFormData = await axios.get(APIURL + '/task_form', { params: { taskType_id: taskType.ddID, company_id: props.comp_id } });
                selectedForms[taskType.ddID] = taskFormData.data.map((form) => form.form_id);
            }
            setSelectedForms(selectedForms);
            setOriginalForms(selectedForms);
        }

        fetchData();
    }, [props.taskTypeList]);

    const handleCheckBoxChange = (event) => {
        if (event.target.checked) {
            let taskType = taskTypeList.find((taskType) => taskType.id == event.target.id);
            console.log(taskType.id == event.target.id);
            setSelectedTaskTypes([...selectedTaskTypes, { title: event.target.name, id: taskType.id, deletable: taskType.deletable }]);
            setTaskTypeList(taskTypeList.map((t) => t.id == event.target.id ? { ...t, is_Active: t.is_Active - 1 } : t));
        } else {
            setSelectedTaskTypes(selectedTaskTypes.filter((taskType) => taskType.id != event.target.id));
            setTaskTypeList(taskTypeList.map((t) => t.id == event.target.id ? { ...t, is_Active: t.is_Active + 1 } : t));
        }
    }

    const handleChipDelete = (taskType) => {
        setSelectedTaskTypes(selectedTaskTypes.filter((t) => t.id !== taskType.id));
        setTaskTypeList(taskTypeList.map((t) => t.id === taskType.id ? { ...t, is_Active: t.is_Active + 1 } : t));
    }

    const handleNewTaskTypeChange = (event) => {
        setNewTaskType(event.target.value);
    }

    const handleAddNewTaskType = () => {
        if (isEmpty(newTaskType)) return;
        let taskTypeObj = { title: newTaskType, id: newId, deletable: true, is_Active: 4 };
        setTaskTypeList([...taskTypeList, taskTypeObj]);
        setSelectedTaskTypes([...selectedTaskTypes, taskTypeObj]);
        setNewTaskType("");
        setNewId(newId - 1);
    }

    const handleDeleteTaskType = (taskType) => {
        setTaskTypeList(taskTypeList.filter((t) => t.title !== taskType.title));
        setSelectedTaskTypes(selectedTaskTypes.filter((t) => t.title !== taskType.title));
    }

    const handleSave = async () => {
        let taskTypesToInsert = taskTypeList.filter((t) => t.id < 0);
        let taskTypesToUpdateName = taskTypeList.filter((t) => {
            let originalTaskType = originalTaskTypes.find((ot) => ot.id === t.id);
            return originalTaskType && originalTaskType.title !== t.title;
        });
        let taskTypesToUpdateStatus = taskTypeList.filter((t) => {
            let originalTaskType = originalTaskTypes.find((ot) => ot.id === t.id);
            if (!originalTaskType) {
                return false;
            }
            if ((originalTaskType.is_Active === 0 || originalTaskType.is_Active === 3) && !selectedTaskTypes.find((st) => st.id === t.id)) {
                return true;
            }
            if ((originalTaskType.is_Active === 1 || originalTaskType.is_Active === 4) && selectedTaskTypes.find((st) => st.id === t.id)) {
                return true;
            }
            return false;
        });
        let taskTypesToDelete = originalTaskTypes.filter((ot) => {
            return !taskTypeList.some((t) => t.id === ot.id);
        });

        // let procCodesToUpdate = Object.keys(selectedProcCodes).filter((key) => selectedProcCodes[key] !== originalProcCodes[key]);
        let formsToUpdate = Object.keys(selectedForms).filter((key) => JSON.stringify(selectedForms[key]) !== JSON.stringify(originalForms[key]));

        try {
            const promises = taskTypesToInsert.map((t) => {
                return axios.post(APIURL + "/dropdowns?comp_id=" + props.comp_id + "&is_Active=" + t.is_Active, {
                    entityValue: t.title,
                    entityName: "taskType"
                });
            }).concat(
                taskTypesToUpdateName.map((t) => {
                    return axios.put(APIURL + "/dropdowns", {
                        ddId: t.id,
                        entityValue: t.title
                    });
                }),
                taskTypesToUpdateStatus.map((t) => {
                    return axios.put(APIURL + "/dropdowns", {
                        ddId: t.id,
                        is_Active: t.is_Active
                    });
                }),
                taskTypesToDelete.map((t) => {
                    return axios.put(APIURL + "/dropdowns/company/config", {
                        ddId: t.id
                    });
                }),
                // procCodesToUpdate.map((key) => {
                //     return axios.post(APIURL + "/procedureCodes/handleMappingForTaskProcCode", {
                //         dropdown_id: key,
                //         procedure_code_id: selectedProcCodes[key],
                //         comp_id: props.comp_id
                //     });
                // }),
                formsToUpdate.map((key) => {
                    return axios.post(APIURL + "/task_form", {
                        taskType_id: key,
                        form_ids: selectedForms[key],
                        company_id: props.comp_id,
                        created_by: props.user_id
                    });
                })
            );
            await Promise.all(promises);
            setOriginalTaskTypes(taskTypeList);
            setOriginalProcCodes(selectedProcCodes);
            setOriginalForms(selectedForms);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        console.log(taskTypeEditMode);
    }, [taskTypeEditMode]);


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Task Types
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            What all types of tasks do you perform?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            Types
                        </Typography>
                        <Box sx={{ minHeight: 32 }}>
                            <Box sx={{ minHeight: 40 }}>
                                {selectedTaskTypes.length === 0 && <Typography variant="body1" sx={{ color: 'gray' }}>No Types selected</Typography>}
                                {selectedTaskTypes.map((taskType, index) => {
                                    return (
                                        <Chip key={index} label={taskType.title} onDelete={() => handleChipDelete(taskType)} sx={{ mr: 1, mb: 1 }} color="primary" />
                                    );
                                })}
                            </Box>
                            <Typography variant="h6">
                                Select Types
                            </Typography>
                        </Box>
                        <FormGroup>
                            <Grid container spacing={0}>
                                {taskTypeList.map((taskType, index) => (
                                    <Grid item xs={6} key={index}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            {taskTypeEditMode && menuTaskType.title === taskType.title ?
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0 }}>
                                                    <Checkbox checked={selectedTaskTypes.find((t) => t.id === taskType.id) ? true : false} onChange={handleCheckBoxChange} name={taskType.title} id={taskType.id} sx={{ margin: 0 }} />
                                                    <TextField
                                                        variant="outlined"
                                                        size='small'
                                                        value={tempTaskType.title}
                                                        inputRef={textFieldRef}
                                                        onBlur={() => {
                                                            setTaskTypeEditMode(false);
                                                            setTaskTypeList(taskTypeList.map((t) => t.id === taskType.id ? tempTaskType : t));
                                                            if (selectedTaskTypes.find((t) => t.id === taskType.id)) {
                                                                setSelectedTaskTypes(selectedTaskTypes.map((t) => t.id === taskType.id ? tempTaskType : t));
                                                            }
                                                        }}
                                                        onChange={(event) => {
                                                            console.log(tempTaskType);
                                                            setTempTaskType({ ...tempTaskType, title: event.target.value });
                                                        }}
                                                        fullWidth
                                                        inputProps={{ style: { paddingTop: 1, paddingBottom: 1 } }}
                                                    />
                                                </Box> :
                                                <FormControlLabel
                                                    sx={{ height: 25, alignSelf: 'center' }}
                                                    control={<Checkbox checked={selectedTaskTypes.find((t) => t.title === taskType.title) ? true : false} onChange={handleCheckBoxChange} name={taskType.title} sx={{ margin: 0 }} id={taskType.id} />}
                                                    label={taskType.title}
                                                />
                                            }
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
                                                {selectedProcCodes[taskType.id] &&
                                                    <IconButton size='small' sx={{ bottom: 6 }} onClick={(event) => { handleClick(event, taskType); handleClose(); setOpenLinkCode(true); }}>
                                                        <Task fontSize='small' />
                                                    </IconButton>
                                                }
                                                {selectedForms[taskType.id]?.length > 0 &&
                                                    <IconButton size='small' sx={{ bottom: 6 }} onClick={(event) => { handleClick(event, taskType); handleClose(); setOpenLinkForm(true); }}>
                                                        <FormatListBulleted fontSize='small' />
                                                    </IconButton>
                                                }
                                                <IconButton size='small' sx={{ bottom: 6 }} onClick={(event) => handleClick(event, taskType)}>
                                                    <MoreVert fontSize='small' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {menuTaskType.deletable &&
                                        <MenuItem onClick={() => { setTaskTypeEditMode(true); handleClose(); }}>Edit</MenuItem>
                                    }
                                    {menuTaskType.deletable &&
                                        <MenuItem onClick={() => { handleDeleteTaskType(menuTaskType); handleClose(); }}>Delete</MenuItem>
                                    }
                                    <MenuItem onClick={() => { setOpenLinkCode(true); handleClose(); }}>Link Code</MenuItem>
                                    <MenuItem onClick={() => { setOpenLinkForm(true); handleClose(); }}>Link Form</MenuItem>
                                </Menu>
                            </Grid>
                        </FormGroup>
                        {!props.disableAddNew &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <TextField
                                    variant="outlined"
                                    label={`Add New Types`}
                                    size='small'
                                    value={newTaskType}
                                    onChange={handleNewTaskTypeChange}
                                    fullWidth
                                />
                                <Button variant="contained" sx={{ ml: 2, width: 100 }} color="primary" onClick={handleAddNewTaskType}>Add</Button>
                            </Box>
                        }
                        <SaveButton onClick={() => handleSave()} disabled={JSON.stringify(taskTypeList) === JSON.stringify(originalTaskTypes) && JSON.stringify(selectedProcCodes) === JSON.stringify(originalProcCodes) && JSON.stringify(selectedForms) === JSON.stringify(originalForms)} />
                    </Stack>
                </Grid>
            </Grid>
            <LinkCode open={openLinkCode} setOpen={setOpenLinkCode} getTaskTypeData={props.getTaskTypeData} procList={props.procList} providerList={props.providerList} officeList={props.officeList} payerList={newPayerDets} taskType={menuTaskType} originalSelectedProcCode={props.procList.find((procCode) => procCode.procCode_id == selectedProcCodes[menuTaskType.id])} selectedProcCodes={selectedProcCodes} setSelectedProcCodes={setSelectedProcCodes} getProcedureData={props.getProcedureData} procedureData={props.procedureData} comp_id={props.comp_id} />
            <LinkForm open={openLinkForm} setOpen={setOpenLinkForm} formList={props.formList} taskType={menuTaskType} selectedForms={selectedForms} setSelectedForms={setSelectedForms} comp_id={props.comp_id} user_id={props.user_id} />
        </Box>
    )
}

export default TaskType;