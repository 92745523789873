import React, { Component } from 'react'
import Home from '../Home/Home'
import Employees from '../Employees/Employees'
import TasksPage from '../Tasks/TasksPage'
import Artifacts from '../Artifacts/Artifacts'
import Notes from '../Notes/Notes'
import Notesv2 from '../Notes/Notesv2'
import ContactsPage from '../Contacts/Contacts'
import axios from 'axios';
import { APIURL } from '../../../config'
import "./details.css"
import { Tabs, Tab } from '@mui/material'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config'

const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

class DetailsPane extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: 0,
            project: {},
            loading: true,
            loggedToSegment: false,
        }
        this.selectTab = this.selectTab.bind(this)
        this.renderDetails = this.renderDetails.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.isFormValidated = this.isFormValidated.bind(this)
    }
    async fetchData() {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        this.setState({ key: "Home", selectedTab: 0, loading: false })
    }
    async componentDidMount() {
        this.setState({ loading: true })
        this.fetchData()
        this.logToSegment()
    }

    logToSegment = async () => {
        if (this.state.loggedToSegment) {
            return
        }
        let page = String(window.location.href).split("/").pop()
        if (page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home"
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1)
        }
        analytics.page(page, {
            title: page,
            path: page,
        }, {
            userId: String(this.props.user.emp_id),
        })
        this.setState({ loggedToSegment: true })
    }

    componentDidUpdate(prevProps) {
        if (this.props.project_details !== prevProps.project_details) {
            this.setState({ loading: true })
            this.fetchData()
        }
    }
    selectTab(event, newValue) {
        this.setState({
            selectedTab: newValue
        })
    }
    isFormValidated = (objForVal) => {
        var missingField = {
            isValidated: true
        }
        let temp = Object.keys(objForVal)
        if (temp[0] === 'selectedEmp') {
            let selectedEm = objForVal.selectedEmp
            for (let i = 0; i < selectedEm.length; i++) {
                for (let objKey in selectedEm[i]) {
                    if (
                        selectedEm[i][objKey] === "" ||
                        selectedEm[i][objKey] === undefined ||
                        selectedEm[i][objKey].length === 0
                    ) {
                        missingField.isValidated = false
                        missingField.missingKey = objKey
                        return missingField
                    }
                }
            }
        }

        for (let objKey in objForVal) {
            if (
                objForVal[objKey] === "" ||
                objForVal[objKey] === undefined ||
                objForVal[objKey] === null ||
                objForVal[objKey].length === 0
            ) {
                if (
                    objKey === "address2" ||
                    objKey === "location_addr2" ||
                    objKey === "tComment" ||
                    objKey === "cus_local_id" ||
                    objKey === "address_line_2" ||
                    objKey === "email" ||
                    objKey === "pManager" ||
                    objKey === "pSelfPay" ||
                    objKey === "pPrimaryPayer" ||
                    objKey === "pSecondaryPayer" ||
                    objKey === "medicaid_id" ||
                    objKey === "medicaid_eligible" ||
                    objKey === "sign_page_date" ||
                    objKey === "last_plan_approval_date" ||
                    objKey === "last_assess_date" ||
                    objKey === "renewal_date" ||
                    objKey === "personal_days_off" ||
                    objKey === "vacation_days" ||
                    objKey === "DDD_tier" ||
                    objKey === "diagnose_code" ||
                    objKey === "func_criteria_met" ||
                    objKey === "waiver_program" ||
                    objKey === "NJCATSelfcare" ||
                    objKey === "NJCATBehavioral" ||
                    objKey === "NJCATMedical" ||
                    objKey === "NJCATFCA" ||
                    objKey === "MedicaidEndDate" ||
                    objKey === "AgencyAssignDate" ||
                    objKey === "County" ||
                    objKey === "Race" ||
                    objKey === "Ethnicity" ||
                    objKey === "TransferOutReason" ||
                    objKey === "TransferOutDate" ||
                    objKey === "tContacts"
                ) {
                    continue
                } else if (objForVal["cus_is_company"] === true && objForVal["company_name"] === "") {
                    missingField.isValidated = false
                    missingField.missingKey = "company_name"
                    return missingField
                }
                else {
                    missingField.isValidated = false
                    missingField.missingKey = objKey
                    return missingField
                }
            } else if (Array.isArray(objForVal[objKey])) {
                let arrValid = objForVal[objKey].every(function (objTesting) {
                    for (let objTestKey in objTesting) {
                        if (
                            objTesting[objTestKey] === "" ||
                            objTesting[objTestKey] === undefined
                        ) {
                            if (
                                objTestKey === "address_line_2" ||
                                objTestKey === "location_addr2"
                            ) {
                                continue
                            } else {
                                missingField.isValidated = false
                                missingField.missingKey = objKey
                                return missingField
                            }
                        }
                    }
                    return true
                })
                if (!arrValid) return false
            } else if (objKey === "active_plan_version") {
                for (const digit of objForVal[objKey]) {
                    if (Number.isNaN(parseInt(digit)) && digit !== ".") {
                        alert("PROJECT ACTIVE PLAN VERSION has to be a number")
                        objForVal[objKey] = ""
                        return false
                    }
                }
            }
        }

        return missingField
    }

    renderDetails() {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        switch (this.state.selectedTab) {
            case 0:
                return <Home
                    {...this.props}
                    user={this.props.user}
                    title={this.props.project_details.pro_title}
                    id={this.props.project_details.pro_id}
                    status={this.props.project_details.pro_is_active}
                    start_date={this.props.project_details.pro_starts_on}
                    end_date={this.props.project_details.pro_ends_on}
                    customer_details={this.props.project_details.customer_details}
                    empList={this.props.project_details.employees}
                    locations={this.props.project_details.locations}
                    pManagerId={this.props.project_details.pro_manager_id}
                    isFormValidated={this.isFormValidated}
                    comp_id={this.props.comp_id}
                    updateProject={this.props.updateProject}
                />
            case 1:
                return <TasksPage
                    user={this.props.user}
                    history={this.props.history}
                    project_details={{
                        pname: this.props.project_details.pro_title,
                        pid: this.props.project_details.pro_id,
                        start_date: this.props.project_details.pro_starts_on,
                        end_date: this.props.project_details.pro_ends_on,
                        cus_id: this.props.project_details.pro_customer_id
                    }}
                    customer_details={this.props.project_details.customer_details}
                    location_list={this.props.project_details.locations}
                    title={this.props.project_details.pro_title}
                    employees={this.props.project_details.employees}
                    comp_id={this.props.comp_id}
                    isFormValidated={this.isFormValidated}
                />
            case 2:
                return <Employees
                    employees={this.props.project_details.employees}
                    project_title={this.props.project_details.pro_title}
                    projId={this.props.project_details.pro_id}
                    project_manager_id={this.props.project_details.pro_manager_id}
                    project_start_date={this.props.project_details.pro_starts_on}
                    project_end_date={this.props.project_details.pro_ends_on}
                    isFormValidated={this.isFormValidated}
                    comp_id={this.props.comp_id}
                    user={this.props.user}
                />
            case 3:
                return <Artifacts
                    projectId={this.props.project_details.pro_id}
                    project_title={this.props.project_details.pro_title}
                    empList={this.props.project_details.employees}
                    user={this.props.user}
                    comp_id={this.props.comp_id}
                />
            case 4:
                return <div style={{ height: '100%' }}>
                    <Notesv2
                        pro_id={this.props.project_details.pro_id}
                        user={this.props.user}
                        com_id={this.props.comp_id}
                        checkTokenExpiry={this.props.checkTokenExpiry}
                    />
                </div>
            case 5:
                return <ContactsPage
                    project_title={this.props.project_details.pro_title}
                    projId={this.props.project_details.pro_id}
                    comp_id={this.props.comp_id}
                    isFormValidated={this.isFormValidated}
                />
            default:
                return <div />
        }
    }
    render() {
        return (
            <div style={{ width: '100%', height: '100%', paddingLeft: '15px' }}>
                <Tabs
                    value={this.state.selectedTab}
                    onChange={this.selectTab}
                    variant="fullWidth"
                    scrollButtons="auto"
                    style={{ position: 'sticky', top: '0px', zIndex: 100, width: '100%' }}
                >
                    <Tab label="OVERVIEW" value={0} />
                    <Tab label="TASKS" value={1} />
                    <Tab label="EMPLOYEES" value={2} />
                    <Tab label="FILES" value={3} />
                    <Tab label="NOTES" value={4} />
                    <Tab label="CONTACTS" value={5} />
                </Tabs>
                <div className='details_body'>
                    <div style={{ marginRight: '-12px', height: '100%' }}>
                        {this.renderDetails()}
                    </div>
                </div>
            </div>
        )
    }
}

export default DetailsPane
